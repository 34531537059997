import React from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useForm, Controller } from 'react-hook-form';
import SectionHeader from '../SectionHeader';
import { useUpdateExtractProperties } from '../../hooks/playlist';
import { calcNewTc, validateExtractTc, validateInOutPosition } from '../../utils/utils';

const styles = () => ({});

function ExtractDialog({
  onClose,
  onSuccess = () => {},
  onError = () => {},
  playlistId,
  extract,
  open,
}) {
  const [durationError, setDurationError] = React.useState(null);
  const { comment: extractComment } = extract;
  const initialState = {
    comment: extractComment,
    in: 0,
    out: 0,
  };
  const { control, handleSubmit, formState } = useForm({
    defaultValues: initialState,
    mode: 'all',
  });
  const { mutateAsync: updateExtractProperties } = useUpdateExtractProperties();

  const submit = ({ comment, in: tcIn, out: tcOut }) => {
    const newIn = tcIn !== 0 ? calcNewTc(extract.in, tcIn) : undefined;
    const newOut = tcOut !== 0 ? calcNewTc(extract.out, tcOut) : undefined;
    const compareIn = newIn || extract.in;
    const compareOut = newOut || extract.out;
    const promises = [
      ...(newIn ? [validateExtractTc(newIn, extract)] : []),
      ...(newOut ? [validateExtractTc(newOut, extract)] : []),
      validateInOutPosition(compareIn, compareOut),
    ];
    Promise.all(promises)
      .then(() => {
        updateExtractProperties({
          playlistId,
          extract,
          comment: formState.dirtyFields.comment ? comment : undefined,
          in: newIn,
          out: newOut,
        })
          .then(onSuccess)
          .catch(onError);
      })
      .catch((error) => {
        setDurationError(error.message);
      });
  };
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <SectionHeader
          title="Edit extract"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Box mb={4} display="grid" gridAutoRows="min-content" gridGap={24} alignItems="start">
            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <TextField
                  multiline
                  label="Comment"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            <Box display="grid" gridAutoFlow="column" gridGap={24}>
              <Controller
                name="in"
                control={control}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label="Adjust extract start"
                    type="number"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                    }}
                  />
                )}
              />
              <Controller
                name="out"
                control={control}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label="Adjust extract end"
                    type="number"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          {durationError && <Alert severity="error">{durationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary" disabled={!formState.isDirty}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default withStyles(styles)(ExtractDialog);
