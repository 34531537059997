import React from 'react';
import {
  withStyles,
  Tooltip,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { pkg } from 'mediadb-lib';

const { PACKAGE_STATUSES } = pkg;

const BUTTON_OPTS = {
  [PACKAGE_STATUSES.REJECTED]: {
    actions: [],
  },
  [PACKAGE_STATUSES.NEW]: {
    actions: [
      {
        label: 'Approve content',
        newStatus: PACKAGE_STATUSES.IN_LABELING,
      },
      {
        label: 'Reject content',
        newStatus: PACKAGE_STATUSES.REJECTED,
      },
    ],
  },
  [PACKAGE_STATUSES.IN_LABELING]: {
    actions: [
      {
        label: 'Complete labeling',
        newStatus: PACKAGE_STATUSES.LABELED,
      },
    ],
  },
  [PACKAGE_STATUSES.LABELED]: {
    actions: [
      {
        label: 'Approve labeling',
        newStatus: PACKAGE_STATUSES.APPROVED,
      },
      {
        label: 'Disapprove labeling',
        newStatus: PACKAGE_STATUSES.IN_LABELING,
      },
    ],
  },
  [PACKAGE_STATUSES.APPROVED]: {
    actions: [
      {
        label: 'New labeling',
        newStatus: PACKAGE_STATUSES.IN_LABELING,
      },
    ],
  },
};

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {},
});
const ChangeStatusButton = ({
  status = '',
  onChangeStatus = () => null,
  currentUser,
  packageOwner,
  packageLabeler,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    setSelectedIndex(0);
  }, [status]);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const getButtonTooltip = () => {
    if (status === PACKAGE_STATUSES.NEW && packageOwner === currentUser)
      return 'You cannot approve your own package';
    if (status === PACKAGE_STATUSES.LABELED && packageLabeler === currentUser)
      return 'You cannot approve your own labeling';
    return '';
  };
  return (
    <>
      {Object.prototype.hasOwnProperty.call(BUTTON_OPTS, status) && (
        <>
          <Tooltip title={getButtonTooltip()}>
            <ButtonGroup
              disabled={
                (status === PACKAGE_STATUSES.NEW && packageOwner === currentUser) ||
                (status === PACKAGE_STATUSES.LABELED && packageLabeler === currentUser)
              }
              variant="text"
              color="primary"
              ref={anchorRef}
            >
              {BUTTON_OPTS[status].actions.length > 0 && (
                <Button onClick={() => onChangeStatus(BUTTON_OPTS[status].actions[selectedIndex])}>
                  {BUTTON_OPTS[status].actions[selectedIndex]?.label}
                </Button>
              )}
              {BUTTON_OPTS[status].actions.length > 1 && (
                <Button color="primary" onClick={handleToggle}>
                  <ArrowDropDownIcon />
                </Button>
              )}
            </ButtonGroup>
          </Tooltip>
          <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {BUTTON_OPTS[status].actions.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(ChangeStatusButton);
