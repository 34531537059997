import { withDrop } from '@vidispine/vdt-materialui';
import { compose } from '@vidispine/vdt-react';
import { withStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import DataTable from './DataTable';

const dropStyles = (theme) => ({
  isOver: {
    '& .VdtDraggable-isDragging': {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    },
  },
  canDrop: {
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      '&:not(.Mui-selected)': {
        backgroundColor: 'initial',
      },
    },
  },
});

export default compose(withStyles(dropStyles, { name: 'DataTableWithDrop' }), withDrop)(DataTable);
