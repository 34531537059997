import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  useSensorTemplates,
  useIsSensorTemplateUsedByDeviceType,
  useRemoveSensorTemplate,
} from '../../../hooks/sensor';
import { useDeviceTypes } from '../../../hooks/device';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import useConfirmationDialog from '../../../contexts/ConfirmationDialogContext';

const styles = (theme) => ({
  root: {},
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
});

const SensorTemplateRemoval = ({ classes, sensorTemplateId }) => {
  const { showAlert } = useSnackbar();
  const { mutateAsync: removeSensorTemplate } = useRemoveSensorTemplate();
  const { data: sensorTemplates = [] } = useSensorTemplates();
  const sensorTemplate = React.useMemo(
    () => sensorTemplates.find((dt) => dt.uuid === sensorTemplateId),
    [sensorTemplates, sensorTemplateId],
  );
  const { data: deviceTypes = [] } = useDeviceTypes();
  const { data: isUsedByDeviceType, status } = useIsSensorTemplateUsedByDeviceType({
    sensorTemplate,
    deviceTypes,
  });
  const { confirm: confirmDelete } = useConfirmationDialog({
    throwOnReject: false,
  });

  const handleRemoveClick = async () => {
    if (
      await confirmDelete({
        children: (
          <DialogContent>
            <DialogContentText>Permanently delete selected sensor?</DialogContentText>
          </DialogContent>
        ),
      })
    ) {
      try {
        await removeSensorTemplate(sensorTemplate);
        showAlert({ severity: 'success', message: 'Succesfully deleted sensor' });
      } catch (error) {
        showAlert({ severity: 'error', message: 'Could not delete sensor' });
      }
    }
  };

  return (
    <>
      {status === 'success' && (
        <Tooltip title={isUsedByDeviceType ? 'Cannot delete sensors used by a device type' : ''}>
          <span>
            <Button
              className={classes.deleteButton}
              disabled={isUsedByDeviceType}
              onClick={handleRemoveClick}
            >
              Remove
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default withStyles(styles)(SensorTemplateRemoval);
