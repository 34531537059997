import { withDrag } from '@vidispine/vdt-materialui';
import { compose } from '@vidispine/vdt-react';
import { withStyles, TableRow } from '@material-ui/core';

const dragStyles = {
  isDragging: {
    '& > *': {
      opacity: 0,
    },
  },
};

export default compose(withStyles(dragStyles, { name: 'TableRowWithDrag' }), withDrag)(TableRow);
