import {
  Box,
  Button,
  InputAdornment,
  withStyles,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Tooltip,
  Chip,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { metadata, pkg } from 'mediadb-lib';
import { useAddCustomField, useListMetadataFields, useUpdateCustomField } from '../hooks/metadata';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  fieldNamePrefix: {
    margin: 0,
  },
});

const MAX_FIELDNAME_LENGTH = 32;

const DEFAULT_VALUES = {
  fieldName: '',
  mediaDBType: 'String',
  required: false,
  isActiveInBrowse: false,
  isActiveInUpload: false,
  enumValues: [],
};

const EnumValueChip = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  label: {
    marginRight: 'auto',
  },
}))(Chip);

const MetadataFieldEditor = ({
  className,
  classes,
  isEditing = false,
  defaultValues = DEFAULT_VALUES,
  namePrefix = '',
  groupName,
  submitText = 'Add',
  cancelText = 'Cancel',
  onSuccess = () => null,
  onCancel,
  // eslint-disable-next-line no-console
  onError = (error) => console.error(error),
}) => {
  const { mutateAsync: addCustomField, status: addStatus } = useAddCustomField();
  const { mutateAsync: updateCustomField, status: updateStatus } = useUpdateCustomField();
  const { data: existingFieldNames } = useListMetadataFields();

  const { control, watch, reset, handleSubmit, errors, formState } = useForm({
    defaultValues,
    mode: 'all',
  });

  const [usedOptions, setUsedOptions] = React.useState([]);

  React.useEffect(() => {
    if (defaultValues.fieldName && defaultValues.mediaDBType === 'Enum') {
      pkg.getFieldFacets(`${namePrefix}${defaultValues?.fieldName}`).then((result) => {
        setUsedOptions(result);
      });
    }
  }, [defaultValues, namePrefix]);

  const mediaDBType = watch('mediaDBType');

  const submit = (values) => {
    const submitPromise = isEditing
      ? updateCustomField({ groupName, ...values }).then(() => reset(values))
      : addCustomField({ groupName, ...values }).then(() => reset(DEFAULT_VALUES));
    submitPromise.then(onSuccess).catch(onError);
  };

  const enumValuesInputRef = React.useRef();
  const [enumValuesHelperText, setEnumValuesHelperText] = React.useState(null);

  return (
    <form onSubmit={handleSubmit(submit)} className={clsx(className, classes.root)}>
      <Controller
        name="fieldName"
        control={control}
        rules={
          !isEditing && {
            required: 'Required',
            validate: {
              allowedChars: (s) =>
                !/^[A-Za-z0-9_]*$/.test(namePrefix + s)
                  ? 'Allowed characters: A-Z, a-z, 0-9, _'
                  : undefined,
              alreadyExists: (s) =>
                existingFieldNames.includes(namePrefix + s)
                  ? 'Field name already exists'
                  : undefined,
              maxLength: (s) =>
                s.length > MAX_FIELDNAME_LENGTH - namePrefix.length
                  ? `Max ${MAX_FIELDNAME_LENGTH} characters`
                  : undefined,
            },
          }
        }
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <TextField
            className={classes.fieldName}
            disabled={isEditing}
            required
            label="Field Name"
            name="fieldName"
            InputProps={{
              startAdornment: namePrefix && (
                <InputAdornment className={classes.fieldNamePrefix} position="start">
                  {namePrefix}
                </InputAdornment>
              ),
            }}
            error={!!errors.fieldName}
            helperText={errors?.fieldName?.message}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <Controller
        name="mediaDBType"
        control={control}
        rules={{
          required: 'Required',
        }}
        defaultValue=""
        render={({ onChange, value }) => (
          <FormControl>
            <InputLabel>Field type</InputLabel>
            <Select
              disabled={isEditing}
              value={value}
              onChange={({ target: { value: newValue } }) => onChange(newValue)}
            >
              {Object.keys(metadata.MEDIADB_FIELD_TYPES).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {metadata.MEDIADB_FIELD_TYPES.Enum === mediaDBType && (
        <Controller
          name="enumValues"
          control={control}
          defaultValue={[]}
          rules={{
            validate: {
              atleastOne: (v) => (v.length === 0 ? 'Enter at least one value' : undefined),
            },
          }}
          render={({ onChange, onBlur, value }) => {
            return (
              <>
                <TextField
                  label="Values"
                  inputRef={enumValuesInputRef}
                  inputProps={{ maxLength: 50 }}
                  error={!!enumValuesHelperText || !!errors.enumValues}
                  helperText={enumValuesHelperText || errors?.enumValues?.message}
                  onBlur={onBlur}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation();
                      event.preventDefault();
                      const newValue = enumValuesInputRef.current.value;
                      if (newValue === '') {
                        setEnumValuesHelperText('Value is empty');
                        return;
                      }
                      if (value.includes(newValue)) {
                        setEnumValuesHelperText('Value already exists');
                        return;
                      }
                      setEnumValuesHelperText(null);
                      onChange([...value, enumValuesInputRef.current.value]);
                      enumValuesInputRef.current.value = '';
                    }
                  }}
                />
                {value.map((v) => (
                  <Tooltip
                    title={usedOptions.includes(v) ? 'Value in use' : ''}
                    placement="bottom-end"
                  >
                    <div>
                      <EnumValueChip
                        disabled={usedOptions.includes(v)}
                        key={v}
                        label={v}
                        onDelete={() => onChange(value.filter((other) => other !== v))}
                      />
                    </div>
                  </Tooltip>
                ))}
              </>
            );
          }}
        />
      )}
      <Controller
        name="label"
        control={control}
        rules={{
          required: 'Required',
        }}
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <TextField
            label="Field label"
            required
            error={!!errors.name}
            helperText={errors && errors.name && errors.name.message}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      {metadata.MULTI_VALUE_FIELDS.includes(mediaDBType) && (
        <Controller
          name="isMultiValue"
          control={control}
          defaultValue={false}
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Switch
                  disabled={isEditing}
                  color="primary"
                  checked={value}
                  onChange={(_, v) => onChange(v)}
                />
              }
              label="Multiple values"
            />
          )}
        />
      )}
      <Controller
        name="required"
        control={control}
        defaultValue={false}
        render={({ onChange, value }) => (
          <FormControlLabel
            control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
            label="Required"
          />
        )}
      />
      <Box mt={1}>
        <InputLabel>Active</InputLabel>
        <Box>
          <Controller
            name="isActiveInBrowse"
            control={control}
            defaultValue={false}
            render={({ onChange, value }) => (
              <Tooltip title="Visible and editable for uploaded packages">
                <FormControlLabel
                  control={
                    <Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />
                  }
                  label="Browse"
                />
              </Tooltip>
            )}
          />
          <Controller
            name="isActiveInUpload"
            control={control}
            defaultValue={false}
            render={({ onChange, value }) => (
              <Tooltip title="Available in the upload form">
                <FormControlLabel
                  control={
                    <Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />
                  }
                  label="Upload"
                />
              </Tooltip>
            )}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {onCancel && (
          <Button
            className={clsx(classes.cancelButton, 'VdtMetadataFieldEditor-cancelButton')}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          className={clsx(classes.submitButton, 'VdtMetadataFieldEditor-submitButton')}
          disableElevation
          color="primary"
          variant="contained"
          disabled={
            !formState.isValid ||
            !formState.isDirty ||
            addStatus === 'loading' ||
            updateStatus === 'loading'
          }
          type="submit"
          onClick={handleSubmit}
        >
          {submitText}
        </Button>
      </Box>
    </form>
  );
};

export default withStyles(styles, { name: 'VdtMetadataFieldEditor' })(MetadataFieldEditor);
