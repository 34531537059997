import React from 'react';
import Box from '@material-ui/core/Box';
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  withStyles,
  Chip,
  Typography,
} from '@material-ui/core';
import { isEmpty, sortBy as _sortBy } from 'lodash';
import {
  Add as AddIcon,
  Edit as EditIcon,
  VerifiedUser as AccessIcon,
  Person as UserIcon,
} from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import SectionHeader from '../../../components/SectionHeader';
import UserAutocomplete from '../../../components/UserAutocomplete';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import {
  useCreatePackageGroup,
  useUpdatePackageGroup,
  usePackageGroupAccess,
  useAddPackageGroupUser,
  useRemovePackageGroupUser,
} from '../../../hooks/packageGroup';

const PACKAGE_GROUP_TEMPLATE = {
  name: '',
  description: '',
  isActive: true,
};

const styles = (theme) => ({
  sectionRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  chipRoot: {
    marginTop: theme.spacing(1),
    width: '100%',
    backgroundColor: '#eee',
  },
  chipLabel: {
    marginRight: 'auto',
  },
  chipIcon: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  inputGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto',
    gridGap: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
});

function PackageGroupEditor({
  classes,
  collectionId,
  onAlert = () => null,
  onCancel = () => null,
  packageGroups,
  searchArgs,
}) {
  const isEditing = !!collectionId;
  const packageGroup = React.useMemo(() => {
    return packageGroups.length > 0
      ? packageGroups.find(({ id }) => id === collectionId)
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const { data: { accessList = [] } = {} } = usePackageGroupAccess({
    packageGroupId: collectionId,
  });
  const sortedAccessList = _sortBy(accessList, 'username').filter(
    (access) => access.permission === 'READ',
  );

  const { showAlert } = useSnackbar();
  const { mutateAsync: addPackageGroup, isLoading: isLoadingAdd } = useCreatePackageGroup(
    searchArgs,
  );
  const { mutateAsync: updatePackageGroup, isLoading: isLoadingUpdate } = useUpdatePackageGroup();
  const { mutateAsync: addPackageGroupUser } = useAddPackageGroupUser({
    onError: () => showAlert(),
  });
  const { mutateAsync: removePackageGroupUser } = useRemovePackageGroupUser();

  const { control, reset, handleSubmit, errors, formState } = useForm({
    defaultValues: PACKAGE_GROUP_TEMPLATE,
    mode: 'all',
  });

  // only show non-admin operators that are not already added
  const filterOptions = (option) => {
    const { userName, groupList: { group: userGroups = [] } = {} } = option;
    const isOperator = userGroups.some((group) => group.groupName === 'sonova_operator');
    const isAdmin = userGroups.some((group) => group.groupName === 'sonova_admin');
    // Need to check if read??
    const isAdded = accessList.some((access) => access.username === userName);
    return isOperator && !isAdmin && !isAdded;
  };

  const handleAddPackageGroup = (newPackageGroup) => {
    return addPackageGroup(newPackageGroup)
      .then(() => {
        onAlert({ severity: 'success', message: 'Package group added' });
        reset(PACKAGE_GROUP_TEMPLATE);
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Package group could not be added' });
      });
  };
  const handleUpdatePackageGroup = (editedPackageGroup) => {
    const updateWith = {
      id: collectionId,
      name: formState.dirtyFields.name ? editedPackageGroup.name : undefined,
      description: formState.dirtyFields.description ? editedPackageGroup.description : undefined,
      isActive: formState.dirtyFields.isActive ? editedPackageGroup.isActive : undefined,
    };
    return updatePackageGroup(updateWith)
      .then(() => {
        onAlert({ severity: 'success', message: 'Package group updated' });
        reset(editedPackageGroup);
      })
      .catch(({ message }) => {
        onAlert({ severity: 'error', message: `Package group could not be updated: ${message}` });
      });
  };

  const addUser = () => {
    const { userName } = selectedUser;
    addPackageGroupUser({ packageGroupId: collectionId, userName })
      .then(() => {
        onAlert({ severity: 'success', message: 'User added' });
        setSelectedUser(null);
      })
      .catch(({ message }) => {
        onAlert({ severity: 'error', message: `User could not be added: ${message}` });
      });
  };
  const removeUser = (userName) => {
    removePackageGroupUser({ packageGroupId: collectionId, userName })
      .then(() => {
        onAlert({ severity: 'success', message: 'User removed' });
        setSelectedUser(null);
      })
      .catch(({ message }) => {
        onAlert({ severity: 'error', message: `User could not be removed: ${message}` });
      });
  };

  const submit = (args) => {
    return isEditing ? handleUpdatePackageGroup(args) : handleAddPackageGroup(args);
  };

  React.useEffect(() => {
    reset(packageGroup || PACKAGE_GROUP_TEMPLATE);
  }, [reset, packageGroup]);

  return (
    <>
      <SectionHeader
        Icon={isEditing ? EditIcon : AddIcon}
        title={isEditing ? 'Edit Package Group' : 'New Package Group'}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      <form onSubmit={handleSubmit(submit)} className={classes.sectionRoot}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: 'Required',
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Name"
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextField label="Description" onChange={onChange} onBlur={onBlur} value={value} />
          )}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          {isEditing && <Button onClick={onCancel}>cancel</Button>}
          <Button
            disableElevation
            disabled={
              !formState.isValid ||
              isEmpty(formState.dirtyFields) ||
              isLoadingAdd ||
              isLoadingUpdate
            }
            variant="contained"
            color="primary"
            type="submit"
          >
            {isEditing ? 'Save' : 'Add Package Group'}
          </Button>
        </Box>
      </form>
      {isEditing && (
        <>
          <SectionHeader
            customClass={classes.marginTop}
            Icon={AccessIcon}
            title="Package Group Access"
            titleTypographyProps={{
              variant: 'h4',
            }}
          />
          <div className={classes.sectionRoot}>
            <div className={classes.inputGroup}>
              <UserAutocomplete
                value={selectedUser}
                label="Add user"
                onChange={setSelectedUser}
                transformOptions={(options) => options.filter(filterOptions)}
                getOptionSelected={(v, o) => v.userName === o.userName}
              />
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!selectedUser}
                  onClick={addUser}
                >
                  Add
                </Button>
              </div>
            </div>
            <div>
              {sortedAccessList.length ? (
                sortedAccessList.map((access) => (
                  <Chip
                    key={access.username}
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel,
                      icon: classes.chipIcon,
                    }}
                    icon={
                      <span>
                        <UserIcon />
                      </span>
                    }
                    label={access.username}
                    onDelete={() => removeUser(access.username)}
                  />
                ))
              ) : (
                <Typography variant="overline">No users added</Typography>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default withStyles(styles)(PackageGroupEditor);
