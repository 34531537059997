import { debounce as _debounce } from 'lodash';
import React from 'react';

export default function useDebounce(value, ms, opts = {}) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetDebouncedValue = React.useCallback(_debounce(setDebouncedValue, ms, opts), [
    setDebouncedValue,
    ms,
  ]);
  React.useEffect(() => {
    debouncedSetDebouncedValue(value);
  }, [value, debouncedSetDebouncedValue]);

  return debouncedValue;
}
