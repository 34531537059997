import React from 'react';
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { History as HistoryIcon } from '@material-ui/icons';
import SectionHeader from '../components/SectionHeader';
import MetadataChangeLog from '../vdt/MetadataChangeLog';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    '& .VdtMetadataFormList-root': {
      margin: 0,
      padding: 0,
      '& .VdtMetadataFormFieldListItem-root': {
        padding: 0,
        '& .VdtMetadataFormFieldListItem-field': {
          margin: theme.spacing(1),
          '& .MuiInputBase-input': {
            fontSize: '1rem',
            fontWeight: 400,
          },
        },
      },
    },
    '& .MuiDialog-container': {
      alignItems: 'baseline',
    },
  },
});

function MetadataChangeLogDialog({
  classes,
  packageId,
  labelItemId,
  open,
  onClose,
  onRestoreSuccess = () => null,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const handleChangeTab = (_, index) => {
    setSelectedTabIndex(index);
  };
  return (
    <Dialog className={classes.root} maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={HistoryIcon}
          title="Change log"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 100,
            backgroundColor: 'white',
          }}
        >
          <Tab label="Package" index={0} />
          <Tab label="Labels" index={1} disabled={!labelItemId} />
        </Tabs>
        <Box style={{ minHeight: 450 }}>
          {selectedTabIndex === 0 && (
            <MetadataChangeLog
              entityId={packageId}
              entityType="collection"
              onRestoreSuccess={onRestoreSuccess}
            />
          )}
          {selectedTabIndex === 1 && (
            <MetadataChangeLog
              entityId={labelItemId}
              entityType="item"
              onRestoreSuccess={onRestoreSuccess}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(MetadataChangeLogDialog);
