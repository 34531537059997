import React from 'react';

import { useSearchUser } from '@vidispine/vdt-react';

import {
  withStyles,
  Paper,
  Box,
  Container,
  IconButton,
  Tooltip,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';

import clsx from 'clsx';

import {
  Settings as SettingsIcon,
  Edit as EditIcon,
  Person as PersonIcon,
  VerifiedUser as AccessIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { Pagination, SearchInput } from '@vidispine/vdt-materialui';

import { parseUser } from '../../../utils/user';
import UserAutocomplete from '../../../components/UserAutocomplete';

import SectionHeader from '../../../components/SectionHeader';
import DataTable from '../../../components/DataTable';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import useUsers from '../../../hooks/useUsers';
import UserEditor from './UserEditor';
import UserAccess from './UserAccess';
import { useDeleteUser } from '../../../hooks/user';

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, 1fr) auto',
    gridGap: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: `
    calc(100vh - 70px 
      - ${theme.spacing(8)}px 
      - ${theme.spacing(6)}px 
      - 48px 
      - 44px
      - ${theme.spacing(1)}px 
      - 54px)
      `,
    overflow: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  userSidePanel: {
    width: '400px',
    maxWidth: '400px',
  },
  searchInputWrapper: {
    '& form:focus-within': {
      '& .MuiButton-root': {
        borderColor: theme.palette.primary.main,
      },
      '& .MuiInputBase-root': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiButton-root': {
      backgroundColor: theme.palette.background.paper,
      //   height: '44px',
      borderLeft: 'none',
    },
    '& .MuiInputBase-root': {
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
      height: '44px',
      borderRadius: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: theme.spacing(1),
    },
  },
});

function DeleteUserDialog({ open, onClose, selectedUser, onSubmit, isLoading }) {
  const [transferUser, setTransferUser] = React.useState('');
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={PersonIcon}
          title="Delete user"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Select a user to transfer package ownership to</DialogContentText>
        <DialogContentText component="div">
          <UserAutocomplete
            label="User"
            onChange={(u) => setTransferUser(u?.userName || '')}
            transformOptions={(options) =>
              options.filter(
                (u) => ![selectedUser.userName, 'admin', 'vidinetviewer'].includes(u.userName),
              )
            }
          />
        </DialogContentText>
        <DialogContentText color="error">
          Deleting a user is irreversible and will delete the user&apos;s playlists from the system.
        </DialogContentText>
        {transferUser && selectedUser && (
          <DialogContentText>
            This action will delete the user <b>{selectedUser?.userName}</b> and transfer ownership
            of their packages to the user <b>{transferUser}</b>. It could take a few minutes before
            the change is applied.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() =>
            onSubmit({ userName: selectedUser.userName, transferAccess: transferUser })
          }
          variant="contained"
          disableElevation
          color="primary"
          disabled={!transferUser || !selectedUser || isLoading}
          startIcon={isLoading ? <CircularProgress size={16} /> : undefined}
        >
          Delete user
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const TABLE_COLUMNS = [
  {
    name: 'Username',
    key: 'userName',
  },
  {
    name: 'Real name',
    key: 'realName',
  },
  {
    name: 'Roles',
    key: 'roles',
  },
  {
    name: 'Status',
    key: 'disabled',
  },
];

const ROWS_PER_PAGE = 20;

function Users({ classes }) {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const { showAlert } = useSnackbar();
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = React.useState(false);

  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeQuery,
    onSearchUser,
    userListDocument: { user: userList = [], hits } = {},
  } = useSearchUser({ rowsPerPage: ROWS_PER_PAGE });

  const users = React.useMemo(() => userList.map((user) => parseUser(user)), [userList]);

  const searchUsers = (query) => {
    onChangeQuery('username', query);
  };

  const { updateUser } = useUsers();

  const searchInputRef = React.useRef(null);

  const handleClickRow = (user, i) => {
    if (!user.disableEdit)
      setSelectedUser(
        selectedUser && selectedUser.userName === users[i].userName ? undefined : users[i],
      );
  };

  const handleUpdateUser = (args) =>
    updateUser(selectedUser, args)
      .then(({ data }) => {
        setSelectedUser(parseUser(data));
        showAlert({ severity: 'success', message: 'Successfully updated user' });
      })
      .catch(() => showAlert({ severity: 'error', message: 'Failed to update user' }));

  const onSubmit = (values) =>
    handleUpdateUser(values)
      .then(() => {
        onSearchUser();
        return Promise.resolve('success');
      })
      .catch(() => Promise.resolve('failed'));

  const handleCloseDeleteUserDialog = (e, reason) =>
    reason !== 'backdropClick' && setOpenDeleteUserDialog(false);

  const { mutate: deleteUser, isLoading: isLoadingDeleteUser } = useDeleteUser({
    onSuccess: () => {
      showAlert({
        severity: 'success',
        message:
          'Successfully deleted user. It could take a few minutes before the change is applied.',
      });
      setSelectedUser(null);
      onSearchUser();
    },
    onError: () => showAlert({ severity: 'error', message: 'Failed to delete user' }),
    onSettled: () => handleCloseDeleteUserDialog(),
  });

  return (
    <>
      <Container className={classes.root} maxWidth={false}>
        <Paper className="adminContainer" variant="outlined">
          <SectionHeader
            Icon={PersonIcon}
            title="Users"
            titleTypographyProps={{
              variant: 'h4',
            }}
          />
          <Box className={classes.searchInputWrapper} pb={1}>
            <SearchInput
              onSubmit={(searchText) => searchUsers(searchText)}
              searchPlaceholder="Search username"
              inputRef={searchInputRef}
            />
          </Box>
          <Box className={classes.tableContainer} value="users">
            <DataTable
              columns={TABLE_COLUMNS}
              rows={
                users
                  ? users.map((user) => ({
                      ...user,
                      name: user.userName,
                      disabled: user.disabled ? 'Disabled' : 'Active',
                      disableEdit: user.userName === 'vidinetviewer' || user.userName === 'admin',
                      userName:
                        user.userName === 'vidinetviewer' || user.userName === 'admin' ? (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <Tooltip title="System user (cannot be edited)">
                              <SettingsIcon fontSize="small" />
                            </Tooltip>
                            {`${user.userName}`}
                          </span>
                        ) : (
                          user.userName
                        ),
                    }))
                  : []
              }
              onClickRow={handleClickRow}
              selectedRowIndices={
                selectedUser ? [users.findIndex((u) => u.userName === selectedUser.userName)] : [-1]
              }
            />
          </Box>
          <Pagination
            count={hits}
            page={page}
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
          />
        </Paper>
        {selectedUser && (
          <Paper
            className={clsx('adminSidePanel', 'adminContainer', classes.userSidePanel)}
            variant="outlined"
          >
            <SectionHeader
              Icon={EditIcon}
              title="Edit User"
              Actions={
                selectedUser.disabled &&
                (() => (
                  <Tooltip title="Delete user">
                    <span>
                      <IconButton
                        onClick={() => setOpenDeleteUserDialog(true)}
                        disabled={isLoadingDeleteUser}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ))
              }
              titleTypographyProps={{
                variant: 'h4',
              }}
            />
            <UserEditor
              user={selectedUser}
              onSubmit={onSubmit}
              onCancel={() => setSelectedUser()}
              submitText="Apply Changes"
            />
            <SectionHeader
              customClass={classes.marginTop}
              Icon={AccessIcon}
              title="User Access"
              titleTypographyProps={{
                variant: 'h4',
              }}
            />
            <UserAccess user={selectedUser} />
          </Paper>
        )}
      </Container>
      {openDeleteUserDialog && selectedUser && (
        <DeleteUserDialog
          open={openDeleteUserDialog}
          onClose={handleCloseDeleteUserDialog}
          onSubmit={deleteUser}
          isLoading={isLoadingDeleteUser}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
}

export default withStyles(styles)(Users);
