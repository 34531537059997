import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import { initializeApi as initializeLibApi } from 'mediadb-lib';
import { QueryClient, QueryClientProvider } from 'react-query';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DragAndDropProvider } from '@vidispine/vdt-materialui';
import { PlaylistContextProvider } from './contexts/PlaylistContext';

import { VIDISPINE_URL, LOGIN_EXPIRES_SECONDS, ADMIN_GROUPS } from './consts/app';
import onGetToken from './utils/onGetToken';
import Header from './components/Header';
import SideNav from './components/SideNav/SideNav';

import Login from './containers/Login';
import Upload from './containers/Upload';
import Search from './containers/Search';
import Admin from './containers/Admin';
import Package from './containers/Package';
import { AuthContext, AuthProvider } from './vdt/AuthProvider';
import { SnackBarContextProvider } from './contexts/SnackbarContext';
import { ConfirmationDialogContextProvider } from './contexts/ConfirmationDialogContext';
import PageError from './components/PageError';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function AppComponent() {
  const { userName, user, onLogout } = React.useContext(AuthContext);

  return user ? (
    <Box height="100%" minHeight="100vh">
      <Header userName={userName} onLogout={onLogout} showNav={false} />
      <Box
        display="flex"
        style={{
          height: 'calc(100vh - 70px)',
          overflow: 'hidden',
        }}
      >
        <SideNav />
        <Box py={4} px={2} style={{ width: '100%' }}>
          <Switch>
            {!userName && <Redirect exact from="*" push to="/login" />}
            <Route exact path="/search/:page(extracts|packages|exported-playlists)">
              <PlaylistContextProvider>
                <Search />
              </PlaylistContextProvider>
            </Route>
            <Route exact path="/upload">
              <Upload />
            </Route>
            <Route exact path="/package/:collectionId(VX\-\d+)">
              <Package />
            </Route>
            <Route path="/admin">
              <DragAndDropProvider>
                <Admin />
              </DragAndDropProvider>
            </Route>
            <Route path="/package/*">
              <PageError
                title="Package not found"
                subtitle={"Looks like you're trying to reach a package that does not exist"}
              />
            </Route>
            <Redirect exact from="*" push to="/search/extracts" />
          </Switch>
        </Box>
      </Box>
    </Box>
  ) : null;
}

export default function App({
  basename,
  cookieOptions = {
    maxAge: LOGIN_EXPIRES_SECONDS,
    path: basename,
  },
}) {
  const [loginError, setLoginError] = React.useState();
  const handleLoginError = ({ message }) => setLoginError(message);
  const resetLoginError = () => setLoginError(undefined);
  const serverUrl = VIDISPINE_URL;

  return (
    <SnackBarContextProvider>
      <ConfirmationDialogContextProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider
            adminGroups={ADMIN_GROUPS}
            cookieOptions={cookieOptions}
            onGetToken={(values) => {
              resetLoginError();
              const token = onGetToken(values);
              initializeLibApi(serverUrl, token);
              return token;
            }}
            onError={handleLoginError}
            LoginComponent={Login}
            LoginProps={{ error: loginError, serverUrl }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AppComponent />
            </MuiPickersUtilsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ConfirmationDialogContextProvider>
    </SnackBarContextProvider>
  );
}
