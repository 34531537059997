import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { device } from 'mediadb-lib';
import Users from './components/Users';
import PackageGroups from './components/PackageGroups';
import AuditLog from './components/AuditLog';
import Metadata from './components/Metadata';
import Devices from './components/Devices';
import LabelTrackGroups from './components/LabelTrackGroups';
import { AuthContext } from '../../vdt/AuthProvider';
import PageError from '../../components/PageError';

const { DEVICE_CATEGORY } = device;

export default function Admin() {
  const { path } = useRouteMatch();
  const { isAdmin } = React.useContext(AuthContext);

  return isAdmin ? (
    <Switch>
      <Route exact path={`${path}/package-groups`}>
        <PackageGroups />
      </Route>
      <Route exact path={`${path}/users`}>
        <Users />
      </Route>
      <Route exact path={`${path}/log`}>
        <AuditLog />
      </Route>
      <Route exact path={`${path}/metadata`}>
        <Metadata />
      </Route>
      <Route exact path={`${path}/devices`}>
        <Devices key="devices" category={DEVICE_CATEGORY.DEVICE} titleText="Devices" />
      </Route>
      <Route exact path={`${path}/sources`}>
        <Devices key="sources" category={DEVICE_CATEGORY.SOURCE} titleText="Sources" />
      </Route>
      <Route exact path={`${path}/label-track-groups`}>
        <LabelTrackGroups />
      </Route>
      <Redirect exact from={path} push to={`${path}/users`} />
      <Redirect exact from="*" push to="/search/extracts" />
    </Switch>
  ) : (
    <PageError title="Not Authorized" subtitle="You're not authorized to view this page" />
  );
}
