"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = require("lodash");

var _utils = require("../utils");

var _consts = require("./consts");

var parseLabelTrackGroups = function parseLabelTrackGroups(doc) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$withReference = _ref.withReference,
      withReference = _ref$withReference === void 0 ? false : _ref$withReference;

  var infTimespan = (0, _lodash.get)(doc, 'timespan.0');
  var parsed = (0, _utils.parseTimespanByDef)({
    group: [_consts.SONOVA_LABEL_TRACK_GROUPS, _consts.SONOVA_LABEL_TRACK_GROUP],
    list: true,
    uuid: 'uuid',
    properties: {
      type: {
        field: _consts.ACCURATE_VIDEO_TRACK_GROUP_FIELDS.TYPE,
        fieldUuid: withReference ? 'reference' : undefined
      },
      name: {
        field: _consts.ACCURATE_VIDEO_TRACK_GROUP_FIELDS.NAME,
        fieldUuid: withReference ? 'reference' : undefined
      },
      isActive: {
        type: _utils.boolOpposite,
        field: _consts.ACCURATE_VIDEO_TRACK_GROUP_FIELDS.DELETED,
        fieldUuid: withReference ? 'reference' : undefined
      },
      order: {
        type: _utils["int"],
        field: _consts.ACCURATE_VIDEO_TRACK_GROUP_FIELDS.ORDER,
        fieldUuid: withReference ? 'reference' : undefined
      },
      tracks: {
        group: _consts.SONOVA_LABEL_TRACK,
        list: true,
        uuid: 'uuid',
        properties: {
          name: {
            field: _consts.ACCURATE_VIDEO_TRACK_FIELDS.NAME,
            fieldUuid: withReference ? 'reference' : undefined
          },
          isRequired: {
            type: _utils.bool,
            field: _consts.ACCURATE_VIDEO_TRACK_FIELDS.REQUIRED,
            fieldUuid: withReference ? 'reference' : undefined
          },
          isActive: {
            type: _utils.boolOpposite,
            field: _consts.ACCURATE_VIDEO_TRACK_FIELDS.DELETED,
            fieldUuid: withReference ? 'reference' : undefined
          },
          order: {
            type: _utils["int"],
            field: _consts.ACCURATE_VIDEO_TRACK_FIELDS.ORDER,
            fieldUuid: withReference ? 'reference' : undefined
          },
          types: {
            field: _consts.ACCURATE_VIDEO_TRACK_FIELDS.TYPES,
            list: true,
            fieldUuid: withReference ? 'reference' : undefined
          },
          inactiveTypes: {
            field: _consts.ACCURATE_VIDEO_TRACK_FIELDS.INACTIVE_TYPES,
            list: true,
            fieldUuid: withReference ? 'reference' : undefined
          }
        }
      }
    }
  }, infTimespan);
  parsed.forEach(function (g) {
    g.tracks.sort(function (a, b) {
      return a.order - b.order;
    });
  });
  return parsed.sort(function (a, b) {
    return a.order - b.order;
  });
};

var _default = parseLabelTrackGroups;
exports["default"] = _default;