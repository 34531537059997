import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import StepButton from '@material-ui/core/StepButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

import { useUploadFiles } from '@vidispine/vdt-react';
import { partition } from 'lodash';
import { Alert } from '@material-ui/lab';
import { pkg } from 'mediadb-lib';
import AddFiles from './components/AddFiles';
import AddInterlocutors from './components/AddInterlocutors';
import AddAdditionalSources from './components/AddAdditionalSources';
import UploadMetadata from './components/UploadMetadata';
import UploadSummary from './components/UploadSummary';
import NavigationBlocker from '../../components/NavigationBlocker';
import useMediaInfo, { getGeneralTrack, getAudioTrack } from './useMediaInfo';
import { LinearProgressWithLabel } from './components/FileTree';

import uploadPackageFiles from './uploadPackageFiles';
import { inferFileMediaType } from '../../utils/utils';
import uuidv4 from '../../utils/uuidv4';

const { PACKAGE_STATUSES } = pkg;

const styles = (theme) => ({
  root: {},
  paper: {
    height: `calc(100vh - 70px - ${theme.spacing(8)}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stepper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(
      4,
    )}px`,
    height: '136px',
  },
  stepContent: {
    flexGrow: '1',
    padding: theme.spacing(4),
    paddingTop: '0',
    paddingBottom: '0',
    position: 'relative',
    maxHeight: `calc(100vh - 70px - 136px - 92px - ${theme.spacing(9)}px)`,
    overflowY: 'auto',
  },
  buttonBar: {
    alignSelf: 'flex-end',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(
      4,
    )}px`,
  },
  nextButton: {
    width: '88px',
  },
});

const steps = ['Add Files', 'Add Persons', 'Additional Sources', 'Package Details', 'Start Upload'];

const removeInvalidFilesFromInterlocutors = (prevInterlocutors, packageFiles) => {
  const newInterlocutors = prevInterlocutors.map((interlocutor) => {
    interlocutor.devices.forEach((device) => {
      device.channels.forEach((channel) => {
        if (!packageFiles.includes(channel.value)) {
          Object.assign(channel, { value: '' });
        }
      });
    });
    return interlocutor;
  });
  return newInterlocutors;
};
const removeInvalidFilesFromAdditionalSources = (prevAdditionalSources, packageFiles) => {
  const newAdditionalSources = prevAdditionalSources.map((device) => {
    device.channels.forEach((channel) => {
      if (!packageFiles.includes(channel.value)) {
        Object.assign(channel, { value: '' });
      }
    });
    return device;
  });
  return newAdditionalSources;
};

const getPackageFilesDuration = (filesMediaInfo, files) => {
  const packageFiles = files.filter(({ metadata }) => !metadata.generic && !metadata.timeSeries);
  const durations = packageFiles.map(({ file }) => {
    try {
      const mediaInfo = filesMediaInfo[file.path];
      return getGeneralTrack(mediaInfo.media.track).Duration;
    } catch (e) {
      return null;
    }
  });
  if (durations.length === 0) {
    return null;
  }

  const durationNumbers = durations.map((d) => Number(d));
  const durationMax = Math.max(...durationNumbers);
  const durationMin = Math.min(...durationNumbers);
  if (durationMax - durationMin < 1.0) {
    return durationMax;
  }
  return null;
};

const getPackageFilesAudioSamplingRate = (filesMediaInfo, files) => {
  const packageFiles = files.filter(({ metadata }) => !metadata.generic && !metadata.timeSeries);
  const packageFilesAudioSamplingRate = [];
  packageFiles.forEach(({ file }) => {
    try {
      const { path } = file;
      const mediaInfo = filesMediaInfo[path];
      if (!mediaInfo) {
        // Haven't loaded any mediaInfo yet
        return;
      }
      const { media: { track } = {} } = mediaInfo;
      const { SamplingRate } = getAudioTrack(track);
      const existing = packageFilesAudioSamplingRate.find(
        ({ value } = {}) => value === SamplingRate,
      );
      if (!existing) {
        packageFilesAudioSamplingRate.push({ value: SamplingRate, filenames: [path] });
      } else {
        existing.filenames.push(path);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not retrieve audio sampling rate for: ${file.path}`);
    }
  }, {});
  return packageFilesAudioSamplingRate;
};

const customMetadataToArray = (customMetadata = {}) =>
  Object.entries(customMetadata).map(([key, value]) => ({
    field: key,
    value,
  }));

const deviceToPackageDescriptionDevice = (device, previewDeviceId, audioSamplingRateFilename) => {
  const channelToPackageDescriptionChannel = ({ name, value: filename, playbackPosition }) => {
    return {
      name,
      filename,
      playbackPosition,
      isAudioSamplingRateSource: audioSamplingRateFilename === filename,
    };
  };
  const sensorToPackageDescriptionSensor = (sensor) => {
    const {
      sensorTemplate: { uuid },
      position,
      channels,
      customMetadata,
    } = sensor;
    return {
      sensorTemplateId: uuid,
      position,
      channels: channels.map(channelToPackageDescriptionChannel),
      customMetadata: customMetadataToArray(customMetadata),
    };
  };

  const isPreview = device.id === previewDeviceId;

  return {
    deviceTemplateId: device.deviceTemplate.uuid,
    position: device.position,
    channels: device.channels.map(channelToPackageDescriptionChannel),
    sensors: device.sensors.map(sensorToPackageDescriptionSensor),
    customMetadata: customMetadataToArray(device.customMetadata),
    ...(isPreview && { isPreview }),
  };
};

const interlocutorToPackageDescriptionInterlocutor = (
  interlocutor,
  previewDeviceId,
  audioSamplingRateFilename,
) => ({
  ...interlocutor,
  customMetadata: customMetadataToArray(interlocutor.customMetadata),
  devices: interlocutor.devices.map((d) =>
    deviceToPackageDescriptionDevice(d, previewDeviceId, audioSamplingRateFilename),
  ),
});

const Upload = ({ classes }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [interlocutors, setInterlocutors] = React.useState([]);
  const [additionalSources, setAdditionalSources] = React.useState([]);
  const [packageMetadata, setPackageMetadata] = React.useState({
    customMetadata: {},
  });
  const [packageMetadataValid, setPackageMetadataValid] = React.useState(false);
  const [usedFiles, setUsedFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const currentUploadUuid = React.useRef(null);
  const [packageCollectionId, setPackageCollectionId] = React.useState();
  const [uploadStatus, setUploadStatus] = React.useState();
  const [uploadError, setUploadError] = React.useState();
  const [validInterlocutors, setValidInterlocutors] = React.useState();
  const [validSourceDevices, setValidSourceDevices] = React.useState();
  const isFinalStep = activeStep === steps.length - 1;
  const isSuccessfulUpload = !!packageCollectionId;
  const hasUploadError = !!uploadError;

  const packageGroupId = React.useMemo(() => {
    const { packageGroupId: pkgGroupId } = packageMetadata;
    return pkgGroupId;
  }, [packageMetadata]);

  const uploadProps = useUploadFiles({});

  const { files } = uploadProps;

  const [progressPercent, setProgressPercent] = React.useState();

  const { mediaInfo, isLoading: isLoadingMediaInfo, loadMediaInfo } = useMediaInfo(files);
  const packageFilesDuration = React.useMemo(() => getPackageFilesDuration(mediaInfo, files), [
    mediaInfo,
    files,
  ]);
  const packageFilesAudioSamplingRate = React.useMemo(
    () => getPackageFilesAudioSamplingRate(mediaInfo, files),
    [files, mediaInfo],
  );
  React.useEffect(() => {
    if (packageFilesDuration !== null) {
      setPackageMetadata((prev) => ({
        ...prev,
        duration: String(packageFilesDuration),
      }));
    }
  }, [packageFilesDuration]);
  const packageFilesDurationValid = packageFilesDuration !== null;

  React.useEffect(() => {
    const fileNames = files.map((f) => f.file.name);
    if (usedFiles.some((name) => !fileNames.includes(name))) {
      setUsedFiles((prev) => prev.filter((name) => fileNames.includes(name)));
      setValidInterlocutors(false);
      setValidSourceDevices(false);
    }
  }, [usedFiles, files]);

  const disableNext = (step) => {
    switch (step) {
      case 0: {
        return !(packageFilesDurationValid && files.length && !isLoadingMediaInfo);
      }
      case 1: {
        return !validInterlocutors;
      }
      case 2: {
        return !validSourceDevices;
      }
      case 3: {
        return !packageMetadataValid;
      }
      default: {
        return false;
      }
    }
  };
  const previousUnfinished = (step) => {
    let hasUnfinishedPrevSteps = false;
    for (let i = 0; i < step; i += 1) {
      if (disableNext(i)) {
        hasUnfinishedPrevSteps = true;
      }
    }
    return hasUnfinishedPrevSteps;
  };

  const nextDisabledTooltip = () => {
    switch (activeStep) {
      case 0: {
        return !files.length
          ? 'Add files to upload'
          : 'All non-generic files need to have the same duration';
      }
      case 1:
      case 2:
      case 3:
        return 'Please fill in the required fields';
      default: {
        return '';
      }
    }
  };

  const handlePrevStep = () => {
    if (isFinalStep) {
      setUploadError(undefined);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  React.useEffect(() => {
    const [pkgFiles, genericFiles] = partition(files, ({ metadata }) => !metadata.generic);
    const [pkgFileNames, genericFileNames] = [pkgFiles, genericFiles].map((partitions) =>
      partitions.map(({ file }) => file.name),
    );
    setInterlocutors((prev) =>
      removeInvalidFilesFromInterlocutors(prev, pkgFileNames, genericFileNames),
    );
    setAdditionalSources((prev) => removeInvalidFilesFromAdditionalSources(prev, pkgFileNames));
  }, [files]);

  const allDevices = [...interlocutors.flatMap(({ devices }) => devices), ...additionalSources];

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <AddFiles
            uploadProps={uploadProps}
            onAddedFiles={loadMediaInfo}
            isLoadingMediaInfo={isLoadingMediaInfo}
            mediaInfo={mediaInfo}
            isValidMediaInfo={packageFilesDurationValid}
          />
        );
      case 1:
        return (
          <AddInterlocutors
            uploadProps={uploadProps}
            interlocutors={interlocutors}
            onChange={setInterlocutors}
            onChangeUsedFiles={setUsedFiles}
            onChangeValid={setValidInterlocutors}
            usedFiles={usedFiles}
          />
        );
      case 2:
        return (
          <AddAdditionalSources
            uploadProps={uploadProps}
            additionalSources={additionalSources}
            onChange={setAdditionalSources}
            onChangeUsedFiles={setUsedFiles}
            onChangeValid={setValidSourceDevices}
            usedFiles={usedFiles}
          />
        );
      case 3:
        return (
          <UploadMetadata
            uploadProps={uploadProps}
            onChange={setPackageMetadata}
            onChangeValid={setPackageMetadataValid}
            value={packageMetadata}
            devices={allDevices}
            packageFilesAudioSamplingRate={packageFilesAudioSamplingRate}
          />
        );
      case 4:
        return (
          <UploadSummary
            interlocutors={interlocutors}
            additionalSources={additionalSources}
            previewDevice={allDevices.find((d) => d.id === packageMetadata.previewDeviceId)}
            packageMetadata={packageMetadata}
            files={files}
            usedFiles={usedFiles}
            mediaInfo={mediaInfo}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const [onCancelUpload, setOnCancelUpload] = React.useState(null);

  const resetUpload = () => {
    setProgressPercent(undefined);
    setIsUploading(false);
    currentUploadUuid.current = null;
    setOnCancelUpload(null);
  };

  const handleUpload = async () => {
    if (uploadError) {
      setUploadError(undefined);
    }
    setIsUploading(true);
    const newUploadUuid = uuidv4();
    currentUploadUuid.current = newUploadUuid;
    setUploadStatus('Creating package');

    let newPackageId = null;
    try {
      const {
        previewDeviceId,
        audioSamplingRate: { filenames: [audioSamplingRateFilename] = [] } = {},
      } = packageMetadata;

      const packageDescription = {
        files: files.map(({ file }) => ({ name: file.name, mediaType: inferFileMediaType(file) })),
        interlocutors: interlocutors.map((i) =>
          interlocutorToPackageDescriptionInterlocutor(
            i,
            previewDeviceId,
            audioSamplingRateFilename,
          ),
        ),
        additionalSources: additionalSources.map((d) =>
          deviceToPackageDescriptionDevice(d, previewDeviceId, audioSamplingRateFilename),
        ),
        metadata: {
          ...packageMetadata,
          customMetadata: customMetadataToArray(packageMetadata.customMetadata),
        },
        packageGroupId,
      };
      const { packageId, fileUploadDescriptions } = await pkg.createPackage(packageDescription);
      setUploadStatus('Uploading package files');
      newPackageId = packageId;
      const { cancelUpload, uploadPromise } = uploadPackageFiles({
        fileUploadDescriptions,
        files,
        onProgress: (percent) => {
          if (currentUploadUuid.current === newUploadUuid) {
            setProgressPercent(percent);
          }
        },
      });
      setOnCancelUpload(() => () => {
        pkg
          .updatePackageStatus({ packageId: newPackageId, status: PACKAGE_STATUSES.CANCELLED })
          .catch(() => {});
        return cancelUpload();
      });
      await uploadPromise;
      setUploadStatus('Package upload complete!');
      setPackageCollectionId(packageId);
      setIsUploading(false);
    } catch (error) {
      if (newPackageId) {
        // try to set package status to error if a package was created
        pkg
          .updatePackageStatus({ packageId: newPackageId, status: PACKAGE_STATUSES.ERROR })
          .catch(() => {});
      }
      resetUpload();
      // eslint-disable-next-line no-console
      console.error(error);
      setUploadError(error);
    }
  };

  const currentUploadStatus = (() => {
    if (!isFinalStep) return undefined;
    if (isUploading) {
      return 'pending';
    }
    if (isSuccessfulUpload) {
      return 'finished';
    }
    return 'inactive';
  })();

  return (
    <Container className={classes.root} maxWidth="md">
      <Paper className={classes.paper} variant="outlined">
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel nonLinear>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                className={classes.stepButton}
                disabled={previousUnfinished(index) || isUploading || isSuccessfulUpload}
                onClick={() => handleStepClick(index)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box className={classes.stepContent}>{getStepContent()}</Box>
        {!hasUploadError && isUploading && (
          <Box m={3}>
            <LinearProgressWithLabel value={progressPercent} />
          </Box>
        )}
        <Box>
          {isUploading && <Alert severity="info">{uploadStatus}</Alert>}
          {isSuccessfulUpload && <Alert severity="success">{uploadStatus}</Alert>}
          {hasUploadError && <Alert severity="error">Failed to upload package</Alert>}
        </Box>
        <Box className={classes.buttonBar}>
          {!isSuccessfulUpload && (
            <Button
              disabled={activeStep === 0 || isUploading}
              onClick={handlePrevStep}
              className={classes.backButton}
            >
              Back
            </Button>
          )}
          {!isFinalStep && (
            <Tooltip arrow title={disableNext(activeStep) ? nextDisabledTooltip() : ''}>
              <span>
                <Button
                  className={classes.nextButton}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleNextStep}
                  disabled={disableNext(activeStep)}
                >
                  Next
                </Button>
              </span>
            </Tooltip>
          )}
          {
            {
              pending: (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    onCancelUpload();
                    resetUpload();
                  }}
                  disabled={!onCancelUpload}
                >
                  Cancel upload
                </Button>
              ),
              inactive: (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={isUploading}
                  onClick={handleUpload}
                >
                  {uploadError ? 'Retry Upload' : 'Start Upload'}
                </Button>
              ),
              finished: (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => window.location.reload()}
                >
                  New Upload
                </Button>
              ),
            }[currentUploadStatus]
          }
        </Box>
      </Paper>
      <NavigationBlocker
        navigationBlocked={isUploading}
        message="Upload will stop if you leave this page, are you sure you want to leave?"
      />
    </Container>
  );
};

export default withStyles(styles)(Upload);
