import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  inputGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto',
    gridGap: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const SelectButtonGroup = ({
  classes,
  value = '',
  onChange = () => null,
  onSubmit,
  buttonText = 'Add',
  placeholder = 'Select one',
  selectProps,
  buttonProps,
  options,
  isDisabled = () => false,
  emptyValueError = 'Required',
  getOptionValue = (o) => o.name,
  renderOption = (o) => o.name,
  renderSelectedOption = (o) => o.name,
  children,
}) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [inputError, setInputError] = React.useState(false);
  const [inputHelperText, setInputHelperText] = React.useState('');
  const handleOnClick = () => {
    if (selectedValue !== '') {
      onSubmit(options.find((o) => getOptionValue(o) === selectedValue));
      setSelectedValue('');
      if (inputError) {
        setInputError(false);
        setInputHelperText('');
      }
    } else {
      setInputError(true);
      setInputHelperText(emptyValueError);
    }
  };
  const handleChange = (e) => {
    const selected = options.map(getOptionValue).find((o) => o === e.target.value);
    setSelectedValue(selected || '');
    onChange(selected || '');
  };

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className={classes.inputGroup}>
      <FormControl error={inputError}>
        <InputLabel>{placeholder}</InputLabel>
        <Select
          variant="standard"
          value={selectedValue}
          onChange={handleChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...selectProps}
        >
          {options.map((option) => (
            <MenuItem
              key={getOptionValue(option)}
              value={getOptionValue(option)}
              disabled={isDisabled(option)}
            >
              {selectedValue === getOptionValue(option)
                ? renderSelectedOption(option)
                : renderOption(option)}
            </MenuItem>
          ))}
          {children}
        </Select>
        <FormHelperText>{inputHelperText}</FormHelperText>
      </FormControl>
      <div className={classes.buttonContainer}>
        <Button
          onClick={handleOnClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(SelectButtonGroup);
