import {
  withStyles,
  Popover,
  TextField,
  Chip,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { uniq } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { HelpOutline as HelpIcon } from '@material-ui/icons';
import React from 'react';

const getFilteredTracks = (labelTrackGroupTemplates, groups) =>
  uniq(
    labelTrackGroupTemplates
      .filter((group) => groups.includes(group.type))
      .flatMap(({ tracks = [] }) => tracks.map(({ name }) => name)),
  ).sort();

const getAllTracks = (labelTrackGroupTemplates) =>
  uniq(
    labelTrackGroupTemplates.flatMap(({ tracks = [] }) => tracks.map(({ name }) => name)),
  ).sort();

const getTrackOptions = (labelTrackGroupTemplates, groupValue = []) => {
  return groupValue.length > 0
    ? getFilteredTracks(labelTrackGroupTemplates, groupValue)
    : getAllTracks(labelTrackGroupTemplates);
};

const getFilteredTypes = (labelTrackGroupTemplates, groups, labelTracks) => {
  if (groups.length > 0 && labelTracks.length > 0) {
    return uniq(
      labelTrackGroupTemplates
        .filter((group) => groups.includes(group.type))
        .flatMap(({ tracks = [] }) => tracks.filter((track) => labelTracks.includes(track.name)))
        .map(({ types = [] }) => types)
        .flat(),
    ).sort();
  }
  if (groups.length > 0) {
    return uniq(
      labelTrackGroupTemplates
        .filter((group) => groups.includes(group.type))
        .map(({ tracks = [] }) => tracks.map(({ types = [] }) => types))
        .flat(2),
    ).sort();
  }
  return uniq(
    labelTrackGroupTemplates
      .flatMap(({ tracks = [] }) => tracks.filter((track) => labelTracks.includes(track.name)))
      .map(({ types = [] }) => types)
      .flat(),
  ).sort();
};

const getAllTypes = (labelTrackGroupTemplates) =>
  uniq(
    labelTrackGroupTemplates
      .map(({ tracks = [] }) => tracks.map(({ types = [] }) => types))
      .flat(2),
  ).sort();

const getTypeOptions = (labelTrackGroupTemplates, values = {}) => {
  const { labelTrackGroupType = [], labelTrack = [] } = values;
  return labelTrackGroupType.length > 0 || labelTrack.length > 0
    ? getFilteredTypes(labelTrackGroupTemplates, labelTrackGroupType, labelTrack)
    : getAllTypes(labelTrackGroupTemplates, labelTrackGroupType, labelTrack);
};

const getGroupOptions = (labelTrackGroupTemplates) => {
  return labelTrackGroupTemplates.map((group) => group.type);
};

const getGroupOptionLabel = (option, labelTrackGroupTemplates) => {
  const group = labelTrackGroupTemplates.find((g) => g.type === option);
  return group && group.name ? group.name : option;
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  fieldWrapper: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    marginRight: '-30px',
  },
  nonMatchingChip: {
    backgroundColor: alpha(theme.palette.error.main, 0.2),
  },
  freeTextChip: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  popoverContent: {
    maxWidth: '16rem',
  },
});

const TypePopoverContent = withStyles(styles)(({ classes }) => {
  return (
    <Box p={2} className={classes.popoverContent}>
      <Typography variant="body2">
        Select an option from the list to add a &nbsp;
        <Chip label="Type tag" size="small" />
        &nbsp; matching label track types.
      </Typography>
      <br />
      <Typography variant="body2">
        Enter free text followed by <b>ENTER</b> to add a &nbsp;
        <Chip label="Description Tag" size="small" classes={{ root: classes.freeTextChip }} />
        &nbsp; matching label track descriptions.
      </Typography>
    </Box>
  );
});

const QueryForm = ({
  classes,
  label = {},
  labelTrackGroupTemplates = [],
  personOptions,
  onChange: onChangeLabel,
}) => {
  const { control, reset, handleSubmit, getValues } = useForm({ mode: 'all' });

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const open = Boolean(popoverAnchorEl);

  // const [selectedPersons, setSelectedPersons] = React.useState(false);
  const [trackOptions, setTrackOptions] = React.useState(
    getTrackOptions(labelTrackGroupTemplates, []),
  );

  const [typeOptions, setTypeOptions] = React.useState(
    getTypeOptions(labelTrackGroupTemplates, {}),
  );

  const updateOptions = React.useCallback(() => {
    setTrackOptions(getTrackOptions(labelTrackGroupTemplates, getValues('labelTrackGroupType')));
    setTypeOptions(
      getTypeOptions(labelTrackGroupTemplates, getValues(['labelTrackGroupType', 'labelTrack'])),
    );
  }, [getValues, labelTrackGroupTemplates]);

  React.useEffect(() => {
    updateOptions();
  }, [updateOptions]);

  React.useEffect(() => {
    reset(label);
  }, [label, reset]);

  const submit = (data) => {
    const { uuid } = label;
    const { labelTrackGroupType, labelTrackGroupName, labelTrack, labelType } = data;
    onChangeLabel({
      uuid,
      ...(labelTrackGroupType.length > 0 ? { labelTrackGroupType } : {}),
      ...(labelTrackGroupName?.length > 0 && labelTrackGroupType.includes('interlocutor')
        ? { labelTrackGroupName }
        : {}),
      ...(labelTrack.length > 0 ? { labelTrack } : {}),
      ...(labelType.length > 0 ? { labelType } : {}),
    });
  };
  return (
    <form className={classes.root}>
      <Controller
        name="labelTrackGroupType"
        control={control}
        defaultValue={label.labelTrackGroupType || []}
        render={({ onChange, value }) => (
          <Autocomplete
            onChange={(_, val) => {
              onChange(val);
              updateOptions();
              handleSubmit((data) => submit(data))();
            }}
            value={value}
            multiple
            options={getGroupOptions(labelTrackGroupTemplates)}
            getOptionLabel={(option) => getGroupOptionLabel(option, labelTrackGroupTemplates)}
            filterSelectedOptions
            ChipProps={{
              size: 'small',
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                size="small"
                label="Group"
              />
            )}
          />
        )}
      />
      {label.labelTrackGroupType?.includes('interlocutor') && (
        <Controller
          name="labelTrackGroupName"
          control={control}
          defaultValue={label.labelTrackGroupName || []}
          render={({ onChange, value }) => (
            <Autocomplete
              onChange={(_, val) => {
                onChange(val);
                updateOptions();
                handleSubmit((data) => submit(data))();
              }}
              value={value}
              multiple
              options={personOptions}
              filterSelectedOptions
              ChipProps={{
                size: 'small',
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  size="small"
                  label="Person"
                />
              )}
            />
          )}
        />
      )}
      <Controller
        name="labelTrack"
        control={control}
        defaultValue={label.labelTrack || []}
        render={({ onChange, value }) => (
          <Autocomplete
            onChange={(_, val) => {
              onChange(val);
              updateOptions();
              handleSubmit((data) => submit(data))();
            }}
            value={value}
            multiple
            options={trackOptions}
            filterSelectedOptions
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <Chip
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getTagProps({ index })}
                  label={option}
                  size="small"
                  classes={{
                    root: !trackOptions.includes(option) ? classes.nonMatchingChip : '',
                  }}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                size="small"
                label="Track"
              />
            )}
          />
        )}
      />
      <Controller
        name="labelType"
        control={control}
        defaultValue={label.labelType || []}
        render={({ onChange, value }) => (
          <div className={classes.fieldWrapper}>
            <Autocomplete
              onChange={(_, val) => {
                onChange(val);
                handleSubmit((data) => submit(data))();
              }}
              value={value}
              multiple
              options={typeOptions}
              filterSelectedOptions
              freeSolo
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getTagProps({ index })}
                    label={option}
                    size="small"
                    classes={{ root: !typeOptions.includes(option) ? classes.freeTextChip : '' }}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  size="small"
                  label="Type"
                />
              )}
            />
            <IconButton onClick={(e) => setPopoverAnchorEl(e.currentTarget)} size="small">
              <HelpIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={popoverAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setPopoverAnchorEl(null)}
              disableRestoreFocus
            >
              <TypePopoverContent />
            </Popover>
          </div>
        )}
      />
    </form>
  );
};

export default withStyles(styles)(QueryForm);
