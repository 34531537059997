import React from 'react';

const { MediaInfo } = window;

async function analyze(mediainfo, file) {
  const getSize = () => file.size;
  const readChunk = (chunkSize, offset) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.error) {
          reject(event.target.error);
        }
        resolve(new Uint8Array(event.target.result));
      };
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });

  return mediainfo.analyzeData(getSize, readChunk);
}

export function getGeneralTrack(tracks) {
  return tracks.find((t) => t['@type'] === 'General');
}

export function getAudioTrack(tracks) {
  return tracks.find((t) => t['@type'] === 'Audio');
}

export async function getMediaInfo(files) {
  const mediainfo = MediaInfo && (await MediaInfo());
  return files.reduce(
    (p, file) =>
      p.then((results) =>
        analyze(mediainfo, file)
          .catch(() => null)
          .then((res) => ({ ...results, [file.path]: res })),
      ),
    Promise.resolve({}),
  );
}

export default function useMediaInfo() {
  const [state, setState] = React.useState({
    isLoading: false,
    mediaInfo: {},
  });

  const loadMediaInfo = React.useCallback(
    (newFiles) => {
      const newFilesMissingMediaInfo = newFiles.filter(
        ({ path }) => !Object.keys(state.mediaInfo).includes(path),
      );
      if (newFilesMissingMediaInfo.length) {
        setState((prev) => ({ ...prev, isLoading: true }));
        getMediaInfo(newFilesMissingMediaInfo).then((newMediaInfo) => {
          setState(({ mediaInfo }) => ({
            isLoading: false,
            mediaInfo: { ...mediaInfo, ...newMediaInfo },
          }));
        });
      }
    },
    [setState, state],
  );

  return { ...state, loadMediaInfo };
}
