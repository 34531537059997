import { useQuery, useMutation, useQueryClient } from 'react-query';
import { pkg } from 'mediadb-lib';

export function usePkgMetadata({ packageId }) {
  return useQuery(['pkgMetadata', packageId], () => pkg.getPackageMetadata(packageId), {
    enabled: !!packageId,
  });
}

export function useCreatePackageTechnicalLabelTrack({ packageId }) {
  const queryClient = useQueryClient();
  return useMutation(pkg.createPackageTechnicalLabelTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries(['packageTechnicalLabels', packageId]);
    },
  });
}

export function usePackageTechnicalLabelTracks({ packageId }) {
  return useQuery(['packageTechnicalLabels', packageId], (headers) =>
    pkg.getPackageTechnicalLabelTracks({ packageId }, headers),
  );
}

export function useAddPackageTechnicalLabels({ packageId }) {
  const queryClient = useQueryClient();
  return useMutation(pkg.addPackageTechnicalLabels, {
    onSuccess: () => {
      queryClient.invalidateQueries(['packageTechnicalLabels', packageId]);
      queryClient.invalidateQueries(['packageFiles', packageId]);
    },
    onError: () => {
      queryClient.invalidateQueries(['packageTechnicalLabels', packageId]);
      queryClient.invalidateQueries(['packageFiles', packageId]);
    },
  });
}

export function useRemovePackageTechnicalLabels({ packageId }) {
  const queryClient = useQueryClient();
  return useMutation(pkg.removePackageTechnicalLabels, {
    onSuccess: () => {
      queryClient.invalidateQueries(['packageTechnicalLabels', packageId]);
      queryClient.invalidateQueries(['packageFiles', packageId]);
    },
  });
}

export function usePkgFiles({ packageId }) {
  return useQuery(['packageFiles', packageId], () => pkg.getPackageFiles(packageId), {
    enabled: !!packageId,
  });
}

export function useUpdatePkgMetadata() {
  const queryClient = useQueryClient();
  return useMutation(pkg.updatePackageMetadata, {
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries(['pkgMetadata', packageId]);
    },
  });
}

export function useUpdatePkgMetadataGroupEntry() {
  const queryClient = useQueryClient();
  return useMutation(pkg.updatePackageMetadataGroupEntry, {
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries(['pkgMetadata', packageId]);
    },
  });
}

export function useUpdatePkgStatus() {
  const queryClient = useQueryClient();
  return useMutation(pkg.updatePackageStatus, {
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries(['pkgMetadata', packageId]);
    },
  });
}

export function useChangePkgPackageGroup() {
  const queryClient = useQueryClient();
  return useMutation(pkg.changePackageGroup, {
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries(['pkgMetadata', packageId]);
    },
  });
}

export function useChangePackageOwner() {
  const queryClient = useQueryClient();
  return useMutation(pkg.changePackageOwner, {
    onSuccess: (_, { packageId }) => {
      queryClient.invalidateQueries(['pkgMetadata', packageId]);
    },
  });
}

export function usePkgAccess({ packageId }) {
  return useQuery(['packageAccess', packageId], () => pkg.getPackageAccess({ packageId }), {
    enabled: !!packageId,
  });
}

export function usePkgUserAccess({ username, packageId }) {
  return useQuery(
    ['packageUserAccess', packageId],
    () => pkg.getUserPackageAccess({ username, packageId }),
    {
      enabled: !!packageId && !!username,
    },
  );
}

function pkgEditorOptimisticUpdateProps({ queryClient, onError, op }) {
  const fromPermission = op === 'REMOVE' ? 'WRITE' : 'READ';
  const toPermission = op === 'REMOVE' ? 'READ' : 'WRITE';
  return {
    onMutate: async ({ packageId, username }) => {
      const queryKey = ['packageAccess', packageId];
      await queryClient.cancelQueries(queryKey);
      const prevAccess = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (prev) => ({
        accessList: (prev?.accessList || []).map((accessEntry) =>
          accessEntry.username === username && accessEntry.permission === fromPermission
            ? { ...accessEntry, permission: toPermission }
            : accessEntry,
        ),
      }));
      return { prevAccess };
    },
    onError: (err, variables, context) => {
      onError(err, variables, context);
      queryClient.setQueryData(['packageAccess', variables.packageId], context.prevAccess);
    },
  };
}

export function useAddPkgEditor({ onError, ...opts } = {}) {
  const queryClient = useQueryClient();
  return useMutation(pkg.addPackageEditor, {
    ...opts,
    ...pkgEditorOptimisticUpdateProps({ onError, queryClient, op: 'ADD' }),
  });
}

export function useRemovePkgEditor({ onError, ...opts } = {}) {
  const queryClient = useQueryClient();
  return useMutation(pkg.removePackageEditor, {
    ...opts,
    ...pkgEditorOptimisticUpdateProps({ onError, queryClient, op: 'REMOVE' }),
  });
}
