import React from 'react';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { withStyles, emphasize } from '@material-ui/core';
import { metadata, systemFields } from 'mediadb-lib';
import FileTree from './FileTree';
import { useCustomMetadataGroup } from '../../../hooks/metadata';
import MetadataFieldView from '../../../components/MetadataFieldView';

const styles = (theme) => ({
  root: {},
  gridContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridGap: theme.spacing(0.5),
    columnGap: theme.spacing(4),
  },
  summaryHeader: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: '500',
    color: theme.palette.primary.main,
    borderRadius: '2px',
    backgroundColor: emphasize(theme.palette.primary.main, 0.9),
    padding: theme.spacing(1),
  },
  primaryHeading: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.03),
    borderRadius: '2px',
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    '&.MuiTypography-subtitle2': {
      fontSize: '0.9375rem',
    },
  },
  secondaryHeading: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.03),
    borderRadius: '2px',
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  span2: {
    gridColumn: '1 / span 2',
  },
  indent1: {
    marginLeft: theme.spacing(1),
  },
  indent2: {
    marginLeft: theme.spacing(2),
  },
  indent3: {
    marginLeft: theme.spacing(3),
  },
  indent4: {
    marginLeft: theme.spacing(4),
  },
  label: {
    color: 'rgba(0,0,0,0.6)',
  },
  personRow: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: `${theme.spacing(1)}px`,
    },
  },
  mailText: {
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
    fontStyle: 'italic',
  },
  accordionSummary: {
    display: 'none',
  },
});

const formatAudioSamplingRate = ({ value } = {}) => {
  return value ? `${value} Hz` : '-';
};

const formatDeviceName = (device) =>
  `${device.deviceTemplate.name}${device.position ? ` - ${device.position}` : ''}`;

const formatSensorName = (sensor) =>
  `${sensor.sensorTemplate.name} (${sensor.sensorTemplate.type}) ${
    sensor.position ? ` - ${sensor.position}` : ''
  }`;

const DeviceSummary = ({ classes, device }) => {
  const { data: customDeviceFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.DEVICE.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );
  const { data: customSensorFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.SENSOR.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );

  return (
    <React.Fragment key={device.id}>
      <Typography
        className={clsx(classes.span2, classes.secondaryHeading, classes.indent2)}
        variant="subtitle2"
      >
        {formatDeviceName(device)}
      </Typography>
      {device.channels.map((channel) => (
        <React.Fragment key={device.id + channel.name}>
          <Typography className={clsx(classes.indent3, classes.label)} variant="body2">
            {channel.name}:
          </Typography>
          <Typography variant="body2">
            {`${channel.value}${channel.playbackPosition ? `  (${channel.playbackPosition})` : ''}`}
          </Typography>
        </React.Fragment>
      ))}
      {customDeviceFields.map((field) => (
        <React.Fragment key={field.fieldName}>
          <Typography className={clsx(classes.indent3, classes.label)} variant="body2">
            {`${field.label}:`}
          </Typography>
          <span>
            <MetadataFieldView field={field} value={device.customMetadata?.[field.fieldName]} />
          </span>
        </React.Fragment>
      ))}
      {device.sensors.map((sensor) => (
        <React.Fragment key={device.id + sensor.id}>
          <Typography
            className={clsx(classes.span2, classes.secondaryHeading, classes.indent3)}
            variant="subtitle2"
          >
            {formatSensorName(sensor)}
          </Typography>
          {sensor.channels.map((channel) => (
            <React.Fragment key={device.id + sensor.id + channel.name}>
              <Typography className={clsx(classes.indent4, classes.label)} variant="body2">
                {channel.name}:
              </Typography>
              <Typography variant="body2">{`${channel.value}`}</Typography>
            </React.Fragment>
          ))}
          {customSensorFields.map((field) => (
            <React.Fragment key={field.fieldName}>
              <Typography className={clsx(classes.indent4, classes.label)} variant="body2">
                {`${field.label}:`}
              </Typography>
              <span>
                <MetadataFieldView field={field} value={sensor.customMetadata?.[field.fieldName]} />
              </span>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

function InterlocutorSummary({ classes, interlocutors }) {
  const { data: customPersonFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.PERSON.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );

  return (
    <>
      <Typography className={clsx(classes.span2, classes.summaryHeader)} variant="h5">
        Persons
      </Typography>
      {interlocutors.map((interlocutor, i) => (
        <React.Fragment key={interlocutor.id}>
          <Typography
            className={clsx(classes.primaryHeading, classes.span2, classes.indent1)}
            variant="subtitle2"
          >
            {`Person ${i + 1}`}
          </Typography>

          {customPersonFields.map((field) => (
            <React.Fragment key={field.fieldName}>
              <Typography className={clsx(classes.indent2, classes.label)} variant="body2">
                {`${field.label}:`}
              </Typography>
              <span>
                <MetadataFieldView
                  field={field}
                  value={interlocutor.customMetadata?.[field.fieldName]}
                />
              </span>
            </React.Fragment>
          ))}

          {interlocutor.devices.map((device) => (
            <DeviceSummary
              key={`${interlocutor.name} ${device.id}`}
              classes={classes}
              device={device}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

function AdditionalSourcesSummary({ classes, additionalSources }) {
  return (
    <>
      <Typography className={clsx(classes.span2, classes.summaryHeader)} variant="h5">
        Additional Sources
      </Typography>
      {additionalSources.map((device) => (
        <DeviceSummary key={`${device.id}`} classes={classes} device={device} />
      ))}
    </>
  );
}

function MetadataSummary({ classes, packageMetadata = {}, previewDevice }) {
  const { data: customPackageFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.PACKAGE.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );
  return (
    <>
      <Typography className={clsx(classes.span2, classes.summaryHeader)} variant="h5">
        Package Details
      </Typography>

      <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
        Package Group:
      </Typography>
      <Typography variant="body2">{packageMetadata.packageGroup?.name}</Typography>

      <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
        Package Name:
      </Typography>
      <MetadataFieldView field={systemFields.PACKAGE_NAME} value={packageMetadata.name} />

      <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
        Package Location:
      </Typography>
      <MetadataFieldView field={systemFields.PACKAGE_LOCATION} value={packageMetadata.location} />

      <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
        Package Description:
      </Typography>
      <MetadataFieldView field={systemFields.PACKAGE_NAME} value={packageMetadata.description} />

      <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
        Package Preview Device:
      </Typography>
      <Typography variant="body2">
        {previewDevice ? formatDeviceName(previewDevice) : '-'}
      </Typography>

      <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
        Package Audio Sampling Rate:
      </Typography>
      <Typography
        variant="body2"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {formatAudioSamplingRate(packageMetadata.audioSamplingRate)}
      </Typography>

      {customPackageFields.map((field) => (
        <React.Fragment key={field.fieldName}>
          <Typography className={clsx(classes.indent1, classes.label)} variant="body2">
            {`${field.label}:`}
          </Typography>
          <span>
            <MetadataFieldView
              field={field}
              value={packageMetadata.customMetadata?.[field.fieldName]}
            />
          </span>
        </React.Fragment>
      ))}
    </>
  );
}

const UploadSummary = ({
  classes,
  files,
  interlocutors,
  additionalSources,
  previewDevice,
  packageMetadata,
  summaryExpanded = true,
  usedFiles,
  mediaInfo,
}) => (
  <Box>
    <Accordion expanded={summaryExpanded} elevation={0}>
      <AccordionSummary classes={{ root: classes.accordionSummary }} />
      <AccordionDetails>
        <Box className={classes.gridContainer}>
          <MetadataSummary
            classes={classes}
            packageMetadata={packageMetadata}
            previewDevice={previewDevice}
          />
          {interlocutors.length > 0 && (
            <InterlocutorSummary classes={classes} interlocutors={interlocutors} />
          )}
          {additionalSources.length > 0 && (
            <AdditionalSourcesSummary classes={classes} additionalSources={additionalSources} />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
    <FileTree files={files} usedFiles={usedFiles} mediaInfo={mediaInfo} />
  </Box>
);
export default withStyles(styles)(UploadSummary);
