import { Box } from '@material-ui/core';
import React from 'react';

export default function TabPanel(props) {
  const { children, value, index, ...innerProps } = props;

  return (
    <Box
      pt={1}
      role="tabpanel"
      hidden={value !== index}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    >
      {value === index && children}
    </Box>
  );
}
