import React from 'react';
import {
  Box,
  withStyles,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  TableRow,
  Table,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { Label as LabelIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { permissionGeq } from 'mediadb-lib';
import { Alert, Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { utils } from '@vidispine/vdt-api';
import { pollJob } from 'mediadb-lib/src/utils';
import SectionHeader from '../../../components/SectionHeader';
import {
  useCreatePackageTechnicalLabelTrack,
  useAddPackageTechnicalLabels,
  useRemovePackageTechnicalLabels,
} from '../../../hooks/pkg';
import { LinearProgressWithLabel } from '../../Upload/components/FileTree';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import NavigationBlocker from '../../../components/NavigationBlocker';

const styles = (theme) => ({
  root: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    padding: 0,
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  deleteEnabled: {},
  deleteDisabled: {
    color: 'lightgrey',
  },
  statusText: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.secondary.dark,
  },
  PROCESSING: {
    color: theme.palette.primary.main,
  },
  ADDING: {
    color: theme.palette.primary.main,
  },
  SUCCESS: {
    color: theme.palette.success.main,
  },
  ERROR: {
    color: theme.palette.error.main,
  },
});

function AddLabelsDialog({
  isOpen,
  onClose,
  onSubmit,
  tracks = [],
  packageTracks = [],
  channelOptions = [],
}) {
  const [selectedTrack, setSelectedTrack] = React.useState(null);
  const [selectedChannel, setSelectedChannel] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(undefined);
  const [progressPercent, setProgressPercent] = React.useState(0);
  const [uploadStatus, setUploadStatus] = React.useState({ uploading: false, done: false });
  const inputValid =
    selectedTrack !== null && selectedFile !== undefined && selectedChannel !== null;

  const handleSubmit = () => {
    setUploadStatus({ uploading: true });
    const onProgress = (transferredBytes) => {
      const percent = (transferredBytes / selectedFile.size) * 100;
      setProgressPercent(percent);
    };

    onSubmit(selectedTrack, selectedFile, selectedChannel?.value, onProgress, () =>
      setUploadStatus({ uploading: false, done: true }),
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={LabelIcon}
          title="Add Technical Labels"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>
        {isOpen && (
          <>
            <div style={{ paddingBottom: '2em' }}>
              <Autocomplete
                onChange={(_, channel) => {
                  setSelectedChannel(channel);
                }}
                value={selectedChannel}
                options={channelOptions}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(o, v) => o.value === v.value}
                ChipProps={{
                  size: 'small',
                }}
                disabled={uploadStatus.uploading || uploadStatus.done}
                renderInput={(params) => {
                  return (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      label="Channel"
                      required
                    />
                  );
                }}
              />
            </div>
            <div style={{ paddingBottom: '2em' }}>
              <Autocomplete
                onChange={(_, track) => {
                  setSelectedTrack(track);
                }}
                value={selectedTrack}
                options={tracks}
                getOptionLabel={(option) => option.label}
                getOptionDisabled={(option) =>
                  !option.isActive ||
                  packageTracks.some(
                    (t) => t.name === option.name && t.channelUuid === selectedChannel.value,
                  )
                }
                filterSelectedOptions
                ChipProps={{
                  size: 'small',
                }}
                disabled={!selectedChannel || uploadStatus.uploading || uploadStatus.done}
                renderInput={(params) => {
                  return (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      label="Technical Label Track"
                      required
                    />
                  );
                }}
              />
            </div>
            <Typography style={{ transform: 'translate(0, 2.6em)', minHeight: '1.5em' }}>
              {selectedFile?.name}
            </Typography>
            <TextField
              size="small"
              label="Select file"
              type="file"
              fullWidth
              required
              disabled={uploadStatus.uploading || uploadStatus.done}
              onChange={(e) => {
                const file = e.target.files[0];
                setSelectedFile(file);
              }}
              inputProps={{ style: { opacity: '0' } }}
            />

            {(uploadStatus.uploading || uploadStatus.done) && (
              <LinearProgressWithLabel value={progressPercent} />
            )}
            <div>
              {uploadStatus.uploading && <Alert severity="info">Uploading.</Alert>}
              {uploadStatus.done && <Alert severity="success">Done!</Alert>}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{uploadStatus.done ? 'Close' : 'Cancel'}</Button>
        {!(uploadStatus.uploading || uploadStatus.done) && (
          <Button
            onClick={handleSubmit}
            disabled={!inputValid}
            variant="contained"
            disableElevation
            color="primary"
          >
            Add labels
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function PackageTechnicalView({
  classes,
  packageId,
  userPermission,
  onClickTrack,
  selectedTrack,
  technicalLabelTracks,
  packageTechnicalTracks,
  interlocutors,
}) {
  const { showAlert } = useSnackbar();
  const [showAddLabelsDialog, setShowAddLabelsDialog] = React.useState(false);
  const [isAdding, setIsAdding] = React.useState(false);
  const hasEditorPermission = permissionGeq(userPermission, 'WRITE');
  const [removing, setRemoving] = React.useState(false);

  const { mutateAsync: createPackageTechnicalLabelTrack } = useCreatePackageTechnicalLabelTrack({
    packageId,
  });

  const { mutateAsync: addPackageTechnicalLabels } = useAddPackageTechnicalLabels({ packageId });

  const {
    mutateAsync: removePackageTechnicalLabels,
    isLoading: isLoadingRemovePackageTechnicalLabels,
  } = useRemovePackageTechnicalLabels({ packageId });

  const handleCloseAddLabels = () => setShowAddLabelsDialog(false);

  const uploadPackageTechnicalLabelsFile = async (
    track,
    file,
    channelUuid,
    onProgress,
    onSuccess,
  ) => {
    const { itemId, fileUploadUrl, uuid } = await createPackageTechnicalLabelTrack({
      packageId,
      trackName: track.name,
      filename: file.name,
      channelUuid,
    });

    const {
      data: { jobId },
    } = await utils.defaultClient({
      method: 'POST',
      url: fileUploadUrl,
      data: file,
      headers: { contentType: 'application/octet-stream' },
      onUploadProgress: (progress) => {
        onProgress(progress.total);
      },
    });

    onSuccess();

    await pollJob({ jobId, firstPollDelay: 5000, pollInterval: 2500, pollLimit: 10 });

    return { itemId, uuid };
  };

  const handleAddLabels = async (track, file, channelUuid, onProgress, onSuccess) => {
    try {
      setIsAdding(true);
      const { itemId, uuid } = await uploadPackageTechnicalLabelsFile(
        track,
        file,
        channelUuid,
        onProgress,
        onSuccess,
      );
      await addPackageTechnicalLabels({
        packageId,
        itemId,
        trackName: track.name,
        uuid,
      });
    } catch (error) {
      showAlert({ severity: 'error', message: 'Failed to add labels' });
    } finally {
      setIsAdding(false);
    }
  };

  const handleRemoveLabels = async (track, itemId) => {
    try {
      setRemoving(true);
      await removePackageTechnicalLabels({
        packageId,
        uuid: track.uuid,
        itemId,
      });
    } catch (error) {
      showAlert({ severity: 'error', message: 'Failed to remove labels' });
    } finally {
      setRemoving(false);
    }
  };

  const channelOptions = interlocutors.flatMap((i) =>
    i.devices.flatMap((d) =>
      d.channels.map((c) => ({
        label: `${i.name} ${d.deviceTemplate.name} ${d.position} - ${c.name}`,
        value: c.uuid,
      })),
    ),
  );

  return (
    <>
      <Box>
        <Tooltip
          placement="top-end"
          title={hasEditorPermission ? '' : 'You need editor rights to add labels'}
        >
          <Box display="flex">
            <Button
              onClick={() => setShowAddLabelsDialog(true)}
              startIcon={<LabelIcon />}
              color="primary"
              style={{ marginLeft: 'auto' }}
              disabled={isAdding || !hasEditorPermission}
            >
              Add Technical Labels
            </Button>
          </Box>
        </Tooltip>
        <Table size="small" className={classes.root}>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Track Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packageTechnicalTracks.map((packageTrack) => (
              <TableRow
                key={`${packageTrack.name}_${packageTrack.channelUuid}`}
                selected={selectedTrack && selectedTrack.uuid === packageTrack.uuid}
                dense="true"
                button="true"
                className={classes.listItem}
                onClick={() => onClickTrack(packageTrack)}
              >
                <TableCell className={clsx(classes.statusText, classes[packageTrack.status])}>
                  {packageTrack.status}
                </TableCell>
                <TableCell>
                  {channelOptions.find((c) => c.value === packageTrack.channelUuid)?.label}
                </TableCell>
                <TableCell>{packageTrack.label}</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      hasEditorPermission
                        ? 'Remove labels'
                        : 'You need editor rights to remove labels'
                    }
                    placement="left"
                  >
                    <span>
                      <IconButton
                        onClick={() => handleRemoveLabels(packageTrack, packageTrack.itemId)}
                        size="small"
                        disabled={
                          isLoadingRemovePackageTechnicalLabels ||
                          !hasEditorPermission ||
                          removing ||
                          packageTrack.status === 'ADDING' ||
                          (packageTrack.status === 'PROCESSING' && isAdding)
                        }
                      >
                        <DeleteIcon
                          className={clsx(
                            isLoadingRemovePackageTechnicalLabels ||
                              !hasEditorPermission ||
                              removing
                              ? classes.deleteDisabled
                              : classes.deleteEnabled,
                          )}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {showAddLabelsDialog && (
        <AddLabelsDialog
          packageId={packageId}
          onSubmit={handleAddLabels}
          isOpen={showAddLabelsDialog}
          onClose={handleCloseAddLabels}
          tracks={technicalLabelTracks}
          packageTracks={packageTechnicalTracks}
          channelOptions={channelOptions}
        />
      )}
      <NavigationBlocker
        navigationBlocked={packageTechnicalTracks.some((t) => t.status === 'PROCESSING')}
        message="Upload will stop if you leave this page, are you sure you want to leave?"
      />
    </>
  );
}

export default withStyles(styles)(PackageTechnicalView);
