import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { sortBy as _sortBy } from 'lodash';
import { useCustomMetadataGroup } from '../../../hooks/metadata';
import MetadataFieldEdit from '../../../components/MetadataFieldEdit';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .fields': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
    },
    paddingRight: theme.spacing(6),
  },
});

const CustomMetadataComponent = ({ classes, source, onChange, customGroupName }) => {
  const { data: customFields = [], isSuccess } = useCustomMetadataGroup(customGroupName, {
    select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
  });
  const { customMetadata = [] } = source;

  const handleChange = (value, fieldName) => {
    onChange({
      ...source,
      customMetadata: {
        ...source.customMetadata,
        [fieldName]: value,
      },
    });
  };

  return (
    isSuccess &&
    customFields.length > 0 && (
      <div className={classes.root}>
        <Typography variant="caption">Metadata</Typography>
        <div className="fields">
          {_sortBy(customFields, 'label').map((field) => (
            <MetadataFieldEdit
              value={customMetadata[field.fieldName]}
              onChange={(value) => handleChange(value, field.fieldName)}
              field={field}
              key={field.fieldName}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default withStyles(styles)(CustomMetadataComponent);
