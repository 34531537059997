import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Tooltip, withStyles, Button, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PlaylistEdit } from 'mdi-material-ui';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty, get, pickBy } from 'lodash';
import { metadata as metadataLib } from 'mediadb-lib';
import MetadataFieldView from './MetadataFieldView';
import MetadataFieldEdit from './MetadataFieldEdit';
import { useUpdatePkgMetadataGroupEntry } from '../hooks/pkg';

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  header: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
});

const MetadataGrid = ({
  classes,
  metadataFields,
  metadata,
  metadataGroupUuid,
  header = 'Information',
  packageId,
  editable = false,
  onSuccess = () => null,
  onError = () => null,
}) => {
  const [editing, setEditing] = useState(false);

  const { mutateAsync: updatePkgMetadataGroupEntry } = useUpdatePkgMetadataGroupEntry();

  const defaultValues = useMemo(() => {
    const defaultVals = {};
    metadataFields.forEach(({ fieldName }) => {
      if (metadata[fieldName]) {
        defaultVals[fieldName] = metadata[fieldName];
      } else {
        defaultVals[fieldName] = '';
      }
    });
    return defaultVals;
  }, [metadata, metadataFields]);

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues,
    mode: 'all',
  });

  const { isValid, dirtyFields } = formState;

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, editing, reset]);

  const submit = (payload) => {
    const changedValues = pickBy(payload, (_, k) => !!dirtyFields[k]);
    updatePkgMetadataGroupEntry({
      packageId,
      groupUuid: metadataGroupUuid,
      metadata: changedValues,
    })
      .then(() => {
        setEditing(false);
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  if (!metadataFields || metadataFields?.length < 1) return null;
  return (
    <>
      <div className={classes.header}>
        <Typography>{header}</Typography>
        {editable && (
          <Tooltip title="Edit information">
            <span>
              <IconButton onClick={() => setEditing((prev) => !prev)}>
                <PlaylistEdit />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
      {!editing ? (
        <Box className={clsx(classes.root, classes.metadataGrid)}>
          {metadataFields.map((field) => (
            <React.Fragment key={field.fieldName}>
              <Typography variant="body2" color="textSecondary">
                {field.label}
              </Typography>
              <MetadataFieldView field={field} value={metadata[field.fieldName]} />
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Box className={classes.form}>
            {metadataFields.map((field) => (
              <Controller
                key={field.fieldName}
                defaultValue=""
                name={field.fieldName}
                control={control}
                rules={{
                  validate: {
                    fieldValidation: (v) => metadataLib.validateFieldValue({ value: v, field }),
                  },
                }}
                render={({ onChange, value }) => (
                  <MetadataFieldEdit
                    field={field}
                    value={value}
                    onChange={(v) => onChange(v)}
                    error={!!get(errors, field.fieldName)}
                  />
                )}
              />
            ))}
            <div className={classes.actions}>
              <Button onClick={() => setEditing(false)}>Cancel</Button>
              <Button
                type="submit"
                disabled={!isValid || isEmpty(dirtyFields)}
                variant="contained"
                disableElevation
                color="primary"
              >
                Save
              </Button>
            </div>
          </Box>
        </form>
      )}
    </>
  );
};

export default withStyles(styles)(MetadataGrid);
