import { useSensorTemplates } from './sensor';
import uuidv4 from '../utils/uuidv4';

function Device(deviceTemplate, correctSensorTemplates) {
  const {
    deviceType: { channels, positions, sensorTemplates },
  } = deviceTemplate;

  // Workaround bug:
  // Referenced object in referenced object is not updated, so use original
  const sensorTemplateIds = sensorTemplates.map(({ uuid }) => uuid);
  const updatedSensorTemplates = correctSensorTemplates.filter((st) =>
    sensorTemplateIds.includes(st.uuid),
  );

  this.deviceTemplate = deviceTemplate;
  this.channels = [...channels];
  this.position = positions.length > 0 ? positions[0] : null;
  this.sensors =
    updatedSensorTemplates.length > 0
      ? updatedSensorTemplates.map((sensorTemplate) => ({
          id: uuidv4(),
          channels: sensorTemplate.channels.map((channelName) => ({ name: channelName })),
          position: '',
          sensorTemplate,
          customMetadata: {},
        }))
      : [];
  this.id = uuidv4();
  this.hasVideo = channels.some((c) => ['video', 'videoAndAudio'].includes(c.mediaType));
  this.customMetadata = {};
}

const useCreateDevice = () => {
  const { data: sensorTemplates = [], status: sensorTemplateStatus } = useSensorTemplates();

  const canCreateDevice = sensorTemplateStatus === 'success';

  return {
    canCreateDevice,
    createDevice: (deviceTemplate) => {
      if (!canCreateDevice) return undefined;
      return new Device(deviceTemplate, sensorTemplates);
    },
  };
};

export default useCreateDevice;
