import { useQuery, useMutation, useQueryClient } from 'react-query';
import { sensor } from 'mediadb-lib';

export function useSensorTemplates() {
  return useQuery('sensorTemplates', sensor.getSensorTemplates);
}

export function useAddSensorTemplate() {
  const queryClient = useQueryClient();
  return useMutation(sensor.addSensorTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplates');
    },
  });
}

export function useUpdateSensorTemplate() {
  const queryClient = useQueryClient();
  return useMutation(sensor.updateSensorTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplates');
    },
  });
}

export function useRemoveSensorTemplate() {
  const queryClient = useQueryClient();
  return useMutation(sensor.removeSensorTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplates');
    },
  });
}

export function useIsSensorTemplateUsedByDeviceType(opts = {}) {
  const { sensorTemplate, deviceTypes } = opts;
  const { uuid } = sensorTemplate || {};
  return useQuery(
    `sensorTemplateRemovable-${uuid}`,
    () => sensor.isSensorTemplateUsedByDeviceType(sensorTemplate, deviceTypes),
    { ...opts, enabled: !!sensorTemplate },
  );
}

export function useSensorTemplateOptions() {
  return useQuery('sensorTemplateOptions', sensor.getSensorTemplateOptions);
}

export function useAddPositionOption() {
  const queryClient = useQueryClient();
  return useMutation(sensor.addPositionOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplateOptions');
    },
  });
}

export function useRemovePositionOption() {
  const queryClient = useQueryClient();
  return useMutation(sensor.removePositionOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplateOptions');
    },
  });
}

export function useAddChannelOption() {
  const queryClient = useQueryClient();
  return useMutation(sensor.addChannelOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplateOptions');
    },
  });
}

export function useRemoveChannelOption() {
  const queryClient = useQueryClient();
  return useMutation(sensor.removeChannelOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('sensorTemplateOptions');
    },
  });
}
