import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { Button, alpha, FormControlLabel, Switch, TextField, withStyles } from '@material-ui/core';
import { isEmpty, partition as _partition } from 'lodash';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import SectionHeader from '../../../components/SectionHeader';
import { updateWhere } from '../../../utils/utils';
import TextButtonGroup from './TextButtonGroup';

import { useLabelTrackGroups, useAddLabelTrack, useUpdateLabelTrack } from '../../../hooks/label';

const LABEL_TRACK_TEMPLATE = {
  uuid: '',
  name: '',
  types: [],
  isRequired: false,
  isActive: true,
  order: '',
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

const CustomChip = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
    backgroundColor: '#eee',
    '& .MuiChip-deleteIcon': {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&.inactiveLabel': {
      backgroundColor: alpha(theme.palette.error.main, 0.2),
      '& .MuiChip-deleteIcon': {
        transform: 'rotate(45deg)',
      },
    },
  },
  deleteIcon: {
    marginLeft: 'auto',
  },
}))(Chip);

const getLabelTracks = (index, labelTrackGroups) => {
  const selectedLabelTrackGroup = labelTrackGroups[index] || {};
  const { tracks = [] } = selectedLabelTrackGroup;
  return tracks;
};

const getLabelTrackNames = (index, labelTrackGroups) =>
  getLabelTracks(index, labelTrackGroups).map((t) => t.name);

const getLabelTrackGroupUuid = (index, labelTrackGroups) => {
  const selectedLabelTrackGroup = labelTrackGroups[index] || {};
  return selectedLabelTrackGroup.uuid;
};

function LabelTrackEditor({
  classes,
  labelTrackId,
  selectedLabelTrackGroupIndex,
  onAlert = () => null,
  onCancel = () => null,
}) {
  const { data: labelTrackGroups = [], isFetching } = useLabelTrackGroups();
  const isEditing = !!labelTrackId;
  const labelTrack = React.useMemo(() => {
    const selected =
      labelTrackGroups.length > 0
        ? labelTrackGroups[selectedLabelTrackGroupIndex].tracks.find(
            (track) => track.uuid === labelTrackId,
          )
        : undefined;

    const { types = [], inactiveTypes = [] } = selected || {};
    const allTypes = types
      .sort((a, b) => a.localeCompare(b))
      .map((t) => ({ name: t, status: 'active' }))
      .concat(
        inactiveTypes
          .sort((a, b) => a.localeCompare(b))
          .map((t) => ({ name: t, status: 'inactive' })),
      );
    return selected ? { ...selected, allTypes } : undefined;
  }, [labelTrackGroups, labelTrackId, selectedLabelTrackGroupIndex]);
  const { mutateAsync: addLabelTrack, isLoading: isLoadingAdd } = useAddLabelTrack();
  const { mutateAsync: updateLabelTrack, isLoading: isLoadingUpdate } = useUpdateLabelTrack();

  const { control, reset, handleSubmit, errors, formState, trigger } = useForm({
    defaultValues: LABEL_TRACK_TEMPLATE,
    mode: 'all',
  });

  const existingLabelTrackNames = React.useMemo(
    () => getLabelTrackNames(selectedLabelTrackGroupIndex, labelTrackGroups),
    [selectedLabelTrackGroupIndex, labelTrackGroups],
  );

  const handleAddLabelTrack = (newLabelTrack) => {
    const labelTrackGroupUuid = getLabelTrackGroupUuid(
      selectedLabelTrackGroupIndex,
      labelTrackGroups,
    );
    return addLabelTrack({ labelTrack: newLabelTrack, labelTrackGroupUuid })
      .then(() => {
        onAlert({ severity: 'success', message: 'Label track added' });
        reset(LABEL_TRACK_TEMPLATE);
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Label track could not be added' });
      });
  };
  const handleUpdateLabelTrack = (editedLabelTrack) => {
    const labelTrackGroupUuid = getLabelTrackGroupUuid(
      selectedLabelTrackGroupIndex,
      labelTrackGroups,
    );
    const [active, inactive] = _partition(
      editedLabelTrack.allTypes,
      ({ status }) => status === 'active',
    );
    const types = active.map((t) => t.name);
    const inactiveTypes = inactive.map((t) => t.name);
    return updateLabelTrack({
      labelTrack: { ...editedLabelTrack, types, inactiveTypes },
      labelTrackGroupUuid,
    })
      .then(() => {
        onAlert({ severity: 'success', message: 'Label track updated' });
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Label track could not be updated' });
      });
  };

  const submit = (args) => {
    return isEditing ? handleUpdateLabelTrack(args) : handleAddLabelTrack(args);
  };
  React.useEffect(() => {
    reset(labelTrack || LABEL_TRACK_TEMPLATE);
  }, [reset, labelTrack, isEditing]);

  React.useEffect(() => {
    if (formState.isDirty) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabelTrackGroupIndex]);

  return (
    <>
      <SectionHeader
        Icon={isEditing ? EditIcon : AddIcon}
        title={isEditing ? 'Edit Label Track' : 'New Label Track'}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      <form onSubmit={handleSubmit(submit)} className={classes.root}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: 'Required',
            validate: {
              alreadyUsed: (v = '') => {
                const errorMessage = 'Name is already used';
                if (isEditing) {
                  if (v.trim() !== labelTrack.name && existingLabelTrackNames.includes(v.trim())) {
                    return errorMessage;
                  }
                } else {
                  return existingLabelTrackNames.includes(v.trim()) ? errorMessage : undefined;
                }
                return undefined;
              },
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Name"
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
        <Controller
          name="isRequired"
          control={control}
          defaultValue={false}
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Required"
            />
          )}
        />
        <Controller
          name="allTypes"
          control={control}
          defaultValue={[]}
          rules={{
            validate: {
              requiredWhenAdding: (v = []) => {
                return isEditing || v.length > 0 ? undefined : 'Required when adding';
              },
            },
          }}
          render={({ onChange, value }) => (
            <>
              <TextButtonGroup
                buttonText="Add"
                occupiedInputs={value.map((v) => v.name) || []}
                onSubmit={(val) => onChange([...value, { name: val, status: 'active' }])}
                emptyValueError="Type name required"
                textFieldProps={{
                  variant: 'standard',
                  label: `Types${isEditing ? '' : ' *'}`,
                }}
                buttonProps={{
                  variant: 'outlined',
                  color: 'primary',
                }}
              />
              <Box>
                {value &&
                  value.length > 0 &&
                  value.map((type) => (
                    <CustomChip
                      className={type.status === 'inactive' ? 'inactiveLabel' : ''}
                      key={type.name}
                      label={type.name}
                      onDelete={() =>
                        isEditing && labelTrack.allTypes.some((t) => t.name === type.name)
                          ? onChange(
                              updateWhere(value, (t) => t.name === type.name, {
                                name: type.name,
                                status: type.status === 'active' ? 'inactive' : 'active',
                              }),
                            )
                          : onChange(value.filter((val) => val !== type))
                      }
                    />
                  ))}
              </Box>
            </>
          )}
        />
        <Controller
          name="order"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <input type="hidden" onChange={onChange} value={value} />
          )}
        />
        <Controller
          name="uuid"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <input type="hidden" onChange={onChange} value={value} />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          {isEditing && <Button onClick={onCancel}>cancel</Button>}
          <Button
            disableElevation
            disabled={
              !formState.isValid ||
              isEmpty(formState.dirtyFields) ||
              isLoadingUpdate ||
              isLoadingAdd ||
              isFetching
            }
            variant="contained"
            color="primary"
            type="submit"
          >
            {isEditing ? 'Save' : 'Add Label Track'}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default withStyles(styles)(LabelTrackEditor);
