"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_CHANNEL_UUID = exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_ITEM_ID = exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_STATUS = exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_NAME = exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK = exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACKS = exports.SONOVA_TECHNICAL_LABEL_TRACKS = exports.SONOVA_TECHNICAL_LABEL_TRACK_PREFIX = exports.SONOVA_TRIGGER_UPDATE_ALL_AV_MARKER_RELATIONS = exports.SONOVA_LABEL_TRACK = exports.SONOVA_LABEL_TRACK_GROUP = exports.SONOVA_LABEL_TRACK_GROUPS = exports.SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT = exports.INTERLOCUTOR_TRACK_GROUP_TYPE = exports.ACCURATE_VIDEO_TRACK_GROUP_FIELDS = exports.ACCURATE_VIDEO_TRACK_FIELDS = exports.ACCURATE_VIDEO_GROUPS = void 0;
var ACCURATE_VIDEO_GROUPS = {
  TRACK: 'AvTrack',
  MARKER: 'AvMarker'
};
exports.ACCURATE_VIDEO_GROUPS = ACCURATE_VIDEO_GROUPS;
var ACCURATE_VIDEO_TRACK_FIELDS = {
  NAME: 'av_track_name',
  TYPE: 'av_track_type',
  TYPES: 'av_track_types',
  COLOUR: 'av_track_colour',
  REQUIRED: 'av_track_required',
  PARENT_ID: 'av_parent_track_id',
  ORDER: 'av_track_order',
  DELETED: 'av_track_deleted',
  INACTIVE_TYPES: 'av_track_inactive_types'
};
exports.ACCURATE_VIDEO_TRACK_FIELDS = ACCURATE_VIDEO_TRACK_FIELDS;
var ACCURATE_VIDEO_TRACK_GROUP_FIELDS = {
  NAME: 'av_track_name',
  TYPE: 'av_track_type',
  ORDER: 'av_track_order',
  DELETED: 'av_track_deleted'
};
exports.ACCURATE_VIDEO_TRACK_GROUP_FIELDS = ACCURATE_VIDEO_TRACK_GROUP_FIELDS;
var INTERLOCUTOR_TRACK_GROUP_TYPE = 'interlocutor';
exports.INTERLOCUTOR_TRACK_GROUP_TYPE = INTERLOCUTOR_TRACK_GROUP_TYPE;
var SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT = 'sonova_labelTrackGroupTemplatesDocument';
exports.SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT = SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT;
var SONOVA_LABEL_TRACK_GROUPS = 'sonova_labelTrackGroups';
exports.SONOVA_LABEL_TRACK_GROUPS = SONOVA_LABEL_TRACK_GROUPS;
var SONOVA_LABEL_TRACK_GROUP = 'sonova_labelTrackGroup';
exports.SONOVA_LABEL_TRACK_GROUP = SONOVA_LABEL_TRACK_GROUP;
var SONOVA_LABEL_TRACK = 'sonova_labelTrack';
exports.SONOVA_LABEL_TRACK = SONOVA_LABEL_TRACK;
var SONOVA_TRIGGER_UPDATE_ALL_AV_MARKER_RELATIONS = 'sonova_trigger_update_all_av_marker_relations';
exports.SONOVA_TRIGGER_UPDATE_ALL_AV_MARKER_RELATIONS = SONOVA_TRIGGER_UPDATE_ALL_AV_MARKER_RELATIONS;
var SONOVA_TECHNICAL_LABEL_TRACK_PREFIX = 'sonova_technicalLabelTrack_';
exports.SONOVA_TECHNICAL_LABEL_TRACK_PREFIX = SONOVA_TECHNICAL_LABEL_TRACK_PREFIX;
var SONOVA_TECHNICAL_LABEL_TRACKS = 'sonova_technicalLabelTracks';
exports.SONOVA_TECHNICAL_LABEL_TRACKS = SONOVA_TECHNICAL_LABEL_TRACKS;
var SONOVA_PACKAGE_TECHNICAL_LABEL_TRACKS = 'sonova_packageTechnicalLabelTracks';
exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACKS = SONOVA_PACKAGE_TECHNICAL_LABEL_TRACKS;
var SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK = 'sonova_packageTechnicalLabelTrack';
exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK = SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK;
var SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_NAME = 'sonova_packageTechnicalLabelTrackName';
exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_NAME = SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_NAME;
var SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_STATUS = 'sonova_packageTechnicalLabelTrackStatus';
exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_STATUS = SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_STATUS;
var SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_ITEM_ID = 'sonova_packageTechnicalLabelTrackItemId';
exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_ITEM_ID = SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_ITEM_ID;
var SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_CHANNEL_UUID = 'sonova_packageTechnicalLabelTrackChannelUuid';
exports.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_CHANNEL_UUID = SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_CHANNEL_UUID;
var _default = {
  ACCURATE_VIDEO_GROUPS: ACCURATE_VIDEO_GROUPS,
  ACCURATE_VIDEO_TRACK_FIELDS: ACCURATE_VIDEO_TRACK_FIELDS,
  ACCURATE_VIDEO_TRACK_GROUP_FIELDS: ACCURATE_VIDEO_TRACK_GROUP_FIELDS,
  INTERLOCUTOR_TRACK_GROUP_TYPE: INTERLOCUTOR_TRACK_GROUP_TYPE,
  SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT: SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT,
  SONOVA_LABEL_TRACK_GROUPS: SONOVA_LABEL_TRACK_GROUPS,
  SONOVA_LABEL_TRACK_GROUP: SONOVA_LABEL_TRACK_GROUP,
  SONOVA_LABEL_TRACK: SONOVA_LABEL_TRACK
};
exports["default"] = _default;