import {
  withStyles,
  emphasize,
  Paper,
  Button,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Remove as RemoveIcon, Close as DeleteIcon } from '@material-ui/icons';
import React from 'react';
import QueryForm from './QueryForm';
import uuidv4 from '../../../utils/uuidv4';
import { updateWhere } from '../../../utils/utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    backgroundColor: emphasize(theme.palette.background.paper, 0.015),
  },
  button: {
    alignSelf: 'flex-end',
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  queryHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  notSwitch: {
    '&.Mui-checked': {
      color: theme.palette.error.main,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.error.light,
      },
    },
  },
});

const QueryGroup = ({
  classes,
  label,
  labelTrackGroupTemplates,
  personOptions,
  onChange,
  onDelete,
}) => {
  const handleToggleNot = () => {
    onChange({ ...label, not: !label.not });
  };
  const handleAddOrLabel = () => {
    onChange({ ...label, labels: [...label.labels, { uuid: uuidv4() }] });
  };

  const handleChange = (newLabel) =>
    onChange({
      ...label,
      labels: updateWhere(label.labels, (l) => l.uuid === newLabel.uuid, newLabel),
    });

  const handleDeleteLabel = (labelToRemove) =>
    onChange({
      ...label,
      labels: label.labels.filter((l) => l.uuid !== labelToRemove.uuid),
    });

  return (
    <Paper className={classes.root} variant="outlined">
      <div className={classes.queryHeader}>
        <FormControlLabel
          control={
            <Switch
              checked={label.not}
              onChange={handleToggleNot}
              name="NOT"
              color="secondary"
              classes={{
                colorSecondary: classes.notSwitch,
              }}
            />
          }
          label={
            <Typography color={label.not ? 'error' : 'textSecondary'} variant="overline">
              NOT
            </Typography>
          }
        />
        <IconButton size="small" onClick={() => onDelete(label)}>
          <DeleteIcon />
        </IconButton>
      </div>
      {label.labels.map((l, index) => (
        <React.Fragment key={l.uuid}>
          {index !== 0 && (
            <div className={classes.separator}>
              <Typography color="textSecondary" variant="overline">
                OR
              </Typography>
              <IconButton size="small" onClick={() => handleDeleteLabel(l)}>
                <RemoveIcon />
              </IconButton>
            </div>
          )}
          <QueryForm
            label={l}
            labelTrackGroupTemplates={labelTrackGroupTemplates}
            personOptions={personOptions}
            onChange={handleChange}
          />
        </React.Fragment>
      ))}
      <Button className={classes.button} color="primary" onClick={handleAddOrLabel}>
        + or
      </Button>
    </Paper>
  );
};

export default withStyles(styles)(QueryGroup);
