import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

function MediaCardFullWidthExpand({ classes, expanded, handleExpand }) {
  return (
    <div className={classes.root}>
      <Tooltip title="Show extracts">
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpand}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default withStyles(styles)(MediaCardFullWidthExpand);
