import React from 'react';

import { withStyles } from '@material-ui/core';
import DataTableWithDrop from '../../../components/DataTableWithDrop';
import TableRowWithDrag from '../../../components/TableRowWithDrag';
import { useLabelTrackGroups, useUpdateLabelTrackGroupsOrder } from '../../../hooks/label';

const styles = (theme) => ({
  root: {
    '& th': {
      position: 'sticky',
      top: '0',
      backgroundColor: theme.palette.background.paper,
    },
  },
});

const TABLE_COLUMNS = [
  {
    name: 'Label track group',
    key: 'name',
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

const getDraggableRowProps = (row, index) => {
  return {
    dragInfo: {
      entity: row,
      entityType: 'labelTrackGroup',
      entityId: row.uuid,
      order: row.order,
    },
    id: row.uuid,
    index,
    wrapDragInDiv: false,
    acceptedEntityTypes: ['labelTrackGroup'],
  };
};

const LabelTrackGroupTable = ({ classes, selected, onClickRow, onAlert }) => {
  const { data: labelTrackGroups = [] } = useLabelTrackGroups();
  const [localLabelTrackGroups, setLocalLabelTrackGroups] = React.useState([]);
  const selectedIndex = localLabelTrackGroups.findIndex(
    (labelTrack) => labelTrack.uuid === selected,
  );
  const [disableSort, setDisableSort] = React.useState(false);

  const { mutateAsync: updateLabelTrackGroupsOrder } = useUpdateLabelTrackGroupsOrder();

  React.useEffect(() => {
    if (labelTrackGroups.length > 0) {
      setLocalLabelTrackGroups([...labelTrackGroups]);
    }
  }, [labelTrackGroups]);

  const resetLocalLabelTrackGroups = () => {
    setLocalLabelTrackGroups([...labelTrackGroups]);
  };

  const onDrop = () => {
    if (disableSort) return;
    const labelTrackGroupsToUpdate = [];
    localLabelTrackGroups.forEach(({ uuid }, index) => {
      const originalLabelTrackGroup = labelTrackGroups.find((group) => group.uuid === uuid);
      if (originalLabelTrackGroup.order !== index) {
        labelTrackGroupsToUpdate.push({ uuid, order: index });
      }
    });
    if (labelTrackGroupsToUpdate.length > 0) {
      setDisableSort(true);
      updateLabelTrackGroupsOrder(labelTrackGroupsToUpdate)
        .catch(() => {
          onAlert({ severity: 'error', message: 'Failed to update order' });
          resetLocalLabelTrackGroups();
        })
        .finally(() => {
          setDisableSort(false);
        });
    }
  };

  const onSort = ({ dragId, hoverId, originalDragIndex }) => {
    if (dragId === undefined || (hoverId === undefined && originalDragIndex === undefined)) return;
    // Reset order on cancel dnd
    if (originalDragIndex !== undefined) {
      resetLocalLabelTrackGroups();
      return;
    }
    setLocalLabelTrackGroups((state) => {
      const newState = [...state];
      const dragIndex = localLabelTrackGroups.findIndex((group) => group.uuid === dragId);
      const hoverIndex = localLabelTrackGroups.findIndex((group) => group.uuid === hoverId);
      const newDragIndex = hoverIndex > -1 ? hoverIndex : originalDragIndex;
      [newState[newDragIndex], newState[dragIndex]] = [newState[dragIndex], newState[newDragIndex]];
      return newState;
    });
  };

  return (
    <div className={classes.root}>
      <DataTableWithDrop
        draggable
        getDraggableRowProps={getDraggableRowProps}
        onDrop={onDrop}
        columns={TABLE_COLUMNS}
        rows={localLabelTrackGroups}
        onClickRow={onClickRow}
        selectedRowIndices={[selectedIndex]}
        RowComponent={TableRowWithDrag}
        RowProps={{
          onSort: disableSort ? undefined : onSort,
        }}
        dropInfo={{ entityType: 'labelTrackGroup' }}
        acceptedEntityTypes={['labelTrackGroup']}
      />
    </div>
  );
};

export default withStyles(styles)(LabelTrackGroupTable);
