import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Divider,
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { device } from 'mediadb-lib';
import { DeviceTemplatesForm } from '../../Admin/components/DeviceTemplatesEditor';
import SelectButtonGroup from './SelectButtonGroup';
import { useDeviceTemplates } from '../../../hooks/device';
import { AuthContext } from '../../../vdt/AuthProvider';
import { useSnackbar } from '../../../contexts/SnackbarContext';

const { SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES } = device;

const styles = (theme) => ({
  newDeviceTemplateMenuItem: {
    '& > *': {
      color: theme.palette.primary.main,
    },
  },
  selectPaper: {
    '& .MuiList-root': {
      paddingBottom: '0px',
    },
    maxHeight: '400px',
  },
  newDeviceTemplateMenuItemContainer: {
    position: 'sticky',
    bottom: '0px',
    backgroundColor: 'white',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

const Editor = ({ classes, onSuccess, formProps, ...props }) => {
  return (
    <Dialog
      fullWidth
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DialogTitle>New Device</DialogTitle>
      <DialogContent>
        <DeviceTemplatesForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formProps}
          showActive={false}
          addProps={{
            isActive: true,
            approvalStatus: SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.PENDING,
          }}
          className={classes.form}
        />
      </DialogContent>
    </Dialog>
  );
};

function DeviceTemplateSelect({ classes, onSubmit, category, disabled }) {
  const [isEditing, setIsEditing] = React.useState(false);
  const { userName } = React.useContext(AuthContext);

  const { showAlert } = useSnackbar();

  const { data: deviceTemplates } = useDeviceTemplates({
    select: (templates) =>
      templates.filter(
        (t) =>
          t.isActive &&
          (t.approvalStatus === SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.APPROVED ||
            (t.lastModifiedBy === userName &&
              t.approvalStatus === SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.PENDING)) &&
          t.deviceType.category === category,
      ),
  });
  return (
    <>
      <SelectButtonGroup
        buttonText="Add Device"
        placeholder="Select Device"
        options={deviceTemplates || []}
        renderOption={(o) => `${o.name} (${o.deviceType.name})`}
        renderSelectedOption={(o) => `${o.name} (${o.deviceType.name})`}
        getOptionValue={(o) => o.uuid}
        onSubmit={onSubmit}
        selectProps={{ MenuProps: { className: classes.selectPaper } }}
        buttonProps={{
          disabled,
          variant: 'outlined',
          color: 'primary',
        }}
      >
        <div className={classes.newDeviceTemplateMenuItemContainer}>
          <Divider />
          <MenuItem
            className={classes.newDeviceTemplateMenuItem}
            onClick={() => setIsEditing(true)}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New device" />
          </MenuItem>
        </div>
      </SelectButtonGroup>
      <Editor
        classes={classes}
        open={isEditing}
        onClose={() => setIsEditing(false)}
        formProps={{
          category,
          onSuccess: () => {
            showAlert({
              severity: 'success',
              message: 'Device created',
            });
            setIsEditing(false);
          },
          onError: () => {
            showAlert({
              severity: 'error',
              message: 'Failed to create device',
            });
            setIsEditing(false);
          },
        }}
      />
    </>
  );
}

export default withStyles(styles)(DeviceTemplateSelect);
