import React from 'react';

import { withStyles, Box, Button, List, ListItem, ListItemText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import ChipList from '../../../components/ChipList';

import { ROLES } from '../../../utils/user';

const styles = (theme) => ({
  formListItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rolesForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

function UserEditor({ classes, onSubmit, onCancel, submitText, user }) {
  const { control, reset, handleSubmit, formState } = useForm({ mode: 'all' });

  const { userName, realName, disabled } = user;

  React.useEffect(() => {
    reset(user || {});
  }, [reset, user]);

  return (
    <>
      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText
            primary="Username"
            secondary={userName}
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="Name"
            secondary={realName}
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="Status"
            secondary={disabled ? 'Disabled' : 'Active'}
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>
        <ListItem className={classes.formListItem} disableGutters>
          <ListItemText
            primary="Roles"
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
          <form className={classes.rolesForm} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="roles"
              control={control}
              defaultValue={user ? user.roles : []}
              // eslint-disable-next-line react/jsx-props-no-spreading
              render={({ onChange, value }) => (
                <ChipList
                  options={ROLES}
                  onChange={onChange}
                  values={value}
                  SelectButtonGroupProps={{
                    placeholder: 'Select new role',
                  }}
                />
              )}
            />
            <Box display="flex" justifyContent="flex-end">
              {user && <Button onClick={onCancel}>cancel</Button>}
              <Button
                disableElevation
                disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
                variant="contained"
                color="primary"
                type="submit"
              >
                {submitText}
              </Button>
            </Box>
          </form>
        </ListItem>
      </List>
    </>
  );
}

export default withStyles(styles)(UserEditor);
