import React from 'react';
import { sortBy as _sortBy } from 'lodash';
import { device } from 'mediadb-lib';
import { useDeviceTypes } from '../../../hooks/device';
import DataTable from '../../../components/DataTable';
import Error from '../../../components/Error';

const { SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES } = device;

const TABLE_COLUMNS = [
  {
    name: 'Device type name',
    key: 'name',
  },
  {
    name: 'Channels',
    key: 'channels',
    render: (channels) => channels.map(({ name }) => name),
  },
  {
    name: 'Sensors',
    key: 'sensorTemplates',
    render: (sensorTemplates) => sensorTemplates.map(({ name }) => name),
  },
  {
    name: 'Positions',
    key: 'positions',
  },
  {
    name: 'Approval status',
    key: 'approvalStatus',
    render: (approvalStatus) =>
      approvalStatus !== 'undefined'
        ? approvalStatus
        : SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.PENDING,
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

export default function DeviceTypesTable({ onClickRow, selected, category }) {
  const { data: deviceTypes = [], status } = useDeviceTypes();
  const categoryDeviceTypes = deviceTypes.filter((deviceType) => deviceType.category === category);
  const sortedDeviceTypes = _sortBy(categoryDeviceTypes, 'name');
  const selectedDeviceTypeIndex = sortedDeviceTypes.findIndex(
    (deviceType) => deviceType.uuid === selected,
  );
  return status === 'error' ? (
    <Error message="Failed to fetch device types" />
  ) : (
    <DataTable
      columns={TABLE_COLUMNS}
      rows={sortedDeviceTypes}
      rowKey="uuid"
      onClickRow={onClickRow}
      selectedRowIndices={[selectedDeviceTypeIndex]}
    />
  );
}
