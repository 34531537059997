import React from 'react';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import { APP_TITLE, APP_LOGO_WHITE_SRC, APP_LOGO_BLUE_SRC } from '../consts/app';

const styles = () => ({
  root: {},
  logo: {
    height: '40px',
  },
  title: {},
});

const AppTitle = ({ classes, logoColor }) => {
  const logoSrc = logoColor === 'blue' ? APP_LOGO_BLUE_SRC : APP_LOGO_WHITE_SRC;
  return (
    <Link to="/search/extracts">
      <Box className={classes.root}>
        <img className={classes.logo} src={logoSrc} alt={APP_TITLE} />
      </Box>
    </Link>
  );
};

export default withStyles(styles)(AppTitle);
