import React from 'react';

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useForm, Controller } from 'react-hook-form';

import SectionHeader from '../SectionHeader';
import { useUpdateExtractBulk } from '../../hooks/playlist';
import { calcNewTc, validateExtractTc, validateInOutPosition } from '../../utils/utils';

const styles = () => ({});

function ExtractTcDialog({
  onClose,
  onSuccess = () => {},
  onError = () => {},
  playlistId,
  extracts,
  open,
}) {
  const initialState = {
    in: 0,
    out: 0,
  };
  const [durationError, setDurationError] = React.useState(null);
  const { control, handleSubmit, formState } = useForm({
    defaultValues: initialState,
    mode: 'all',
  });
  const { mutateAsync: updateExtractBulk } = useUpdateExtractBulk();
  const submit = ({ in: tcIn, out: tcOut }) => {
    const updatedExtracts = extracts.map((extract) => {
      const newIn = tcIn !== 0 ? calcNewTc(extract.in, tcIn) : undefined;
      const newOut = tcOut !== 0 ? calcNewTc(extract.out, tcOut) : undefined;
      return {
        ...extract,
        comment: undefined,
        in: newIn,
        out: newOut,
        compareIn: newIn || extract.in,
        compareOut: newOut || extract.out,
      };
    });
    const promises = [];
    updatedExtracts.forEach((extract) => {
      if (extract.in) promises.push(validateExtractTc(extract.in, extract));
      if (extract.out) promises.push(validateExtractTc(extract.out, extract));
      promises.push(validateInOutPosition(extract.compareIn, extract.compareOut));
    });
    Promise.all(promises)
      .then(() => {
        updateExtractBulk({
          playlistId,
          extracts: updatedExtracts,
        })
          .then(onSuccess)
          .catch(onError);
      })
      .catch((error) => setDurationError(error.message));
  };
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <SectionHeader
          title="Edit extracts"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Box>
            <Box mb={4} display="grid" gridAutoFlow="column" gridGap={24}>
              <Controller
                name="in"
                control={control}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label="Adjust extract start"
                    type="number"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                    }}
                  />
                )}
              />
              <Controller
                name="out"
                control={control}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label="Adjust extract end"
                    type="number"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          {durationError && <Alert severity="error">{durationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary" disabled={!formState.isDirty}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default withStyles(styles)(ExtractTcDialog);
