import React from 'react';

import { withStyles, Chip, Box } from '@material-ui/core';

import SelectButtonGroup from '../containers/Upload/components/SelectButtonGroup';

const styles = (theme) => ({
  chipRoot: {
    marginTop: theme.spacing(1),
    width: '100%',
    backgroundColor: '#eee',
  },
  chipLabel: {
    marginRight: 'auto',
  },
  chipIcon: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
});

function ChipList({
  classes,
  options,
  values = [],
  onChange,
  getChipIcon = () => {},
  getOptionValue = (v) => v,
  getOptionLabel = (v) => v,
  getOptionSelected = (o, v) => o === v,
  SelectButtonGroupProps = {},
  ChipProps = {},
}) {
  return (
    <Box>
      <SelectButtonGroup
        options={options}
        buttonProps={{
          variant: 'outlined',
          color: 'primary',
        }}
        isDisabled={(o) => values.find((v) => getOptionSelected(v, o)) !== undefined}
        onSubmit={(value) => onChange([...values, value])}
        renderOption={getOptionValue}
        renderSelectedOption={getOptionValue}
        getOptionValue={getOptionValue}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...SelectButtonGroupProps}
      />
      {values.map((value) => (
        <Chip
          classes={{
            root: classes.chipRoot,
            label: classes.chipLabel,
            icon: classes.chipIcon,
          }}
          key={getOptionValue(value)}
          icon={<span>{getChipIcon(value)}</span>}
          label={getOptionLabel(value)}
          onDelete={() => onChange(values.filter((v) => !getOptionSelected(v, value)))}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...ChipProps}
        />
      ))}
    </Box>
  );
}

export default withStyles(styles)(ChipList);
