export const SONOVA_PACKAGE_STATUS = 'sonova_packageStatus';
export const SONOVA_PACKAGE_NAME = 'sonova_packageName';
export const SONOVA_PACKAGE_LOCATION = 'sonova_packageLocation';
export const SONOVA_PACKAGE_DESCRIPTION = 'sonova_packageDescription';
export const SONOVA_PACKAGE = 'sonova_package';
export const SONOVA_PACKAGE_DURATION = 'sonova_packageDuration';
export const SONOVA_PACKAGE_PERSON = 'sonova_packagePerson';
export const SONOVA_IS_GENERIC = 'sonova_isGeneric';
export const SONOVA_LABEL_ITEM_ID = 'sonova_labelItemId';
export const SONOVA_PACKAGE_ID = 'sonova_packageId';
export const SONOVA_DEVICE_POSITION = 'sonova_devicePosition';
export const SONOVA_CHANNELS = 'sonova_channels';
export const SONOVA_CHANNEL = 'sonova_channel';
export const SONOVA_CHANNEL_NAME = 'sonova_channelName';
export const SONOVA_CHANNEL_FILENAME = 'sonova_channelFilename';
export const SONOVA_CHANNEL_PLAYBACK = 'sonova_channelPlayback';
export const SONOVA_DEVICE = 'sonova_device';
export const SONOVA_DEVICES = 'sonova_devices';
export const SONOVA_INTERLOCUTOR_NAME = 'sonova_interlocutorName';
export const SONOVA_INTERLOCUTORS = 'sonova_interlocutors';
export const SONOVA_INTERLOCUTOR = 'sonova_interlocutor';
export const SONOVA_DEVICE_TEMPLATES = 'sonova_deviceTemplates';
export const SONOVA_DEVICE_TEMPLATES_DOCUMENT = 'sonova_deviceTemplatesDocument';
export const SONOVA_ADDITIONAL_SOURCES_TEMPLATES_DOCUMENT =
  'sonova_additionalSourcesTemplatesDocument';
export const SONOVA_LABEL_TRACK_GROUP_TEMPLATES = 'sonova_labelTrackGroups';
export const SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT =
  'sonova_labelTrackGroupTemplatesDocument';
export const SONOVA_PACKAGE_PREVIEW = 'sonova_packagePreview';
export const SONOVA_EXPORT = 'sonova_export';
export const SONOVA_EXPORT_PLAYLIST_NAME = 'sonova_export_playlist_name';
export const SONOVA_EXPORT_FINISHED = 'sonova_export_finished';
export const SONOVA_EXPORT_SUCCESSFUL = 'sonova_export_successful';
export const SONOVA_EXPORT_STATUS = 'sonova_export_status';
export const SONOVA_EXPORT_JOB_ID = 'sonova_export_job_id';
export const SONOVA_PACKAGE_GROUP = 'sonova_packageGroup';
export const SONOVA_PACKAGE_GROUP_NAME = 'sonova_packageGroupName';
export const SONOVA_PACKAGE_GROUP_DESCRIPTION = 'sonova_packageGroupDescription';
export const SONOVA_PACKAGE_GROUP_IS_ACTIVE = 'sonova_packageGroupIsActive';
export const SONOVA_COLLECTION_TYPE = 'sonova_collection_type';
export const SONOVA_COLLECTION_TYPES = {
  PACKAGE: 'package',
  EXPORT: 'export',
  PLAYLIST: 'playlist',
  PACKAGE_GROUP: 'packageGroup',
};

export const ACCURATE_VIDEO_GROUPS = {
  TRACK: 'AvTrack',
  MARKER: 'AvMarker',
};
export const ACCURATE_VIDEO_TRACK_FIELDS = {
  NAME: 'av_track_name',
  TYPE: 'av_track_type',
  TYPES: 'av_track_types',
  COLOUR: 'av_track_colour',
  REQUIRED: 'av_track_required',
  PARENT_ID: 'av_parent_track_id',
  PARENT_TITLE: 'av_parent_title',
};
export const ACCURATE_VIDEO_TRACK_GROUP_FIELDS = {
  NAME: 'av_track_name',
  TYPE: 'av_track_type',
};
export const ACCURATE_VIDEO_MARKER_FIELDS = {
  NAME: 'av_marker_name',
  TRACK_ID: 'av_marker_track_id',
  DESCRIPTION: 'av_marker_description',
  TRACK_NAME: 'av_marker_track_name',
  TRACK_GROUP_NAME: 'av_marker_track_group_name',
  TRACK_GROUP_TYPE: 'av_marker_track_group_type',
};
