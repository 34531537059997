import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React from 'react';
import { Remove as RemoveIcon } from '@material-ui/icons';
import NumberField from '../../../components/metadatafields/Number';

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: theme.spacing(1),
    '& .MuiTextField-root': {
      width: 60,
      marginRight: theme.spacing(1),
    },
  },
  label: {
    flexBasis: 100,
    marginRight: theme.spacing(1),
  },
  remove: {
    marginTop: 'auto',
  },
});

const TechnicalForm = ({
  classes,
  register,
  control,
  fieldKey,
  name,
  min,
  max,
  label,
  onRemove,
}) => {
  return (
    <div className={classes.root}>
      <Tooltip title={label}>
        <Typography noWrap variant="caption" className={classes.label}>
          {label}
        </Typography>
      </Tooltip>
      <Controller
        name={`${fieldKey}.min`}
        control={control}
        defaultValue={min}
        render={({ onChange, onBlur, value }) => (
          <NumberField value={value} size="small" label="Min" onBlur={onBlur} onChange={onChange} />
        )}
      />
      <Controller
        name={`${fieldKey}.max`}
        control={control}
        defaultValue={max}
        render={({ onChange, onBlur, value }) => (
          <NumberField value={value} size="small" label="Max" onBlur={onBlur} onChange={onChange} />
        )}
      />
      <input type="hidden" name={`${fieldKey}.name`} defaultValue={name} ref={register()} />
      <IconButton className={classes.remove} size="small" onClick={onRemove}>
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(TechnicalForm);
