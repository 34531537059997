/* eslint-disable no-param-reassign */
import React from 'react';
import { SearchInput as VdtSearchInput } from '@vidispine/vdt-materialui';
import { withStyles } from '@material-ui/core';
import SearchSuggestions from './SearchSuggestions';

const styles = (theme) => ({
  button: {
    border: '1px solid',
    borderLeft: 'none',
    borderColor: theme.palette.grey[400],
    transition: 'border-color .3s ease-in',
  },
  suggestions: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderTop: 'none',
  },
});

function SearchInput({ classes, suggestions, onClickSuggestion, searchInputRef, ...props }) {
  const [focusedSuggestion, setFocusedSuggestion] = React.useState(0);
  const validSuggestions = React.useMemo(() => suggestions.filter((s) => s.length > 0), [
    suggestions,
  ]);
  const suggestionsWithCurrent = React.useMemo(
    () => [...(searchInputRef.current ? [searchInputRef.current.value] : []), ...validSuggestions],
    [validSuggestions, searchInputRef],
  );

  React.useEffect(() => {
    setFocusedSuggestion(0);
  }, [suggestionsWithCurrent]);

  const mod = (n, m) => ((n % m) + m) % m;
  const newFocused = (prev, e) =>
    mod(prev + (e.key === 'ArrowDown' ? 1 : -1), suggestionsWithCurrent.length);

  return (
    <VdtSearchInput
      classes={{ button: classes.button }}
      searchPlaceholder="Search exported playlists"
      SuggestionsComponent={SearchSuggestions}
      SuggestionsProps={{
        suggestions: validSuggestions,
        classes: { popper: classes.suggestions },
        focused: focusedSuggestion - 1,
        onHover: (i) => setFocusedSuggestion(i + 1),
        onClick: (s) => {
          onClickSuggestion(s);
          searchInputRef.current.value = s;
        },
      }}
      inputRef={searchInputRef}
      InputBaseProps={{
        onKeyDown: (e) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            searchInputRef.current.value = suggestionsWithCurrent[newFocused(focusedSuggestion, e)];
            setFocusedSuggestion((prev) => newFocused(prev, e));
            e.preventDefault();
          }
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default withStyles(styles)(SearchInput);
