import { Typography } from '@material-ui/core';
import React from 'react';
import useCreateDevice from '../../../hooks/useCreateDevice';
import { updateWhere } from '../../../utils/utils';
import DeviceComponent from './DeviceComponent';
import DeviceTemplateSelect from './DeviceTemplateSelect';

export default function InterlocutorComponent({
  files,
  interlocutor,
  onChange,
  onChangeFiles,
  onDeleteUsedFiles,
  usedFiles,
  customDeviceFields,
  customSensorFields,
}) {
  const { devices } = interlocutor;
  const packageAudioFiles = files.filter(({ metadata }) => !metadata.generic);

  const { createDevice, canCreateDevice } = useCreateDevice();

  const handleAddDevice = (deviceTemplate) => {
    const newDevice = createDevice(deviceTemplate);
    onChange({ ...interlocutor, devices: [newDevice, ...interlocutor.devices] });
  };

  const handleChangeDevice = (newDevice) => {
    onChange({
      ...interlocutor,
      devices: updateWhere(interlocutor.devices, (d) => d.id === newDevice.id, newDevice),
    });
  };

  const handleDeleteDevice = (device) => {
    const filenames = [...device.channels, ...device.sensors.flatMap((s) => s.channels)]
      .map((channel) => channel.value)
      .filter((filename) => filename !== undefined);
    if (filenames.length) onDeleteUsedFiles(filenames);
    onChange({
      ...interlocutor,
      devices: interlocutor.devices.filter((d) => d.id !== device.id),
    });
  };

  return (
    <>
      <Typography variant="caption">Devices</Typography>
      <DeviceTemplateSelect
        onSubmit={handleAddDevice}
        category="device"
        disabled={!canCreateDevice}
      />
      {devices.length > 0 &&
        devices.map((device) => (
          <DeviceComponent
            device={device}
            key={device.id}
            files={packageAudioFiles}
            usedFiles={usedFiles}
            deviceFields={customDeviceFields}
            sensorFields={customSensorFields}
            onDelete={() => handleDeleteDevice(device)}
            onChange={handleChangeDevice}
            onChangeFiles={onChangeFiles}
          />
        ))}
    </>
  );
}
