import React from 'react';
import {
  Container,
  Paper,
  withStyles,
  LinearProgress,
  IconButton,
  Typography,
  emphasize,
} from '@material-ui/core';
import clsx from 'clsx';
import { TextBoxSearch as LogIcon } from 'mdi-material-ui';
import { ExpandLess, ExpandMore, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useAuditLog } from '../../../hooks/auditlog';
import useDebounce from '../../../hooks/useDebounce';
import SectionHeader from '../../../components/SectionHeader';
import FilterCard from '../../Search/components/FilterCard';
import { DateRangeField, SelectField, TextField } from '../../Search/components/InputFields';
import UserAutocomplete from '../../../components/UserAutocomplete';
import DataTable from '../../../components/DataTable';

const styles = (theme) => ({
  paper: {
    maxHeight: `calc(100vh - 70px - ${theme.spacing(8)}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  tableRoot: {
    overflow: 'auto',
    margin: theme.spacing(2),
    padding: `0px ${theme.spacing(1)}px`,
    '& .MuiTableBody-root .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  pagination: {
    marginTop: 'auto',
    minHeight: '52px',
  },
  searchProgress: {
    height: '20px',
    margin: '20px 50px 0px 40px',
  },
});

const HorizontalFilterCard = withStyles({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    '& .filterRow': {
      marginRight: '16px',
      minWidth: '250px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    zIndex: 99,
  },
})(FilterCard);

const INITIAL_FILTER_VALUES = [
  { field: 'method', value: '' },
  { field: 'path', value: '' },
  { field: 'user', value: null },
];

const HorizonalSelectField = withStyles({
  root: { '& .VdtSelectField-formHelperText': { display: 'none' } },
})(SelectField);

const HorizonalTextField = withStyles({
  root: { '& .MuiFormHelperText-root': { display: 'none' } },
})(TextField);

const FILTERS = [
  {
    field: 'user',
    label: 'User',
    type: 'value',
    component: UserAutocomplete,
    componentProps: {
      transformOptions: (options) => options.map(({ userName }) => userName),
      getOptionLabel: (u) => u,
    },
  },
  {
    field: 'method',
    label: 'Method',
    type: 'value',
    component: HorizonalSelectField,
    componentProps: {
      showClear: true,
      options: [
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
      ],
    },
  },
  {
    field: 'path',
    label: 'Path',
    type: 'value',
    component: HorizonalTextField,
  },
  {
    field: 'timestamp',
    label: 'Timestamp',
    type: 'range',
    component: DateRangeField,
  },
];

function AuditLogFilters({ onChange }) {
  const [filterValues, setFilterValues] = React.useState(INITIAL_FILTER_VALUES);
  const debouncedFilterValues = useDebounce(filterValues, 500);

  React.useEffect(() => {
    onChange(debouncedFilterValues);
  }, [debouncedFilterValues, onChange]);

  return (
    <HorizontalFilterCard
      onReset={() => setFilterValues(INITIAL_FILTER_VALUES)}
      FilterProps={{
        filters: FILTERS,
        facets: [],
        values: filterValues,
        onChange: setFilterValues,
      }}
    />
  );
}

function ViewRequestBody({ body, className }) {
  function parseBody() {
    try {
      return JSON.stringify(JSON.parse(body), undefined, 2);
    } catch (e) {
      return body;
    }
  }

  return <pre className={className}>{parseBody(body)}</pre>;
}

function InfoBlock({ className, label, children, style = {} }) {
  return (
    <div className={className} style={style}>
      <Typography
        // style={{ fontWeight: 500 }}
        color="textSecondary"
        variant="overline"
        display="block"
        gutterBottom
      >
        {label}
      </Typography>
      {children}
    </div>
  );
}

function QueryParams({ className, value }) {
  return value
    ? value
        .split('&')
        .map((kv) => kv.split('='))
        .map(
          ([k, v]) =>
            k &&
            v && (
              <p className={className} key={k}>
                <span style={{ fontWeight: 500 }}>{k}</span>: {v}
              </p>
            ),
        )
    : '-';
}

const ExpandedAuditLogEntry = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: emphasize(theme.palette.background.paper, 0.02),
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr);',
    gridTemplateRows: 'repeat(2, auto);',
    gridGap: theme.spacing(2),
  },
  infoBlock: {
    wordBreak: 'break-all',
  },
  bodyBlock: {
    overflow: 'auto',
  },
  body: {
    padding: theme.spacing(2),
    backgroundColor: emphasize(theme.palette.background.paper, 0.06),
    overflow: 'auto',
  },
  queryParams: {
    margin: '4px',
  },
}))(({ classes, entry }) => {
  const { body, responseCode, contentType, contentLength, queryParameters } = entry;
  return (
    <div className={classes.root}>
      <InfoBlock className={classes.infoBlock} label="Response code">
        {responseCode || '-'}
      </InfoBlock>
      <InfoBlock className={classes.infoBlock} label="Content length">
        {contentLength || '-'}
      </InfoBlock>
      <InfoBlock className={classes.infoBlock} label="Content type">
        {contentType || '-'}
      </InfoBlock>
      <InfoBlock className={classes.infoBlock} label="Query parameters">
        <QueryParams className={classes.queryParams} value={queryParameters} />
      </InfoBlock>
      <InfoBlock className={classes.bodyBlock} label="Body" style={{ gridColumn: '1 / span 4' }}>
        {body ? <ViewRequestBody className={classes.body} body={body} /> : '-'}
      </InfoBlock>
    </div>
  );
});

const MethodText = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.dark,
    fontWeight: '600',
    '&.get': {
      color: theme.palette.primary.main,
    },
    '&.post': {
      color: theme.palette.success.main,
    },
    '&.put': {
      color: theme.palette.warning.main,
    },
  },
}))(({ classes, method }) => {
  return (
    <p
      className={clsx(
        classes.root,
        { get: method === 'GET' },
        { post: method === 'POST' },
        { put: method === 'PUT' },
      )}
    >
      {method}
    </p>
  );
});

const TABLE_COLUMNS = [
  {
    name: 'User',
    key: 'username',
  },
  {
    name: 'Method',
    key: 'method',
    render: (method) => <MethodText method={method} />,
  },
  {
    name: 'Path',
    key: 'path',
  },
  {
    name: 'Timestamp',
    key: 'timestamp',
  },
];
function AuditLogPagination({ page, onChangePage, hasNextPage, isFetching }) {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: 50 }}
    >
      <IconButton
        size="small"
        disabled={page === 0}
        onClick={() => {
          onChangePage(page - 1);
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <span style={{ padding: '0px 10px' }}>{page + 1}</span>
      <IconButton
        size="small"
        disabled={!hasNextPage || isFetching}
        style={{ marginRight: '24px' }}
        onClick={() => onChangePage(page + 1)}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
}
function AuditLogTable({ classes, entries }) {
  const [expandedRow, setExpandedRow] = React.useState();
  return (
    <div className={classes.root}>
      <DataTable
        getRowKey={(row, i) => `${i}-${row.timestamp}`}
        columns={TABLE_COLUMNS}
        rows={entries}
        TableProps={{ stickyHeader: true }}
        ExpandedComponent={({ row }) => <ExpandedAuditLogEntry entry={row} />}
        expandedRows={[expandedRow]}
        RowActions={({ rowKey }) => (
          <IconButton onClick={() => setExpandedRow((prev) => rowKey !== prev && rowKey)}>
            {expandedRow === rowKey ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      />
    </div>
  );
}

function filterValuesToQueryParams(filterValues) {
  const findFilter = (name) => filterValues.find(({ field }) => field === name);
  const timestamp = findFilter('timestamp')?.range || [];
  return {
    starttime: timestamp[0] || undefined,
    endtime: timestamp[1] || undefined,
    path: findFilter('path')?.value || undefined,
    method: findFilter('method')?.value || undefined,
    username: findFilter('user')?.value || undefined,
  };
}

const ROWS_PER_PAGE = 10;

function AuditLog({ classes }) {
  const [filterValues, setFilterValues] = React.useState(INITIAL_FILTER_VALUES);

  const [page, setPage] = React.useState(0);
  const { data: auditLog, isPreviousData, isFetching } = useAuditLog(
    {
      first: page * ROWS_PER_PAGE,
      rows: ROWS_PER_PAGE,
      performCount: false,
      body: true,
      ...filterValuesToQueryParams(filterValues),
    },
    {
      keepPreviousData: true,
    },
  );
  const entries = auditLog?.entry || [];

  const onChangeFilter = React.useCallback((newFilterValues) => {
    setFilterValues(newFilterValues);
    setPage(0);
  }, []);
  return (
    <Container maxWidth={false}>
      <Paper className={clsx('adminContainer', classes.paper)} variant="outlined">
        <SectionHeader
          Icon={LogIcon}
          title="Audit Log"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <AuditLogFilters onChange={onChangeFilter} />
        <div className={classes.searchProgress}>
          {isPreviousData && <LinearProgress variant="query" />}
        </div>
        <AuditLogTable classes={{ root: classes.tableRoot }} entries={entries} />
        <AuditLogPagination
          isFetching={isFetching}
          page={page}
          onChangePage={(newPage) => {
            setPage(newPage);
          }}
          hasNextPage={ROWS_PER_PAGE === entries.length}
        />
      </Paper>
    </Container>
  );
}

export default withStyles(styles)(AuditLog);
