"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _consts = require("./consts");

var _metadata = _interopRequireDefault(require("./metadata"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var TYPES = _metadata["default"].MEDIADB_FIELD_TYPES;
var _default = {
  PACKAGE_NAME: {
    fieldName: _consts.SONOVA_PACKAGE_NAME,
    label: 'Package name',
    mediaDBType: TYPES.String,
    required: true,
    isMultiValue: false
  },
  PACKAGE_LOCATION: {
    fieldName: _consts.SONOVA_PACKAGE_LOCATION,
    label: 'Package location',
    mediaDBType: TYPES.String,
    required: true,
    isMultiValue: false
  },
  PACKAGE_DESCRIPTION: {
    fieldName: _consts.SONOVA_PACKAGE_DESCRIPTION,
    label: 'Package description',
    mediaDBType: TYPES.String,
    required: false,
    isMultiValue: false
  }
};
exports["default"] = _default;