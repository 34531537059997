/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import PackageCard from './PackageCard';

export default function PackageGrid({
  packages = [],
  PackageCardProps,
  GridContainerProps,
  GridItemProps,
  onItemClick,
}) {
  const { style: gridItemPropsStyle = {} } = GridItemProps;
  return (
    <Grid container {...GridContainerProps} style={{ width: 'calc(100% + 16px)' }}>
      {packages &&
        packages.map((pkg, index) => (
          <>
            {index % 10 === 0 && index !== 0 && (
              <Grid
                item
                key={`divider-${pkg.packageId}`}
                {...GridItemProps}
                style={gridItemPropsStyle}
              >
                <Divider />
              </Grid>
            )}
            <Grid
              item
              xs="auto"
              key={pkg.packageId}
              onClick={() => onItemClick && onItemClick(pkg)}
              {...GridItemProps}
              style={{ cursor: onItemClick ? 'pointer' : 'initial', ...gridItemPropsStyle }}
            >
              <PackageCard
                index={index}
                packageId={pkg.packageId}
                package={pkg}
                {...PackageCardProps}
              />
            </Grid>
          </>
        ))}
    </Grid>
  );
}
