import { withStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Filter from './Filter';

const styles = (theme) => ({
  root: {},
  filter: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

function FilterCard({ classes, className, onReset, FilterProps = {}, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box className={clsx(className, classes.root)} {...props}>
      <Filter
        className={classes.filter}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...FilterProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
      />
    </Box>
  );
}

export default withStyles(styles)(FilterCard);
