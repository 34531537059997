import { Box, Button, Typography, Link, Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { ChevronLeft } from 'mdi-material-ui';

const styles = () => ({});

const PageError = ({ title, subtitle }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Paper
        elevation={1}
        style={{
          backgroundColor: '#fff',
          padding: '33px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            color: '#FFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FF0000',
            padding: '30px',
            fontSize: '35px',
            fontWeight: '700',
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            marginBottom: '17px',
          }}
        >
          !
        </span>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" gutterBottom style={{ fontSize: '1rem', marginTop: '5px' }}>
          {subtitle}
        </Typography>
        <Button
          href="/search/packages"
          color="primary"
          component={Link}
          variant="outlined"
          startIcon={<ChevronLeft />}
          style={{ marginTop: 8 }}
        >
          Back to start page
        </Button>
      </Paper>
    </Box>
  );
};

export default withStyles(styles)(PageError);
