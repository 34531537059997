/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { debounce } from 'lodash';
import { DialogContent, DialogContentText, IconButton, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { parseMetadataType } from '@vidispine/vdt-js';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { auditlog, playlist } from 'mediadb-lib';

import { Sort, Pagination, CollectionTable, SpellCheck } from '@vidispine/vdt-materialui';

import { useAutocomplete } from '@vidispine/vdt-react';

import { downloadCollectionAsZip, getDateString } from '../../../utils/utils';
import SearchInput from './SearchInput';

import {
  SONOVA_EXPORT,
  SONOVA_EXPORT_PLAYLIST_NAME,
  SONOVA_EXPORT_STATUS,
} from '../../../consts/metadata';
import { useDeleteExportedPlaylist, useSearchExportedPlaylists } from '../../../hooks/playlist';
import useConfirmationDialog from '../../../contexts/ConfirmationDialogContext';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import DisabledOnClickIconButton from '../../../components/DisabledOnClickIconButton';

const { MEDIADB_EVENT } = auditlog;

const defaultSortOptions = [
  { label: 'Created', value: 'created' },
  { label: 'Playlist', value: SONOVA_EXPORT_PLAYLIST_NAME },
  { label: 'User', value: '__owner' },
  { label: 'Status', value: SONOVA_EXPORT_STATUS },
];

const styles = (theme) => ({
  root: {
    minHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    maxHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    paddingRight: theme.spacing(1),
    marginRight: `-${theme.spacing(1)}px`,
    overflowY: 'auto',
    '& .statusText': {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: theme.palette.warning.main,
      '&.statusText--success': {
        color: theme.palette.success.main,
      },
      '&.statusText--error': {
        color: theme.palette.error.main,
      },
    },
    '& .MuiTableRow-root': {
      cursor: 'auto !important',
      height: '61px',
      '& .MuiTableCell-root:last-child': {
        color: '#f09',
        padding: '6px',
        width: '61px',
      },
    },
    width: '100%',
    '& .SearchInputWrapper': {
      flexGrow: 6,
      '& form:focus-within': {
        '& .MuiButton-root': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiInputBase-root': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiButton-root': {
        backgroundColor: theme.palette.background.paper,
        height: '44px',
        borderLeft: 'none',
      },
      '& .MuiInputBase-root': {
        fontSize: '1rem',
        backgroundColor: theme.palette.background.paper,
        height: '44px',
        borderRight: 'none',
        paddingLeft: theme.spacing(2),
      },
    },
    '& .SearchControlsWrapper': {
      flexGrow: 1,
    },
  },
  searchWrapper: {
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    paddingBottom: theme.spacing(2),
  },
});

const ROWS_PER_PAGE = 10;

const ExportedPlaylists = ({ classes }) => {
  const { suggestions, setAutocompleteText } = useAutocomplete({
    field: SONOVA_EXPORT_PLAYLIST_NAME,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAutocompleteText = React.useCallback(debounce(setAutocompleteText, 100), [
    setAutocompleteText,
  ]);

  const [page, setPage] = React.useState(0);
  const first = page * ROWS_PER_PAGE;
  const [sort, setSort] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [refetchInterval, setRefetchInterval] = React.useState(false);
  const { data, isPreviousData: isLoading } = useSearchExportedPlaylists(
    { name: searchText, first: first + 1, number: ROWS_PER_PAGE, sort },
    { refetchInterval, keepPreviousData: true },
  );
  const { mutateAsync: deleteExportedPlaylist } = useDeleteExportedPlaylist();
  const { showAlert } = useSnackbar();

  React.useEffect(() => {
    const hasUnfinishedExports = (() => {
      if (!data) return false;
      const { collection = [] } = data;
      const exportsIsFinished = collection.map((c) => {
        const { metadata = {} } = c;
        const { isFinished } = playlist.getExportStatus(metadata);
        return isFinished;
      });
      return exportsIsFinished.some((s) => !s);
    })();
    if (hasUnfinishedExports) {
      setRefetchInterval(5000);
    } else {
      setRefetchInterval(false);
    }
  }, [data]);

  const { hits: count = 0, suggestion = [] } = data || {};

  const searchInputRef = React.useRef(null);

  const onDownload = (id, playlistName) => {
    auditlog.addMediaDBLogEntry(
      MEDIADB_EVENT.DOWNLOAD('exportedPlaylist', { exportId: id, playlistName }),
    );
    downloadCollectionAsZip(id, `${playlistName}.zip`);
  };
  const CreatedComponent = ({ value }) => (value ? getDateString(value) : '');

  const DownloadComponent = ({ innerProps }) => {
    // check status field from innerProps whether to render button
    const {
      collectionId,
      collectionType: { metadata = {} },
    } = innerProps;
    const playlistName = playlist.getExportPlaylistName(metadata);
    const { isFinished, isSuccess } = playlist.getExportStatus(metadata);
    return (
      <>
        {isFinished && isSuccess && (
          <Tooltip title="Download Exported Playlist">
            <span>
              <DisabledOnClickIconButton
                duration={2500}
                onClick={() => onDownload(collectionId, playlistName)}
              >
                <DownloadIcon />
              </DisabledOnClickIconButton>
            </span>
          </Tooltip>
        )}
      </>
    );
  };
  const DeleteComponent = ({ innerProps }) => {
    const { confirm: confirmDelete } = useConfirmationDialog({ throwOnReject: false });
    const {
      collectionId,
      collectionType: { metadata = {} },
    } = innerProps;
    const playlistName = playlist.getExportPlaylistName(metadata);
    const { isFinished } = playlist.getExportStatus(metadata);

    const handleClick = async () => {
      if (
        await confirmDelete({
          titleText: `Delete exported playlist`,
          rejectText: 'Cancel',
          confirmText: 'Delete',
          fullWidth: true,
          children: (
            <DialogContent>
              <DialogContentText>
                This action will permanently delete the exported playlist: <b>{playlistName}</b> and
                cannot be undone.
              </DialogContentText>
            </DialogContent>
          ),
        })
      ) {
        deleteExportedPlaylist(collectionId)
          .then(() => {
            showAlert({
              severity: 'success',
              message: `Exported playlist ${playlistName} was deleted`,
            });
          })
          .catch((error) => {
            showAlert({ severity: 'error', message: error.message });
          });
      }
    };
    return (
      <>
        {isFinished && (
          <Tooltip title="Delete Exported Playlist">
            <IconButton onClick={handleClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const ActionsComponent = (props) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DownloadComponent {...props} />
        <DeleteComponent {...props} />
      </div>
    );
  };

  const StatusComponent = ({ value, innerProps }) => {
    const {
      collectionType: { metadata = {} },
    } = innerProps;
    const { isFinished, isSuccess } = playlist.getExportStatus(metadata);
    return (
      <div
        className={clsx(
          'statusText',
          { 'statusText--success': isFinished && isSuccess },
          { 'statusText--error': isFinished && !isSuccess },
        )}
      >
        {isFinished && !isSuccess ? (
          <Tooltip title={value}>
            <span>Failed</span>
          </Tooltip>
        ) : (
          <>{value}</>
        )}
      </div>
    );
  };
  const options = [
    {
      label: 'Playlist',
      value: SONOVA_EXPORT_PLAYLIST_NAME,
    },
    {
      label: 'Created',
      value: 'created',
      valueComponent: CreatedComponent,
    },
    {
      label: 'User',
      value: '__owner',
    },
    {
      label: 'Export id',
      value: 'collectionId',
    },
    {
      label: 'Status',
      value: SONOVA_EXPORT_STATUS,
      valueComponent: StatusComponent,
    },
    {
      label: '',
      valueComponent: ActionsComponent,
    },
  ];

  const metadataToProps = ({ collectionType, parseMetadataOpts, options: opts }) => {
    const { metadata = {} } = collectionType;
    const parsedMetadata = parseMetadataType(metadata, parseMetadataOpts);
    const { [SONOVA_EXPORT]: exportGroup = {} } = parsedMetadata;
    const joinedParsed = { ...parsedMetadata, ...exportGroup };
    return opts.map(({ value }) => {
      const { [value]: parsedValue } = joinedParsed;
      return parsedValue;
    });
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.searchWrapper}
      >
        <Grid item className="SearchInputWrapper">
          <SearchInput
            submitting={isLoading}
            suggestions={suggestions}
            onSubmit={setSearchText}
            searchPlaceholder="Search exported playlists"
            onChange={debouncedSetAutocompleteText}
            onClickSuggestion={(s) => {
              setSearchText(s);
              debouncedSetAutocompleteText(s);
            }}
            searchInputRef={searchInputRef}
          />
        </Grid>
        <Grid item className="SearchControlsWrapper">
          <Grid container alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <Grid item>
              <Hidden smDown>
                <Sort
                  initialSort={{ option: defaultSortOptions[0], direction: 'descending' }}
                  options={defaultSortOptions}
                  onChange={(e, field, order) => setSort([{ field, order }])}
                />
              </Hidden>
            </Grid>
            <Grid item style={{ overflow: 'hidden' }}>
              <Pagination
                onChangePage={({ page: newPage }) => setPage(newPage)}
                count={count}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
                page={page}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={1}>
        {suggestion && suggestion.length > 0 ? (
          <SpellCheck
            searchText={searchText.toLowerCase()}
            suggestions={suggestion}
            onClick={(s) => {
              setSearchText(s);
              searchInputRef.current.value = s;
            }}
          />
        ) : null}
      </Box>
      {data && (
        <CollectionTable
          collectionListType={data}
          options={options}
          onRowClick={() => null}
          metadataToProps={metadataToProps}
        />
      )}
    </Box>
  );
};

export default withStyles(styles)(ExportedPlaylists);
