/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { TextField, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  inputGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto',
    gridGap: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const TextButtonGroup = ({
  classes,
  occupiedInputs = [],
  buttonText = 'Add',
  emptyValueError = 'Required',
  onSubmit,
  textFieldProps = {},
  buttonProps = {},
}) => {
  const [typeValue, setTypeValue] = React.useState('');
  const [inputError, setInputError] = React.useState(false);
  const [inputHelperText, setInputHelperText] = React.useState('');
  const handleOnClick = () => {
    if (typeValue !== '') {
      onSubmit(typeValue);
      setTypeValue('');
      if (inputError) {
        setInputError(false);
        setInputHelperText('');
      }
    } else {
      setInputError(true);
      setInputHelperText(emptyValueError);
    }
  };
  return (
    <div className={classes.inputGroup}>
      <FormControl error={inputError}>
        <TextField
          value={typeValue}
          onChange={({ target: { value = '' } = {} }) => setTypeValue(value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setTypeValue(typeValue);
              handleOnClick();
            }
          }}
          {...textFieldProps}
        />
        <FormHelperText>{inputHelperText}</FormHelperText>
      </FormControl>
      <div className={classes.buttonContainer}>
        <Button
          disabled={occupiedInputs.includes(typeValue.trim())}
          onClick={handleOnClick}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(TextButtonGroup);
