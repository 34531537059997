import React from 'react';
import { useDropzone } from 'react-dropzone';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  dropzoneRoot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  dropzoneLabel: {
    color: theme.palette.primary.main,
    ...theme.typography.body1,
  },
  dropzoneInput: {
    minHeight: '50vh',
    minWidth: theme.spacing(4),
    borderWidth: 2,
    borderColor: theme.palette.primary.light,
    borderRadius: 3,
    borderStyle: 'dotted',
    backgroundColor: 'rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'min-height 0.3s',
  },
  dropzoneDrag: {
    borderStyle: 'solid',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  dropzoneHasFiles: {
    minHeight: theme.spacing(8),
  },
});

function FileDropzone({ classes, files, onAddFiles }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onAddFiles });

  return (
    <div className={classes.dropzoneRoot}>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps()}
        className={clsx(classes.dropzoneInput, {
          [classes.dropzoneDrag]: isDragActive,
          [classes.dropzoneHasFiles]: files.length > 0,
        })}
      >
        <div className={classes.dropzoneLabel}>
          {isDragActive ? 'Drop files' : 'Drop files or click to open'}
        </div>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps()}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(FileDropzone);
