import React from 'react';
import {
  Search as SearchIcon,
  VideoLibrary as PackageIcon,
  Publish as PublishIcon,
  SupervisorAccount as UsersIcon,
  Hearing as DeviceIcon,
  Mic as MicIcon,
  Movie as MovieIcon,
  Assignment as MetadataIcon,
  LocalOffer as TagIcon,
  PermMedia as PackageGroupIcon,
} from '@material-ui/icons';
import { TextBoxSearch as LogIcon } from 'mdi-material-ui';
import NavigationDrawer from './NavigationDrawer';
import { AuthContext } from '../../vdt/AuthProvider';

const userNavItems = [
  {
    title: 'Search',
    icon: <SearchIcon />,
    path: '/search/extracts',
    type: 'item',
  },
  {
    title: 'Packages',
    icon: <PackageIcon />,
    path: '/search/packages',
    type: 'item',
  },
  {
    title: 'Exported Playlists',
    icon: <MovieIcon />,
    path: '/search/exported-playlists',
    type: 'item',
  },
  {
    title: 'Upload',
    icon: <PublishIcon />,
    path: '/upload',
    type: 'item',
  },
];

const adminNavItems = [
  {
    title: 'Admin',
    expanded: false,
    type: 'group',
    items: [
      {
        title: 'Package Groups',
        icon: <PackageGroupIcon />,
        path: '/admin/package-groups',
        type: 'item',
      },
      {
        title: 'Users',
        icon: <UsersIcon />,
        path: '/admin/users',
        type: 'item',
      },
      {
        title: 'Metadata',
        icon: <MetadataIcon />,
        path: '/admin/metadata',
        type: 'item',
      },
      {
        title: 'Devices',
        icon: <DeviceIcon />,
        path: '/admin/devices',
        type: 'item',
      },
      {
        title: 'Additional Sources',
        icon: <MicIcon />,
        path: '/admin/sources',
        type: 'item',
      },
      {
        title: 'Label Track Groups',
        icon: <TagIcon />,
        path: '/admin/label-track-groups',
        type: 'item',
      },
      {
        title: 'Log',
        icon: <LogIcon />,
        path: '/admin/log',
        type: 'item',
      },
    ],
  },
];

export default function SideNav() {
  const { isAdmin } = React.useContext(AuthContext);
  const [navItems, setNavItems] = React.useState(userNavItems);
  const handleGroupExpandToggle = (item) => {
    setNavItems((prev) => prev.map((i) => (i.title === item.title ? item : i)));
  };
  React.useEffect(() => {
    setNavItems(isAdmin ? [...userNavItems, ...adminNavItems] : userNavItems);
  }, [isAdmin]);
  return (
    <NavigationDrawer
      navItems={navItems}
      drawerProps={{
        variant: 'permanent',
      }}
      onGroupExpandToggle={handleGroupExpandToggle}
    />
  );
}
