import { useQuery, useMutation, useQueryClient } from 'react-query';
import { label } from 'mediadb-lib';

export function useTechnicalLabelTracks() {
  return useQuery('technicalLabelTracks', label.getTechnicalLabelTracks);
}

export function useAddTechnicalLabelTrack() {
  const queryClient = useQueryClient();
  return useMutation(label.addTechnicalLabelTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries('technicalLabelTracks');
    },
  });
}

export function useUpdateTechnicalLabelTrack() {
  const queryClient = useQueryClient();
  return useMutation(label.updateTechnicalLabelTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries('technicalLabelTracks');
    },
  });
}

export function useIsTechnicalLabelTrackUsedbyPackage(opts = {}) {
  const { technicalLabelTrack } = opts;
  const { name } = technicalLabelTrack || {};
  return useQuery(
    `technicalLabelTrackRemovable-${name}`,
    () => label.isTechnicalLabelTrackUsedbyPackage(technicalLabelTrack),
    { ...opts, enabled: !!technicalLabelTrack },
  );
}

export function useRemoveTechnicalLabelTrack() {
  const queryClient = useQueryClient();
  return useMutation(label.removeTechnicalLabelTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries('technicalLabelTracks');
    },
  });
}

export function useLabelTrackGroups() {
  return useQuery('labelTrackGroups', label.getLabelTrackGroups);
}

export function useAddLabelTrackGroup() {
  const queryClient = useQueryClient();
  return useMutation(label.addLabelTrackGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('labelTrackGroups');
    },
  });
}

export function useUpdateLabelTrackGroup() {
  const queryClient = useQueryClient();
  return useMutation(label.updateLabelTrackGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('labelTrackGroups');
    },
  });
}
export function useUpdateLabelTrackGroupsOrder() {
  const queryClient = useQueryClient();
  return useMutation(label.updateLabelTrackGroupsOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries('labelTrackGroups');
    },
  });
}

export function useAddLabelTrack() {
  const queryClient = useQueryClient();
  return useMutation(label.addLabelTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries('labelTrackGroups');
    },
  });
}

export function useUpdateLabelTrack() {
  const queryClient = useQueryClient();
  return useMutation(label.updateLabelTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries('labelTrackGroups');
    },
  });
}

export function useUpdateLabelTracksOrder() {
  const queryClient = useQueryClient();
  return useMutation(label.updateLabelTracksOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries('labelTrackGroups');
    },
  });
}
