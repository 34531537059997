import React from 'react';

import { withStyles, Box } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';

const styles = () => ({
  treeView: {
    '& .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-root:hover > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'unset',
    },
  },
});

function InterlocutorNode({ interlocutor, InterlocutorComponent, ...props }) {
  const { devices } = interlocutor;
  return (
    <Box>
      <TreeItem
        TransitionProps={{ timeout: 0 }}
        nodeId={interlocutor.uuid}
        key={interlocutor.uuid}
        label={
          <InterlocutorComponent
            interlocutor={interlocutor}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        }
      >
        {devices.map((d) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <DeviceNode key={d.uuid} device={d} {...props} />
        ))}
      </TreeItem>
    </Box>
  );
}

function DeviceNode({ device, DeviceComponent, ...props }) {
  const childChannels = device.channels;
  const { sensors } = device;
  return (
    <Box>
      <TreeItem
        TransitionProps={{ timeout: 0 }}
        nodeId={device.uuid}
        key={device.uuid}
        // eslint-disable-next-line react/jsx-props-no-spreading
        label={
          <DeviceComponent
            device={device}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        }
      >
        {childChannels.map((c) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ChannelNode key={c.uuid} channel={c} {...props} />
        ))}
        {sensors.map((s) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <SensorNode key={s.uuid} sensor={s} {...props} />
        ))}
      </TreeItem>
    </Box>
  );
}

function ChannelNode({ channel, ChannelComponent, ...props }) {
  return (
    <TreeItem
      TransitionProps={{ timeout: 0 }}
      nodeId={channel.uuid}
      label={
        <ChannelComponent
          channel={channel}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      }
    />
  );
}

function SensorNode({ sensor, SensorComponent, ...props }) {
  const { channels } = sensor;
  return (
    <TreeItem
      TransitionProps={{ timeout: 0 }}
      nodeId={sensor.uuid}
      label={
        <SensorComponent
          sensor={sensor}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      }
    >
      {channels.map((c) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ChannelNode key={c.uuid} channel={c} {...props} />
      ))}
    </TreeItem>
  );
}

const parseDeviceChildUUIDS = (devices) => {
  const channels = devices.flatMap((d) => d.channels);
  const sensors = devices.flatMap((d) => d.sensors);
  const sensorChannels = sensors.map((s) => s.channels);
  return [...devices, ...channels, ...sensors, ...sensorChannels].map((x) => x.uuid);
};

const parseInterlocutorChildUUIDS = (interlocutors) => {
  const devices = interlocutors.flatMap((i) => i.devices);
  const interlocutorUuids = interlocutors.map((i) => i.uuid);
  const deviceUuids = parseDeviceChildUUIDS(devices);
  return [...interlocutorUuids, ...deviceUuids];
};

function InterlocutorTree({
  classes,
  interlocutors,
  devices,
  InterlocutorComponent,
  DeviceComponent,
  ChannelComponent,
  ...props
}) {
  const [expanded, setExpanded] = React.useState([]);

  React.useEffect(() => {
    setExpanded(
      interlocutors ? parseInterlocutorChildUUIDS(interlocutors) : parseDeviceChildUUIDS(devices),
    );
  }, [interlocutors, devices]);

  return (
    <Box>
      <TreeView className={classes.treeView} disableSelection expanded={expanded}>
        {interlocutors
          ? interlocutors.map((i) => (
              <InterlocutorNode
                key={i.uuid}
                interlocutor={i}
                InterlocutorComponent={InterlocutorComponent}
                DeviceComponent={DeviceComponent}
                ChannelComponent={ChannelComponent}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            ))
          : devices.map((d) => (
              <DeviceNode
                key={d.uuid}
                device={d}
                DeviceComponent={DeviceComponent}
                ChannelComponent={ChannelComponent}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            ))}
      </TreeView>
    </Box>
  );
}

export default withStyles(styles)(InterlocutorTree);
