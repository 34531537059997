import React from 'react';
import { sortBy as _sortBy } from 'lodash';
import { useSensorTemplates } from '../../../hooks/sensor';
import DataTable from '../../../components/DataTable';
import Error from '../../../components/Error';

const TABLE_COLUMNS = [
  {
    name: 'Sensor name',
    key: 'name',
  },
  {
    name: 'Sensor type',
    key: 'type',
  },
  { name: 'Id', key: 'uuid' },
  {
    name: 'Channels',
    key: 'channels',
  },
  {
    name: 'Positions',
    key: 'positions',
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

export default function SensorTemplatesTable({ onClickRow, selected, category }) {
  const { data: sensorTemplates = [], status } = useSensorTemplates();
  const categorySensorTemplates = sensorTemplates.filter((st) => st.category === category);
  const sortedSensorTemplates = _sortBy(categorySensorTemplates, 'name');
  const selectedSensorTemplateIndex = sortedSensorTemplates.findIndex((st) => st.uuid === selected);
  return status === 'error' ? (
    <Error message="Failed to fetch sensors" />
  ) : (
    <DataTable
      columns={TABLE_COLUMNS}
      rows={sortedSensorTemplates}
      rowKey="uuid"
      onClickRow={onClickRow}
      selectedRowIndices={[selectedSensorTemplateIndex]}
    />
  );
}
