/* eslint-disable react/jsx-props-no-spreading */
import { Chip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { metadata, isInvalidDate } from 'mediadb-lib';
import { DateTime } from 'luxon';
import { hhmmss } from '../utils/utils';

const styles = () => ({});

const TYPES = metadata.MEDIADB_FIELD_TYPES;

function Chips({ values }) {
  return (
    <>
      {(values || []).map((v) => (
        <Chip style={{ marginRight: '4px', marginBottom: '4px' }} size="small" label={v} key={v} />
      ))}
    </>
  );
}

function Empty() {
  return <Typography variant="body2">-</Typography>;
}

function StringView({ multiple, value }) {
  if (!value?.length) {
    return <Empty />;
  }
  if (multiple) {
    return <Chips values={value} />;
  }
  return <Typography variant="body2">{value}</Typography>;
}

function EnumView({ multiple, value }) {
  if (!value?.length) {
    return <Empty />;
  }
  if (multiple) {
    return <Chips values={value} />;
  }
  return <Typography variant="body2">{value}</Typography>;
}

function DateView({ value }) {
  if (!value) {
    return <Empty />;
  }
  const formattedDatestring = DateTime.fromJSDate(new Date(value)).toLocaleString(
    DateTime.DATE_MED,
  );
  return <Typography variant="body2">{formattedDatestring}</Typography>;
}

function TimeView({ value, format = 'date', invalidTimeText = 'Invalid Time' }) {
  if (!value) {
    return <Empty />;
  }
  const formattedTimeString = (() => {
    if (format === 'date') {
      const date = new Date(value);
      const isValid = !isInvalidDate(date);
      return isValid ? date.toISOString().slice(11, 16) : invalidTimeText;
    }
    if (format === 'seconds') {
      const seconds = Number(value);
      return Number.isNaN(seconds) ? invalidTimeText : hhmmss(seconds);
    }
    return invalidTimeText;
  })();
  return <Typography variant="body2">{formattedTimeString}</Typography>;
}

function NumberView({ value }) {
  if (!value) {
    return <Empty />;
  }
  return <Typography variant="body2">{value}</Typography>;
}

function MetadataFieldView({ field, value, ...props }) {
  const { isMultiValue = false, label, mediaDBType } = field;
  const sharedProps = {
    label,
    value,
  };
  const component = {
    [TYPES.String]: <StringView multiple={isMultiValue} {...sharedProps} {...props} />,
    [TYPES.Enum]: <EnumView multiple={isMultiValue} {...sharedProps} {...props} />,
    [TYPES.Date]: <DateView {...sharedProps} {...props} />,
    [TYPES.Time]: <TimeView {...sharedProps} {...props} />,
    [TYPES.Number]: <NumberView {...sharedProps} {...props} />,
  }[mediaDBType];

  return component || null;
}

export default withStyles(styles)(MetadataFieldView);
