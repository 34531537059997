import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  useDeviceTemplates,
  useIsDeviceTemplateUsedByActivePackage,
  useRemoveDeviceTemplate,
} from '../../../hooks/device';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import useConfirmationDialog from '../../../contexts/ConfirmationDialogContext';

const styles = (theme) => ({
  root: {},
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
});

const DeviceTemplateRemoval = ({ classes, deviceTemplateId }) => {
  const { showAlert } = useSnackbar();
  const { mutateAsync: removeDeviceTemplate } = useRemoveDeviceTemplate();
  const { data: deviceTemplates = [] } = useDeviceTemplates();
  const deviceTemplate = React.useMemo(
    () => deviceTemplates.find((dt) => dt.uuid === deviceTemplateId),
    [deviceTemplates, deviceTemplateId],
  );
  const { data: isUsedByActivePackage, status } = useIsDeviceTemplateUsedByActivePackage({
    deviceTemplate,
  });
  const { confirm: confirmDelete } = useConfirmationDialog({
    throwOnReject: false,
  });

  const handleRemoveClick = async () => {
    if (
      await confirmDelete({
        children: (
          <DialogContent>
            <DialogContentText>Permanently delete selected device?</DialogContentText>
          </DialogContent>
        ),
      })
    ) {
      try {
        await removeDeviceTemplate(deviceTemplate);
        showAlert({ severity: 'success', message: 'Succesfully deleted device' });
      } catch (error) {
        showAlert({ severity: 'error', message: 'Could not delete device' });
      }
    }
  };

  return (
    <>
      {status === 'success' && (
        <Tooltip
          title={isUsedByActivePackage ? 'Cannot delete devices used by active packages' : ''}
        >
          <span>
            <Button
              className={classes.deleteButton}
              disabled={isUsedByActivePackage}
              onClick={handleRemoveClick}
            >
              Remove
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default withStyles(styles)(DeviceTemplateRemoval);
