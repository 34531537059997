"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ACTIVE_PACKAGE_STATUSES = exports.PACKAGE_STATUSES = void 0;
var PACKAGE_STATUSES = {
  NEW: 'New',
  IN_LABELING: 'In labeling',
  LABELED: 'Labeled',
  APPROVED: 'Approved',
  PROCESSING: 'Processing',
  ERROR: 'Error',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled'
};
exports.PACKAGE_STATUSES = PACKAGE_STATUSES;
var ACTIVE_PACKAGE_STATUSES = [PACKAGE_STATUSES.APPROVED, PACKAGE_STATUSES.IN_LABELING, PACKAGE_STATUSES.LABELED, PACKAGE_STATUSES.NEW, PACKAGE_STATUSES.PROCESSING];
exports.ACTIVE_PACKAGE_STATUSES = ACTIVE_PACKAGE_STATUSES;
var _default = {
  PACKAGE_STATUSES: PACKAGE_STATUSES,
  ACTIVE_PACKAGE_STATUSES: ACTIVE_PACKAGE_STATUSES
};
exports["default"] = _default;