import React from 'react';
import {
  Typography,
  Menu,
  MenuItem,
  IconButton,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { playlist as playlistLib } from 'mediadb-lib';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '../../contexts/SnackbarContext';

import useConfirmationDialog from '../../contexts/ConfirmationDialogContext';
import PlaylistExportPropertiesDialog from './PlaylistExportProperties';
import PlaylistDialog from './PlaylistDialog';
import { useExportPlaylist } from '../../hooks/playlist';
import { AuthContext } from '../../vdt/AuthProvider';
import { downloadPlaylistInfo } from '../../utils/utils';

export default function PlaylistMenu({
  playlist,
  selectedPlaylist,
  invalidPlaylist,
  onDeletePlaylist,
}) {
  const { userName: selfUserName } = React.useContext(AuthContext);

  const [exportPropertiesPlaylist, setExportPropertiesPlaylist] = React.useState(null);
  const [editPlaylist, setEditPlaylist] = React.useState(false);
  const [playlistDialogOpen, setPlaylistDialogOpen] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const { showAlert } = useSnackbar();
  const history = useHistory();
  const { mutateAsync: exportPlaylist } = useExportPlaylist();
  const { confirm: confirmExport } = useConfirmationDialog({
    throwOnReject: false,
    DialogProps: {
      rejectText: 'Cancel',
      confirmText: 'Export',
    },
  });
  const { confirm: confirmDelete } = useConfirmationDialog({ throwOnReject: false });
  const closeMenu = () => {
    setMenuAnchor(null);
  };
  const handleClickPlaylistExport = async (playlistToExport) => {
    closeMenu();
    const { name, id } = playlistToExport;

    const hasPlaylistExportChannels = (exportChannels, extracts) =>
      exportChannels.some((channel) => {
        if (channel.trackName) {
          return extracts.some((extract) => {
            return extract.technicalLabelTracks.some((track) => {
              return track.uuid === channel.uuid;
            });
          });
        }
        return true;
      }) || false;

    const hasExtractExportChannels = (exportChannels, extracts) =>
      extracts.some((extract) => {
        if (extract.useExtractSpecificChannels && extract.exportChannels) {
          return (
            extract.interlocutors.some((interlocutor) => {
              return interlocutor.devices.some((device) => {
                return (
                  device.channels.some((channel) =>
                    extract.exportChannels.some((channelUuid) => channelUuid === channel.uuid),
                  ) ||
                  device.sensors.some((sensor) =>
                    sensor.channels.some((channel) =>
                      extract.exportChannels.some((channelUuid) => channelUuid === channel.uuid),
                    ),
                  )
                );
              });
            }) ||
            extract.technicalLabelTracks.some((track) =>
              extract.exportChannels.some((channelUuid) => channelUuid === track.uuid),
            )
          );
        }
        return false;
      });

    const hasExportTechnicalLabels = (extracts, exportTechnicalLabels) => {
      return exportTechnicalLabels || extracts.some((extract) => extract.exportTechnicalLabels);
    };

    const isInvalidPlaylistExport = async () => {
      const { exportChannels, extracts, exportTechnicalLabels } = await playlistLib.getPlaylist(id);
      return (
        !hasPlaylistExportChannels(exportChannels, extracts) &&
        !hasExtractExportChannels(exportChannels, extracts) &&
        !hasExportTechnicalLabels(extracts, exportTechnicalLabels)
      );
    };

    try {
      if (await isInvalidPlaylistExport()) {
        showAlert({ severity: 'error', message: 'No export channels are selected' });
        return;
      }
      if (
        await confirmExport({
          titleText: `Do you want to export ${name}?`,
        })
      ) {
        await exportPlaylist(id);
        history.push('/search/exported-playlists');
      }
    } catch (e) {
      showAlert({ severity: 'error', message: 'Failed to export playlist' });
    }
  };

  const handleClickPlaylistExportProperties = (pl) => {
    setExportPropertiesPlaylist(pl);
    closeMenu();
  };
  const handleClickNewPlaylist = () => {
    setPlaylistDialogOpen(true);
    closeMenu();
  };
  const handleClickDownloadPlaylist = (pl) => {
    downloadPlaylistInfo(pl, `${pl.name}.json`);
    closeMenu();
  };
  const handleClickEditPlaylist = () => {
    setEditPlaylist(true);
    setPlaylistDialogOpen(true);
    closeMenu();
  };
  const handleClosePlaylistDialog = () => {
    setPlaylistDialogOpen(false);
    if (editPlaylist) setEditPlaylist(false);
  };

  const handleDeletePlaylist = async (playlistName) => {
    if (
      await confirmDelete({
        titleText: 'Confirm',
        rejectText: 'Cancel',
        confirmText: 'Delete playlist',
        fullWidth: true,
        children: (
          <DialogContent>
            <DialogContentText>
              Permanently delete playlist <b>{playlistName}</b>?
            </DialogContentText>
          </DialogContent>
        ),
      })
    ) {
      await onDeletePlaylist(playlist)
        .then(() => {
          closeMenu();
          showAlert({ severity: 'success', message: 'Succesfully deleted playlist' });
        })
        .catch(() => showAlert({ severity: 'error', message: 'Failed to delete playlist' }));
    }
  };
  return (
    <>
      {exportPropertiesPlaylist && (
        <PlaylistExportPropertiesDialog
          selectedPlaylist={exportPropertiesPlaylist}
          onClose={() => setExportPropertiesPlaylist(null)}
          onSuccess={() => {
            showAlert({ severity: 'success', message: 'Saved Export Properties' });
            setExportPropertiesPlaylist(null);
          }}
          onError={() => {
            showAlert({ severity: 'error', message: 'Failed to save export properties' });
            setExportPropertiesPlaylist(null);
          }}
        />
      )}
      {playlistDialogOpen && (
        <PlaylistDialog
          open={playlistDialogOpen}
          playlist={playlist}
          isEditing={editPlaylist}
          onClose={() => handleClosePlaylistDialog()}
          onSuccess={() => {
            showAlert({ severity: 'success', message: 'Playlist saved' });
            handleClosePlaylistDialog();
          }}
          onError={() => {
            showAlert({ severity: 'error', message: 'Failed to save playlist' });
          }}
        />
      )}
      <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        <MenuItem
          disabled={!selectedPlaylist}
          onClick={(e) => {
            e.stopPropagation();
            handleClickEditPlaylist();
          }}
        >
          <Typography variant="body2">Edit</Typography>
        </MenuItem>
        <MenuItem
          disabled={!selectedPlaylist || invalidPlaylist}
          onClick={(e) => {
            e.stopPropagation();
            handleClickPlaylistExportProperties(playlist);
          }}
        >
          <Typography variant="body2">Export properties</Typography>
        </MenuItem>
        <MenuItem
          disabled={!selectedPlaylist || invalidPlaylist}
          onClick={(e) => {
            e.stopPropagation();
            handleClickPlaylistExport(playlist);
          }}
        >
          <Typography variant="body2">Export</Typography>
        </MenuItem>
        <MenuItem
          disabled={!selectedPlaylist || selectedPlaylist.owner !== selfUserName}
          onClick={(e) => {
            e.stopPropagation();
            handleDeletePlaylist(selectedPlaylist.name);
          }}
        >
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleClickNewPlaylist();
          }}
        >
          <Typography variant="body2">New playlist</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleClickDownloadPlaylist(playlist);
          }}
          disabled={!selectedPlaylist}
        >
          <Typography variant="body2">Download playlist</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
