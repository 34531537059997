import React from 'react';
import { user as UserApi } from '@vidispine/vdt-api';
import { ROLE_TO_GROUP } from '../utils/user';

const groupsToGroupList = (groups) => ({
  groupList: {
    group: groups.map((g) => ({ groupName: g })),
  },
});

const parseUserArgs = ({ roles, ...args }) => ({
  ...args,
  ...groupsToGroupList(roles.map((r) => ROLE_TO_GROUP[r])),
});

export default function useUsers() {
  const updateUser = React.useCallback(
    ({ userName }, args) =>
      UserApi.updateUser({
        userName,
        userDocument: parseUserArgs(args),
      }),
    [],
  );

  return {
    updateUser,
  };
}
