import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function ChannelSelect({ classes, onChange, channel, selectProps, files, usedFiles }) {
  const [selectedValue, setSelectedValue] = React.useState(channel.value || '');
  const handleChange = ({ target: { value } }) => {
    setSelectedValue(value);
    onChange({ ...channel, value, prevValue: selectedValue });
  };
  const alreadyInUse = ({ name }) => usedFiles.includes(name);

  return (
    <FormControl className={classes.channelForm}>
      <InputLabel required>{channel.name}</InputLabel>
      <Select
        className={classes.channelFormSelect}
        variant="standard"
        value={selectedValue}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectProps}
      >
        {files.map((f) => {
          const { file } = f;
          return (
            <MenuItem key={file.name} value={file.name} disabled={alreadyInUse(file)}>
              {file.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ChannelSelect;
