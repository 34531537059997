import React from 'react';

import { List, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core';
import { PermMedia as PackageGroupIcon } from '@material-ui/icons';

import { useGetUserAccess } from '../../../hooks/user';

function UserAccess({ user }) {
  const { userName } = user;

  const { data: packageGroups = [] } = useGetUserAccess({
    userName,
  });

  return (
    <>
      <List dense disablePadding>
        {packageGroups.length ? (
          packageGroups.map(({ id, name }) => (
            <ListItem key={id} disableGutters>
              <ListItemIcon size="sm">
                <PackageGroupIcon />
              </ListItemIcon>
              <ListItemText
                primary={name}
                secondary={id}
                primaryTypographyProps={{
                  variant: 'body2',
                }}
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="overline">User not added to any package groups</Typography>
        )}
      </List>
    </>
  );
}

export default UserAccess;
