import React from 'react';
import { sortBy as _sortBy } from 'lodash';
import { useDeviceTemplates } from '../../../hooks/device';
import DataTable from '../../../components/DataTable';
import Error from '../../../components/Error';

const TABLE_COLUMNS = [
  {
    name: 'Device name',
    key: 'name',
  },
  {
    name: 'Device type',
    key: 'deviceType',
    render: (deviceType) => deviceType.name,
  },
  { name: 'Id', key: 'uuid' },
  {
    name: 'Approval status',
    key: 'approvalStatus',
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

export default function DeviceTemplatesTable({ onClickRow, selected, category }) {
  const { data: deviceTemplates = [], status } = useDeviceTemplates();
  const categoryDeviceTemplates = deviceTemplates.filter(
    (deviceTemplate) => deviceTemplate.deviceType.category === category,
  );
  const sortedDeviceTemplates = _sortBy(categoryDeviceTemplates, 'name');
  const selectedDeviceTemplateIndex = sortedDeviceTemplates.findIndex(
    (deviceType) => deviceType.uuid === selected,
  );
  return status === 'error' ? (
    <Error message="Failed to fetch device templates" />
  ) : (
    <DataTable
      columns={TABLE_COLUMNS}
      rows={sortedDeviceTemplates}
      rowKey="uuid"
      onClickRow={onClickRow}
      selectedRowIndices={[selectedDeviceTemplateIndex]}
    />
  );
}
