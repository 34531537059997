import React from 'react';
import { debounce } from 'lodash';
import { withStyles, LinearProgress, Typography, Button, Paper } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Sort, SpellCheck } from '@vidispine/vdt-materialui';
import { useAutocomplete } from '@vidispine/vdt-react';
import { ToggleButton } from '@material-ui/lab';
import { ACCURATE_VIDEO_MARKER_FIELDS } from 'mediadb-lib';
import AdvancedSearch from '../../../components/AdvancedSearch';
import SearchInput from './SearchInput';

const defaultSortOptions = [
  { label: 'Creation date', value: 'created' },
  { label: 'Name', value: 'packageName' },
];

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    paddingRight: theme.spacing(2),
  },
  searchWrapper: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr max-content',
    paddingBottom: theme.spacing(1),

    '& form:focus-within': {
      '& .MuiButton-root': {
        borderColor: theme.palette.primary.main,
      },
      '& .MuiInputBase-root': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiButton-root': {
      backgroundColor: theme.palette.background.paper,
      height: '44px',
      borderLeft: 'none',
    },
    '& .MuiInputBase-root': {
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
      height: '44px',
      borderRight: 'none',
      paddingLeft: theme.spacing(2),
    },
    '& .VdtSearchInput-root': {
      paddingRight: theme.spacing(1),
    },
  },
  hitsContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  hitsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 4,
  },
  queryBuilderContainer: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  toggleButton: {
    // marginLeft: theme.spacing(1),
    color: alpha(theme.palette.primary.main, 0.7),
    borderColor: alpha(theme.palette.primary.main, 0.3),
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
      },
    },
  },
  searchProgress: {
    gridArea: 'progress',
    minHeight: '10px',
    margin: '0px -8px -8px -8px',
  },
});

const PackageSearch = ({
  classes,
  isLoading,
  searchText,
  label,
  hits,
  maxPossibleHits,
  amountFetched,
  amountChecked,
  enableLoadMore,
  canCancel,
  suggestion = [],
  onChangeSort,
  onLoadMore,
  onCancel,
  setText,
  setLabel,
  isFetchingPackages = false,
  isError,
}) => {
  const [showQueryBuilder, setShowQueryBuilder] = React.useState(false);
  const { suggestions, setAutocompleteText } = useAutocomplete({
    field: ACCURATE_VIDEO_MARKER_FIELDS.NAME,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAutocompleteText = React.useCallback(
    debounce((text) => setAutocompleteText(text.toLowerCase()), 100),
    [setAutocompleteText],
  );

  const toggleQueryBuilder = () => {
    if (showQueryBuilder) {
      setLabel([]);
    } else {
      setText('');
    }
    setShowQueryBuilder((prev) => !prev);
  };

  const searchInputRef = React.useRef(null);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchWrapper}>
          {!showQueryBuilder && (
            <SearchInput
              suggestions={suggestions}
              onSubmit={setText}
              submitting={isLoading}
              searchPlaceholder="Search labels"
              onChange={debouncedSetAutocompleteText}
              onClickSuggestion={(s) => {
                setText(s);
                debouncedSetAutocompleteText(s);
              }}
              searchInputRef={searchInputRef}
            />
          )}
          <ToggleButton
            size="small"
            className={classes.toggleButton}
            value="queryBuilder"
            onChange={toggleQueryBuilder}
          >
            {showQueryBuilder ? 'Basic' : 'Advanced'}
          </ToggleButton>
        </div>

        <Paper variant="outlined" className={classes.hitsContainer}>
          <div className={classes.hitsWrapper}>
            <div style={{ width: 200 }}>
              <Sort
                initialSort={{ option: defaultSortOptions[0], direction: 'descending' }}
                options={defaultSortOptions}
                onChange={onChangeSort}
                secondaryColor="#0083ca"
              />
            </div>
            <div>
              {isError ? (
                <Typography variant="subtitle1" color="error">
                  Error occurred at {amountFetched + 1} of {maxPossibleHits || hits} matching
                  packages
                </Typography>
              ) : (
                <>
                  {amountChecked !== undefined && (
                    <div>
                      <Typography variant="subtitle1">
                        <b>{`${amountFetched} ${amountFetched === 1 ? 'hit ' : 'hits '}`}</b>
                        found in
                        <b>{` ${amountChecked} of ${maxPossibleHits} `}</b>
                        matching packages
                      </Typography>
                    </div>
                  )}{' '}
                  {hits !== undefined && amountChecked === undefined && (
                    <div>
                      <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                        <b>{`${amountFetched} of ${hits} hits`}</b>
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </div>
            <div style={{ width: 200, textAlign: 'right' }}>
              {canCancel && !enableLoadMore ? (
                <Button className={classes.toggleButton} onClick={onCancel} size="small">
                  Stop
                </Button>
              ) : (
                <Button
                  className={classes.toggleButton}
                  disabled={!enableLoadMore}
                  onClick={onLoadMore}
                  size="small"
                >
                  {isError ? 'Try Again' : 'Load more'}
                </Button>
              )}
            </div>
          </div>
          <Box className={classes.searchProgress}>
            {isFetchingPackages && <LinearProgress variant="query" />}
          </Box>
        </Paper>
        <Box mb={1}>
          {searchText && suggestion && suggestion.length > 0 ? (
            <SpellCheck
              searchText={searchText.toLowerCase()}
              suggestions={suggestion}
              onClick={(s) => {
                setText(s);
                searchInputRef.current.value = s;
              }}
            />
          ) : null}
        </Box>
      </div>
      {showQueryBuilder && (
        <div className={classes.queryBuilderContainer}>
          <AdvancedSearch label={label} onSubmit={setLabel} />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(PackageSearch);
