import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Hearing as DeviceIcon } from '@material-ui/icons';
import SectionHeader from '../../../components/SectionHeader';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import TabPanel from './TabPanel';
import DeviceTypesEditor from './DeviceTypesEditor';
import DeviceTemplatesEditor from './DeviceTemplatesEditor';
import SensorTemplatesEditor from './SensorTemplatesEditor';
import DeviceTypesTable from './DeviceTypesTable';
import DeviceTemplatesTable from './DeviceTemplatesTable';
import SensorTemplatesTable from './SensorTemplatesTable';

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, 1fr) 400px',
    gridGap: theme.spacing(2),
  },
  tabPanel: {
    maxHeight: `
    calc(100vh - 70px - 32px
      - ${theme.spacing(8)}px 
      - ${theme.spacing(6)}px 
      - 48px 
      - 48px) 
      `,
    height: `
    calc(100vh - 70px - 32px
      - ${theme.spacing(8)}px 
      - ${theme.spacing(6)}px 
      - 48px 
      - 48px) 
      `,
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.paper,
    },
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    overflow: 'auto',
  },
});

const Devices = ({ classes, category, titleText }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const handleChangeTab = (_, index) => setSelectedTabIndex(index);

  const [selectedDeviceTypeId, setSelectedDeviceTypeId] = React.useState();
  const [selectedDeviceTemplateId, setSelectedDeviceTemplateId] = React.useState();
  const [selectedSensorTemplateId, setSelectedSensorTemplateId] = React.useState();

  const { showAlert } = useSnackbar();

  return (
    <Container className={classes.root} maxWidth={false}>
      <Paper className="adminContainer" variant="outlined">
        <SectionHeader
          Icon={DeviceIcon}
          title={titleText}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <Box display="flex" justifyContent="center" mb={2}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Device types" index={0} />
            <Tab label="Devices" index={1} />
            <Tab label="Sensors" index={2} />
          </Tabs>
        </Box>
        <TabPanel className={classes.tabPanel} value={selectedTabIndex} index={0}>
          <DeviceTypesTable
            category={category}
            selected={selectedDeviceTypeId}
            onClickRow={(dt) => {
              if (selectedDeviceTypeId === dt.uuid) {
                setSelectedDeviceTypeId();
              } else {
                setSelectedDeviceTypeId(dt.uuid);
              }
            }}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={selectedTabIndex} index={1}>
          <DeviceTemplatesTable
            category={category}
            selected={selectedDeviceTemplateId}
            onClickRow={(dt) => {
              if (selectedDeviceTemplateId === dt.uuid) {
                setSelectedDeviceTemplateId();
              } else {
                setSelectedDeviceTemplateId(dt.uuid);
              }
            }}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={selectedTabIndex} index={2}>
          <SensorTemplatesTable
            category={category}
            selected={selectedSensorTemplateId}
            onClickRow={(sensorTemplate) => {
              if (selectedSensorTemplateId === sensorTemplate.uuid) {
                setSelectedSensorTemplateId();
              } else {
                setSelectedSensorTemplateId(sensorTemplate.uuid);
              }
            }}
          />
        </TabPanel>
      </Paper>
      <Paper className={clsx('adminSidePanel', 'adminContainer')} variant="outlined">
        <Box>
          {
            [
              <DeviceTypesEditor
                deviceTypeId={selectedDeviceTypeId}
                category={category}
                onSuccess={() =>
                  showAlert({
                    severity: 'success',
                    message: selectedDeviceTypeId ? 'Device type updated' : 'Device type created',
                  })
                }
                onError={() =>
                  showAlert({
                    severity: 'error',
                    message: selectedDeviceTypeId
                      ? 'Failed to update device type'
                      : 'Failed to create device type',
                  })
                }
                onCancel={() => {
                  setSelectedDeviceTypeId();
                }}
              />,
              <DeviceTemplatesEditor
                deviceTemplateId={selectedDeviceTemplateId}
                category={category}
                onSuccess={() =>
                  showAlert({
                    severity: 'success',
                    message: selectedDeviceTemplateId ? 'Device updated' : 'Device created',
                  })
                }
                onError={() =>
                  showAlert({
                    severity: 'error',
                    message: selectedDeviceTemplateId
                      ? 'Failed to update device'
                      : 'Failed to create device',
                  })
                }
                onCancel={() => {
                  setSelectedDeviceTemplateId();
                }}
              />,
              <SensorTemplatesEditor
                sensorTemplateId={selectedSensorTemplateId}
                category={category}
                onSuccess={() => {
                  showAlert({
                    severity: 'success',
                    message: selectedSensorTemplateId ? 'Sensor updated' : 'Sensor created',
                  });
                }}
                onError={() =>
                  showAlert({
                    severity: 'error',
                    message: selectedSensorTemplateId
                      ? 'Failed to update sensor'
                      : 'Failed to create sensor',
                  })
                }
                onCancel={() => {
                  setSelectedSensorTemplateId();
                }}
              />,
            ][selectedTabIndex]
          }
        </Box>
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(Devices);
