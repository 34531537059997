import { omit as _omit } from 'lodash';
import React from 'react';

import { pkg } from 'mediadb-lib';

const { PACKAGE_STATUSES } = pkg;
const SEARCHABLE_PACKAGE_STATUSES = [
  PACKAGE_STATUSES.IN_LABELING,
  PACKAGE_STATUSES.LABELED,
  PACKAGE_STATUSES.APPROVED,
];
const DEFAULT_SEARCH_FILTER = {
  name: 'isApproved',
  field: 'packageStatus',
  value: SEARCHABLE_PACKAGE_STATUSES,
};

const CHANGE_TEXT = 'CHANGE_TEXT';
const CHANGE_LABEL = 'CHANGE_LABEL';
const CHANGE_TECHNICAL = 'CHANGE_TECHNICAL';
const CHANGE_SORT = 'CHANGE_SORT';
const CHANGE_FILTER = 'CHANGE_FILTER';
const CHANGE_FACET = 'CHANGE_FACET';
const CLEAR_FILTERS = 'CLEAR_FILTERS';
const SET_ITERATION = 'SET_ITERATION';
const SET_SHOULD_STOP = 'SET_SHOULD_STOP';

const ACTION_TYPE = {
  CHANGE_TEXT,
  CHANGE_LABEL,
  CHANGE_TECHNICAL,
  CHANGE_SORT,
  CHANGE_FACET,
  CHANGE_FILTER,
  CLEAR_FILTERS,
  SET_ITERATION,
  SET_SHOULD_STOP,
};

const getDefaultInitialState = ({ label, sort, filter = [], facet = [], suggestion } = {}) => {
  return {
    query: {
      label,
      sort,
      filter: [...filter, DEFAULT_SEARCH_FILTER],
      suggestion,
      facet,
    },
    iteration: 1,
    shouldStop: false,
  };
};

const queryReducer = (state, action) => {
  const reset = { iteration: 1, shouldStop: false };
  switch (action.type) {
    case ACTION_TYPE.CHANGE_LABEL: {
      return {
        query: {
          ...state.query,
          label: action.label,
        },
        ...reset,
      };
    }
    case ACTION_TYPE.CHANGE_TECHNICAL: {
      return {
        query: {
          ...state.query,
          technical: action.technical,
        },
        ...reset,
      };
    }
    case ACTION_TYPE.CHANGE_TEXT: {
      return {
        query: {
          ...state.query,
          text: action.text ? action.text : undefined,
        },
        ...reset,
      };
    }
    case ACTION_TYPE.CHANGE_SORT: {
      return {
        query: {
          ...state.query,
          sort: action.field
            ? [
                {
                  field: action.field,
                  order: action.order || 'descending',
                },
              ]
            : [],
        },
        ...reset,
      };
    }
    case ACTION_TYPE.CHANGE_FILTER: {
      return {
        query: {
          ...state.query,
          filter: [...action.filter, DEFAULT_SEARCH_FILTER],
        },
        ...reset,
      };
    }
    case ACTION_TYPE.CHANGE_FACET: {
      return {
        query: {
          ...state.query,
          facet: action.facet,
        },
        ...reset,
      };
    }
    case ACTION_TYPE.CLEAR_FILTERS: {
      return {
        query: {
          ...state.query,
          filter: [
            ...state.query.filter.map((f) => _omit(f, ['value', 'range'])),
            DEFAULT_SEARCH_FILTER,
          ],
        },
        ...reset,
      };
    }
    case ACTION_TYPE.SET_ITERATION: {
      return {
        ...state,
        iteration: action.iteration,
      };
    }
    case ACTION_TYPE.SET_SHOULD_STOP: {
      return {
        ...state,
        shouldStop: action.shouldStop,
      };
    }
    default: {
      return state;
    }
  }
};

export default function useSearchQuery(initialState) {
  const [state, dispatch] = React.useReducer(queryReducer, getDefaultInitialState(initialState));

  const setText = React.useCallback(
    (text) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_TEXT,
        text,
      }),
    [],
  );
  const setLabel = React.useCallback(
    (label) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_LABEL,
        label,
      }),
    [],
  );
  const setTechnical = React.useCallback(
    (technical) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_TECHNICAL,
        technical,
      }),
    [],
  );
  const setSort = React.useCallback(
    (event, field, order) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_SORT,
        field,
        order,
      }),
    [],
  );
  const setFilter = React.useCallback(
    (filter) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_FILTER,
        filter,
      }),
    [],
  );
  const setFacet = React.useCallback(
    (facet) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_FACET,
        facet,
      }),
    [],
  );
  const clearFilters = React.useCallback(() => dispatch({ type: ACTION_TYPE.CLEAR_FILTERS }), []);
  const setIteration = React.useCallback(
    (iteration) =>
      dispatch({
        type: ACTION_TYPE.SET_ITERATION,
        iteration,
      }),
    [],
  );

  const setShouldStop = React.useCallback(
    (shouldStop) =>
      dispatch({
        type: ACTION_TYPE.SET_SHOULD_STOP,
        shouldStop,
      }),
    [],
  );

  return {
    query: state.query,
    iteration: state.iteration,
    shouldStop: state.shouldStop,
    setText,
    setLabel,
    setTechnical,
    setSort,
    setFilter,
    setFacet,
    clearFilters,
    setIteration,
    setShouldStop,
  };
}
