import { LinearProgress, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { uniqBy as _uniqBy, get as _get } from 'lodash';

import { metadata } from 'mediadb-lib';
import {
  VideoLibrary as PackageIcon,
  Person as PersonIcon,
  Hearing as DeviceIcon,
  LeakAdd as SensorIcon,
} from '@material-ui/icons';

const styles = () => ({
  root: {},
  row: {},
});

const FieldTypeIcon = ({ fieldName }) => {
  const iconStyles = { height: 14, marginBottom: 2 };
  if (fieldName.startsWith(metadata.CUSTOM_GROUPS.PACKAGE.prefix)) {
    return <PackageIcon style={iconStyles} />;
  }

  if (fieldName.startsWith(metadata.CUSTOM_GROUPS.PERSON.prefix)) {
    return <PersonIcon style={iconStyles} />;
  }

  if (fieldName.startsWith(metadata.CUSTOM_GROUPS.DEVICE.prefix)) {
    return <DeviceIcon style={iconStyles} />;
  }

  if (fieldName.startsWith(metadata.CUSTOM_GROUPS.SENSOR.prefix)) {
    return <SensorIcon style={iconStyles} />;
  }
  return null;
};

function FilterRow({
  className,
  field,
  value,
  onChange,
  label,
  facet,
  component: FieldComponent,
  componentProps,
  toFilterValue = (v) => v,
  fromFilterValue = (v) => v,
}) {
  return (
    <div className={className}>
      <FieldComponent
        name={field}
        label={
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <FieldTypeIcon fieldName={field} />
            <span>{label}</span>
          </div>
        }
        onChange={(newValue) => onChange(fromFilterValue(newValue))}
        facet={facet}
        value={toFilterValue(value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...componentProps}
      />
    </div>
  );
}

function Filter({ classes, className, filters, facets, values, isFetchingFacets, onChange }) {
  const getFieldFromArr = (field, arr) => arr?.find((v) => v?.field === field);

  return (
    <div className={clsx(className, classes.root)}>
      {filters.map((filter) => {
        const rowValueEntry = getFieldFromArr(filter?.field, values);
        const rowValue = _get(rowValueEntry, filter?.type);
        return (
          <FilterRow
            className={clsx(classes.row, 'filterRow')}
            onChange={(newValue) =>
              onChange(
                _uniqBy(
                  [
                    {
                      field: filter.field,
                      useFacet: filter.useFacet,
                      ...(Array.isArray(newValue) && newValue.every((v) => v === null)
                        ? {}
                        : { [filter.type]: newValue }),
                    },
                    ...values,
                  ],
                  'field',
                ),
              )
            }
            key={filter.field}
            value={rowValue}
            facet={getFieldFromArr(filter.field, facets)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...filter}
          />
        );
      })}
      {isFetchingFacets && <LinearProgress />}
    </div>
  );
}

export default withStyles(styles)(Filter);
