import { user as userApi } from '@vidispine/vdt-api';
import { useMutation, useQuery } from 'react-query';
import { user } from 'mediadb-lib';

export function useSearchUsers({ username }, opts = {}) {
  return useQuery(
    ['searchUsers', username],
    () =>
      userApi
        .searchUser({
          userSearchDocument: {
            sort: [{ field: 'username', order: 'ascending' }],
            ...(username && { field: [{ name: 'username', value: username }] }),
          },
        })
        .then(({ data }) => data),
    opts,
  );
}

export function useGetUserAccess({ userName }) {
  return useQuery(['getUserAccess', userName], () => user.getUserAccess(userName));
}

export function useDeleteUser(opts = {}) {
  return useMutation(
    ({ userName, transferAccess }) => user.deleteUser({ userName, transferAccess }),
    opts,
  );
}
