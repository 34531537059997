import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Tab, Tabs, withStyles } from '@material-ui/core';

import clsx from 'clsx';

import { LocalOffer as TagIcon } from '@material-ui/icons';

import TabPanel from './TabPanel';
import SectionHeader from '../../../components/SectionHeader';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import LabelTrackEditor from './LabelTrackEditor';
import LabelTrackGroupEditor from './LabelTrackGroupEditor';
import LabelTrackTable from './LabelTrackTable';
import LabelTrackGroupTable from './LabelTrackGroupTable';
import TechnicalLabelTrackEditor from './TechnicalLabelTrackEditor';
import TechnicalLabelTrackTable from './TechnicalLabelTrackTable';

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, 1fr) 400px',
    gridGap: theme.spacing(2),
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `
    calc(100vh - 70px
      - ${theme.spacing(8)}px
      - ${theme.spacing(6)}px
      - 48px
      - 48px
      - 48px
      - ${theme.spacing(2)}px)
      `,
    overflow: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

const LabelTrackGroups = ({ classes, titleText = 'Label Track Groups', TitleIcon = TagIcon }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const handleChangeTab = (_, index) => setSelectedTabIndex(index);
  const [selectedLabelTrackId, setSelectedLabelTrackId] = React.useState(null);
  const [selectedLabelTrackGroupId, setSelectedLabelTrackGroupId] = React.useState(null);
  const [selectedTechnicalLabelTrackName, setSelectedTechnicalLabelTrackName] = React.useState(
    null,
  );
  const [selectedLabelTrackGroupIndex, setSelectedLabelTrackGroupIndex] = React.useState(0);
  const { showAlert } = useSnackbar();

  const handleClickTechnicalTrackRow = ({ name: trackName }) => {
    if (selectedTechnicalLabelTrackName === trackName) {
      setSelectedTechnicalLabelTrackName(null);
    } else {
      setSelectedTechnicalLabelTrackName(trackName);
    }
  };
  const handleClickTrackRow = ({ uuid }) => {
    if (selectedLabelTrackId === uuid) {
      setSelectedLabelTrackId(null);
    } else {
      setSelectedLabelTrackId(uuid);
    }
  };
  const handleClickGroupRow = ({ uuid }) => {
    if (selectedLabelTrackGroupId === uuid) {
      setSelectedLabelTrackGroupId(null);
    } else {
      setSelectedLabelTrackGroupId(uuid);
    }
  };

  const handleLabelTrackGroupChange = ({ target: { value } }) => {
    setSelectedLabelTrackGroupIndex(value);
    setSelectedLabelTrackId(null);
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Paper className="adminContainer" variant="outlined">
        <SectionHeader
          Icon={TitleIcon}
          title={titleText}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <Box display="flex" justifyContent="center" mb={2}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Groups" index={0} />
            <Tab label="Tracks" index={1} />
            <Tab label="Technical" index={2} />
          </Tabs>
        </Box>
        <TabPanel className={classes.tabPanel} value={selectedTabIndex} index={0}>
          <LabelTrackGroupTable
            selected={selectedLabelTrackGroupId}
            onClickRow={handleClickGroupRow}
            onAlert={({ severity, message }) => showAlert({ severity, message })}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={selectedTabIndex} index={1}>
          <LabelTrackTable
            selected={selectedLabelTrackId}
            selectedLabelTrackGroupIndex={selectedLabelTrackGroupIndex}
            onClickRow={handleClickTrackRow}
            onLabelTrackGroupChange={handleLabelTrackGroupChange}
            onAlert={({ severity, message }) => showAlert({ severity, message })}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={selectedTabIndex} index={2}>
          <TechnicalLabelTrackTable
            selectedTrackName={selectedTechnicalLabelTrackName}
            onClickRow={handleClickTechnicalTrackRow}
            onAlert={({ severity, message }) => showAlert({ severity, message })}
          />
        </TabPanel>
      </Paper>
      <Paper className={clsx('adminSidePanel', 'adminContainer')} variant="outlined">
        <Box>
          {
            [
              <LabelTrackGroupEditor
                labelTrackGroupId={selectedLabelTrackGroupId}
                onCancel={() => setSelectedLabelTrackGroupId(null)}
                onAlert={({ severity, message }) => showAlert({ severity, message })}
              />,
              <LabelTrackEditor
                labelTrackId={selectedLabelTrackId}
                // force remount to reset form completely
                key={selectedLabelTrackId}
                selectedLabelTrackGroupIndex={selectedLabelTrackGroupIndex}
                onCancel={() => setSelectedLabelTrackId(null)}
                onAlert={({ severity, message }) => showAlert({ severity, message })}
              />,
              <TechnicalLabelTrackEditor
                selectedTrackName={selectedTechnicalLabelTrackName}
                // force remount to reset form completely, needed after removing a track
                key={selectedTechnicalLabelTrackName}
                onCancel={() => setSelectedTechnicalLabelTrackName(null)}
                onAlert={({ severity, message }) => showAlert({ severity, message })}
              />,
            ][selectedTabIndex]
          }
        </Box>
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(LabelTrackGroups);
