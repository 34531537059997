import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { merge } from 'lodash';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MimeTypeIcon } from '@vidispine/vdt-materialui';
import parseFileSize from 'filesize';
import { withStyles, emphasize } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { getGeneralTrack } from '../useMediaInfo';
import { hhmmss } from '../../../utils/utils';

const styles = (theme) => ({
  fileListItemSecondaryAction: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  fileListItemContainer: {
    cursor: 'pointer',
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(0.5),
    },
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // backgroundColor: ,
    '&:hover $fileListItemSecondaryAction': {
      visibility: 'visible',
    },
    '& .MuiListItemText-root': {
      display: 'grid',
      gridTemplateColumns: '1fr minmax(100px, 150px) minmax(100px, 150px)',
    },
  },
  fileListFolder: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  fileListItemContainerComplete: {
    cursor: 'default',
  },
  fileListItemContainerHover: {
    '&:hover': {
      backgroundColor: emphasize(theme.palette.background.paper, 0.05),
    },
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  progressRoot: {
    gridColumn: '1 / span 3',
  },
  treeItemIconContainer: {
    display: 'none',
  },
  sizeColumn: {
    gridColumn: '3',
  },
});

export function LinearProgressWithLabel({ value }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant={value !== undefined ? 'determinate' : 'indeterminate'}
          value={value}
        />
      </Box>
      <Box minWidth={35}>
        {value !== undefined && (
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function getDuration(mediaInfo, file) {
  try {
    return getGeneralTrack(mediaInfo[file.path].media.track).Duration;
  } catch (e) {
    return null;
  }
}

function FileNode({
  classes,
  file,
  metadata = {},
  progress,
  onRemove,
  onClick,
  onChangeMetadata,
  selected,
  usedFiles = [],
  mediaInfo,
}) {
  const fileInUse = usedFiles.includes(file.name);
  const { type: mimeType, size, name } = file;
  const progressPercent =
    progress && progress.transferedBytes
      ? Math.floor((progress.transferedBytes / progress.totalBytes) * 100)
      : 0;
  const isComplete = progressPercent === 100;
  const fileSizeHuman = size ? parseFileSize(size) : undefined;

  const duration = getDuration(mediaInfo, file);
  const formattedDuration = duration && hhmmss(duration);

  const isGeneric = metadata.generic || false;

  return (
    <ListItem
      ContainerComponent="div"
      ContainerProps={{
        className: clsx(
          classes.fileListItemContainer,
          { [classes.fileListItemContainerHover]: !selected },
          { [classes.fileListItemContainerComplete]: isComplete },
        ),
      }}
      onClick={isComplete ? undefined : onClick}
      selected={selected}
    >
      <ListItemAvatar>
        <Avatar variant="square">
          <MimeTypeIcon mimeType={mimeType} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <>
            <Typography
              variant="body2"
              component="span"
              display="block"
              noWrap
              style={{ marginRight: '8px' }}
            >
              {name}
            </Typography>
            {duration && (
              <Typography variant="caption" component="span" display="block" noWrap>
                {formattedDuration}
              </Typography>
            )}
            {size && (
              <Typography
                className={classes.sizeColumn}
                variant="caption"
                component="span"
                display="block"
                noWrap
              >
                {fileSizeHuman}
              </Typography>
            )}
            <div className={classes.progressRoot}>
              {progress && <LinearProgressWithLabel value={progressPercent} />}
            </div>
          </>
        }
      />
      {progress ? (
        <ListItemSecondaryAction>
          {isComplete ? <CheckCircleIcon className={classes.successIcon} /> : <QueryBuilderIcon />}
        </ListItemSecondaryAction>
      ) : (
        <ListItemSecondaryAction className={classes.fileListItemSecondaryAction}>
          {!isGeneric && !onChangeMetadata && !fileInUse && (
            <Tooltip title="Unused file, will be uploaded as generic">
              <WarningIcon className={classes.warningIcon} />
            </Tooltip>
          )}
          <Tooltip title={onChangeMetadata ? 'Mark as generic' : 'Generic'}>
            <span>
              <Checkbox
                checked={isGeneric}
                onChange={() => onChangeMetadata(file.path, { generic: !isGeneric })}
                color="primary"
                disabled={!onChangeMetadata}
              />
            </span>
          </Tooltip>
          {onRemove && (
            <IconButton edge="end" onClick={() => onRemove(file.path)}>
              <DeleteIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function FolderNode({ classes, path, onRemove, onChangeMetadata, files: descendants }) {
  const totalBytes = descendants.reduce((total, f) => total + f.file.size || 0, 0);
  const transferedBytes = descendants.reduce(
    (total, f) => total + ((f.progress && f.progress.transferedBytes) || 0),
    0,
  );
  const started = descendants.some((c) => c.progress);
  const progress = (started || undefined) && {
    transferedBytes,
    totalBytes,
  };
  const progressPercent =
    progress && progress.transferedBytes
      ? Math.floor((progress.transferedBytes / progress.totalBytes) * 100)
      : 0;

  const name = path.split('/').pop();
  const isComplete = progressPercent === 100;
  const fileSizeHuman = totalBytes ? parseFileSize(totalBytes) : undefined;

  const allGeneric = descendants.every(({ metadata }) => metadata.generic);

  return (
    <ListItem
      ContainerComponent="div"
      ContainerProps={{
        className: clsx(classes.fileListItemContainer, classes.fileListFolder, {
          [classes.fileListItemContainerComplete]: isComplete,
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar variant="square">
          <MimeTypeIcon mimeType="collection" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <>
            <Typography variant="body2" component="span" display="block" noWrap>
              {name}
            </Typography>
            {totalBytes && (
              <Typography
                className={classes.sizeColumn}
                variant="caption"
                component="span"
                display="block"
                noWrap
              >
                {fileSizeHuman}
              </Typography>
            )}
            <div className={classes.progressRoot}>
              {progress && <LinearProgressWithLabel value={progressPercent} />}
            </div>
          </>
        }
      />
      {progress ? (
        <ListItemSecondaryAction>
          {isComplete ? <CheckCircleIcon className={classes.successIcon} /> : <QueryBuilderIcon />}
        </ListItemSecondaryAction>
      ) : (
        <ListItemSecondaryAction>
          <Tooltip title={onChangeMetadata ? 'Mark as generic' : 'Generic'}>
            <span>
              <Checkbox
                onClick={(e) => e.stopPropagation()}
                checked={allGeneric}
                onChange={() => onChangeMetadata(path, { generic: !allGeneric })}
                color="primary"
                disabled={!onChangeMetadata}
              />
            </span>
          </Tooltip>
          {onRemove && (
            <IconButton edge="end" onClick={() => onRemove(path)}>
              <DeleteIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function fileToPathTree(file) {
  const result = {};
  const pathComponents = file.file.path.split('/').filter((p) => p.length > 0);
  pathComponents[0] = `/${pathComponents[0]}`;
  const subPaths = pathComponents.map((_, idx) => pathComponents.slice(0, idx + 1).join('/'));
  subPaths.reduce(
    // eslint-disable-next-line no-return-assign, no-param-reassign
    (obj, key, index, arr) => (obj[key] = arr.length === index + 1 ? file : {}),
    result,
  );
  return result;
}

function parseFilesIntoTree(files) {
  return merge(...files.map((f) => fileToPathTree(f)));
}

function getFolderPaths(fileTree) {
  return Object.entries(fileTree)
    .filter(([, children]) => !(children.file instanceof File))
    .map(([path, children]) => [path, ...getFolderPaths(children)])
    .flat();
}

function FileTreeNode({ fileTree, files, ...props }) {
  const {
    classes,
    removeFile,
    removeFolder,
    selectedFilePath,
    changeFolderMetadata,
    changeFileMetadata,
    usedFiles,
    mediaInfo,
  } = props;

  return Object.entries(fileTree).map(([path, child]) => {
    if (child.file instanceof File) {
      return (
        <TreeItem
          nodeId={path}
          key={path}
          classes={{ iconContainer: classes.treeItemIconContainer }}
          label={
            <FileNode
              classes={classes}
              key={path}
              file={child.file}
              metadata={child.metadata}
              progress={child.progress}
              onRemove={removeFile}
              onChangeMetadata={changeFileMetadata}
              selected={selectedFilePath === child.file.path}
              usedFiles={usedFiles}
              mediaInfo={mediaInfo}
            />
          }
        />
      );
    }
    return (
      <TreeItem
        key={`${path}`}
        nodeId={`${path}`}
        classes={{ iconContainer: classes.treeItemIconContainer }}
        label={
          <FolderNode
            classes={classes}
            path={path}
            files={files.filter((f) => f.file.path.startsWith(`${path}/`))}
            onRemove={removeFolder}
            onChangeMetadata={changeFolderMetadata}
          />
        }
      >
        <FileTreeNode
          fileTree={child}
          files={files}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </TreeItem>
    );
  });
}

function FileTree({
  classes,
  files,
  onRemoveFiles,
  onChangeMetadata,
  selectedFile,
  usedFiles,
  mediaInfo,
}) {
  const fileTree = React.useMemo(() => parseFilesIntoTree(files), [files]);

  const fileIndex = (path) => files.findIndex((f) => f.file.path === path);

  const forAllDescendants = (cb) => (folderPath, ...args) => {
    const descendants = files
      .map((f, i) => f.file.path.startsWith(`${folderPath}/`) && i)
      .filter((i) => i !== false);
    cb(descendants, ...args);
  };

  const removeFolder =
    onRemoveFiles && forAllDescendants((descendants) => onRemoveFiles(descendants)());

  const removeFile =
    onRemoveFiles &&
    ((path) => {
      onRemoveFiles([fileIndex(path)])();
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeFolderMetadata =
    onChangeMetadata &&
    forAllDescendants((descendants, newMetadata) =>
      descendants.forEach((idx) => {
        onChangeMetadata(idx)({ ...files[idx].metadata, ...newMetadata });
      }),
    );

  const changeFileMetadata =
    onChangeMetadata &&
    ((path, newMetadata = {}) => {
      onChangeMetadata(fileIndex(path))({ ...files[fileIndex(path)].metadata, ...newMetadata });
    });

  const selectedFilePath = selectedFile && files[selectedFile].file.path;

  const [expanded, setExpanded] = React.useState(getFolderPaths(fileTree));

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  return (
    fileTree && (
      <TreeView expanded={expanded} onNodeToggle={handleToggle}>
        <FileTreeNode
          classes={classes}
          fileTree={fileTree}
          files={files}
          removeFile={removeFile}
          removeFolder={removeFolder}
          selectedFilePath={selectedFilePath}
          changeFileMetadata={changeFileMetadata}
          changeFolderMetadata={changeFolderMetadata}
          usedFiles={usedFiles}
          mediaInfo={mediaInfo}
        />
      </TreeView>
    )
  );
}

export default withStyles(styles)(FileTree);
