import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';
import { Pagination, SearchInput } from '@vidispine/vdt-materialui';
import { useSearchPackageGroup } from '../hooks/packageGroup';

const ROWS_PER_PAGE = 100;
const styles = (theme) => ({
  root: {},
  searchWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& form:focus-within': {
      '& .MuiButton-root': {
        borderColor: theme.palette.primary.main,
      },
      '& .MuiInputBase-root': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiButton-root': {
      backgroundColor: theme.palette.background.paper,
      borderLeft: 'none',
    },
    '& .MuiInputBase-root': {
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
      height: '44px',
      borderRadius: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

function PackageGroupSelect({ classes, onChange, value: selectedGroup = '', FormControlProps }) {
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  const handleChangePage = ({ page: newPage }) => {
    setPage(newPage);
  };

  const { data: { packageGroups = [], hits = 0 } = {}, isFetching } = useSearchPackageGroup({
    name: searchText,
    first: page * ROWS_PER_PAGE + 1,
    number: ROWS_PER_PAGE,
  });

  const handleSearch = (query) => {
    setPage(0);
    setSearchText(query);
  };

  const options = React.useMemo(() => {
    return selectedGroup.id
      ? [selectedGroup, ...packageGroups.filter(({ id }) => id !== selectedGroup.id)]
      : packageGroups;
  }, [packageGroups, selectedGroup]);

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl className={classes.root} {...FormControlProps}>
      <InputLabel>Package group</InputLabel>
      <Select
        variant="standard"
        value={selectedGroup}
        onChange={handleChange}
        onOpen={() => handleSearch('')}
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
      >
        <SearchInput
          className={classes.searchWrapper}
          onSubmit={handleSearch}
          submitting={isFetching}
          searchPlaceholder="Search package groups"
          InputBaseProps={{
            onKeyDown: (e) => e.stopPropagation(),
          }}
        />
        {options.map((option) => (
          <MenuItem key={option.id} value={option} disabled={!option.isActive}>
            {option.name}
            {option.description && <i>&nbsp;-&nbsp;{option.description}</i>}
          </MenuItem>
        ))}
        {hits > ROWS_PER_PAGE && (
          <Pagination
            count={hits}
            page={page}
            rowsPerPage={ROWS_PER_PAGE}
            onChangePage={handleChangePage}
            rowsPerPageOptions={[]}
            TablePaginationProps={{
              labelDisplayedRows: () => '',
            }}
          />
        )}
      </Select>
    </FormControl>
  );
}
export default withStyles(styles)(PackageGroupSelect);
