import React from 'react';
import { withStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';

const styles = (theme) => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    overflow: 'hidden',
    '& .MuiCardHeader-content': {
      overflow: 'hidden',
    },
    '& .MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
});

function PackageCardHeader({ classes, title }) {
  return (
    <CardHeader
      className={classes.root}
      title={title}
      titleTypographyProps={{
        variant: 'h4',
      }}
    />
  );
}

export default withStyles(styles)(PackageCardHeader);
