import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

export default function Error({ message }) {
  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box mr={1} display="flex" alignItems="center">
        <ErrorIcon color="error" />
      </Box>
      <Typography style={{ maxWidth: '80%' }} variant="body1">
        {message}
      </Typography>
    </Box>
  );
}
