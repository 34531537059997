import { TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import useDebounce from '../hooks/useDebounce';
import { useSearchUsers } from '../hooks/user';

const styles = () => ({});

function UserAutocomplete({
  label,
  value,
  onChange,
  options,
  transformOptions = (o) => o,
  ...props
}) {
  const [inputValue, setInputValue] = React.useState('');
  const debouncedInputValue = useDebounce(inputValue, 500, { leading: true });
  const { data } = useSearchUsers(
    { username: debouncedInputValue || '' },
    { keepPreviousData: true, enabled: !options },
  );
  const resolvedOptions = transformOptions(options || data?.user || []);
  const handleInputChange = (_, val) => {
    setInputValue(val);
  };

  const handleChange = (_, val) => {
    onChange(val);
  };

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      options={resolvedOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={(u) => u.userName}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="standard"
          label={label}
        />
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default withStyles(styles)(UserAutocomplete);
