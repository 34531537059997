import { withStyles } from '@material-ui/core';
import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

const styles = () => ({});

function DateField({ ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KeyboardDatePicker clearable format="yyyy/MM/dd" {...props} />;
}

export default withStyles(styles)(DateField);
