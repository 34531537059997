import { utils, ChunkedUpload } from '@vidispine/vdt-api';

const chunkedUploadProps = {
  chunkSize: 200000000, // 200mb chunks
  minChunkSize: 10000000, // 10mb
  maxConcurrentTransfers: 10,
  minFileSize: 100000000, // 100MB
  maxConcurrentFileTransfers: 1,
};

const chunkedUpload = (file, props = {}) => {
  const uploadInstance = new ChunkedUpload(file, props);
  return {
    upload: () =>
      new Promise((resolve, reject) => {
        uploadInstance.options.onFail = (error) => reject(error);
        uploadInstance.options.onComplete = (resp) => resolve(resp);
        uploadInstance.upload();
      }),
    cancel: () => uploadInstance.cancel(),
  };
};

const uploadToUrl = (uploadUrl) => ({ file: chunk, onUploadProgress, headers }) => {
  return utils.defaultClient({
    method: 'POST',
    url: uploadUrl,
    data: chunk,
    headers: { contentType: 'application/octet-stream', ...headers },
    onUploadProgress,
  });
};

export function uploadPackageFile({ uploadUrl, file, onProgress }) {
  return chunkedUpload(file, {
    api: {
      method: uploadToUrl(uploadUrl),
      props: {
        queryParams: {},
      },
    },
    onProgress,
    chunkedUploadProps,
  });
}

const uploadProgress = ({ files, onProgress }) => {
  const filesTransferedBytes = {};
  const totalBytes = (files || []).reduce((sum, curr) => sum + (curr.file.size || 0), 0);
  const onFileProgress = (filename) => ({ transferedBytes }) => {
    filesTransferedBytes[filename] = transferedBytes;
    const totalTransferedBytes = Object.values(filesTransferedBytes).reduce(
      (sum, curr) => sum + (curr || 0),
      0,
    );
    onProgress((100 * totalTransferedBytes) / totalBytes);
  };
  return { onFileProgress };
};

export default function uploadPackageFiles({ fileUploadDescriptions, files, onProgress }) {
  const { onFileProgress } = uploadProgress({ files, onProgress });
  const uploadInstances = fileUploadDescriptions.map(({ filename, url }) => {
    const currentFile = files.find(({ file }) => file.name === filename);
    return uploadPackageFile({
      file: currentFile.file,
      uploadUrl: url,
      onProgress: onFileProgress(filename),
    });
  });
  const cancelUpload = () => Promise.all(uploadInstances.map(({ cancel }) => cancel()));
  const uploadPromise = uploadInstances.reduce(
    (previousPromise, { upload }) => previousPromise.then(() => upload()),
    Promise.resolve(),
  );
  return {
    uploadPromise,
    cancelUpload,
  };
}
