import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@material-ui/core';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SectionHeader from '../components/SectionHeader';

export const ConfirmationDialog = ({
  open,
  titleText = 'Confirm',
  rejectText = 'No',
  confirmText = 'Yes',
  onConfirm,
  onReject,
  confirmationButtonProps = {},
  ...props
}) => {
  const { children = null } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog open={open} onClose={onReject} {...props}>
      <DialogTitle>
        <SectionHeader
          title={titleText}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      {children}
      <DialogActions>
        <Button onClick={onReject}>{rejectText}</Button>
        <Button
          variant="contained"
          disableElevation
          onClick={(e) => onConfirm(e)}
          color="primary"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...confirmationButtonProps}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TextConfirmationDialog = ({ fieldName, ...props }) => {
  const [confirmationText, setConfirmationText] = React.useState(null);
  const isCorrectConfirmationText = confirmationText === fieldName;

  return (
    <ConfirmationDialog
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      titleText="Delete metadata field"
      rejectText="Cancel"
      confirmText="Delete"
      fullWidth
      confirmationButtonProps={{
        disabled: !isCorrectConfirmationText,
      }}
    >
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText>
          This action is irreversible and will cause existing occurrences of the metadata field to
          become inaccessible and no longer searchable.
        </DialogContentText>
        <DialogContentText>
          Enter the field name <span style={{ fontWeight: 'bold' }}>{fieldName}</span> to confirm.
        </DialogContentText>
        <TextField
          fullWidth
          placeholder={fieldName}
          onChange={(e) => setConfirmationText(e.currentTarget.value)}
          required
        />
      </DialogContent>
    </ConfirmationDialog>
  );
};
