import { useQuery, useMutation, useQueryClient } from 'react-query';
import { metadata } from 'mediadb-lib';
import { metadatafield as metadatafieldApi } from '@vidispine/vdt-api';

export function useCustomMetadataGroup(groupName, opts) {
  return useQuery(
    ['customMetadataGroup', groupName],
    () => metadata.getCustomMetadataGroup(groupName),
    opts,
  );
}

export function useListMetadataFields() {
  return useQuery('listMetadataFields', () =>
    metadatafieldApi
      .listMetadataField()
      .then(({ data: { field = [] } }) => field.map(({ name }) => name)),
  );
}

export function useRemoveCustomField() {
  const queryClient = useQueryClient();
  return useMutation(metadata.removeCustomField, {
    onSuccess: (_, { groupName }) => {
      queryClient.invalidateQueries(['customMetadataGroup', groupName]);
    },
  });
}

export function useAddCustomField() {
  const queryClient = useQueryClient();
  return useMutation(metadata.addCustomField, {
    onSuccess: (_, { groupName }) => {
      queryClient.invalidateQueries(['customMetadataGroup', groupName]);
      queryClient.invalidateQueries('listMetadataFields');
    },
  });
}

export function useUpdateCustomField() {
  const queryClient = useQueryClient();
  return useMutation(metadata.updateCustomField, {
    onSuccess: (_, { groupName }) => {
      queryClient.invalidateQueries(['customMetadataGroup', groupName]);
    },
  });
}

export const useCustomMetadataGroups = () => {
  const {
    data: customPackageFields = [],
    isLoading: isLoadingPackageFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.PACKAGE.name);
  const {
    data: customPersonFields = [],
    isLoading: isLoadingPersonFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.PERSON.name);
  const {
    data: customDeviceFields = [],
    isLoading: isLoadingDeviceFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.DEVICE.name);
  const {
    data: customSensorFields = [],
    isLoading: isLoadingSensorFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.SENSOR.name);

  return {
    fields: [
      ...customPackageFields,
      ...customPersonFields,
      ...customDeviceFields,
      ...customSensorFields,
    ],
    isLoading:
      isLoadingPackageFields ||
      isLoadingPersonFields ||
      isLoadingDeviceFields ||
      isLoadingSensorFields,
  };
};
