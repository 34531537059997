import { withStyles } from '@material-ui/core';
import { useForm, useFieldArray } from 'react-hook-form';
import React from 'react';
import { isEqual } from 'lodash';
import GroupedMultiselectField from './GroupedMultiselectField';
import { useTechnicalLabelTracks } from '../../../hooks/label';
import TechnicalForm from './TechnicalForm';

const styles = (theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    width: '100%',
  },
  accordion: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    '& > .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'unset',
    },
  },
  details: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  types: {
    width: '100%',
    marginTop: 0,
    '& > .MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiChip-root': {
      display: 'none',
    },
  },
});

const Technical = ({ classes, onSubmit, localTechnical }) => {
  const { data: technicalLabelTracks = [] } = useTechnicalLabelTracks();
  const { control, reset: resetForm, register, watch, getValues } = useForm({
    defaultValues: {
      technical: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'technical',
  });

  React.useEffect(() => {
    // "Submit" on changes
    const technical = watch('technical');
    if (!isEqual(localTechnical, technical)) {
      onSubmit(technical);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  React.useEffect(() => {
    const { technical = [] } = getValues();
    // Reset form on clear
    if (localTechnical.length === 0 && technical.length > 0) {
      resetForm();
    }
  }, [getValues, localTechnical, resetForm]);

  const selectedTypes = fields.map((f) => f.name);

  const options = technicalLabelTracks
    .filter((t) => t.isActive)
    .map((t) => ({ name: t.name, value: t.name, label: t.label }));

  const leGroup = { name: 'technical', label: 'Technical Labels', options };

  const handleOnChange = ({ value }) => {
    value.forEach((name) => {
      if (!selectedTypes.includes(name)) {
        append({ name, min: '', max: '' });
      }
    });
    selectedTypes.forEach((name, index) => {
      if (!value.includes(name)) {
        remove(index);
      }
    });
  };

  return (
    <form className={classes.root}>
      <GroupedMultiselectField
        label="Technical labels"
        value={selectedTypes}
        onChange={handleOnChange}
        groupedOptions={[leGroup]}
        variant="filled"
      />

      {fields.map(({ id, name, min, max }, index) => (
        <TechnicalForm
          key={id}
          control={control}
          fieldKey={`technical[${index}]`}
          register={register}
          name={name}
          min={min}
          max={max}
          label={options.find((o) => o.value === name)?.label || name}
          onRemove={() => {
            remove(index);
          }}
        />
      ))}
    </form>
  );
};

export default withStyles(styles)(Technical);
