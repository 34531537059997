import React from 'react';
import { withStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import clsx from 'clsx';

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    display: 'inline-block',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.dark,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 20,
    textTransform: 'uppercase',
    fontWeight: '600',
    color: theme.palette.secondary.dark,
    backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
    '&.progress1': {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
    '&.progress2': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&.success': {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.1),
    },
    '&.warning': {
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
    },
    '&.error': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.1),
    },
  },
});
const StatusChip = ({ classes, status, statusClass }) => (
  <div className={clsx(classes.root, statusClass || '')}>
    <span>{status}</span>
  </div>
);

export default withStyles(styles)(StatusChip);
