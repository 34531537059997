import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export const SnackbarContext = React.createContext();

export function SnackBarContextProvider({ children }) {
  const [alert, showAlert] = React.useState();

  return (
    <SnackbarContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <Snackbar open autoHideDuration={4000} onClose={() => showAlert(null)}>
          <MuiAlert severity={alert.severity}>{alert.message}</MuiAlert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return React.useContext(SnackbarContext);
}
