import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce, uniqBy } from 'lodash';
import { LinearProgress, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { Launch as OpenIcon, Tab as TabIcon } from '@material-ui/icons';

import { Sort, Pagination, SpellCheck } from '@vidispine/vdt-materialui';

import { useAutocomplete } from '@vidispine/vdt-react';
import DataTable from '../../../components/DataTable';
import FilterDrawer from './FilterDrawer';
import { getDateString } from '../../../utils/utils';

import { SONOVA_PACKAGE_NAME } from '../../../consts/metadata';
import useQuery from '../useQuery';
import { useFindPackages } from '../../../hooks/search';
import FilterCard from './FilterCard';
import SearchInput from './SearchInput';
import { TagField } from './InputFields';
import StatusText from './StatusText';

const AUTOCOMPLETE_DEBOUNCE_TIMEOUT = 300;

const defaultSortOptions = [
  { label: 'Creation date', value: 'created' },
  { label: 'Status', value: 'packageStatus' },
  { label: 'Name', value: 'packageName' },
  { label: 'Owner', value: 'user' },
];

const defaultFilters = [
  {
    field: 'packageGroupName',
    label: 'Package group',
    type: 'value',
    useFacet: true,
    component: TagField,
    componentProps: {
      multiple: true,
    },
  },
  {
    field: 'packageStatus',
    label: 'Package status',
    type: 'value',
    useFacet: true,
    component: TagField,
    componentProps: {
      multiple: true,
    },
  },
];

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(2),
    flexGrow: 1,
    marginRight: `-${theme.spacing(1)}px`,
    '& .MuiTableRow-root': {
      cursor: 'auto !important',
      height: '61px',
      '& .MuiTableCell-root:last-child': {
        color: '#f09',
        padding: '6px',
        width: '61px',
      },
    },
    '& .SearchInputWrapper': {
      flexGrow: 6,
      '& form:focus-within': {
        '& .MuiButton-root': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiInputBase-root': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiButton-root': {
        backgroundColor: theme.palette.background.paper,
        height: '44px',
        borderLeft: 'none',
      },
      '& .MuiInputBase-root': {
        fontSize: '1rem',
        backgroundColor: theme.palette.background.paper,
        height: '44px',
        borderRight: 'none',
        paddingLeft: theme.spacing(2),
      },
    },
    '& .SearchControlsWrapper': {
      flexGrow: 1,
    },
  },
  searchTableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    maxHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
  },
  tableWrapper: {
    overflow: 'auto',
    paddingRight: theme.spacing(1),
  },
  searchWrapper: {
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    paddingBottom: theme.spacing(2),
  },
  searchProgress: {
    minHeight: '10px',
    margin: '0px 20px 5px 10px',
  },
  actionsContainer: {
    display: 'flex',
  },
});

const PackageTable = ({ classes }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { suggestions, setAutocompleteText } = useAutocomplete({
    field: SONOVA_PACKAGE_NAME,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAutocompleteText = React.useCallback(
    debounce(setAutocompleteText, AUTOCOMPLETE_DEBOUNCE_TIMEOUT),
    [setAutocompleteText],
  );

  const searchInputRef = React.useRef(null);

  const enterPackage = (id) => {
    history.push({
      pathname: `/package/${id}/`,
      state: { prevPath: pathname },
    });
  };
  const enterPackageNewTab = (id) => {
    window.open(`/package/${id}/`, '_blank');
  };

  const TABLE_COLUMNS = [
    {
      name: 'Package Id',
      key: 'packageId',
    },
    {
      name: 'Package Name',
      key: 'name',
    },
    {
      name: 'Created',
      key: 'created',
      render: (value) => (value ? getDateString(value) : ''),
    },
    {
      name: 'Owner',
      key: 'owner',
    },
    {
      name: 'Status',
      key: 'status',
      render: (value) => {
        return <StatusText statusText={value} />;
      },
    },
  ];

  const [searchText, setSearchText] = React.useState('');

  const { state, onChangePage, onChangeRowsPerPage, onChangeSort, setFilter } = useQuery({
    suggestion: {
      maximumSuggestions: 3,
      accuracy: 0.7,
    },
    filter: defaultFilters.map(({ field, useFacet }) => ({ field, useFacet })),
    label: {
      operation: 'AND',
    },
  });

  const { query = {}, first, number, page, rowsPerPage } = state;

  const { data: { hits, packages, facet, suggestion } = {}, isFetching } = useFindPackages(query, {
    first,
    number,
  });

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  React.useEffect(() => {
    handleFilterChange(
      uniqBy(
        [
          {
            field: 'packageName',
            value: [searchText || '*'],
          },
          ...query.filter,
        ],
        'field',
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <div className={classes.root}>
      <FilterDrawer>
        {facet && (
          <FilterCard
            FilterProps={{
              filters: defaultFilters,
              facets: facet,
              values: query.filter,
              onChange: handleFilterChange,
            }}
          />
        )}
      </FilterDrawer>
      <div className={classes.searchTableWrapper}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.searchWrapper}
        >
          <Grid item className="SearchInputWrapper">
            <SearchInput
              suggestions={suggestions}
              onSubmit={(text) => setSearchText(`*${text}*`)}
              searchPlaceholder="Search packages"
              onChange={debouncedSetAutocompleteText}
              onClickSuggestion={(s) => {
                setSearchText(s);
                debouncedSetAutocompleteText(s);
              }}
              searchInputRef={searchInputRef}
            />
          </Grid>
          <Grid item className="SearchControlsWrapper">
            <Grid container alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
              <Grid item>
                <Hidden smDown>
                  <Sort
                    initialSort={{ option: defaultSortOptions[0], direction: 'descending' }}
                    options={defaultSortOptions}
                    onChange={onChangeSort}
                    secondaryColor="#0083ca"
                  />
                </Hidden>
              </Grid>
              <Grid item style={{ overflow: 'hidden' }}>
                <Pagination
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  count={hits}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mb={1}>
          {suggestion && suggestion.length > 0 ? (
            <SpellCheck
              searchText={searchText.toLowerCase()}
              suggestions={suggestion}
              onClick={(s) => {
                setSearchText(`"${s}"`);
                searchInputRef.current.value = s;
              }}
            />
          ) : null}
        </Box>
        <Box className={classes.searchProgress}>
          {isFetching && <LinearProgress variant="query" />}
        </Box>
        <div className={classes.tableWrapper}>
          <DataTable
            rowKey="packageId"
            columns={TABLE_COLUMNS}
            rows={packages}
            RowActions={({ row }) => (
              <div className={classes.actionsContainer}>
                <Tooltip title="Open package">
                  <IconButton onClick={() => enterPackage(row.packageId)}>
                    <OpenIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open package in new tab">
                  <IconButton onClick={() => enterPackageNewTab(row.packageId)}>
                    <TabIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PackageTable);
