/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { metadata as MetadataApi } from '@vidispine/vdt-api';
import { parseMetadataType } from '@vidispine/vdt-js';
import { useApi } from '@vidispine/vdt-react';
import MetadataChangeLogTable from './MetadataChangeLogTable';

const MetadataChangeLog = ({
  entityId,
  entityType = 'item',
  ChangeLogComponent = MetadataChangeLogTable,
  ChangeLogProps = {},
}) => {
  const {
    data: { changeSet: changeSets = [] } = {},
    request: listEntityMetadataChangeRequest,
  } = useApi(MetadataApi.listEntityMetadataChange);

  React.useEffect(() => {
    listEntityMetadataChangeRequest({
      entity: entityType,
      entityId,
    });
  }, [entityId, entityType, listEntityMetadataChangeRequest]);

  const changes = changeSets
    .flatMap((changeSet) => {
      const { metadata, id } = changeSet;
      const { revision } = metadata;
      const parseOptions = {
        includeAttributes: true,
        timespanAsList: true,
        groupAsList: true,
        fieldAsList: true,
      };
      const metadataType = parseMetadataType(metadata, parseOptions);
      return metadataType.map(({ field = [], group = [], ...props }) => {
        const { timestamp, user } = group.length > 0 ? group[0] : field[0];
        return {
          revision,
          id,
          user,
          timestamp,
          group,
          field,
          ...props,
        };
      });
    })
    .reverse();

  const setEmptyFieldValues = (fields = []) =>
    fields.map((f) => ({
      ...f,
      value: [{ value: '' }],
    }));

  const getPreviousFieldsThatHaveChanged = React.useCallback((previousFields, changedFields) => {
    const fields = [];
    if (!previousFields.length && changedFields.length) {
      fields.concat();
    }

    return [
      ...previousFields.filter((pf) => changedFields.some((f) => f.name === pf.name)),
      ...setEmptyFieldValues(
        changedFields.filter((cf) => !previousFields.some((f) => f.name === cf.name)),
      ),
    ];
  }, []);
  const getPreviousGroupsThatHaveChanged = React.useCallback(
    (previousGroups, changedGroups) =>
      previousGroups
        .filter((pg) => changedGroups.some((g) => g.name === pg.name))
        .map(({ name, field: previousChildFields = [], group: previousChildGroups = [] }) => {
          const {
            field: changedChildFields = [],
            group: changedChildGroups = [],
          } = changedGroups.find((g) => g.name === name);
          return {
            name,
            field: getPreviousFieldsThatHaveChanged(previousChildFields, changedChildFields),
            group: getPreviousGroupsThatHaveChanged(previousChildGroups, changedChildGroups),
          };
        }),
    [getPreviousFieldsThatHaveChanged],
  );

  const mergeFields = React.useCallback((exitingFields = [], fieldsToAdd = []) => {
    const newFields = fieldsToAdd.filter(({ name }) => !exitingFields.some((f) => f.name === name));
    return [...exitingFields, ...newFields];
  }, []);

  const mergeGroups = React.useCallback(
    (existingGroups = [], groupsWithFieldsToAdd = []) => {
      const newGroups = groupsWithFieldsToAdd.filter(
        ({ name }) => !existingGroups.some((g) => g.name === name),
      );
      const extendedGroups = groupsWithFieldsToAdd
        .filter(({ name }) => existingGroups.some((g) => g.name === name))
        .map(({ name: addGroupName, field: addGroupChildFields, group: addGroupChildGroups }) => {
          const existingGroup = existingGroups.find((g) => g.name === addGroupName);
          const { field: exitingGroupChildFields, group: exitingGroupChildGroups } = existingGroup;
          return {
            ...existingGroup,
            field: mergeFields(exitingGroupChildFields, addGroupChildFields),
            group: mergeGroups(exitingGroupChildGroups, addGroupChildGroups),
          };
        });
      return [...extendedGroups, ...newGroups];
    },
    [mergeFields],
  );

  const toChangeWithBeforeChange = React.useCallback(
    (change) => {
      const {
        revision = '',
        start,
        end,
        group: changedGroups = [],
        field: changedFields = [],
      } = change;
      if (!revision.length) {
        return change;
      }
      const revisions = revision.split(',');
      const beforeChange = {
        field: [],
        group: [],
        start,
        end,
      };
      const previousChanges = changes.filter(
        (c) => revisions.includes(c.id) && c.start === start && c.end === end,
      );
      let previousFields = [];
      let previousGroups = [];
      previousChanges.forEach((previousChange) => {
        previousFields = mergeFields(previousFields, previousChange.field);
        previousGroups = mergeGroups(previousGroups, previousChange.group);
      });
      beforeChange.field = getPreviousFieldsThatHaveChanged(previousFields, changedFields);
      beforeChange.group = getPreviousGroupsThatHaveChanged(previousGroups, changedGroups);
      return {
        ...change,
        beforeChange,
      };
    },
    [
      changes,
      getPreviousFieldsThatHaveChanged,
      getPreviousGroupsThatHaveChanged,
      mergeFields,
      mergeGroups,
    ],
  );

  const changeLog = React.useMemo(() => changes.map(toChangeWithBeforeChange), [
    changes,
    toChangeWithBeforeChange,
  ]);

  return <ChangeLogComponent changeLog={changeLog} {...ChangeLogProps} />;
};

export default MetadataChangeLog;
