import React from 'react';

import Box from '@material-ui/core/Box';
import SensorIcon from '@material-ui/icons/LeakAdd';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { emphasize, withStyles } from '@material-ui/core';
import { metadata } from 'mediadb-lib';
import { sortBy as _sortBy } from 'lodash';
import { updateWhere } from '../../../utils/utils';
import PositionSelect from './PositionSelect';
import ChannelSelect from './ChannelSelect';
import CustomMetadataComponent from './CustomMetadataComponent';

const styles = (theme) => ({
  sensorContainer: {
    border: `1px solid ${emphasize(theme.palette.background.paper, 0.05)}`,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(6),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .fields': {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
    },
  },
  titleHeading: {
    flexGrow: 1,
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'min-content 1fr 1fr',
    gridGap: theme.spacing(1),
  },
  channel: {},
  channelForm: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  channelFormSelect: {
    flexGrow: 1,
  },
  positionSelect: {
    marginLeft: theme.spacing(3),
  },
});

const SensorComponent = ({
  classes,
  sensor,
  files,
  usedFiles,
  sensorIcon = <SensorIcon />,
  onChange,
  onChangeFiles,
}) => {
  const {
    channels,
    position,
    sensorTemplate: { name, type, positions },
  } = sensor;

  const isTimeSeries = (file) => file.name.split('.').pop() === 'csv';
  const timeSeriesFiles = files.filter((f) => isTimeSeries(f.file));

  const updateChannel = React.useCallback(
    (newChannel) => {
      onChange({
        ...sensor,
        channels: updateWhere(sensor.channels, (c) => c.name === newChannel.name, newChannel),
      });
    },
    [onChange, sensor],
  );

  const onChangePosition = (newPosition) => {
    onChange({ ...sensor, position: newPosition });
  };

  const onChangeChannel = (newChannel) => {
    onChangeFiles(newChannel);
    updateChannel(newChannel);
  };

  React.useEffect(() => {
    const fileNames = files.map((f) => f.file.name);
    if (channels.some((c) => c.value && !fileNames.includes(c.value))) {
      const channelsWithFileDeleted = channels.filter(
        (c) => c.value && !fileNames.includes(c.value),
      );
      channelsWithFileDeleted.forEach(({ name: channelName, value }) => {
        updateChannel({ name: channelName, prevValue: value });
      });
    }
  }, [channels, files, updateChannel]);

  return (
    <Box className={classes.sensorContainer}>
      <Box display="flex" mb={1} justifyContent="space-between">
        <Box className={classes.titleHeading}>
          <Icon>{sensorIcon}</Icon>
          <Typography variant="subtitle2">{`${name} - ${type}`}</Typography>
          {positions.length > 0 && (
            <PositionSelect
              classes={classes}
              position={position}
              positions={_sortBy(positions)}
              onChange={onChangePosition}
            />
          )}
        </Box>
      </Box>
      <div className={classes.inputContainer}>
        <Typography variant="caption">Channels</Typography>
        <div className="fields">
          {channels.length > 0 &&
            _sortBy(channels, 'name').map((channel) => (
              <div className={classes.channel} key={channel.name}>
                <ChannelSelect
                  classes={classes}
                  channel={channel}
                  files={timeSeriesFiles}
                  usedFiles={usedFiles}
                  onChange={onChangeChannel}
                />
              </div>
            ))}
        </div>
      </div>

      <CustomMetadataComponent
        source={sensor}
        onChange={onChange}
        customGroupName={metadata.CUSTOM_GROUPS.SENSOR.name}
      />
    </Box>
  );
};

export default withStyles(styles)(SensorComponent);
