import React from 'react';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { TextBoxOutline } from 'mdi-material-ui';

import { getDateString } from '../utils/utils';

const styles = (theme) => ({
  metadataListItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiListItemText-root': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
  },
});

function MetadataList({ classes, fields }) {
  return (
    <List disablePadding>
      {fields.map((field) => (
        <ListItem key={field.name} className={classes.metadataListItem} disableGutters>
          <ListItemIcon>{field.icon ? field.icon : <TextBoxOutline />}</ListItemIcon>
          <ListItemText
            primary={field.type === 'date' ? getDateString(field.value) : field.value || '-'}
            secondary={field.label || field.name}
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default withStyles(styles)(MetadataList);
