import { IconButton, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { ChevronLeft as ExpandIcon } from '@material-ui/icons';

const styles = (theme) => ({
  filterDrawer: {
    width: '48px',
    marginLeft: `-${theme.spacing(1)}px`,
    marginTop: `-${theme.spacing(2)}px`,
    marginRight: theme.spacing(2),
    minHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    maxHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    borderRight: '1px solid',
    borderRightColor: theme.palette.grey[300],
    overflow: 'hidden',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterContainer: {
    marginRight: theme.spacing(1),
    height: 'calc(100% - 48px)',
    minHeight: 'calc(100% - 48px)',
    alignItems: 'space-between',
    display: 'grid',
    gridTemplateRows: 'min-content auto auto 1fr',
  },
  toggleButton: {
    transform: 'rotate(-180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  toggleButtonExpanded: {
    transform: 'rotate(0deg)',
  },
  drawerOpen: {
    overflowY: 'auto',
    width: '260px',
  },
  hidden: {
    visibility: 'hidden',
  },
});

function FilterDrawer({ children, classes }) {
  const [filtersExpanded, setFiltersExpanded] = React.useState(true);

  const toggleFilters = () => {
    setFiltersExpanded((prev) => !prev);
  };

  return (
    <div
      className={clsx(classes.filterDrawer, {
        [classes.drawerOpen]: filtersExpanded,
      })}
    >
      <div className={classes.buttonContainer}>
        <IconButton
          className={clsx(classes.toggleButton, {
            [classes.toggleButtonExpanded]: filtersExpanded,
          })}
          onClick={toggleFilters}
        >
          <ExpandIcon />
        </IconButton>
      </div>
      <div
        className={clsx(classes.filterContainer, {
          [classes.hidden]: !filtersExpanded,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default withStyles(styles)(FilterDrawer);
