import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import React from 'react';

const styles = () => ({});

function renderMultipleValues({ value, onChange }) {
  return value.map((v) => (
    <Chip
      style={{ marginRight: '4px' }}
      size="small"
      key={v}
      tabIndex={-1}
      label={v}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onDelete={(e) => {
        const newValue = value.filter((other) => v !== other);
        onChange(newValue, e);
      }}
    />
  ));
}

function EnumField({ field, required, label, options, value, onChange, multiple, ...props }) {
  return (
    <FormControl required={required}>
      <InputLabel>{label}</InputLabel>
      <Select
        onChange={(e) => onChange(e.target.value, e)}
        multiple={multiple}
        value={value}
        MenuProps={{ getContentAnchorEl: null }}
        renderValue={
          multiple
            ? (v) => {
                return renderMultipleValues({ value: v, onChange });
              }
            : undefined
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {options.map((o) =>
          multiple ? (
            <MenuItem key={o} value={o}>
              <Checkbox size="small" color="primary" checked={value.indexOf(o) > -1} />
              {o}
            </MenuItem>
          ) : (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(EnumField);
