/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';

export const ConfirmationDialogContext = React.createContext({
  ActiveDialog: undefined,
  setActiveDialog: () => null,
});

export function ConfirmationDialogContextProvider({ children }) {
  const [ActiveDialog, setActiveDialog] = React.useState(null);

  return (
    <ConfirmationDialogContext.Provider value={{ setActiveDialog }}>
      {children}
      {ActiveDialog && <ActiveDialog />}
    </ConfirmationDialogContext.Provider>
  );
}

const useConfirmationDialog = ({
  throwOnReject = true,
  DialogComponent = ConfirmationDialog,
  DialogProps = {},
} = {}) => {
  const { setActiveDialog } = React.useContext(ConfirmationDialogContext);

  const confirm = (confirmProps) =>
    new Promise((resolve, reject) => {
      const close = () => {
        setActiveDialog(null);
      };
      const onConfirm = (e) => {
        e.stopPropagation();
        close();
        resolve(true);
      };
      const onReject = (e) => {
        e.stopPropagation();
        close();
        if (throwOnReject) reject();
        else resolve(false);
      };
      setActiveDialog(() => (props) => (
        <DialogComponent
          open
          onConfirm={onConfirm}
          onReject={onReject}
          {...props}
          {...DialogProps}
          {...confirmProps}
        />
      ));
    });
  return { confirm };
};

export default useConfirmationDialog;
