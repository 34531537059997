import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { shortenUuid, metadata, systemFields } from 'mediadb-lib';
import { get } from 'lodash';
import { useCustomMetadataGroup } from '../../../hooks/metadata';
import MetadataFieldEdit from '../../../components/MetadataFieldEdit';
import useCleanupEffect from '../../../hooks/useCleanupEffect';
import PackageGroupSelect from '../../../components/PackageGroupSelect';

const styles = (theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  textInput: {},
  chipContainer: {
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContent: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridAutoFlow: 'column',
  },
});

function validateMetadataValues({ values, customFields }) {
  const { packageGroup, name, location, customMetadata } = values;
  const nameError = name?.length > 0 ? undefined : 'Required';
  const locationError = location?.length > 0 ? undefined : 'Required';
  const packageGroupError = packageGroup?.id ? undefined : 'Required';
  const customFieldErrors = metadata.validateCustomFields(customMetadata, customFields, {
    abortEarly: false,
  });
  return {
    ...(nameError ? { name: nameError } : undefined),
    ...(locationError ? { location: locationError } : undefined),
    ...(packageGroupError ? { packageGroup: packageGroupError } : undefined),
    ...customFieldErrors,
  };
}

const UploadMetadata = ({
  classes,
  value: values,
  onChange,
  onChangeValid,
  devices,
  packageFilesAudioSamplingRate,
}) => {
  const { data: customPackageFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.PACKAGE.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );

  const [localValues, setLocalValues] = React.useState(values);
  const {
    name,
    location,
    description,
    customMetadata = {},
    previewDeviceId,
    audioSamplingRate = {},
    packageGroup,
  } = localValues;

  const { value: audioSamplingRateValue } = audioSamplingRate;

  const errors = validateMetadataValues({
    values: localValues,
    customFields: customPackageFields,
  });

  const isTouched = (path) => get(localValues, path) !== undefined;
  const isValid = Object.entries(errors).length === 0;

  const isValidPreviewDevice = !!devices.find((d) => d.id === previewDeviceId);

  const isValidAudioSamplingRate =
    packageFilesAudioSamplingRate.some(({ value }) => value === audioSamplingRate.value) &&
    audioSamplingRate.filenames.length > 0;

  React.useEffect(() => {
    onChangeValid(isValid);
  }, [isValid, onChangeValid]);

  React.useEffect(() => {
    // Clear if sampling rate is removed as option
    if (!packageFilesAudioSamplingRate.some(({ value }) => value === audioSamplingRateValue)) {
      setLocalValues((existing) => ({
        ...existing,
        audioSamplingRate: {},
      }));
    }
    // Set default if only one
    if (!audioSamplingRateValue && packageFilesAudioSamplingRate.length === 1) {
      setLocalValues((existing) => ({
        ...existing,
        audioSamplingRate: packageFilesAudioSamplingRate[0],
      }));
    }
  }, [packageFilesAudioSamplingRate, audioSamplingRateValue]);

  useCleanupEffect(() => {
    onChange(localValues);
  }, [localValues, onChange]);

  return (
    <Box className={classes.root} display="flex" flexDirection="column" p={2}>
      <PackageGroupSelect
        value={packageGroup}
        onChange={(value) => {
          setLocalValues({
            ...localValues,
            packageGroup: value,
            packageGroupId: value.id,
          });
        }}
        FormControlProps={{
          required: true,
        }}
        error={!!errors.packageGroup}
      />
      <MetadataFieldEdit
        value={name}
        onChange={(value) => {
          setLocalValues({
            ...localValues,
            name: value,
          });
        }}
        error={isTouched('name') && !!errors.name}
        field={systemFields.PACKAGE_NAME}
      />
      <MetadataFieldEdit
        value={location}
        onChange={(value) => {
          setLocalValues({
            ...localValues,
            location: value,
          });
        }}
        error={isTouched('location') && !!errors.location}
        field={systemFields.PACKAGE_LOCATION}
      />
      <MetadataFieldEdit
        value={description}
        onChange={(value) => {
          setLocalValues({
            ...localValues,
            description: value,
          });
        }}
        field={systemFields.PACKAGE_DESCRIPTION}
      />
      {customPackageFields.map((field) => (
        <MetadataFieldEdit
          value={customMetadata[field.fieldName]}
          onChange={(value) => {
            setLocalValues({
              ...localValues,
              customMetadata: {
                ...localValues.customMetadata,
                [field.fieldName]: value,
              },
            });
          }}
          field={field}
          key={field.fieldName}
          error={isTouched(`customMetadata.${field.fieldName}`) && !!errors[field.fieldName]}
        />
      ))}
      <FormControl className={classes.textInput}>
        <InputLabel>Package Preview Device</InputLabel>
        <Select
          variant="standard"
          value={isValidPreviewDevice ? previewDeviceId : ''}
          onChange={(e) => setLocalValues({ ...localValues, previewDeviceId: e.target.value })}
        >
          {devices.map((device) => (
            <MenuItem key={device.id} value={device.id}>
              {`${device.deviceTemplate.name}${
                device.position ? ` - ${device.position}` : ''
              } (${shortenUuid(device.id)})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.textInput}>
        <InputLabel>Package Audio Sampling Rate</InputLabel>
        <Select
          variant="standard"
          value={isValidAudioSamplingRate ? audioSamplingRateValue : ''}
          onChange={({ target: { value } = {} }) =>
            setLocalValues((existing) => ({
              ...existing,
              audioSamplingRate: packageFilesAudioSamplingRate.find((o) => o.value === value),
            }))
          }
        >
          {packageFilesAudioSamplingRate.map(({ value, filenames }) => (
            <MenuItem key={value} value={value}>
              <Tooltip title={filenames.join('\n')}>
                <div style={{ width: '100%' }}>{`${value} Hz`}</div>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default withStyles(styles)(UploadMetadata);
