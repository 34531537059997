import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IconButton, Tab, Tabs, Tooltip, withStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import clsx from 'clsx';

import { CheckboxField } from '@vidispine/vdt-materialui';

import {
  Assignment as MetadataIcon,
  Add as AddIcon,
  Edit as EditIcon,
  FilterList as FilterListIcon,
  Delete,
} from '@material-ui/icons';

import { omit } from 'lodash';
import { metadata, systemFields } from 'mediadb-lib';
import SectionHeader from '../../../components/SectionHeader';
import DataTable from '../../../components/DataTable';
import MetadataFieldEditor from '../../../vdt/MetadataFieldEditor';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import useConfirmationDialog from '../../../contexts/ConfirmationDialogContext';
import TabPanel from './TabPanel';

import { TextConfirmationDialog } from '../../../dialogs/ConfirmationDialog';

import { useCustomMetadataGroup, useRemoveCustomField } from '../../../hooks/metadata';

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, 1fr) 400px',
    gridGap: theme.spacing(2),
  },
  tabPanel: {
    maxHeight: `
    calc(100vh - 70px 
      - ${theme.spacing(8)}px 
      - ${theme.spacing(6)}px 
      - 48px 
      - 48px 
      - ${theme.spacing(2)}px)
      `,
    overflow: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 'unset',
    '& th': {
      position: 'sticky',
      top: '0',
      backgroundColor: theme.palette.background.paper,
    },
  },
});

const tableColumns = [
  {
    name: 'Field Name',
    key: 'fieldName',
  },
  {
    name: 'Type',
    key: 'mediaDBType',
  },
  {
    name: 'Label',
    key: 'label',
  },
  {
    name: 'Multiple values',
    key: 'isMultiValue',
  },
  {
    name: 'Required',
    key: 'required',
  },
  {
    name: 'Browse',
    key: 'isActiveInBrowse',
  },
  {
    name: 'Upload',
    key: 'isActiveInUpload',
  },
];

const rowFilter = (name) => (row) => row[name];

function useFilters() {
  const [filters, setFilters] = React.useState({});

  const addFilter = React.useCallback(
    (name, filter) => {
      setFilters((prev) => ({ ...prev, [name]: filter }));
    },
    [setFilters],
  );

  const removeFilter = React.useCallback(
    (name) => {
      setFilters((prev) => omit(prev, [name]));
    },
    [setFilters],
  );

  const applyFilters = React.useCallback(
    (target) => Object.values(filters).reduce((acc, curr) => acc.filter(curr), target),
    [filters],
  );

  return {
    addFilter,
    removeFilter,
    applyFilters,
    filters,
  };
}

function FilterActions({ checkedFilters = {}, onClickFilter }) {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Box display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
        <Box mr={1} mt="8px">
          <FilterListIcon />
        </Box>
        <CheckboxField
          color="primary"
          label="Browse"
          input={{
            checked: checkedFilters.isActiveInBrowse,
            onChange: ({ target: { checked } = {} } = {}) =>
              onClickFilter('isActiveInBrowse', checked),
          }}
        />
        <CheckboxField
          color="primary"
          label="Upload"
          input={{
            checked: checkedFilters.isActiveInUpload,
            onChange: ({ target: { checked } = {} } = {}) =>
              onClickFilter('isActiveInUpload', checked),
          }}
        />
      </Box>
    </Box>
  );
}

const CUSTOM_GROUPS = [
  metadata.CUSTOM_GROUPS.PACKAGE,
  metadata.CUSTOM_GROUPS.PERSON,
  metadata.CUSTOM_GROUPS.DEVICE,
  metadata.CUSTOM_GROUPS.SENSOR,
];

const Metadata = ({ classes }) => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const { showAlert } = useSnackbar();
  const { confirm: confirmDelete } = useConfirmationDialog({
    throwOnReject: false,
    DialogComponent: TextConfirmationDialog,
  });

  const { name: groupName, prefix: fieldPrefix } = CUSTOM_GROUPS[selectedTabIndex];

  const { data: customFields = [] } = useCustomMetadataGroup(groupName);

  const currentFields = React.useMemo(() => {
    const customWithKey = customFields.map((f) => ({ ...f, fieldKey: f.fieldName }));
    return groupName === metadata.CUSTOM_GROUPS.PACKAGE.name
      ? Object.values(systemFields)
          .map((f) => {
            return {
              ...f,
              disableEdit: true,
              fieldKey: f.fieldName,
              fieldName: (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Tooltip title="System field (cannot be edited)">
                    <SettingsIcon fontSize="small" />
                  </Tooltip>
                  {`${f.fieldName}`}
                </span>
              ),
            };
          })
          .concat(customWithKey)
      : customWithKey;
  }, [customFields, groupName]);

  const { mutateAsync: removeCustomField } = useRemoveCustomField();

  const { addFilter, removeFilter, applyFilters, filters } = useFilters();

  const filteredRowFields = React.useMemo(() => applyFilters(currentFields), [
    applyFilters,
    currentFields,
  ]);

  const selectedField = React.useMemo(() => {
    const field = currentFields.find(
      ({ fieldName } = {}) => selectedRows.length && selectedRows[0].fieldName === fieldName,
    );
    if (filteredRowFields.some((rf) => field && rf.fieldName === field.fieldName)) {
      return field;
    }
    return null;
  }, [currentFields, filteredRowFields, selectedRows]);

  const handleOnDelete = async (fieldName) => {
    if (
      await confirmDelete({
        fieldName,
      })
    ) {
      await removeCustomField({
        groupName,
        fieldName,
      })
        .then(() =>
          showAlert({ severity: 'success', message: 'Succesfully deleted metadata field' }),
        )
        .catch(() =>
          showAlert({
            severity: 'error',
            message: 'Could not delete metadata field',
          }),
        );
    }
  };

  const handleClickRow = (row) => {
    if (!row.disableEdit) setSelectedRows((prev) => (prev.includes(row) ? [] : [row]));
  };

  const handleChangeTab = (_, index) => {
    setSelectedTabIndex(index);
    setSelectedRows([]);
  };

  const handleClickFilter = (name, checked) => {
    if (checked) {
      addFilter(name, rowFilter(name));
    } else {
      removeFilter(name);
    }
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Paper className="adminContainer" variant="outlined">
        <SectionHeader
          Icon={MetadataIcon}
          title="Metadata"
          titleTypographyProps={{
            variant: 'h4',
          }}
          Actions={FilterActions}
          actionProps={{
            checkedFilters: Object.keys(filters),
            groupName,
            onClickFilter: handleClickFilter,
          }}
        />
        <Box display="flex" justifyContent="center" mb={2}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {CUSTOM_GROUPS.length > 0 &&
              CUSTOM_GROUPS.map(({ label, name }, index) => (
                <Tab key={name} label={label} index={index} />
              ))}
          </Tabs>
        </Box>
        {CUSTOM_GROUPS.length > 0 &&
          CUSTOM_GROUPS.map(({ name }, index) => (
            <TabPanel
              className={classes.tabPanel}
              key={name}
              value={selectedTabIndex}
              index={index}
            >
              <DataTable
                rowKey="fieldKey"
                columns={tableColumns}
                rows={filteredRowFields}
                selectedRows={selectedRows}
                onClickRow={handleClickRow}
              />
            </TabPanel>
          ))}
      </Paper>
      <Paper className={clsx('adminSidePanel', 'adminContainer')} variant="outlined">
        {selectedField ? (
          <>
            <SectionHeader
              Icon={EditIcon}
              title="Edit Field"
              Actions={() => (
                <IconButton size="small" onClick={() => handleOnDelete(selectedField.fieldName)}>
                  <Delete />
                </IconButton>
              )}
              titleTypographyProps={{
                variant: 'h4',
              }}
            />
            <MetadataFieldEditor
              groupName={groupName}
              namePrefix={fieldPrefix}
              defaultValues={{
                ...selectedField,
                fieldName: selectedField.fieldName.replace(fieldPrefix, ''),
              }}
              isEditing
              // remount when switching selected as an easy way to reset the form with defaultValues
              key={selectedField.fieldName}
              submitText="Save"
              onSuccess={() =>
                showAlert({
                  severity: 'success',
                  message: 'Metadata field updated',
                })
              }
              onCancel={() => setSelectedRows([])}
              onError={() =>
                showAlert({
                  severity: 'error',
                  message: 'Failed to update metadata field',
                })
              }
            />
          </>
        ) : (
          <>
            <SectionHeader
              Icon={AddIcon}
              title="New Field"
              titleTypographyProps={{
                variant: 'h4',
              }}
            />
            <MetadataFieldEditor
              groupName={groupName}
              namePrefix={fieldPrefix}
              submitText="Add Field"
              onSuccess={() =>
                showAlert({
                  severity: 'success',
                  message: 'Metadata field created',
                })
              }
              onError={() =>
                showAlert({
                  severity: 'error',
                  message: 'Failed to create metadata field',
                })
              }
            />
          </>
        )}
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(Metadata);
