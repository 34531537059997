import React from 'react';
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { PlaylistEdit } from 'mdi-material-ui';
import SectionHeader from '../components/SectionHeader';
import UserAutocomplete from '../components/UserAutocomplete';
import { useChangePackageOwner } from '../hooks/pkg';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

function ChangeOwnerDialog({
  classes,
  open,
  onClose,
  packageMetadata,
  onSuccess = () => null,
  onError = () => null,
}) {
  const currentOwner = React.useMemo(() => {
    return {
      userName: packageMetadata.owner,
    };
  }, [packageMetadata]);
  const { mutateAsync: changePackageOwner, isLoading } = useChangePackageOwner();
  const [selectedOwner, setSelectedOwner] = React.useState({});
  const handleChangePackageOwner = () => {
    const { packageId } = packageMetadata;
    changePackageOwner({ packageId, newOwner: selectedOwner?.userName })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch(() => {
        onError();
      });
  };
  return (
    <Dialog className={classes.root} maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={PlaylistEdit}
          title="Change Package Owner"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <UserAutocomplete
          value={selectedOwner}
          onChange={(user) => {
            setSelectedOwner(() => user ?? {});
          }}
          transformOptions={(options) => {
            return options.filter((u) => !['admin', 'vidinetviewer'].includes(u.userName));
          }}
          getOptionSelected={(o, v) => {
            return o.userName === v.userName;
          }}
          getOptionDisabled={(o) => {
            return [selectedOwner.userName, currentOwner.userName].includes(o.userName);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleChangePackageOwner}
          disabled={
            isLoading || !selectedOwner.userName || currentOwner.userName === selectedOwner.userName
          }
          variant="contained"
          disableElevation
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ChangeOwnerDialog);
