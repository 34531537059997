import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import FileTree from './FileTree';
import FileDropzone from './FileDropzone';

const styles = (theme) => ({
  root: {},
  fileTreeHeader: {
    padding: theme.spacing(2),
  },
  stickyContainer: {
    position: 'sticky',
    top: 0,
    zIndex: '10',
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
});

const COMMON_MIME_TYPES = {
  csv: 'text/csv',
  wav: 'audio/wav',
};

// More info
// https://github.com/react-dropzone/react-dropzone/issues/977
// https://github.com/react-dropzone/file-selector/blob/d8287918d49a9cb9c25ff08ce9d3a63e089234c2/src/file.ts#L9
function mimeTypeFallback(f) {
  const { type, name } = f;
  const ext = name?.split('.').pop();
  Object.defineProperty(f, 'type', {
    value: type || COMMON_MIME_TYPES[ext],
    writable: false,
    configurable: false,
    enumerable: true,
  });
  return f;
}

function isDefaultGeneric(f) {
  const mediaType = f.file.type?.split('/')[0];
  return !['audio', 'video'].includes(mediaType);
}

function isTimeSeriesFile(f) {
  return [
    'text/x-csv',
    'application/vnd.ms-excel',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ].includes(f.file.type);
}

const AddFiles = ({
  classes,
  uploadProps,
  onAddedFiles,
  isLoadingMediaInfo,
  mediaInfo,
  isValidMediaInfo,
}) => {
  const { onAddFiles, onChangeMetadata, onRemoveFiles, files } = uploadProps;

  const onAddFilesUnique = React.useCallback(
    (newFiles = []) => {
      const currFilesPaths = files.map(({ file: { path } }) => path);
      const uniqueFiles = newFiles
        .filter(({ path }) => !currFilesPaths.includes(path))
        .map(mimeTypeFallback);
      onAddFiles(uniqueFiles);
      onAddedFiles(uniqueFiles);
    },
    [onAddFiles, files, onAddedFiles],
  );

  const allGenericOrTimeSeries = files.every(
    ({ metadata }) => metadata.generic || metadata.timeSeries,
  );

  React.useEffect(() => {
    files.forEach((f, i) => {
      if (f.metadata.generic === undefined) {
        onChangeMetadata(i)({
          ...f.metadata,
          generic: isDefaultGeneric(f) && !isTimeSeriesFile(f),
        });
      }
      if (f.metadata.timeSeries === undefined) {
        onChangeMetadata(i)({ ...f.metadata, timeSeries: isTimeSeriesFile(f) });
      }
    });
  }, [files, onChangeMetadata]);

  const alert = (() => {
    if (isLoadingMediaInfo) {
      return (
        <Alert severity="info">
          <Box display="flex" alignItems="center">
            Loading media info{' '}
            <CircularProgress style={{ marginLeft: '8px' }} color="primary" size={14} />
          </Box>
        </Alert>
      );
    }
    if (isValidMediaInfo) {
      return <Alert severity="success">Package files duration verified</Alert>;
    }
    if (files.length > 0) {
      return (
        <Alert severity="error">
          {allGenericOrTimeSeries
            ? 'Please add at least one non-generic audio or video file'
            : 'Package files duration does not match'}
        </Alert>
      );
    }

    return null;
  })();

  return (
    <div className={classes.root}>
      <Box className={classes.stickyContainer}>
        <FileDropzone onAddFiles={onAddFilesUnique} files={files} />
        {alert}
      </Box>
      {files && files.length > 0 && (
        <div>
          <Typography variant="subtitle2" align="center" classes={{ root: classes.fileTreeHeader }}>
            Please mark all generic files
          </Typography>
          <FileTree
            files={files}
            mediaInfo={mediaInfo}
            onRemoveFiles={onRemoveFiles}
            onChangeMetadata={onChangeMetadata}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(AddFiles);
