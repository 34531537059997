import { invert } from 'lodash';

export const GROUP_TO_ROLE = {
  sonova_operator: 'Operator',
  sonova_admin: 'Admin',
};

export const ROLE_TO_GROUP = invert(GROUP_TO_ROLE);

export const ROLES = Object.values(GROUP_TO_ROLE);

const parseUserGroups = (user) => (user.groupList || {}).group || [];

export const parseUser = (user) => ({
  ...user,
  roles: parseUserGroups(user)
    .map(({ groupName }) => GROUP_TO_ROLE[groupName])
    .filter((r) => r),
});
