import { createTheme, alpha } from '@material-ui/core/styles';
import 'typeface-roboto';

import { orange } from '@material-ui/core/colors';

const sonovaBlue = '#0083ca';
const sonovaDarkBlue = '#003c64';
const sonovaLightBlue = '#6eb4dc';
const secondaryColor = '#ccc';
const borderRadius = '2px';
const theme = createTheme({
  props: {
    MuiInput: { inputProps: { spellCheck: 'false' } },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    subtitle1: {
      fontSize: 12,
      color: 'rgba(0,0,0,0.6)',
      fontStyle: 'italic',
    },
    h3: {
      fontSize: '26px',
      fontWeight: '400',
    },
    h4: {
      fontSize: '18px',
      fontWeight: '400',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: sonovaBlue,
      dark: sonovaDarkBlue,
      light: sonovaLightBlue,
    },
    secondary: {
      main: secondaryColor,
    },
    warning: {
      main: orange[400],
    },
    text: {
      primary: 'rgba(0,0,0,0.75)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          scrollbarColor: 'rgba(0,0,0,0.1) transparent',
          scrollbarWidth: 'thin',
        },

        '*::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.1)',
          borderRadius: '500px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius,
      },
    },
  },
});
theme.overrides = {
  ...theme.overrides,
  MuiListItem: {
    root: {
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
      },
    },
  },
  MuiTableRow: {
    root: {
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
      },
      '&.MuiTableRow-hover': {
        cursor: 'pointer',
      },
    },
  },
  MuiTableCell: {
    stickyHeader: {
      backgroundColor: theme.palette.common.white,
    },
  },
  MuiPaper: {
    root: {
      '&.MuiPaper-rounded': {
        borderRadius,
      },
      '&.adminContainer': {
        minHeight: `calc(100vh - 70px - ${theme.spacing(8)}px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: theme.spacing(3),
      },
      '&.adminSidePanel': {
        maxHeight: `calc(100vh - 70px - ${theme.spacing(8)}px)`,
        overflow: 'auto',
      },
    },
  },
  MuiDialogTitle: {
    root: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
  },
  MuiDialogContent: {
    root: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  MuiDialogActions: {
    root: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.7rem',
    },
  },
};

export default theme;
