import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';

const styles = (theme) => ({
  popper: {
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
  },
  focused: {
    backgroundColor: theme.palette.secondary.main,
  },
});

function SearchSuggestions({ suggestions = [], focused, onHover, onClick, anchorEl, classes }) {
  return suggestions.length > 0 ? (
    <Popper
      anchorEl={anchorEl}
      open
      style={{
        width: anchorEl ? anchorEl.clientWidth : null,
      }}
      className={classes.popper}
    >
      <List onMouseLeave={() => onHover(-1)}>
        {suggestions.map((suggestion, i) => (
          <ListItem
            onMouseEnter={() => onHover(i)}
            className={clsx({
              [classes.focused]: focused === i,
            })}
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}-${suggestion}`}
            onMouseDown={() => onClick(suggestion)}
          >
            {suggestion}
          </ListItem>
        ))}
      </List>
    </Popper>
  ) : null;
}

export default withStyles(styles, { name: 'VdtSearchSuggestions' })(SearchSuggestions);
