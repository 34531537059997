import React from 'react';
import { Box, IconButton, LinearProgress, Tooltip, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { formatTimeCodeText } from '@vidispine/vdt-js';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {
  ContentCut as ExtractIcon,
  Timelapse as CoverageIcon,
  TimerOutline as DurationIcon,
} from 'mdi-material-ui';
import { Add as AddIcon } from '@material-ui/icons';

import { getDateString, hhmmss } from '../../../utils/utils';
import { useAddPlaylistExtracts } from '../../../hooks/playlist';
import { PlaylistContext } from '../../../contexts/PlaylistContext';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import Timeline from './Timeline';
import StatusText from './StatusText';

const styles = (theme) => ({
  root: {
    padding: '0 !important',
  },
  metadataList: {
    display: 'flex',
  },
  packageMetadataList: {
    paddingBottom: theme.spacing(1),
  },
  extractMetadataList: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  metadataListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    flex: '0 1 160px',
    overflow: 'hidden',
  },
  metadataListItemText: {
    marginTop: 0,
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column-reverse',
    '& > *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  metadataListItemTextSecondary: {
    fontSize: '0.75rem',
  },
  metadataListItemIcon: {
    minWidth: '28px',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  timeline: {
    margin: '8px',
  },
  noExtractsContainer: {
    padding: theme.spacing(1.5),
  },
});

const renderFieldValue = (field) => {
  if (field.name === 'Status') {
    return <StatusText statusText={field.value} />;
  }
  switch (field.type) {
    case 'date':
      return getDateString(field.value);
    case 'duration':
      return field.value ? hhmmss(field.value) : '-';
    default:
      return field.value || '-';
  }
};
const reducer = (accumulator, currentValue) => accumulator + currentValue;

function PackageCardContent({ classes, metadata, extracts = [], isFetchingExtracts, pkg }) {
  const { selectedPlaylist } = React.useContext(PlaylistContext);
  const { mutate: addPlaylistExtracts } = useAddPlaylistExtracts();
  const { showAlert } = useSnackbar();

  const { packageId, duration } = pkg;

  const durationSeconds = parseInt(duration, 10);
  const durationTimeCode = formatTimeCodeText(durationSeconds).conformTimeBase({
    denominator: 1000000,
  });

  const handleAddExtracts = () => {
    if (!selectedPlaylist) {
      showAlert({ severity: 'warning', message: 'Select a playlist to add the extract to' });
      return;
    }
    addPlaylistExtracts({
      playlistId: selectedPlaylist.id,
      extracts: extracts.map(({ start, end }) => ({
        packageId,
        inTCText: start,
        outTCText: end,
      })),
    });
  };

  const totalDuration = React.useMemo(() => {
    const allDurations = extracts.map((extract) => {
      return extract.duration;
    });
    return allDurations.length > 0 ? hhmmss(allDurations.reduce(reducer)) : 0;
  }, [extracts]);

  const totalCoverage = React.useMemo(() => {
    const allCoverages = extracts.map(({ start, end }) => {
      const startTimeCode = formatTimeCodeText(start).conformTimeBase({ denominator: 1000000 });
      const endTimeCode = formatTimeCodeText(end).conformTimeBase({ denominator: 1000000 });
      const extractDurationTimeCode = endTimeCode.subtract(startTimeCode);
      const coverage =
        Math.round(10000 * (extractDurationTimeCode.toSeconds() / durationTimeCode.toSeconds())) /
        100;
      return coverage;
    });
    return allCoverages.length > 0 ? Number.parseFloat(allCoverages.reduce(reducer)).toFixed(2) : 0;
  }, [durationTimeCode, extracts]);

  return (
    <CardContent className={classes.root}>
      <List disablePadding className={classes.metadataList}>
        {metadata.map((field) => (
          <ListItem className={classes.metadataListItem} key={field.name}>
            <ListItemText
              className={classes.metadataListItemText}
              classes={{
                secondary: classes.metadataListItemTextSecondary,
              }}
              primary={renderFieldValue(field)}
              primaryTypographyProps={{
                variant: 'body2',
              }}
              secondary={field.name}
            />
          </ListItem>
        ))}
      </List>
      <Divider className={classes.divider} />
      {extracts.length > 0 ? (
        <Box display="flex">
          <Box flexGrow="1">
            <List
              disablePadding
              className={clsx(classes.metadataList, classes.extractMetadataList)}
            >
              <ListItem className={classes.metadataListItem}>
                <ListItemIcon className={classes.metadataListItemIcon}>
                  <ExtractIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.metadataListItemText}
                  primary={`${extracts.length} ${extracts.length === 1 ? 'Extract' : 'Extracts'}`}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
              <ListItem className={classes.metadataListItem}>
                <ListItemIcon className={classes.metadataListItemIcon}>
                  <DurationIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.metadataListItemText}
                  primary={totalDuration || '00:00:00'}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
              <ListItem className={classes.metadataListItem}>
                <ListItemIcon className={classes.metadataListItemIcon}>
                  <CoverageIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.metadataListItemText}
                  primary={`${totalCoverage}%`}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
            </List>
            <Timeline
              className={classes.timeline}
              extracts={extracts.map(({ startSeconds, endSeconds }) => {
                return {
                  start: startSeconds,
                  end: endSeconds,
                };
              })}
              height={6}
              duration={durationSeconds}
            />
          </Box>
          <Box display="flex" alignItems="end">
            <Tooltip title="Add all extracts to selected playlist">
              <IconButton onClick={() => handleAddExtracts()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <div className={classes.noExtractsContainer}>
          {isFetchingExtracts ? (
            <Typography variant="overline" color="textSecondary">
              Loading extracts
              <LinearProgress />
            </Typography>
          ) : (
            <Typography variant="overline" color="textSecondary">
              No matching extracts
            </Typography>
          )}
        </div>
      )}
    </CardContent>
  );
}

export default withStyles(styles)(PackageCardContent);
