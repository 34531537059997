import React from 'react';
// import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import {
  MediaCardHeader,
  MediaCardThumbnail,
  MediaCardContent,
  MediaCardActions,
  MediaCardAvatar,
  MediaCardMenu,
} from '@vidispine/vdt-materialui';
import MediaCardFullWidthCard from './MediaCardFullWidthCard';
import MediaCardFullWidthExpand from './MediaCardFullWidthExpand';

/**
 * MediaCard displays information, thumbnail and action buttons
 */

const styles = (theme) => ({
  root: {
    display: 'grid',
    width: '100%',
    gridTemplateAreas:
      '"thumb header actions actions" "thumb content content expand" "expanded expanded expanded expanded"',
    gridTemplateColumns: 'fit-content(180px) 1fr auto min-content',
    padding: theme.spacing(1),
  },
  thumbnail: {
    gridArea: 'thumb',
  },
  header: {
    gridArea: 'header',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    gridArea: 'content',
  },
  actions: {
    gridArea: 'actions',
  },
  expandContainer: {
    gridArea: 'expand',
  },
  expandedContent: {
    gridArea: 'expanded',
  },
});

function MediaCardFullWidth({
  classes,
  onHover,
  onCheck,
  onClick,
  onExpand,
  expanded,
  checked: controlledCheck,
  title,
  subheader,
  image,
  content,
  expandedContent,
  avatar,
  CardComponent,
  CardProps,
  HeaderComponent,
  HeaderProps,
  ThumbnailComponent,
  ThumbnailProps,
  ContentComponent,
  ContentProps,
  ExpandedContentComponent,
  ExpandedContentProps,
  ExpandComponent,
  ActionsComponent,
  ActionsProps,
  AvatarComponent,
  AvatarProps,
  MenuComponent,
  MenuProps,
  ...innerProps
}) {
  const [uncontrolledChecked, setChecked] = React.useState(false);

  const handleExpand = () => {
    if (onExpand) onExpand(!expanded);
  };

  const checked = controlledCheck === undefined ? uncontrolledChecked : controlledCheck;
  const handleChecked = (event) => {
    if (controlledCheck === undefined) setChecked(!checked);
    if (onCheck) onCheck(event, !checked, innerProps);
  };
  const handleOnClick = onClick ? (event) => onClick(event, innerProps) : undefined;
  return (
    <CardComponent
      className={classes.root}
      checked={checked}
      innerProps={innerProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...CardProps}
    >
      {ThumbnailComponent && (
        <div className={classes.thumbnail}>
          <ThumbnailComponent
            image={image}
            innerProps={innerProps}
            onClick={handleOnClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...ThumbnailProps}
          />
        </div>
      )}
      {HeaderComponent && (
        <div className={classes.header}>
          <HeaderComponent
            title={title}
            subheader={subheader}
            avatar={avatar}
            handleChecked={handleChecked}
            checked={checked}
            innerProps={innerProps}
            AvatarComponent={AvatarComponent}
            AvatarProps={AvatarProps}
            MenuComponent={MenuComponent}
            MenuProps={MenuProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...HeaderProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...innerProps}
          />
        </div>
      )}
      {ActionsComponent && (
        <div className={classes.actions}>
          <ActionsComponent
            handleChecked={handleChecked}
            checked={checked}
            innerProps={innerProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...ActionsProps}
          />
        </div>
      )}
      {ContentComponent && (
        <div className={classes.content}>
          <ContentComponent
            content={content}
            innerProps={innerProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...ContentProps}
          >
            {content}
          </ContentComponent>
        </div>
      )}
      {ExpandComponent && (
        <div className={classes.expandContainer}>
          <ExpandComponent expanded={expanded} handleExpand={handleExpand} />
        </div>
      )}
      {ExpandedContentComponent && (
        <Collapse className={classes.expandedContent} in={expanded} timeout="auto" unmountOnExit>
          <ExpandedContentComponent
            content={expandedContent}
            innerProps={innerProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...ExpandedContentProps}
          />
        </Collapse>
      )}
    </CardComponent>
  );
}

// MediaCardFullWidth.propTypes = {
//   classes: PropTypes.objectOf(PropTypes.any),
//   onClick: PropTypes.func,
//   onHover: PropTypes.func,
//   onCheck: PropTypes.func,
//   checked: PropTypes.bool,
//   hover: PropTypes.bool,
//   title: PropTypes.string,
//   subheader: PropTypes.string,
//   image: PropTypes.string,
//   content: PropTypes.string,
//   avatar: PropTypes.string,
//   CardComponent: PropTypes.elementType,
//   HeaderComponent: PropTypes.elementType,
//   ThumbnailComponent: PropTypes.elementType,
//   ContentComponent: PropTypes.elementType,
//   ActionsComponent: PropTypes.elementType,
//   AvatarComponent: PropTypes.elementType,
//   MenuComponent: PropTypes.elementType,
//   CardProps: PropTypes.objectOf(PropTypes.any),
//   HeaderProps: PropTypes.objectOf(PropTypes.any),
//   ThumbnailProps: PropTypes.objectOf(PropTypes.any),
//   ContentProps: PropTypes.objectOf(PropTypes.any),
//   ActionsProps: PropTypes.objectOf(PropTypes.any),
//   AvatarProps: PropTypes.objectOf(PropTypes.any),
//   MenuProps: PropTypes.objectOf(PropTypes.any),
// };
MediaCardFullWidth.defaultProps = {
  classes: {},
  onClick: undefined,
  onHover: undefined,
  onCheck: undefined,
  checked: undefined,
  expanded: false,
  hover: undefined,
  title: undefined,
  subheader: undefined,
  image: undefined,
  content: undefined,
  expandedContent: undefined,
  avatar: undefined,
  CardComponent: MediaCardFullWidthCard,
  HeaderComponent: MediaCardHeader,
  ThumbnailComponent: MediaCardThumbnail,
  ContentComponent: MediaCardContent,
  ExpandComponent: MediaCardFullWidthExpand,
  ExpandedContentComponent: MediaCardContent,
  ActionsComponent: MediaCardActions,
  AvatarComponent: MediaCardAvatar,
  MenuComponent: MediaCardMenu,
  CardProps: {},
  HeaderProps: {},
  ThumbnailProps: {},
  ContentProps: {},
  ActionsProps: {},
  AvatarProps: {},
  MenuProps: {},
};

export default withStyles(styles, { name: 'VdtMediaCardFullWidth' })(MediaCardFullWidth);
