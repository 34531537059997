import React from 'react';
import { UserAvatarButton } from '@vidispine/vdt-materialui';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  MenuItem,
} from '@material-ui/core';
import { Info as AboutIcon } from '@material-ui/icons';
import AppTitle from './AppTitle';
import SectionHeader from './SectionHeader';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    height: '70px',
    // TODO: Fix so VdtUserAvatarButton don't set colors
    '& .MuiAvatar-root': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
  },
});

const AboutDialog = ({ children, open, handleClose }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography>
        <SectionHeader
          Icon={AboutIcon}
          title={process.env.REACT_APP_NAME}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const Header = ({ classes, userName, onLogout }) => {
  const [openAbout, setOpenAbout] = React.useState(false);
  return (
    <>
      <AppBar className={classes.root} position="relative" elevation={0}>
        <Box
          px={2}
          py={1}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <AppTitle logoColor="white" />
          </Grid>
          <Grid item>
            <UserAvatarButton
              userName={userName}
              onLogout={onLogout}
              locale={{ options: undefined }}
            >
              <MenuItem button onClick={() => setOpenAbout(true)}>
                About
              </MenuItem>
            </UserAvatarButton>
          </Grid>
        </Box>
      </AppBar>
      <AboutDialog open={openAbout} handleClose={() => setOpenAbout(false)}>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary={`Version ${process.env.REACT_APP_VERSION}`} />
          </ListItem>
        </List>
      </AboutDialog>
    </>
  );
};

export default withStyles(styles)(Header);
