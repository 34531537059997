import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles, emphasize } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import {
  ChevronLeft as ExpandIcon,
  KeyboardArrowDown as ToggleCommentIcon,
  Delete as DeleteIcon,
  Close as ClearIcon,
  Timer as TimeIcon,
  Comment as CommentIcon,
  Public as PublicIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { isEqual } from 'lodash';
import PlaylistExtractList from './PlaylistExtractList';
import PlaylistMenu from './PlaylistMenu';
import {
  useSearchPlaylists,
  usePlaylist,
  useDeletePlaylist,
  useRemovePlaylistExtracts,
  useTogglePublicPlaylist,
} from '../../hooks/playlist';
import { PlaylistContext } from '../../contexts/PlaylistContext';
import ExtractTcDialog from './ExtractTcDialog';
import { useSnackbar } from '../../contexts/SnackbarContext';
import useConfirmationDialog from '../../contexts/ConfirmationDialogContext';
import ExtractCommentDialog from './ExtractCommentDialog';
import { AuthContext } from '../../vdt/AuthProvider';

const styles = (theme) => ({
  root: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '48px',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    '& .MuiPaper-root': {
      '& .SearchInputWrapper': {
        '& form:focus-within': {
          '& .MuiButton-root': {
            borderColor: theme.palette.primary.main,
          },
          '& .MuiInputBase-root': {
            borderColor: theme.palette.primary.main,
          },
        },
        '& .MuiButton-root': {
          backgroundColor: theme.palette.background.paper,
          //   height: '44px',
          borderLeft: 'none',
        },
        '& .MuiInputBase-root': {
          fontSize: '1rem',
          backgroundColor: theme.palette.background.paper,
          height: '44px',
          borderRadius: 0,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          paddingLeft: theme.spacing(1),
        },
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '48px',
      height: 'calc(100% - 70px)',
      top: 'unset',
      bottom: '0',
    },
  },
  ownerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(3)}px`,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  commentContainer: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.05),
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: theme.spacing(2),
    borderRadius: 2,
    position: 'relative',
    '& .MuiIconButton-root': {
      visibility: 'hidden',
      position: 'absolute',
      bottom: 2,
      right: 2,
    },
    '&:hover': {
      '& .MuiIconButton-root': {
        visibility: 'visible',
      },
    },
  },
  commentText: {
    fontSize: '0.75rem',
    fontStyle: 'italic',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    whiteSpace: 'initial',
  },
  commentExpandButton: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  commentExpandButtonCollapsed: {
    transform: 'rotate(-180deg)',
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: '400px',
    '& .MuiPaper-root': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: '400px',
    },
  },
  drawerHeader: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    paddingLeft: 0,
  },
  bulkAppbar: {
    height: 'initial !important',
    marginBottom: theme.spacing(2),
  },
  bulkToolbar: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(min-content, 1fr) max-content',
    gridGap: theme.spacing(1),
  },
  bulkTitle: {
    overflow: 'hidden',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(auto, min-content) max-content',
    gridGap: theme.spacing(0.5),
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  sectionHeader: {
    flexGrow: 1,
  },
  scrollContainer: {
    maxHeight: 'calc(100vh - 166px)',
    overflow: 'auto',
  },
  toggleButton: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  toggleButtonExpanded: {
    transform: 'rotate(-180deg)',
  },
  drawerContent: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0.3s linear,opacity 0.3s linear',
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
  },
});

const PlaylistDrawer = ({ classes }) => {
  const [showCommentExpand, setShowCommentExpand] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [editExtractsTc, setEditExtractsTc] = React.useState(false);
  const [editExtractsComment, setEditExtractsComment] = React.useState(false);
  const { selectedPlaylist, setSelectedPlaylist } = React.useContext(PlaylistContext);
  const [selectedExtracts, setSelectedExtracts] = React.useState([]);
  const [truncateCommentRows, setTruncateCommentRows] = React.useState(true);
  const { userName: selfUserName } = React.useContext(AuthContext);
  const toggleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { mutateAsync: deletePlaylist } = useDeletePlaylist();
  const { mutateAsync: removePlaylistExtracts } = useRemovePlaylistExtracts();
  const { data: playlists = [] } = useSearchPlaylists();
  const { data: playlist = {} } = usePlaylist(selectedPlaylist ? selectedPlaylist.id : undefined);
  React.useEffect(() => {
    if (selectedPlaylist) {
      const spl = playlists.find((pl) => pl.id === selectedPlaylist.id);
      if (!isEqual(spl, selectedPlaylist)) {
        setSelectedPlaylist(spl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlists]);

  const commentRef = React.useCallback(
    (el) => {
      setShowCommentExpand(!!(el && el.scrollHeight && el.scrollHeight > 40));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playlist],
  );

  const invalidSelectedExtracts = selectedExtracts.some(
    (e) =>
      playlist.missingPackages.includes(e.packageId) ||
      playlist.unauthorizedPackages.includes(e.packageId),
  );

  const { showAlert } = useSnackbar();
  const { confirm: confirmDelete } = useConfirmationDialog({ throwOnReject: false });
  const handleDeletePlaylist = (pl) => {
    setSelectedPlaylist(null);
    return deletePlaylist(pl);
  };

  const { confirm: confirmTogglePublic } = useConfirmationDialog({
    throwOnReject: false,
  });

  const {
    mutate: togglePublicPlaylist,
    loading: isLoadingTogglePublic,
  } = useTogglePublicPlaylist();

  const handleTogglePublic = async () => {
    const ok = await confirmTogglePublic({
      titleText: 'Confirm',
      rejectText: 'Cancel',
      confirmText: selectedPlaylist?.isPublic ? 'Make private' : 'Make public',
      fullWidth: true,
      children: (
        <DialogContent>
          <DialogContentText>
            This action will make the playlist <b>{selectedPlaylist?.name}</b>
            {` ${selectedPlaylist?.isPublic ? 'private' : 'public'}.`}
          </DialogContentText>
        </DialogContent>
      ),
    });
    if (ok) {
      togglePublicPlaylist({
        playlistId: selectedPlaylist.id,
        publish: !selectedPlaylist?.isPublic,
      });
    }
  };

  const handleRemoveExtracts = async () => {
    if (
      await confirmDelete({
        titleText: 'Confirm',
        rejectText: 'Cancel',
        confirmText: 'Delete extracts',
        fullWidth: true,
        children: (
          <DialogContent>
            <DialogContentText>Permanently delete selected extracts?</DialogContentText>
          </DialogContent>
        ),
      })
    ) {
      await removePlaylistExtracts({
        playlistId: selectedPlaylist.id,
        extracts: selectedExtracts,
      })
        .then(() => {
          setSelectedExtracts([]);
          showAlert({ severity: 'success', message: 'Succesfully deleted selected extracts' });
        })
        .catch(() =>
          showAlert({
            severity: 'error',
            message: 'Could not delete selected extracts',
          }),
        );
    }
  };

  return (
    <>
      {editExtractsTc && (
        <ExtractTcDialog
          open={editExtractsTc}
          playlistId={playlist.id}
          extracts={selectedExtracts}
          onClose={() => setEditExtractsTc(false)}
          onSuccess={() => {
            showAlert({ severity: 'success', message: 'Timecodes updated' });
            setEditExtractsTc(false);
            setSelectedExtracts([]);
          }}
          onError={() => {
            showAlert({ severity: 'error', message: 'Failed to update timecodes' });
          }}
        />
      )}
      {editExtractsComment && (
        <ExtractCommentDialog
          open={editExtractsComment}
          playlistId={playlist.id}
          extracts={selectedExtracts}
          onClose={() => setEditExtractsComment(false)}
          onSuccess={() => {
            showAlert({ severity: 'success', message: 'Comments updated' });
            setEditExtractsComment(false);
            setSelectedExtracts([]);
          }}
          onError={() => {
            showAlert({ severity: 'error', message: 'Failed to update comments' });
          }}
        />
      )}
      <Drawer
        anchor="right"
        variant="permanent"
        className={clsx(classes.root, {
          [classes.drawerOpen]: drawerOpen,
        })}
      >
        {selectedExtracts.length > 0 ? (
          <AppBar className={classes.bulkAppbar} position="static">
            <Toolbar className={classes.bulkToolbar}>
              <div className={classes.bulkTitle}>
                <Typography>{selectedPlaylist.name}</Typography>
                <Typography>{` (${selectedExtracts.length})`}</Typography>
              </div>
              <div>
                <Tooltip
                  title={invalidSelectedExtracts ? 'Invalid extracts selected' : 'Edit comments'}
                >
                  <span>
                    <IconButton
                      disabled={invalidSelectedExtracts}
                      color="inherit"
                      onClick={() => setEditExtractsComment(true)}
                    >
                      <CommentIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={invalidSelectedExtracts ? 'Invalid extracts selected' : 'Edit timecodes'}
                >
                  <span>
                    <IconButton
                      disabled={invalidSelectedExtracts}
                      color="inherit"
                      onClick={() => setEditExtractsTc(true)}
                    >
                      <TimeIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Delete extracts">
                  <IconButton color="inherit" onClick={handleRemoveExtracts}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Clear selection">
                  <IconButton color="inherit" onClick={() => setSelectedExtracts([])}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          <Box className={classes.drawerHeader}>
            <IconButton
              className={clsx(classes.toggleButton, {
                [classes.toggleButtonExpanded]: drawerOpen,
              })}
              onClick={toggleDrawerOpen}
            >
              <ExpandIcon />
            </IconButton>
            <Autocomplete
              onChange={(_, val) => {
                setSelectedPlaylist(val);
              }}
              value={selectedPlaylist}
              options={playlists}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              ChipProps={{
                size: 'small',
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  size="small"
                  label="Playlist"
                />
              )}
            />
            <PlaylistMenu
              playlist={playlist}
              selectedPlaylist={selectedPlaylist}
              invalidPlaylist={
                playlist.missingPackages?.length > 0 || playlist.unauthorizedPackages?.length > 0
              }
              onDeletePlaylist={handleDeletePlaylist}
            />
          </Box>
        )}
        <Fade in={drawerOpen}>
          {selectedPlaylist !== null ? (
            <div className={classes.scrollContainer}>
              <div className={classes.ownerContainer}>
                <div>
                  <PersonIcon
                    fontSize="small"
                    style={{ marginRight: '2px', position: 'relative', top: '-2px' }}
                  />
                  <Typography variant="caption">{selectedPlaylist.owner}</Typography>
                </div>
                {selectedPlaylist && selectedPlaylist.owner === selfUserName && (
                  <Tooltip
                    title={
                      selectedPlaylist?.isPublic
                        ? 'The playlist is public'
                        : 'The playlist is private'
                    }
                  >
                    <span>
                      <IconButton
                        onClick={handleTogglePublic}
                        disabled={isLoadingTogglePublic}
                        color={selectedPlaylist?.isPublic ? 'primary' : undefined}
                      >
                        <PublicIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className={classes.commentContainer}>
                <Typography
                  ref={commentRef}
                  className={classes.commentText}
                  variant="body2"
                  color="textSecondary"
                  style={{
                    WebkitLineClamp: truncateCommentRows === false ? 'unset' : 2,
                  }}
                >
                  {playlist.comment || 'No comment'}
                </Typography>
                {showCommentExpand && (
                  <IconButton
                    size="small"
                    className={clsx(classes.toggleButton, {
                      [classes.toggleButtonExpanded]: !truncateCommentRows,
                    })}
                    onClick={() => setTruncateCommentRows((prev) => !prev)}
                  >
                    <ToggleCommentIcon />
                  </IconButton>
                )}
              </div>
              <PlaylistExtractList
                playlist={playlist}
                selectedExtracts={selectedExtracts}
                setSelectedExtracts={setSelectedExtracts}
              />
            </div>
          ) : (
            <Box p={3}>
              <Typography variant="overline" color="textSecondary">
                No selected playlist
              </Typography>
            </Box>
          )}
        </Fade>
      </Drawer>
    </>
  );
};

export default withStyles(styles)(PlaylistDrawer);
