import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemSecondaryAction,
  withStyles,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Person as PersonIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { permissionGeq } from 'mediadb-lib';
import { usePkgAccess, useAddPkgEditor, useRemovePkgEditor } from '../../../hooks/pkg';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import SectionHeader from '../../../components/SectionHeader';
import UserAutocomplete from '../../../components/UserAutocomplete';
import { AuthContext } from '../../../vdt/AuthProvider';

const styles = (theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
});

function AddPackageEditorDialog({ open, onClose, onSubmit, readers = [] }) {
  const [username, setUsername] = React.useState('');
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={PersonIcon}
          title="Add package editor"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>
        {open && (
          <UserAutocomplete
            label="User"
            onChange={setUsername}
            options={readers.map((r) => r.username)}
            getOptionLabel={(o) => o}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSubmit(username)}
          disabled={!username}
          variant="contained"
          disableElevation
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PackageAccess({ packageId, classes, userPermission }) {
  const { userName: selfUserName } = React.useContext(AuthContext);
  const { data: { accessList = [] } = {} } = usePkgAccess({ packageId });
  const { showAlert } = useSnackbar();
  const { mutate: addPkgEditor } = useAddPkgEditor({
    onError: () => showAlert({ severity: 'error', message: 'Failed to add package editor' }),
  });
  const { mutate: removePkgEditor } = useRemovePkgEditor({
    onError: () => showAlert({ severity: 'error', message: 'Failed to remove package editor' }),
  });

  const [showAddEditorDialog, setShowAddEditorDialog] = React.useState(false);

  const handleCloseAddPackageEditor = () => setShowAddEditorDialog(false);
  const handleAddPackageEditor = (username) => {
    addPkgEditor({ packageId, username });
    handleCloseAddPackageEditor();
  };

  const editors = accessList.filter(({ permission }) => permission === 'WRITE');
  const readers = accessList.filter(({ permission }) => permission === 'READ');
  return (
    <>
      <Box>
        <Box display="flex">
          {permissionGeq(userPermission, 'WRITE') && (
            <Button
              onClick={() => setShowAddEditorDialog(true)}
              startIcon={<PersonIcon />}
              color="primary"
              style={{ marginLeft: 'auto' }}
            >
              Add editor
            </Button>
          )}
        </Box>
        <List style={{ padding: 0 }}>
          <ListSubheader>Editors</ListSubheader>
          {editors.map(({ username }) => (
            <ListItem key={username} dense className={classes.listItem}>
              <ListItemIcon>
                <PersonIcon color={username === selfUserName ? 'primary' : undefined} />
              </ListItemIcon>
              <ListItemText>{username}</ListItemText>
              <ListItemSecondaryAction>
                {permissionGeq(userPermission, 'WRITE') && username !== selfUserName && (
                  <Tooltip title="Remove editor" placement="left">
                    <IconButton
                      onClick={() => removePkgEditor({ packageId, username })}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
      {showAddEditorDialog && (
        <AddPackageEditorDialog
          editors={editors}
          readers={readers}
          onSubmit={handleAddPackageEditor}
          open={showAddEditorDialog}
          onClose={handleCloseAddPackageEditor}
        />
      )}
    </>
  );
}

export default withStyles(styles)(PackageAccess);
