import { sortBy } from 'lodash';
import React from 'react';
import {
  DateRangeField,
  TimeRangeField,
  RangeSliderField,
  TagField,
  TextField,
} from '../containers/Search/components/InputFields';
import { AuthContext } from '../vdt/AuthProvider';
import { useCustomMetadataGroups } from './metadata';

const GENERAL_FILTER_FIELDS = () =>
  sortBy(
    [
      // HIDDEN UNTIL VIDICORE >= 22.2.1
      // only include for non-admins
      // ...(!isAdmin
      //   ? [
      //       {
      //         label: 'Write access',
      //         field: 'access',
      //         type: 'value',
      //         component: SwitchField,
      //         toFilterValue: (acl) => !!acl,
      //         fromFilterValue: (v) => (v ? { permission: 'WRITE', username } : undefined),
      //         componentProps: {},
      //       },
      //     ]
      //   : []),
      {
        field: 'packageGroupName',
        label: 'Package group',
        type: 'value',
        useFacet: true,
        component: TagField,
        componentProps: {
          multiple: true,
        },
      },
      {
        field: 'packageName',
        label: 'Package name',
        type: 'value',
        component: TagField,
        componentProps: {
          freeSolo: true,
          multiple: true,
        },
      },
      {
        field: 'packageLocation',
        label: 'Package location',
        type: 'value',
        component: TagField,
        useFacet: true,
        componentProps: {
          multiple: true,
          freeSolo: true,
        },
      },
      {
        field: 'created',
        label: 'Uploaded',
        type: 'range',
        component: DateRangeField,
      },
      {
        field: 'lastModified',
        label: 'Last modified',
        type: 'range',
        component: DateRangeField,
      },
      {
        field: 'packageDuration',
        label: 'Duration (minutes)',
        type: 'range',
        component: RangeSliderField,
        componentProps: {
          min: 0,
          max: 7200,
          step: 600,
          infSteps: true,
          marks: [
            { value: 1800, label: 30 },
            { value: 3600, label: 60 },
            { value: 5400, label: 90 },
          ],
          valueLabelFormat: (val) => val / 60,
        },
      },
      {
        field: 'packageStatus',
        label: 'Package status',
        type: 'value',
        useFacet: true,
        component: TagField,
        componentProps: {
          multiple: true,
        },
      },
      {
        field: 'deviceName',
        label: 'Device name',
        type: 'value',
        useFacet: true,
        component: TagField,
        componentProps: {
          multiple: true,
        },
      },
      {
        field: 'interlocutorName',
        label: 'Person',
        type: 'value',
        useFacet: true,
        component: TagField,
        componentProps: {
          multiple: true,
        },
      },
    ],
    'label',
  );

const getFilterFieldsFromCustomFields = (fields) => {
  const getComponentFromMediaDbType = (mediaDBType) => {
    if (mediaDBType === 'String') {
      return {
        component: TagField,
        type: 'value',
        useFacet: true,
        componentProps: { multiple: true, freeSolo: true },
      };
    }
    if (mediaDBType === 'Number') {
      return {
        component: RangeSliderField,
        type: 'range',
        useFacet: true,
        componentProps: {
          infSteps: true,
          infStepShowValue: true,
        },
      };
    }
    if (mediaDBType === 'Date') {
      return { component: DateRangeField, type: 'range' };
    }
    if (mediaDBType === 'Enum') {
      return {
        component: TagField,
        type: 'value',
        useFacet: true,
        componentProps: { multiple: true },
      };
    }
    if (mediaDBType === 'Time') {
      return {
        component: TimeRangeField,
        type: 'range',
      };
    }
    return { component: TextField, type: 'value' };
  };

  return fields
    .filter((field) => field.isActiveInBrowse)
    .map(({ fieldName, label, mediaDBType } = {}) => ({
      field: fieldName,
      label: label || fieldName,
      ...getComponentFromMediaDbType(mediaDBType, label || fieldName),
    }));
};

const useFilterFields = () => {
  const { userName: username, isAdmin } = React.useContext(AuthContext);
  const { fields: customFields, isLoading } = useCustomMetadataGroups();
  const customFilterFields = React.useMemo(() => getFilterFieldsFromCustomFields(customFields), [
    customFields,
  ]);
  return {
    filterFields: [...GENERAL_FILTER_FIELDS({ username, isAdmin }), ...customFilterFields],
    isLoading,
  };
};

export default useFilterFields;
