/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  ArrowRightAlt as ArrowRightAltIcon,
  ArrowForwardIos as ArrowRightIcon,
} from '@material-ui/icons';
import { lighten, darken } from '@material-ui/core/styles';
import clsx from 'clsx';
import { getDateTimeString } from '../utils/utils';

const styles = (theme) => {
  const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
  return {
    root: {
      width: '100%',
      wordWrap: 'break-word',
    },
    head: {
      '& .MuiTableCell-head:nth-child(1)': {
        Width: 110,
        minWidth: 110,
      },
      '& .MuiTableCell-head:nth-child(2)': {
        minWidth: 190,
      },
      '& .MuiTableCell-head:nth-child(3)': {
        width: '100%',
      },
    },
    row: {
      '&:hover .VdtMetadataChangeLogTable-restoreButton': {
        visibility: 'visible',
      },
    },
    rowCellRestore: {
      position: 'sticky',
      top: -20,
    },
    changeTimespan: {
      textAlign: 'right',
      fontStyle: 'italic',
      fontSize: 12,
    },
    restoreButton: {
      minWidth: 40,
      visibility: 'hidden',
    },
    group: {
      paddingTop: 4,
    },
    groupLabel: {
      display: 'flex',
      fontSize: 12,
      padding: '0 2px',

      '& > span': {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          fontSize: 10,
          opacity: 0.6,
        },
      },
    },
    field: {
      display: 'flex',
      padding: '0 2px',
    },
    fieldAdded: {
      backgroundColor: getBackgroundColor(theme.palette.success.main, 0.85),
      '& .VdtMetadataChangeLogTable-fieldValueSeparator': {
        display: 'none',
      },
    },
    fieldChanged: {
      backgroundColor: getBackgroundColor(theme.palette.info.main, 0.85),
    },
    fieldDeleted: {
      backgroundColor: getBackgroundColor(theme.palette.error.main, 0.85),
      '& .VdtMetadataChangeLogTable-fieldPreviousValue': {
        textDecoration: 'line-through',
      },
      '& .VdtMetadataChangeLogTable-fieldValueSeparator': {
        display: 'none',
      },
    },
    fieldUnchanged: {
      '& .VdtMetadataChangeLogTable-fieldPreviousValue': {
        display: 'none',
      },
      '& .VdtMetadataChangeLogTable-fieldValueSeparator': {
        display: 'none',
      },
    },
    fieldName: {
      fontWeight: 'bold',
      paddingRight: 4,
    },
    fieldPreviousValue: {},
    fieldValueSeparator: {
      fontWeight: 'bold',
      paddingRight: 2,
      paddingLeft: 2,
    },
    fieldValue: {},
  };
};

const FieldLabel = ({ classes = {}, fieldName }) => (
  <Typography className={clsx(['VdtMetadataChangeLogTable-fieldName', classes.fieldName])}>
    {`${fieldName}:`}
  </Typography>
);

const FieldValue = ({ classes = {}, previousValue, value, reference, previousReference }) => (
  <>
    <Typography
      className={clsx(['VdtMetadataChangeLogTable-fieldPreviousValue', classes.fieldPreviousValue])}
    >
      {previousValue || <i>{previousReference}</i>}
    </Typography>
    <ArrowRightAltIcon
      className={clsx([
        'VdtMetadataChangeLogTable-fieldValueSeparator',
        classes.fieldValueSeparator,
      ])}
    />
    <Typography className={clsx(['VdtMetadataChangeLogTable-fieldValue', classes.fieldValue])}>
      {value || <i>{reference}</i>}
    </Typography>
  </>
);

const GroupLabel = ({ classes = {}, groupNames }) => (
  <Typography className={clsx(['VdtMetadataChangeLogTable-groupLabel', classes.groupLabel])}>
    {groupNames.map((n) => (
      <span key={n}>
        {`${n} `}
        <ArrowRightIcon />
      </span>
    ))}
  </Typography>
);

const isEmpty = (v) => [undefined, null, ''].includes(v);

const MetadataChangeLogTable = ({
  className,
  classes,
  changeLog = [],
  FieldComponent = Box,
  GroupComponent = Box,
  FieldLabelComponent = FieldLabel,
  FieldValueComponent = FieldValue,
  GroupLabelComponent = GroupLabel,
  FieldProps = {},
  GroupProps = {},
}) => {
  const toFieldComponent = ({
    field: { name, value: valueOrArray, reference } = {},
    fieldBeforeChange: { value: prevValueOrArray, previousReference } = {},
  }) => {
    const value = Array.isArray(valueOrArray)
      ? valueOrArray.map((v) => v.value).join(', ')
      : valueOrArray;
    const prevValue = Array.isArray(prevValueOrArray)
      ? prevValueOrArray[0]?.value
      : prevValueOrArray;
    const valueElseReference = value || reference;
    const prevValueElseReference = prevValue || previousReference;
    const isAdded = isEmpty(prevValueElseReference) && !isEmpty(valueElseReference);
    const isDeleted = prevValueElseReference && isEmpty(valueElseReference);
    const isChanged =
      !isAdded && !isEmpty(prevValueElseReference) && prevValueElseReference !== valueElseReference;
    const isUnchanged = !isAdded && !isDeleted && !isChanged;

    return (
      <FieldComponent
        className={clsx([
          'VdtMetadataChangeLogTable-field',
          classes.field,
          isAdded && 'VdtMetadataChangeLogTable-fieldAdded',
          isAdded && classes.fieldAdded,
          isUnchanged && 'VdtMetadataChangeLogTable-fieldUnchanged',
          isUnchanged && classes.fieldUnchanged,
          isDeleted && 'VdtMetadataChangeLogTable-fieldDeleted',
          isDeleted && classes.fieldDeleted,
          isChanged && 'VdtMetadataChangeLogTable-fieldChanged',
          isChanged && classes.fieldChanged,
        ])}
        {...FieldProps}
      >
        <FieldLabelComponent classes={classes} fieldName={name} />
        <FieldValueComponent
          classes={classes}
          previousValue={prevValue}
          value={value}
          previousReference={previousReference}
          reference={reference}
        />
      </FieldComponent>
    );
  };

  const toGroupComponent = ({
    parentKey,
    group: {
      name,
      groupNames: parentGroupNames = [],
      group: childGroups = [],
      field: childFields = [],
    },
    groupBeforeChange: { field: fieldsBeforeChange = [], group: groupsBeforeChange = [] } = {},
  }) => {
    const getFieldBeforeChange = (fieldName) =>
      fieldsBeforeChange.find((fbc) => fbc.name === fieldName);
    const getChildGroupBeforeChange = (groupName) =>
      groupsBeforeChange.find((fbc) => fbc.name === groupName);
    const groupNames = JSON.parse(JSON.stringify(parentGroupNames));
    groupNames.push(name);
    return (
      <>
        {(childGroups.length > 0 || childFields.length > 0) && (
          <GroupComponent
            className={clsx(['VdtMetadataChangeLogTable-group', classes.group])}
            {...GroupProps}
          >
            {childFields.length > 0 && (
              <GroupLabelComponent classes={classes} groupNames={groupNames} />
            )}
            {childFields.map((field) => (
              <React.Fragment key={`${parentKey}-${field.uuid}`}>
                {toFieldComponent({
                  field,
                  fieldBeforeChange: getFieldBeforeChange(field.name),
                })}
              </React.Fragment>
            ))}
            {childGroups.map((group) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${parentKey}-${group.uuid}`}>
                {toGroupComponent({
                  parentKey: `${parentKey}-${group.uuid}`,
                  group: {
                    ...group,
                    groupNames,
                  },
                  groupBeforeChange: getChildGroupBeforeChange(group.name),
                })}
              </React.Fragment>
            ))}
          </GroupComponent>
        )}
      </>
    );
  };

  const ChangeComponent = ({
    key,
    fields = [],
    groups = [],
    fieldsBeforeChange = [],
    groupsBeforeChange = [],
  }) => (
    <Box>
      {fields.map((field) => (
        <React.Fragment key={`${key}-${field.uuid}`}>
          {toFieldComponent({
            field,
            fieldBeforeChange: fieldsBeforeChange.find((fbc) => fbc.name === field.name),
          })}
        </React.Fragment>
      ))}
      {groups.map((group) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${key}-${group.uuid}`}>
          {toGroupComponent({
            parentKey: `${key}-${group.uuid}`,
            group,
            groupBeforeChange: groupsBeforeChange.find((gbc) => gbc.name === group.name),
          })}
        </React.Fragment>
      ))}
    </Box>
  );

  return (
    <Table className={clsx([className, 'VdtMetadataChangeLogTable-root', classes.root])}>
      <TableHead className={clsx(['VdtMetadataChangeLogTable-head', classes.head])}>
        <TableRow>
          <TableCell>User</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Change</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={clsx(['VdtMetadataChangeLogTable-body', classes.body])}>
        {changeLog.map((change) => {
          const {
            id,
            user,
            timestamp,
            field: fields = [],
            group: groups = [],
            start,
            end,
            beforeChange: { field: fieldsBeforeChange, group: groupsBeforeChange } = {},
          } = change;
          const key = `${id}-${start}-${end}`;
          return (
            <TableRow
              key={key}
              className={clsx(['VdtMetadataChangeLogTable-row', classes.row])}
              style={{ verticalAlign: 'top' }}
            >
              <TableCell
                className={clsx(['VdtMetadataChangeLogTable-rowCellUser', classes.rowCellUser])}
              >
                {user}
              </TableCell>
              <TableCell
                className={clsx(['VdtMetadataChangeLogTable-rowCellDate', classes.rowCellDate])}
              >
                {getDateTimeString(timestamp)}
              </TableCell>
              <TableCell
                className={clsx(['VdtMetadataChangeLogTable-rowCellChange', classes.rowCellChange])}
              >
                {ChangeComponent({
                  key,
                  fields,
                  groups,
                  fieldsBeforeChange,
                  groupsBeforeChange,
                })}
                <Typography
                  className={clsx([
                    'VdtMetadataChangeLogTable-changeTimespan',
                    classes.changeTimespan,
                  ])}
                >
                  {(start !== '-INF' || end !== '+INF') && `${start} - ${end}`}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles, { name: 'VdtMetadataChangeLogTable' })(MetadataChangeLogTable);
