import React from 'react';
import {
  IconButton,
  Tooltip,
  Typography,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box,
  Button,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Add as AddIcon } from '@material-ui/icons';
import { findNearestThumbnail, formatTimeCodeText } from '@vidispine/vdt-js';
import Avatar from '@material-ui/core/Avatar';
import { ContentCut as ExtractIcon } from 'mdi-material-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { PlaylistContext } from '../../../contexts/PlaylistContext';
import { usePackageLabels } from '../../../hooks/search';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useAddPlaylistExtracts } from '../../../hooks/playlist';
import Timeline from './Timeline';

const styles = () => ({
  root: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      padding: 8,
    },
  },
});

const displayTimeCode = (timeCode) => {
  const { hours, minutes, seconds, partialSeconds } = timeCode.toTime();
  const zeroPad = (n) => `${n}`.padStart(2, '0');
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}${
    partialSeconds ? `.${(partialSeconds * 1000) / 100}` : ''
  }`;
};

const TimelineRow = withStyles(() => ({
  row: {
    '& > td': {},
  },
}))(({ start, end, duration, primary = null, actions = null, classes, className }) => {
  const durationSeconds = parseInt(duration, 10);
  const durationTimeCode = formatTimeCodeText(durationSeconds).conformTimeBase({
    denominator: 1000000,
  });
  const startTimeCode = formatTimeCodeText(start).conformTimeBase({ denominator: 1000000 });
  const endTimeCode = formatTimeCodeText(end).conformTimeBase({ denominator: 1000000 });
  const extractDurationTimeCode = endTimeCode.subtract(startTimeCode);
  const coverage =
    Math.round(10000 * (extractDurationTimeCode.toSeconds() / durationTimeCode.toSeconds())) / 100;
  return (
    <>
      <TableRow className={clsx(className, classes.row, 'TimelineRow-data TimelineRow')}>
        <TableCell rowSpan={2} className="TimelineCell-primary">
          {primary}
        </TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          {displayTimeCode(startTimeCode)} - {displayTimeCode(endTimeCode)}
        </TableCell>
        <TableCell>{displayTimeCode(extractDurationTimeCode)}</TableCell>
        <TableCell>{coverage === 0 ? '< 0.01' : coverage}%</TableCell>
        {actions && (
          <TableCell rowSpan={2} className="TimelineRow-actions">
            {actions}
          </TableCell>
        )}
      </TableRow>
      <TableRow className={clsx(className, classes.row, 'TimelineRow-timeline TimelineRow')}>
        <TableCell colSpan={3}>
          <Timeline
            extracts={[{ start: startTimeCode.toSeconds(), end: endTimeCode.toSeconds() }]}
            duration={durationSeconds}
          />
        </TableCell>
      </TableRow>
    </>
  );
});

function FullWidthRow({ children }) {
  return (
    <TableRow>
      <TableCell colSpan={4}>{children}</TableCell>
    </TableRow>
  );
}

function useLimitRows({ step = 10, rows }) {
  const [visibleRowCount, setVisibleRowCount] = React.useState(step);

  return {
    rows: rows.slice(0, visibleRowCount),
    showMore: () => setVisibleRowCount((prev) => prev + step),
    hasMore: rows.length > visibleRowCount,
    hiddenRowCount: rows.length - visibleRowCount,
  };
}

const ALTERNATIVE_LABEL_BACKGROUND_COLOR = '#eeeeee73';

const LabelRows = withStyles((theme) => ({
  row: {
    '&:nth-child(4n - 1)': {
      background: ALTERNATIVE_LABEL_BACKGROUND_COLOR,
    },
    '&:nth-child(4n)': {
      background: ALTERNATIVE_LABEL_BACKGROUND_COLOR,
    },
    '&:hover': {
      '& .TimelineRow-actions > *': {
        visibility: 'visible',
      },
    },
    '& .MuiTableCell-root': {
      fontSize: '12px',
      '&.TimelineCell-primary': {
        borderLeft: `3px solid ${theme.palette.primary.light}`,
        paddingLeft: '16px',
        '& .LabelRows-labelDescription-indented': {
          display: 'flex',
          '&::before': {
            content: '"┕"',
            marginRight: '4px',
            color: theme.palette.primary.main,
          },
        },
      },
      '&.TimelineRow-actions > *': {
        visibility: 'hidden',
      },
      '& .Timeline-extract': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
}))(({ packageId, start, end, packageDuration, classes, showAlert }) => {
  const { data: labels = [], status } = usePackageLabels({ packageId, start, end });
  const { rows, showMore, hasMore, hiddenRowCount } = useLimitRows({ rows: labels });
  const { mutate: addPlaylistExtracts } = useAddPlaylistExtracts();
  const { selectedPlaylist } = React.useContext(PlaylistContext);

  const noLabels = status === 'success' && labels.length === 0;

  const handleAddExtract = (inTCText, outTCText) => {
    if (!selectedPlaylist) {
      showAlert({ severity: 'warning', message: 'Select a playlist to add the extract to' });
      return;
    }
    addPlaylistExtracts({
      playlistId: selectedPlaylist.id,
      extracts: [{ packageId, inTCText, outTCText }],
    });
  };

  return (
    <>
      {(status === 'loading' || noLabels) && (
        <FullWidthRow>
          <Box display="flex" justifyContent="center">
            {noLabels ? (
              <Typography style={{ fontStyle: 'italic' }}>No labels found</Typography>
            ) : (
              <CircularProgress size={20} />
            )}
          </Box>
        </FullWidthRow>
      )}
      {rows.map(
        ({
          start: labelStart,
          end: labelEnd,
          labelDescription,
          labelTrack,
          labelTrackGroupName,
          labelType,
          labelId,
        }) => {
          return (
            <TimelineRow
              key={labelId}
              className={classes.row}
              start={labelStart}
              end={labelEnd}
              duration={packageDuration}
              primary={
                <Tooltip title={labelDescription || ''} arrow>
                  <div style={{ width: 'fit-content', wordBreak: 'break-all' }}>
                    <Typography variant="caption">{labelTrackGroupName}</Typography>
                    <Typography
                      variant="caption"
                      style={{ marginLeft: 0 }}
                      className="LabelRows-labelDescription-indented"
                    >
                      {labelTrack}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ marginLeft: 8 }}
                      className="LabelRows-labelDescription-indented"
                    >
                      {labelType}
                    </Typography>
                  </div>
                </Tooltip>
              }
              actions={
                <Tooltip title="Add extract to selected playlist">
                  <IconButton onClick={() => handleAddExtract(labelStart, labelEnd)} size="small">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              }
            />
          );
        },
      )}
      {hasMore && (
        <FullWidthRow>
          <Box display="flex" justifyContent="center">
            <Button onClick={showMore} color="primary" size="small">
              More labels ({hiddenRowCount})
            </Button>
          </Box>
        </FullWidthRow>
      )}
    </>
  );
});

const ExtractRow = withStyles(() => ({
  extractExpanded: {
    transform: 'rotate(180deg)',
  },
  extractExpand: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  row: {
    '& .MuiTableCell-root': {
      fontWeight: 500,
    },
  },
}))(
  ({
    classes,
    extract,
    showAlert,
    packageId,
    thumbnailItem,
    packageDuration: packageDurationStr,
  }) => {
    const [expanded, setExpanded] = React.useState(false);
    const { selectedPlaylist } = React.useContext(PlaylistContext);
    const { mutate: addPlaylistExtracts } = useAddPlaylistExtracts();

    const { start, end } = extract;

    const packageDuration = parseInt(packageDurationStr, 10);

    const thumbnail = thumbnailItem.thumbnails ? findNearestThumbnail(thumbnailItem, start) : null;

    const handleAddExtract = () => {
      if (!selectedPlaylist) {
        showAlert({ severity: 'warning', message: 'Select a playlist to add the extract to' });
        return;
      }
      addPlaylistExtracts({
        playlistId: selectedPlaylist.id,
        extracts: [{ packageId, inTCText: start, outTCText: end }],
      });
    };

    return (
      <>
        <TimelineRow
          className={classes.row}
          start={start}
          end={end}
          duration={packageDuration}
          primary={
            <Avatar
              src={thumbnail}
              variant="square"
              style={{ width: '140px', height: '64px', transform: 'translateX(-9px)' }}
            >
              <ExtractIcon />
            </Avatar>
          }
          actions={
            <>
              <Tooltip title="Add extract to selected playlist">
                <IconButton onClick={handleAddExtract} size="small">
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show labels within the extract">
                <IconButton
                  onClick={() => setExpanded((prev) => !prev)}
                  size="small"
                  className={clsx(
                    {
                      [classes.extractExpanded]: expanded,
                    },
                    classes.extractExpand,
                  )}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        />
        {expanded && (
          <LabelRows
            showAlert={showAlert}
            packageDuration={packageDuration}
            packageId={packageId}
            start={start}
            end={end}
          />
        )}
      </>
    );
  },
);

const ColumnComponent = withStyles(() => ({
  columnTitle: {
    cursor: 'pointer',
    userSelect: 'none',
    float: 'left',
  },
  sortedBy: {
    transform: 'rotate(180deg)',
  },
  sortBy: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}))(({ classes, onSortClick, sortState, name, label }) => {
  return (
    <TableCell onClick={() => onSortClick(name)}>
      <Typography className={classes.columnTitle} variant="body2" color="textSecondary">
        {label}
      </Typography>
      <ExpandMoreIcon
        style={{
          float: 'left',
          visibility: sortState.column === name ? 'visible' : 'hidden',
        }}
        size="small"
        className={clsx({ [classes.sortedBy]: !sortState.ascending }, classes.sortBy)}
      />
    </TableCell>
  );
});

function PackageCardExpandedContent({
  classes,
  innerProps: { package: pkg = {}, onSortClick, sortState } = {},
  thumbnailItem,
  extracts,
}) {
  const { showAlert } = useSnackbar();
  const { duration, packageId } = pkg;
  const { rows, showMore, hasMore, hiddenRowCount } = useLimitRows({ rows: extracts });

  return (
    <>
      <CardContent className={classes.root}>
        {!extracts || !extracts.length ? (
          <Typography style={{ textAlign: 'center', marginTop: '8px' }}>
            No extracts found
          </Typography>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="164px" />
                <ColumnComponent
                  onSortClick={onSortClick}
                  sortState={sortState}
                  label="Timespan"
                  name="timespan"
                />
                <ColumnComponent
                  onSortClick={onSortClick}
                  sortState={sortState}
                  label="Duration"
                  name="duration"
                />
                <ColumnComponent
                  onSortClick={onSortClick}
                  sortState={sortState}
                  label="Coverage"
                  name="coverage"
                />
                <TableCell width="80px" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((extract) => (
                <ExtractRow
                  key={`${extract.start}-${extract.end}`}
                  thumbnailItem={thumbnailItem}
                  extract={extract}
                  packageDuration={duration}
                  showAlert={showAlert}
                  packageId={packageId}
                />
              ))}
              {hasMore && (
                <FullWidthRow>
                  <Box display="flex" justifyContent="center">
                    <Button onClick={showMore} color="primary">
                      More extracts ({hiddenRowCount})
                    </Button>
                  </Box>
                </FullWidthRow>
              )}
            </TableBody>
          </Table>
        )}
      </CardContent>
      <div />
    </>
  );
}

export default withStyles(styles)(PackageCardExpandedContent);
