import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { pkg } from 'mediadb-lib';

const { PACKAGE_STATUSES } = pkg;

const styles = (theme) => ({
  root: {
    fontWeight: 600,
    textTransform: 'uppercase',
    '&.progress1': {
      color: theme.palette.primary.light,
    },
    '&.progress2': {
      color: theme.palette.primary.main,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '&.warning': {
      color: theme.palette.warning.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
});

const StatusText = ({ statusText, classes }) => {
  return (
    <div
      className={clsx(
        classes.root,
        { success: statusText === PACKAGE_STATUSES.APPROVED },
        {
          error: [
            PACKAGE_STATUSES.CANCELLED,
            PACKAGE_STATUSES.ERROR,
            PACKAGE_STATUSES.REJECTED,
          ].includes(statusText),
        },
        { progress1: statusText === PACKAGE_STATUSES.IN_LABELING },
        { progress2: statusText === PACKAGE_STATUSES.LABELED },
      )}
    >
      {statusText}
    </div>
  );
};

export default withStyles(styles)(StatusText);
