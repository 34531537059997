import React from 'react';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '61px',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    '& .MuiPaper-root': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '61px',
      height: 'calc(100% - 70px)',
      top: 'unset',
      bottom: '0',
    },
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: '220px',
    '& .MuiPaper-root': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: '220px',
    },
  },
  toggleButtonExpanded: {
    justifyContent: 'flex-end',
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
    },
  },
  navButton: {
    '&.selected': {
      color: theme.palette.primary.main,
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  navGroup: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
  subNavButton: {
    paddingLeft: theme.spacing(3),
    '&:hover, &.selected': {
      '& span': {
        fontWeight: '600',
      },
    },
  },
  navButtonText: {
    textTransform: 'capitalize',
  },
  subNavButtonText: {
    fontSize: '0.1rem',
  },
});

function NavItem({ classes, title, icon, path, drawerOpen }) {
  return (
    <ListItem
      button
      className={classes.navButton}
      activeClassName="selected"
      component={NavLink}
      disableRipple
      to={path}
    >
      <Tooltip title={!drawerOpen ? title : ''}>
        <ListItemIcon>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText
        primary={title}
        className={classes.navButtonText}
        primaryTypographyProps={{
          variant: 'subtitle2',
        }}
      />
    </ListItem>
  );
}

function NavGroup({ classes, title, expanded, items, drawerOpen, onClick }) {
  return (
    <>
      <ListItem
        button
        className={clsx(classes.navButton, classes.navGroup)}
        disableRipple
        onClick={onClick}
      >
        <ListItemText
          primary={title}
          className={clsx(classes.navButtonText, { [classes.hidden]: !drawerOpen })}
          primaryTypographyProps={{
            variant: 'subtitle2',
          }}
        />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {items.map((item) =>
            item.type === 'item' ? (
              <NavItem
                key={item.title}
                classes={classes}
                title={item.title}
                icon={item.icon}
                path={item.path}
                drawerOpen={drawerOpen}
              />
            ) : (
              <NavGroup
                key={item.title}
                classes={classes}
                title={item.title}
                expanded={item.expanded}
                items={item.items}
                drawerOpen={drawerOpen}
              />
            ),
          )}
        </List>
      </Collapse>
    </>
  );
}

const NavigationDrawer = ({ classes, navItems, drawerProps, onGroupExpandToggle }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };
  const toggleExpanded = (group) => {
    const newGroup = { ...group, expanded: !group.expanded };
    onGroupExpandToggle(newGroup);
  };
  return (
    <Drawer
      className={clsx(classes.root, {
        [classes.drawerOpen]: drawerOpen,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...drawerProps}
    >
      <List disablePadding>
        <ListItem
          button
          className={clsx(classes.toggleButton, {
            [classes.toggleButtonExpanded]: drawerOpen,
          })}
          onClick={toggleDrawerOpen}
          disableRipple
        >
          <ListItemIcon>{drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}</ListItemIcon>
        </ListItem>
      </List>
      <Divider />
      <List component="nav" disablePadding>
        {navItems.map((item) =>
          item.type === 'item' ? (
            <NavItem
              key={item.title}
              classes={classes}
              title={item.title}
              icon={item.icon}
              path={item.path}
              drawerOpen={drawerOpen}
            />
          ) : (
            <NavGroup
              key={item.title}
              classes={classes}
              title={item.title}
              expanded={item.expanded}
              items={item.items}
              drawerOpen={drawerOpen}
              onClick={() => toggleExpanded(item)}
            />
          ),
        )}
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(NavigationDrawer);
