import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, FormControlLabel, Switch } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { get as _get } from 'lodash';
import ChipList from '../../../components/ChipList';
import {
  useSensorTemplates,
  useAddSensorTemplate,
  useSensorTemplateOptions,
  useUpdateSensorTemplate,
  useAddPositionOption,
  useRemovePositionOption,
  useRemoveChannelOption,
  useAddChannelOption,
} from '../../../hooks/sensor';
import SectionHeader from '../../../components/SectionHeader';
import SensorTemplateRemoval from './SensorTemplateRemoval';

import { NewPosition, NewSensorChannel, RemovableOption } from './EditorComponents';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

const DEFAULT_FORM_VALUES = { name: '', type: '', channels: [], positions: [], isActive: false };
const DEFAULT_SELECTED_VALUES = { channel: '', position: '' };

function SensorTemplatesEditor({
  classes,
  category,
  sensorTemplateId,
  onSuccess,
  onError,
  onCancel,
}) {
  const [selectedValues, setSelectedValues] = React.useState(DEFAULT_SELECTED_VALUES);

  const { data: sensorTemplates = [] } = useSensorTemplates();
  const existingSensorNames = sensorTemplates.map((s) => s.name.toUpperCase());
  const sensorTemplate = sensorTemplates.find((st) => st.uuid === sensorTemplateId);
  const isEditing = !!sensorTemplate;

  const formSensorTemplate = React.useMemo(
    () =>
      sensorTemplate && {
        name: sensorTemplate.name,
        type: sensorTemplate.type,
        channels: _get(sensorTemplate, 'channels', []).map((c) => ({ value: c })),
        positions: _get(sensorTemplate, 'positions', []).map((p) => ({ value: p })),
        isActive: sensorTemplate.isActive,
      },
    [sensorTemplate],
  );

  const { data: { channelOptions = [], positionOptions = [] } = {} } = useSensorTemplateOptions();

  const { mutateAsync: updateSensor } = useUpdateSensorTemplate();
  const { mutateAsync: addSensor } = useAddSensorTemplate();
  const { mutateAsync: addPositionOption } = useAddPositionOption();
  const { mutateAsync: removePositionOption } = useRemovePositionOption();
  const { mutateAsync: addChannelOption } = useAddChannelOption();
  const { mutateAsync: removeChannelOption } = useRemoveChannelOption();

  const { control, reset, handleSubmit, errors, formState } = useForm({
    defaultValues: formSensorTemplate,
    mode: 'all',
  });

  const submit = (submittedFormSensor) => {
    const validSensor = {
      ...submittedFormSensor,
      positions: submittedFormSensor.positions.map(({ value }) => value),
      channels: submittedFormSensor.channels.map(({ value }) => value),
    };
    const submitPromise = isEditing
      ? updateSensor({ ...validSensor, uuid: sensorTemplate.uuid }).then(() => {
          reset(submittedFormSensor);
          setSelectedValues(DEFAULT_SELECTED_VALUES);
        })
      : addSensor({ category, ...validSensor }).then(() => {
          reset(DEFAULT_FORM_VALUES);
          setSelectedValues(DEFAULT_SELECTED_VALUES);
        });
    submitPromise.then(onSuccess).catch(onError);
  };

  React.useEffect(() => {
    reset(formSensorTemplate || DEFAULT_FORM_VALUES);
    setSelectedValues(DEFAULT_SELECTED_VALUES);
  }, [reset, formSensorTemplate]);

  return (
    <>
      <div className={classes.header}>
        <SectionHeader
          Icon={isEditing ? EditIcon : AddIcon}
          title={isEditing ? 'Edit sensor' : 'New sensor'}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <SensorTemplateRemoval sensorTemplateId={sensorTemplateId} />
      </div>

      <form onSubmit={handleSubmit(submit)} className={classes.root}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: 'Required',
            validate: {
              alreadyUsed: (v = '') => {
                if (isEditing && v.trim().toUpperCase() === sensorTemplate.name.toUpperCase()) {
                  return undefined;
                }
                return existingSensorNames.includes(v.trim().toUpperCase())
                  ? 'Name is already used'
                  : undefined;
              },
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Sensor name"
              error={!!errors.name}
              helperText={errors && errors.name && errors.name.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          defaultValue=""
          rules={{
            required: 'Required',
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Sensor type"
              error={!!errors.type}
              helperText={errors && errors.type && errors.type.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="channels"
          control={control}
          defaultValue={[]}
          rules={{
            required: 'Required',
            validate: {
              noChannel: (channels = []) => {
                return channels.length === 0 ? 'At least one channel is required' : undefined;
              },
            },
          }}
          render={({ onChange, value }) => (
            <ChipList
              options={channelOptions}
              onChange={onChange}
              values={value}
              getOptionValue={(o) => o.value}
              getOptionLabel={(o) => o.value}
              getOptionSelected={(v, o) => v.value === o.value}
              ChipProps={{ disabled: isEditing }}
              SelectButtonGroupProps={{
                value: selectedValues.channel,
                onChange: (v) => setSelectedValues((prev) => ({ ...prev, channel: v })),
                selectProps: { disabled: isEditing },
                buttonProps: { disabled: isEditing, variant: 'outlined', color: 'primary' },
                placeholder: 'Channels',
                renderSelectedOption: (o) => o.value,
                renderOption: ({ value: channel, uuid }) => (
                  <RemovableOption onRemove={() => removeChannelOption(uuid)}>
                    {channel}
                  </RemovableOption>
                ),
                children: (
                  <NewSensorChannel
                    disabled={(newChannel) =>
                      newChannel.length === 0 ||
                      channelOptions.find((p) => p.value === newChannel) !== undefined
                    }
                    onSubmit={(channel) => addChannelOption(channel)}
                  />
                ),
              }}
            />
          )}
        />
        <Controller
          name="positions"
          control={control}
          defaultValue={[]}
          render={({ onChange, value }) => (
            <ChipList
              options={positionOptions}
              onChange={onChange}
              values={value}
              getOptionValue={(o) => o.value}
              getOptionLabel={(o) => o.value}
              getOptionSelected={(v, o) => v.value === o.value}
              SelectButtonGroupProps={{
                value: selectedValues.position,
                onChange: (v) => setSelectedValues((prev) => ({ ...prev, position: v })),
                placeholder: 'Positions',
                renderSelectedOption: (o) => o.value,
                renderOption: ({ value: position, uuid }) => (
                  <RemovableOption onRemove={() => removePositionOption(uuid)}>
                    {position}
                  </RemovableOption>
                ),
                children: (
                  <NewPosition
                    disabled={(newPos) =>
                      newPos.length === 0 ||
                      positionOptions.find((p) => p.value === newPos) !== undefined
                    }
                    onSubmit={(position) => addPositionOption(position)}
                  />
                ),
              }}
            />
          )}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue={false}
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          {isEditing && <Button onClick={onCancel}>cancel</Button>}
          <Button
            disableElevation
            disabled={!formState.isValid || !formState.isDirty}
            variant="contained"
            color="primary"
            type="submit"
          >
            {isEditing ? 'Save' : 'Add sensor'}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default withStyles(styles)(SensorTemplatesEditor);
