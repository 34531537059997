import React from 'react';

import { withStyles, Paper, Box, Container } from '@material-ui/core';

import { PermMedia as PackageGroupIcon } from '@material-ui/icons';
import { Pagination, SearchInput } from '@vidispine/vdt-materialui';
import clsx from 'clsx';

import SectionHeader from '../../../components/SectionHeader';
import DataTable from '../../../components/DataTable';
import { useSnackbar } from '../../../contexts/SnackbarContext';

import { useSearchPackageGroup } from '../../../hooks/packageGroup';
import PackageGroupEditor from './PackageGroupEditor';

const ROWS_PER_PAGE = 40;

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, 1fr) 400px',
    gridGap: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: `
    calc(100vh - 70px 
      - ${theme.spacing(8)}px 
      - ${theme.spacing(6)}px 
      - 48px
      - 44px
      - ${theme.spacing(2)}px 
      - 52px)
      `,
    overflow: 'auto',
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  searchInputWrapper: {
    paddingTop: theme.spacing(1),
    '& form:focus-within': {
      '& .MuiButton-root': {
        borderColor: theme.palette.primary.main,
      },
      '& .MuiInputBase-root': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiButton-root': {
      backgroundColor: theme.palette.background.paper,
      borderLeft: 'none',
    },
    '& .MuiInputBase-root': {
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
      height: '44px',
      borderRadius: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: theme.spacing(1),
    },
  },
});

const TABLE_COLUMNS = [
  {
    name: 'Package Group Name',
    key: 'name',
  },
  {
    name: 'Description',
    key: 'description',
  },
  {
    name: 'Id',
    key: 'id',
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

function PackageGroups({ classes }) {
  const [selected, setSelected] = React.useState();
  const { showAlert } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  const { data: { packageGroups = [], hits = 0 } = {} } = useSearchPackageGroup({
    name: searchText,
    first: page * ROWS_PER_PAGE + 1,
    number: ROWS_PER_PAGE,
  });

  const searchInputRef = React.useRef(null);

  const handleSearch = (query) => {
    setPage(0);
    setSearchText(query);
  };

  const handleChangePage = ({ page: newPage }) => {
    setPage(newPage);
  };

  const handleClickRow = ({ id }) => {
    setSelected(selected && selected === id ? undefined : id);
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Paper className={clsx('adminContainer', classes.paper)} variant="outlined">
        <SectionHeader
          Icon={PackageGroupIcon}
          title="Package Groups"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />

        <Box className={classes.searchInputWrapper} pb={1}>
          <SearchInput
            onSubmit={handleSearch}
            searchPlaceholder="Search package groups"
            inputRef={searchInputRef}
          />
        </Box>

        <div className={classes.tableContainer}>
          <DataTable
            columns={TABLE_COLUMNS}
            rows={packageGroups}
            onClickRow={handleClickRow}
            selectedRowIndices={[packageGroups.findIndex(({ id }) => id === selected)]}
          />
        </div>

        <Pagination
          count={hits}
          page={page}
          rowsPerPage={ROWS_PER_PAGE}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[]}
        />
      </Paper>

      <Paper className={clsx('adminSidePanel', 'adminContainer')} variant="outlined">
        <PackageGroupEditor
          collectionId={selected}
          onCancel={() => setSelected()}
          onAlert={({ severity, message }) => showAlert({ severity, message })}
          packageGroups={packageGroups}
          searchArgs={{
            name: searchText,
            first: page * ROWS_PER_PAGE + 1,
            number: ROWS_PER_PAGE,
          }}
        />
      </Paper>
    </Container>
  );
}

export default withStyles(styles)(PackageGroups);
