import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Button, Typography, Link, Tooltip, IconButton } from '@material-ui/core';
import { ChevronLeft, ContentCopy, Refresh } from 'mdi-material-ui';

const CopyButton = ({ stackTrace }) => {
  const [copyClicked, setCopyClicked] = React.useState(false);

  const handleTooltipClick = () => {
    navigator.clipboard.writeText(stackTrace);
    setCopyClicked(true);
  };

  return (
    <Tooltip title={copyClicked ? 'Copied' : 'Copy to clipboard'}>
      <IconButton
        style={{ color: 'white', alignSelf: 'flex-start' }}
        onClick={handleTooltipClick}
        onMouseEnter={() => {
          setCopyClicked(false);
        }}
      >
        <ContentCopy color={copyClicked ? 'secondary' : undefined} />
      </IconButton>
    </Tooltip>
  );
};

const ErrorStackTrace = ({ stackTrace }) => {
  return (
    <div style={{ padding: '0 2rem 2rem 2rem', marginTop: '1rem' }}>
      <div
        style={{
          display: 'flex',
          gap: '24px',
          backgroundColor: '#363636',
          padding: '16px',
          borderRadius: '11px',
          fontFamily: '"Courier", sans-serif',
          fontSize: '0.75rem',
          height: '200px',
          color: '#fff',
        }}
      >
        <div style={{ overflowY: 'scroll' }}>{stackTrace}</div>
        <CopyButton stackTrace={stackTrace} />
      </div>
    </div>
  );
};

const ErrorBoundaryComponent = ({ error }) => {
  return (
    <Container
      maxWidth="md"
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {error && (
        <Paper
          style={{
            backgroundColor: 'white',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            id="errorIcon"
            style={{
              color: '#FFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FF0000',
              padding: '30px',
              fontSize: '35px',
              fontWeight: '700',
              borderRadius: '50%',
              width: '10px',
              height: '10px',
              marginBottom: '17px',
            }}
          >
            !
          </span>
          <Typography variant="h3" gutterBottom>
            An error occurred.
          </Typography>
          <ErrorStackTrace stackTrace={error.stack} />
          <div style={{ display: 'flex', gap: '24px' }}>
            <Button
              href="/search/packages"
              color="primary"
              component={Link}
              variant="outlined"
              startIcon={<ChevronLeft />}
            >
              Back to start page
            </Button>
            <Button
              href={window.location.href}
              color="primary"
              component={Link}
              variant="outlined"
              startIcon={<Refresh />}
            >
              Refresh Page
            </Button>
          </div>
        </Paper>
      )}
    </Container>
  );
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return <ErrorBoundaryComponent error={error} errorInfo={errorInfo} />;
    }
    return children;
  }
}
