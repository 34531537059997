/* eslint-disable import/prefer-default-export */
import { utils as api, user as UserApi, debug as debugApi } from '@vidispine/vdt-api';

// TODO: Switch to noauth isOnline when that is exported from vdt
const ServerIsOnline = async () => {
  try {
    await debugApi.echo({ xmlDocument: null });
  } catch ({ message = '' }) {
    if (message.includes('400')) return true;
  }
  return false;
};

const onGetToken = async ({ userName, password, serverUrl }) => {
  const queryParams = { seconds: 2592000, autoRefresh: 'true' };
  const headers = { password, username: userName };
  if (serverUrl) {
    api.defaultClient.defaults.baseURL = serverUrl;
  }
  try {
    const { data: token } = await UserApi.getToken({ userName, queryParams, headers });
    return token;
  } catch (error) {
    if (await ServerIsOnline()) {
      throw Error('Incorrect Username/Password');
    }
    throw Error('Server is offline');
  }
};

export default onGetToken;
