import {
  withStyles,
  Paper,
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Collapse,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { KeyboardArrowUp as ExpandIcon } from '@material-ui/icons';
import { pkg } from 'mediadb-lib';
import uuidv4 from '../../utils/uuidv4';
import { updateWhere } from '../../utils/utils';

import QueryGroup from './components/QueryGroup';
import { useLabelTrackGroups } from '../../hooks/label';

function Label() {
  this.uuid = uuidv4();
  this.operation = 'OR';
  this.not = false;
  this.labels = [{ uuid: uuidv4() }];
}

const styles = (theme) => ({
  root: {},
  container: {
    padding: theme.spacing(3),
    display: 'grid',
  },
  buttonBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridGap: theme.spacing(1),
  },
  separatorText: {
    marginLeft: theme.spacing(2),
  },
  toggleButton: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  toggleButtonCollapsed: {
    transform: 'rotate(-180deg)',
  },
});

const AdvancedSearch = ({ classes, label = {}, onSubmit }) => {
  const { data: labelTrackGroups = [] } = useLabelTrackGroups();
  const [personOptions, setPersonOptions] = React.useState([]);

  React.useEffect(() => {
    pkg.getPersonSearchOptions().then((result) => {
      setPersonOptions(result);
    });
  }, []);

  const [labels, setLabels] = React.useState(
    label.labels && label.labels.length > 0 ? label.labels : [new Label()],
  );

  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  const handleReset = () => {
    setLabels([new Label()]);
    onSubmit({ operation: 'AND', labels: [] });
  };
  const handleSearch = () => {
    onSubmit({ operation: 'AND', labels });
  };

  const handleAddLabel = () => {
    setLabels((prev) => [...prev, new Label()]);
  };

  const handleDeleteLabel = (labelToDelete) => {
    setLabels((prev) =>
      prev.length > 1 ? prev.filter((l) => l.uuid !== labelToDelete.uuid) : [new Label()],
    );
  };

  const handleChange = (newLabel) => {
    const { uuid, not, ...rest } = newLabel;
    // Wrapping NOT operators
    const updateWith = newLabel.not ? { uuid, operation: 'NOT', not, labels: [rest] } : newLabel;
    setLabels((prev) => updateWhere(prev, (l) => l.uuid === newLabel.uuid, updateWith));
  };

  return (
    <Paper className={classes.root} variant="outlined">
      <Container className={classes.container} maxWidth="lg">
        <Collapse in={!collapsed}>
          {labels.map((l, index) => (
            <React.Fragment key={l.uuid}>
              <QueryGroup
                key={l.uuid}
                // Unwrap NOT operators
                label={l.operation === 'NOT' ? { ...l.labels[0], uuid: l.uuid, not: true } : l}
                labelTrackGroupTemplates={labelTrackGroups}
                personOptions={personOptions}
                onChange={handleChange}
                onDelete={handleDeleteLabel}
              />

              {labels.length > 1 && index !== labels.length - 1 && (
                <Typography
                  className={classes.separatorText}
                  color="textSecondary"
                  variant="overline"
                >
                  AND
                </Typography>
              )}
            </React.Fragment>
          ))}
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button color="primary" onClick={handleAddLabel}>
              + And
            </Button>
          </Box>
        </Collapse>
        <Box className={classes.buttonBar}>
          <div className={classes.flexContainer}>
            <IconButton
              className={clsx(classes.toggleButton, {
                [classes.toggleButtonCollapsed]: collapsed,
              })}
              onClick={toggleCollapsed}
            >
              <ExpandIcon />
            </IconButton>
          </div>
          {collapsed && (
            <Typography variant="overline" color="textSecondary">
              {label.labels
                ? `${label.labels.length || 'No'} active ${
                    label.labels.length === 1 ? 'query' : 'queries'
                  }`
                : 'No active queries'}
            </Typography>
          )}
          <div className={classes.flexContainer}>
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={handleSearch} variant="contained" disableElevation color="primary">
              Search
            </Button>
          </div>
        </Box>
      </Container>
    </Paper>
  );
};

export default withStyles(styles)(AdvancedSearch);
