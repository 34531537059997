import { metadata } from 'mediadb-lib';

export const hasInvalidDevices = (devices, customDeviceFields, customSensorFields) => {
  const missingRequiredDeviceMetadata = devices.some(
    (d) => !metadata.isValidCustomFields(d.customMetadata, customDeviceFields),
  );
  const missingDeviceChannel = devices.some((d) => d.channels.some((c) => !c.value));
  const missingSensorChannel = devices.some(
    (d) => d.sensors && d.sensors.length && d.sensors.some((s) => s.channels.some((c) => !c.value)),
  );
  const missingRequiredSensorMetadata = devices.some(
    (d) =>
      d.sensors &&
      d.sensors.length &&
      d.sensors.some((s) => !metadata.isValidCustomFields(s.customMetadata, customSensorFields)),
  );
  const missingPlaybackPosition = devices
    .filter((d) => !d.hasVideo)
    .some((dev) => dev.channels.some((c) => !c.playbackPosition));

  const isInvalid =
    missingPlaybackPosition ||
    missingRequiredDeviceMetadata ||
    missingDeviceChannel ||
    missingRequiredSensorMetadata ||
    missingSensorChannel;

  return isInvalid;
};

export const hasInvalidPersonMetadata = (interlocutors, customPersonFields) => {
  const isInvalid = interlocutors.some(
    (i) => !metadata.isValidCustomFields(i.customMetadata, customPersonFields),
  );
  return isInvalid;
};
