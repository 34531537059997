import React from 'react';
import { debounce as _debounce } from 'lodash';

const useStateWithBrowserStorage = (
  defaultValue = '',
  { key, persistBetweenSessions = true, debounceDelay = 500 },
) => {
  if (!key) {
    throw new Error('Missing "key"');
  }
  const browserStorage = React.useMemo(
    () => (persistBetweenSessions ? window.localStorage : window.sessionStorage),
    [persistBetweenSessions],
  );
  const [value, setValue] = React.useState(
    JSON.parse(browserStorage.getItem(key) || JSON.stringify(defaultValue)),
  );
  const debouncedSetStoredState = React.useRef(
    _debounce((v) => {
      browserStorage.setItem(key, JSON.stringify(v));
    }, debounceDelay),
  ).current;
  const setValueWithBrowserStorage = (v) => {
    setValue(v);
    debouncedSetStoredState(v);
  };
  return [value, setValueWithBrowserStorage];
};

export default useStateWithBrowserStorage;
