import React from 'react';
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { PlaylistEdit } from 'mdi-material-ui';
import { metadata, systemFields } from 'mediadb-lib';
import { Controller, useForm } from 'react-hook-form';
import { pickBy, get } from 'lodash';
import SectionHeader from '../components/SectionHeader';

import { useCustomMetadataGroup } from '../hooks/metadata';
import MetadataFieldEdit from '../components/MetadataFieldEdit';

import { useUpdatePkgMetadata } from '../hooks/pkg';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

function MetadataForm({
  packageId,
  defaultValues,
  customPackageFields,
  classes,
  onClose,
  onSuccess,
  onError,
}) {
  const { control, handleSubmit, formState, errors } = useForm({
    defaultValues,
    mode: 'all',
  });

  const { mutateAsync: updatePkgMetadata } = useUpdatePkgMetadata();

  const submit = ({ customMetadata: { ...customFields } = {}, ...defaultFields }) => {
    const {
      dirtyFields: { customMetadata: { ...dirtyCustomFields } = {}, ...dirtyDefaultFields } = {},
    } = formState;
    const changedValues = {
      ...pickBy(defaultFields, (_, k) => !!dirtyDefaultFields[k]),
      customMetadata: pickBy(customFields, (_, k) => !!dirtyCustomFields[k]),
    };
    updatePkgMetadata({ packageId, metadata: changedValues }).then(onSuccess).catch(onError);
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <DialogContent className={classes.contentRoot}>
        {[
          systemFields.PACKAGE_NAME,
          systemFields.PACKAGE_LOCATION,
          systemFields.PACKAGE_DESCRIPTION,
        ].map((field) => (
          <Controller
            key={field.fieldName}
            defaultValue=""
            name={field.fieldName}
            control={control}
            rules={{
              validate: {
                fieldValidation: (v) => metadata.validateFieldValue({ value: v, field }),
              },
            }}
            render={({ onChange, value }) => (
              <MetadataFieldEdit
                field={field}
                value={value}
                onChange={(v) => onChange(v)}
                error={!!get(errors, field.fieldName)}
              />
            )}
          />
        ))}
        {customPackageFields.map((field) => (
          <Controller
            key={field.fieldName}
            defaultValue=""
            name={`customMetadata.${field.fieldName}`}
            control={control}
            rules={{
              validate: {
                fieldValidation: (v) => metadata.validateFieldValue({ value: v, field }),
              },
            }}
            render={({ onChange, value }) => (
              <MetadataFieldEdit
                field={field}
                value={value}
                onChange={(v) => onChange(v)}
                error={!!get(errors, `customMetadata.${field.fieldName}`)}
              />
            )}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          disabled={!formState.isValid || !formState.isDirty}
          variant="contained"
          disableElevation
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

function MetadataDialog({
  classes,
  open,
  onClose,
  defaultValues,
  packageId,
  onSuccess = () => null,
  onError = () => null,
}) {
  const { data: customPackageFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.PACKAGE.name,
    {
      select: (fields) => fields.filter(({ isActiveInBrowse }) => isActiveInBrowse),
    },
  );
  return (
    <Dialog className={classes.root} maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={PlaylistEdit}
          title="Edit Metadata"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>

      <MetadataForm
        onSuccess={onSuccess}
        onError={onError}
        packageId={packageId}
        // force remount to reset form completely
        key={open}
        customPackageFields={customPackageFields}
        defaultValues={defaultValues}
        onClose={onClose}
        classes={classes}
      />
    </Dialog>
  );
}

export default withStyles(styles)(MetadataDialog);
