import { useQuery, useMutation, useQueryClient } from 'react-query';
import { packageGroup } from 'mediadb-lib';

export function useSearchPackageGroup(args) {
  return useQuery(['searchPackageGroup', args], () => packageGroup.searchPackageGroup(args));
}

export function useCreatePackageGroup(args) {
  const queryClient = useQueryClient();
  return useMutation(packageGroup.createPackageGroup, {
    onSuccess: ({ data: { id } }, { name, description, isActive }) => {
      const newPackageGroup = { id, name, description, isActive };
      // update package group search query data directly instead of refetching since the collection indexing takes some time.
      const { packageGroups = [], hits, ...rest } =
        queryClient.getQueryData(['searchPackageGroup', args]) || {};
      const { number } = args;
      const oldPackageGroups = hits >= number ? [...packageGroups].splice(-1, 1) : packageGroups;
      const newPackageGroups = {
        ...rest,
        hits: hits + 1,
        packageGroups: [newPackageGroup, ...oldPackageGroups],
      };
      queryClient.setQueryData(['searchPackageGroup', args], newPackageGroups);
    },
  });
}

export function useUpdatePackageGroup() {
  const queryClient = useQueryClient();
  return useMutation(packageGroup.updatePackageGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('searchPackageGroup');
    },
  });
}

export function usePackageGroupAccess({ packageGroupId }) {
  return useQuery(
    ['packageGroupAccess', packageGroupId],
    () => packageGroup.getPackageGroupAccess({ packageGroupId }),
    {
      enabled: !!packageGroupId,
    },
  );
}

export function useAddPackageGroupUser() {
  const queryClient = useQueryClient();
  return useMutation(packageGroup.addPackageGroupUser, {
    onMutate: async ({ packageGroupId, userName }) => {
      const queryKey = ['packageGroupAccess', packageGroupId];
      await queryClient.cancelQueries(queryKey);
      const previousAccess = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (prev) => ({
        accessList: [...(prev?.accessList || []), { username: userName, permission: 'READ' }],
      }));
      return { previousAccess };
    },
    onError: (err, newAccess, context) => {
      queryClient.setQueryData(
        ['packageGroupAccess', newAccess.packageGroupId],
        context.previousAccess,
      );
    },
  });
}

export function useRemovePackageGroupUser() {
  const queryClient = useQueryClient();
  return useMutation(packageGroup.removePackageGroupUser, {
    onMutate: async ({ packageGroupId, userName }) => {
      const queryKey = ['packageGroupAccess', packageGroupId];
      await queryClient.cancelQueries(queryKey);
      const previousAccess = queryClient.getQueryData(queryKey);
      const accessList = (previousAccess?.accessList || []).filter(
        (access) => access.username !== userName,
      );
      queryClient.setQueryData(queryKey, { accessList });
      return { previousAccess };
    },
    onError: (err, newAccess, context) => {
      queryClient.setQueryData(
        ['packageGroupAccess', newAccess.packageGroupId],
        context.previousAccess,
      );
    },
  });
}
