import React from 'react';
import { Prompt } from 'react-router-dom';

const NavigationBlocker = ({ navigationBlocked, ...props }) => {
  if (navigationBlocked) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }
  return (
    <Prompt
      when={navigationBlocked}
      message="Are you sure you want to leave?"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default NavigationBlocker;
