import React from 'react';

import { withStyles } from '@material-ui/core';
import { useTechnicalLabelTracks } from '../../../hooks/label';
import DataTable from '../../../components/DataTable';

const styles = (theme) => ({
  root: {
    '& th': {
      position: 'sticky',
      top: '0',
      backgroundColor: theme.palette.background.paper,
    },
  },
});

const TABLE_COLUMNS = [
  {
    name: 'Track Field Name',
    key: 'name',
  },
  {
    name: 'Track Label',
    key: 'label',
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

const TechnicalLabelTrackTable = ({ classes, selectedTrackName, onClickRow }) => {
  const { data: technicalLabelTracks = [] } = useTechnicalLabelTracks();

  return (
    <div className={classes.root}>
      <DataTable
        columns={TABLE_COLUMNS}
        rows={technicalLabelTracks}
        rowKey="name"
        onClickRow={onClickRow}
        selectedRows={[technicalLabelTracks.find((track) => track.name === selectedTrackName)]}
      />
    </div>
  );
};

export default withStyles(styles)(TechnicalLabelTrackTable);
