import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  withStyles,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { get as _get } from 'lodash';
import { device } from 'mediadb-lib';
import {
  useAddDeviceTemplate,
  useDeviceTemplates,
  useDeviceTypes,
  useUpdateDeviceTemplate,
} from '../../../hooks/device';
import SectionHeader from '../../../components/SectionHeader';
import DeviceTemplateRemoval from './DeviceTemplateRemoval';

const { SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES, SONOVA_DEVICE_TYPE_APPROVAL_STATUSES } = device;

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

const DEFAULT_FORM_VALUES = { name: '', deviceType: undefined, isActive: false };

export function DeviceTemplatesForm({
  className,
  category,
  deviceTemplateId,
  onSuccess,
  onError,
  onCancel,
  showActive = true,
  addProps = {},
}) {
  const { data: deviceTypes = [] } = useDeviceTypes();
  const approvedCategoryDeviceTypes = deviceTypes.filter(
    (deviceType) =>
      deviceType.approvalStatus === SONOVA_DEVICE_TYPE_APPROVAL_STATUSES.APPROVED &&
      deviceType.category === category,
  );
  const activeDeviceTypeUuids = deviceTypes
    .filter((deviceType) => deviceType.isActive)
    .map((dt) => dt.uuid);
  const { data: deviceTemplates = [] } = useDeviceTemplates();
  const deviceTemplate = deviceTemplates.find((dt) => dt.uuid === deviceTemplateId);
  const isEditing = !!deviceTemplate;
  const {
    mutateAsync: addDeviceTemplate,
    isLoading: isLoadingAddDeviceTemplate,
  } = useAddDeviceTemplate();
  const {
    mutateAsync: updateDeviceTemplate,
    isLoading: isLoadingUpdateDeviceTemplate,
  } = useUpdateDeviceTemplate();

  const formDeviceTemplate = React.useMemo(
    () =>
      deviceTemplate && {
        name: deviceTemplate.name,
        isActive: deviceTemplate.isActive,
        deviceTypeUuid: _get(deviceTemplate, 'deviceType.uuid'),
      },
    [deviceTemplate],
  );

  const { control, reset, handleSubmit, errors, formState } = useForm({
    defaultValues: formDeviceTemplate,
    mode: 'all',
  });

  const submit = (submittedDeviceTemplate) => {
    const submitPromise = isEditing
      ? updateDeviceTemplate({ ...submittedDeviceTemplate, uuid: deviceTemplate.uuid }).then(() =>
          reset(submittedDeviceTemplate),
        )
      : addDeviceTemplate({ ...addProps, ...submittedDeviceTemplate }).then(() =>
          reset(DEFAULT_FORM_VALUES),
        );
    submitPromise.then(onSuccess).catch(onError);
  };

  React.useEffect(() => {
    reset(formDeviceTemplate || DEFAULT_FORM_VALUES);
  }, [reset, formDeviceTemplate]);

  return (
    <form onSubmit={handleSubmit(submit)} className={className}>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        rules={{
          required: 'Required',
        }}
        render={({ onChange, onBlur, value }) => (
          <TextField
            required
            label="Device name"
            error={!!errors.name}
            helperText={errors && errors.name && errors.name.message}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <Controller
        name="deviceTypeUuid"
        control={control}
        rules={{
          required: 'Required',
        }}
        defaultValue=""
        render={({ onChange, value }) => (
          <FormControl>
            <InputLabel>Device type</InputLabel>
            <Select
              disabled={isEditing}
              value={value}
              onChange={({ target: { value: newUuid } }) => onChange(newUuid)}
            >
              {approvedCategoryDeviceTypes.map((deviceType) => {
                const deviceTypeIsInactive = !activeDeviceTypeUuids.includes(deviceType.uuid);
                return (
                  <MenuItem
                    disabled={deviceTypeIsInactive}
                    key={deviceType.uuid}
                    value={deviceType.uuid}
                  >
                    {deviceType.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      />
      {showActive && (
        <Controller
          name="isActive"
          control={control}
          defaultValue={false}
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
      )}
      <Box display="flex" justifyContent="flex-end">
        {isEditing && <Button onClick={onCancel}>cancel</Button>}
        <Button
          disableElevation
          disabled={
            !formState.isValid ||
            !formState.isDirty ||
            isLoadingAddDeviceTemplate ||
            isLoadingUpdateDeviceTemplate
          }
          variant="contained"
          color="primary"
          type="submit"
        >
          {isEditing ? 'Save' : 'Add device'}
        </Button>
      </Box>
    </form>
  );
}

function DeviceTemplatesEditor({ classes, ...props }) {
  const { deviceTemplateId } = props;
  const { data: deviceTemplates = [] } = useDeviceTemplates();
  const deviceTemplate = deviceTemplates.find((dt) => dt.uuid === deviceTemplateId);
  const isEditing = !!deviceTemplate;

  return (
    <>
      <div className={classes.header}>
        <SectionHeader
          Icon={isEditing ? EditIcon : AddIcon}
          title={isEditing ? 'Edit device' : 'New device'}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <DeviceTemplateRemoval deviceTemplateId={deviceTemplateId} />
      </div>
      <DeviceTemplatesForm
        className={classes.form}
        addProps={{ approvalStatus: SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.APPROVED }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
}

export default withStyles(styles)(DeviceTemplatesEditor);
