import { withStyles } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import React from 'react';

const setUTCAsLocalTZ = (d) => {
  const date = new Date(d);
  return new Date(1970, 0, 1, date.getUTCHours(), date.getUTCMinutes(), 0, 0);
};

const setLocalTZAsUTC = (d) => {
  const date = new Date(d);
  return new Date(Date.UTC(1970, 0, 1, date.getHours(), date.getMinutes(), 0, 0));
};

const styles = () => ({});

function TimeField({ onChange, value, ...props }) {
  const localValue = value && !Number.isNaN(value.valueOf()) ? setUTCAsLocalTZ(value) : value;
  return (
    <KeyboardTimePicker
      clearable
      ampm={false}
      onChange={(v) => {
        if (v && !Number.isNaN(v.valueOf())) {
          const utcValue = setLocalTZAsUTC(v);
          onChange(utcValue);
        } else {
          onChange(v);
        }
      }}
      value={localValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default withStyles(styles)(TimeField);
