import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeviceIcon from '@material-ui/icons/Hearing';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { partition as _partition, sortBy as _sortBy } from 'lodash';
import { shortenUuid, metadata } from 'mediadb-lib';
import { updateWhere } from '../../../utils/utils';
import SensorComponent from './SensorComponent';
import PositionSelect from './PositionSelect';
import ChannelSelect from './ChannelSelect';
import CustomMetadataComponent from './CustomMetadataComponent';

const videoExtensions = [
  '3g2',
  '3gp',
  'aaf',
  'asf',
  'avchd',
  'avi',
  'drc',
  'flv',
  'm2v',
  'm4p',
  'm4v',
  'mkv',
  'mng',
  'mov',
  'mp2',
  'mp4',
  'mpe',
  'mpeg',
  'mpg',
  'mpv',
  'mxf',
  'nsv',
  'ogg',
  'ogv',
  'qt',
  'rm',
  'rmvb',
  'roq',
  'svi',
  'vob',
  'webm',
  'wmv',
  'yuv',
];

const extension = (filename) => filename.split('.').pop();

const isVideo = (file) => videoExtensions.includes(extension(file.name));
const isTimeSeries = (file) => extension(file.name) === 'csv';

const styles = (theme) => ({
  deviceContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '2px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .fields': {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
    },
  },
  titleHeading: {
    flexGrow: 1,
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'min-content 1fr 1fr',
    gridGap: theme.spacing(1),
  },
  channel: {
    display: 'grid',
    gridGap: theme.spacing(6),
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr max-content',
  },
  channelForm: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  channelFormSelect: {
    flexGrow: 1,
  },
  positionSelect: {
    marginLeft: theme.spacing(3),
  },
  playbackPositionForm: {
    '& .MuiFormLabel-root': {
      position: 'static',
    },
  },
});

function PlaybackPositionSelect({ classes, onChange, channel }) {
  const [playbackPosition, setPlaybackPosition] = React.useState(channel.playbackPosition || '');
  const handleChange = ({ target: { value } }) => {
    setPlaybackPosition(value);
    onChange({ ...channel, playbackPosition: value });
  };

  return (
    <FormControl className={classes.playbackPositionForm}>
      <InputLabel shrink required>
        Playback
      </InputLabel>
      <RadioGroup row value={playbackPosition} onChange={handleChange}>
        <FormControlLabel
          value="left"
          control={<Radio color="primary" size="small" />}
          label={<Typography variant="body2">Left</Typography>}
        />
        <FormControlLabel
          value="center"
          control={<Radio color="primary" size="small" />}
          label={<Typography variant="body2">Center</Typography>}
        />
        <FormControlLabel
          value="right"
          control={<Radio color="primary" size="small" />}
          label={<Typography variant="body2">Right</Typography>}
        />
      </RadioGroup>
    </FormControl>
  );
}

const DeviceComponent = ({
  classes,
  device,
  files,
  usedFiles,
  deviceIcon = <DeviceIcon />,
  onDelete,
  onChange,
  onChangeFiles,
}) => {
  const {
    channels,
    position,
    sensors,
    id,
    deviceTemplate: {
      name,
      deviceType: { positions },
    },
  } = device;

  const onChangeChannel = (newChannel) => {
    onChangeFiles(newChannel);
    onChange({
      ...device,
      channels: updateWhere(device.channels, (c) => c.name === newChannel.name, newChannel),
    });
  };
  const onChangePlayback = (newChannel) => {
    onChange({
      ...device,
      channels: updateWhere(device.channels, (c) => c.name === newChannel.name, newChannel),
    });
  };

  const onChangePosition = (newPosition) => {
    onChange({ ...device, position: newPosition });
  };

  const nonTimeSeriesFiles = files.filter((f) => !isTimeSeries(f.file));
  const [videoFiles, audioFiles] = _partition(nonTimeSeriesFiles, (f) => isVideo(f.file));

  const onChangeSensor = (newSensor) => {
    onChange({
      ...device,
      sensors: updateWhere(device.sensors, (s) => s.id === newSensor.id, newSensor),
    });
  };

  return (
    <Box className={classes.deviceContainer}>
      <Box display="flex" mb={1} justifyContent="space-between">
        <Box className={classes.titleHeading}>
          <Icon>{deviceIcon}</Icon>
          <Typography variant="subtitle2">{`${name} (${shortenUuid(id)})`}</Typography>
          {positions.length > 0 && (
            <PositionSelect
              classes={classes}
              position={position}
              positions={_sortBy(positions)}
              onChange={onChangePosition}
            />
          )}
        </Box>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <div className={classes.inputContainer}>
        <Typography variant="caption">Channels</Typography>
        <div className="fields">
          {channels.length > 0 &&
            _sortBy(channels, 'name').map((channel) => (
              <div className={classes.channel} key={channel.name}>
                <ChannelSelect
                  classes={classes}
                  channel={channel}
                  files={
                    { video: videoFiles, audio: audioFiles, videoAndAudio: videoFiles }[
                      channel.mediaType
                    ] || []
                  }
                  usedFiles={usedFiles}
                  onChange={onChangeChannel}
                />
                {!device.hasVideo && (
                  <PlaybackPositionSelect
                    classes={classes}
                    channel={channel}
                    onChange={onChangePlayback}
                  />
                )}
              </div>
            ))}
        </div>
      </div>

      <CustomMetadataComponent
        source={device}
        onChange={onChange}
        customGroupName={metadata.CUSTOM_GROUPS.DEVICE.name}
      />

      {sensors.map((sensor) => (
        <React.Fragment key={sensor.id}>
          <SensorComponent
            sensor={sensor}
            files={files}
            usedFiles={usedFiles}
            onChange={onChangeSensor}
            onChangeFiles={onChangeFiles}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default withStyles(styles)(DeviceComponent);
