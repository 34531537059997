import React from 'react';
import { withStyles } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import {
  RecordVoiceOver as InterlocutorIcon,
  Hearing as DeviceIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
  Equalizer as SourcesIcon,
  Mic as SourceIcon,
  InsertDriveFile as FileIcon,
  LeakAdd as SensorIcon,
} from '@material-ui/icons';

import { partition as _partition, sortBy as _sortBy } from 'lodash';
import { capitalize } from '../../../utils/utils';

const styles = (theme) => ({
  treeView: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  treeItem: {
    overflow: 'hidden',
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  disabled: {
    '&:focus, &:hover': {
      '& > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'unset',
        cursor: 'default',
      },
    },
  },
  customLabelAvatar: {
    backgroundColor: 'transparent',
    marginRight: theme.spacing(2),
    width: '24px',
    height: '24px',
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
      width: '0.7em',
      height: '0.7em',
    },
  },
});

const CustomTreeItemLabel = ({ classes, label, secondary, type }) => {
  const getIcon = () => {
    switch (type) {
      case 'interlocutor':
        return <InterlocutorIcon />;
      case 'sources':
        return <SourcesIcon />;
      case 'device':
        return <DeviceIcon />;
      case 'source':
        return <SourceIcon />;
      case 'sensor':
        return <SensorIcon />;
      default:
        return <FileIcon />;
    }
  };
  return (
    <ListItem dense>
      <Avatar variant="square" className={classes.customLabelAvatar}>
        {getIcon()}
      </Avatar>
      <ListItemText primary={label} secondary={secondary || undefined} />
    </ListItem>
  );
};

function PackageTree({
  classes,
  interlocutors = {},
  onChannelClick = () => null,
  onDeviceClick = () => null,
  onSensorClick = () => null,
  onPersonClick = () => null,
}) {
  const [expandedNodes, setExpandedNodes] = React.useState([]);
  const [selectedNode, setSelectedNode] = React.useState('');

  React.useEffect(() => {
    const nodes = [];
    interlocutors.forEach((interlocutor) => {
      nodes.push(interlocutor.uuid);
      interlocutor.devices.forEach((device) => {
        nodes.push(device.uuid);
        device.sensors.forEach((sensor) => {
          nodes.push(sensor.uuid);
        });
      });
    });
    setExpandedNodes(nodes);
  }, [interlocutors]);

  const handleLabelClick = (event, node, type, interlocutor, device, channel, sensor) => {
    event.preventDefault();
    if (type === 'source') {
      return;
    }
    setSelectedNode(node);
    if (type === 'device') {
      onDeviceClick(device, interlocutor);
    } else if (type === 'sensor') {
      onSensorClick(sensor, device, interlocutor);
      if (!expandedNodes.includes(node)) {
        setExpandedNodes((prevNodes) => [...prevNodes, node]);
      }
    } else if (type === 'person') {
      onPersonClick(interlocutor);
    } else {
      onChannelClick(channel, device, interlocutor, sensor);
    }
  };

  const [persons, additionalSources] = _partition(
    interlocutors,
    (i) => !i.name.includes('Additional Sources'),
  );
  const sortedPersons = _sortBy(persons, (p) => Number(p.name.split(' ')?.[1]));

  return (
    <TreeView
      className={classes.treeView}
      onNodeToggle={(_, nodes) => setExpandedNodes(nodes)}
      expanded={expandedNodes}
      disableSelection
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
    >
      {[...sortedPersons, ...additionalSources].map((interlocutor) => {
        const isSource = interlocutor.name === 'Additional Sources';
        return (
          <TreeItem
            className={clsx(classes.treeItem, { [classes.disabled]: isSource })}
            classes={{
              root: selectedNode === interlocutor.uuid && !isSource ? 'Mui-selected' : '',
            }}
            key={interlocutor.uuid}
            nodeId={interlocutor.uuid}
            onLabelClick={(event) =>
              handleLabelClick(
                event,
                interlocutor.uuid,
                isSource ? 'source' : 'person',
                interlocutor,
              )
            }
            label={
              <CustomTreeItemLabel
                type={isSource ? 'sources' : 'interlocutor'}
                classes={classes}
                label={interlocutor.name}
              />
            }
            TransitionProps={{ timeout: 0 }}
          >
            {interlocutor.devices.map((device) => (
              <TreeItem
                className={classes.treeItem}
                classes={{
                  root: selectedNode === device.uuid ? 'Mui-selected' : '',
                }}
                key={device.uuid}
                nodeId={device.uuid}
                onLabelClick={(event) =>
                  handleLabelClick(event, device.uuid, 'device', interlocutor, device)
                }
                label={
                  <CustomTreeItemLabel
                    type={interlocutor.name === 'Additional Sources' ? 'source' : 'device'}
                    classes={classes}
                    label={
                      device.position
                        ? `${device.deviceTemplate.name} - ${device.position}`
                        : device.deviceTemplate.name
                    }
                  />
                }
                TransitionProps={{ timeout: 0 }}
              >
                {device.channels.map((channel) => (
                  <Tooltip
                    key={channel.uuid}
                    title={`${channel.name}: ${channel.filename} ${
                      channel.playbackPosition ? `- ${capitalize(channel.playbackPosition)}` : ''
                    }`}
                    enterDelay={600}
                    enterNextDelay={300}
                  >
                    <TreeItem
                      className={classes.treeItem}
                      classes={{
                        root: selectedNode === channel.uuid ? 'Mui-selected' : '',
                      }}
                      nodeId={channel.uuid}
                      onLabelClick={(event) =>
                        handleLabelClick(event, channel.uuid, 'file', interlocutor, device, channel)
                      }
                      label={
                        <CustomTreeItemLabel
                          classes={classes}
                          label={`${channel.name}: ${channel.filename}`}
                          secondary={
                            channel.playbackPosition
                              ? `Playback: ${capitalize(channel.playbackPosition)}`
                              : undefined
                          }
                        />
                      }
                      TransitionProps={{ timeout: 0 }}
                    />
                  </Tooltip>
                ))}
                {device.sensors.map((sensor) => (
                  <TreeItem
                    classes={{
                      root: selectedNode === sensor.uuid ? 'Mui-selected' : '',
                    }}
                    key={sensor.uuid}
                    nodeId={sensor.uuid}
                    onLabelClick={(event) =>
                      handleLabelClick(
                        event,
                        sensor.uuid,
                        'sensor',
                        interlocutor,
                        device,
                        null,
                        sensor,
                      )
                    }
                    label={
                      <CustomTreeItemLabel
                        type="sensor"
                        classes={classes}
                        label={`${sensor.sensorTemplate.name} (${sensor.sensorTemplate.type}) ${
                          sensor.position ? ` - ${sensor.position}` : ''
                        }`}
                      />
                    }
                    TransitionProps={{ timeout: 0 }}
                  >
                    {sensor.channels.map((channel) => (
                      <Tooltip
                        key={channel.uuid}
                        title={`${channel.name}: ${channel.filename}`}
                        enterDelay={600}
                        enterNextDelay={300}
                      >
                        <TreeItem
                          classes={{
                            root: selectedNode === channel.uuid ? 'Mui-selected' : '',
                          }}
                          nodeId={channel.uuid}
                          onLabelClick={(event) =>
                            handleLabelClick(
                              event,
                              channel.uuid,
                              'file',
                              interlocutor,
                              device,
                              channel,
                              sensor,
                            )
                          }
                          label={
                            <CustomTreeItemLabel
                              classes={classes}
                              label={`${channel.name}: ${channel.filename}`}
                            />
                          }
                          TransitionProps={{ timeout: 0 }}
                        />
                      </Tooltip>
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        );
      })}
    </TreeView>
  );
}

export default withStyles(styles)(PackageTree);
