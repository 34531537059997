import { useQuery, useMutation, useQueryClient } from 'react-query';
import { device } from 'mediadb-lib';

export function useDeviceTypes() {
  return useQuery('deviceTypes', device.getDeviceTypes);
}

export function useDeviceTypeOptions() {
  return useQuery('deviceTypeOptions', device.getDeviceTypeOptions);
}

export function useAddDeviceType() {
  const queryClient = useQueryClient();
  return useMutation(device.addDeviceType, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypes');
    },
  });
}

export function useAddPositionOption() {
  const queryClient = useQueryClient();
  return useMutation(device.addPositionOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypeOptions');
    },
  });
}

export function useRemovePositionOption() {
  const queryClient = useQueryClient();
  return useMutation(device.removePositionOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypeOptions');
    },
  });
}

export function useAddChannelOption() {
  const queryClient = useQueryClient();
  return useMutation(device.addChannelOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypeOptions');
    },
  });
}

export function useRemoveChannelOption() {
  const queryClient = useQueryClient();
  return useMutation(device.removeChannelOption, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypeOptions');
    },
  });
}

export function useUpdateDeviceType() {
  const queryClient = useQueryClient();
  return useMutation(device.updateDeviceType, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypes');
    },
  });
}

export function useApproveDeviceType() {
  const queryClient = useQueryClient();
  return useMutation(device.approveDeviceType, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypes');
    },
  });
}

export function useRemoveDeviceType() {
  const queryClient = useQueryClient();
  return useMutation(device.removeDeviceType, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTypes');
    },
  });
}

export function useDeviceTemplates(opts = {}) {
  return useQuery('deviceTemplates', device.getDeviceTemplates, opts);
}

export function useAddDeviceTemplate() {
  const queryClient = useQueryClient();
  return useMutation(device.addDeviceTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTemplates');
    },
  });
}

export function useUpdateDeviceTemplate() {
  const queryClient = useQueryClient();
  return useMutation(device.updateDeviceTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTemplates');
    },
  });
}

export function useRemoveDeviceTemplate() {
  const queryClient = useQueryClient();
  return useMutation(device.removeDeviceTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries('deviceTemplates');
    },
  });
}

export function useIsDeviceTemplateUsedByActivePackage(opts = {}) {
  const { deviceTemplate } = opts;
  const { uuid } = deviceTemplate || {};
  return useQuery(
    `deviceTemplateRemovable-${uuid}`,
    () => device.isDeviceTemplateUsedByActivePackage(deviceTemplate),
    { ...opts, enabled: !!deviceTemplate },
  );
}

export function useSensors() {
  return useQuery('sensors', device.getSensors);
}
