import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    '&.Timeline-root': {
      position: 'relative',
      backgroundColor: '#e1e1e1',
      '& .Timeline-extract': {
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        minWidth: '6px',
      },
    },
  },
});

function Timeline({ extracts, duration, classes, height = 4, className }) {
  return (
    <div className={clsx(className, classes.root, 'Timeline-root')} style={{ height }}>
      {extracts.map(({ start, end }) => {
        const extractDuration = end - start;
        const coverage = Math.round(10000 * (extractDuration / duration)) / 100;
        const startPosition = Math.round(10000 * (start / duration)) / 100;
        return (
          <div
            key={`${start}-${end}`}
            className="Timeline-extract"
            style={{ left: `${startPosition}%`, width: `${coverage}%`, height }}
          />
        );
      })}
    </div>
  );
}

export default withStyles(styles)(Timeline);
