import React from 'react';
import Box from '@material-ui/core/Box';
import { Button, withStyles } from '@material-ui/core';
import { metadata } from 'mediadb-lib';
import PersonComponent from './PersonComponent';
import { updateWhere } from '../../../utils/utils';
import { hasInvalidDevices, hasInvalidPersonMetadata } from '../uploadValidation';
import InterlocutorComponent from './InterlocutorComponent';
import { useCustomMetadataGroup } from '../../../hooks/metadata';
import useCleanupEffect from '../../../hooks/useCleanupEffect';
import uuidv4 from '../../../utils/uuidv4';
import CustomMetadataComponent from './CustomMetadataComponent';

const styles = (theme) => ({
  root: {},
  stickyContainer: {
    position: 'sticky',
    top: '0',
    zIndex: '10',
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

function Interlocutor() {
  this.id = uuidv4();
  this.devices = [];
}

const AddInterlocutors = ({
  classes,
  uploadProps,
  onChange,
  onChangeUsedFiles,
  onChangeValid,
  interlocutors,
  usedFiles,
}) => {
  const { files } = uploadProps;
  const [localInterlocutors, setLocalInterlocutors] = React.useState(interlocutors);

  useCleanupEffect(() => {
    onChange(localInterlocutors);
  }, [localInterlocutors, onChange]);

  const handleDeleteUsedFiles = (deletedFiles) => {
    const newUsedFiles = [...usedFiles];
    deletedFiles.forEach((file) => {
      const indexToRemove = newUsedFiles.indexOf(file);
      newUsedFiles.splice(indexToRemove, 1);
    });
    onChangeUsedFiles(newUsedFiles);
  };

  const handleChangeFiles = (newChannel) => {
    const { value, prevValue } = newChannel;
    const newUsedFiles = [...usedFiles];
    const indexToRemove = usedFiles.indexOf(prevValue);
    if (indexToRemove !== -1) {
      newUsedFiles.splice(indexToRemove, 1);
    }
    newUsedFiles.push(value);
    onChangeUsedFiles(newUsedFiles);
  };

  const handleAddInterlocutor = () => {
    setLocalInterlocutors((existing) => [...existing, new Interlocutor()]);
  };

  const handleDeleteInterlocutor = (interlocutor) => {
    const filenames = interlocutor.devices.flatMap((device) =>
      [...device.channels, ...device.sensors.flatMap((s) => s.channels)].map(
        (channel) => channel.value,
      ),
    );
    handleDeleteUsedFiles(filenames);
    const newInterlocutors = localInterlocutors.filter((i) => i.id !== interlocutor.id);
    setLocalInterlocutors(newInterlocutors);
  };

  const handleChangeInterlocutor = (newInterlocutor) => {
    setLocalInterlocutors(
      updateWhere(localInterlocutors, (i) => i.id === newInterlocutor.id, newInterlocutor),
    );
  };

  const devices = localInterlocutors.flatMap(({ devices: ds = [] }) => ds);

  const {
    data: customDeviceFields = [],
    isLoading: isLoadingDeviceFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.DEVICE.name, {
    select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
  });

  const {
    data: customSensorFields = [],
    isLoading: isLoadingSensorFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.SENSOR.name, {
    select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
  });

  const {
    data: customPersonFields = [],
    isLoading: isLoadingPersonFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.PERSON.name, {
    select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
  });
  const isLoading = isLoadingDeviceFields || isLoadingSensorFields || isLoadingPersonFields;
  const isValid =
    !hasInvalidDevices(devices, customDeviceFields, customSensorFields) &&
    !hasInvalidPersonMetadata(localInterlocutors, customPersonFields);

  React.useEffect(() => {
    onChangeValid(isValid);
  }, [isValid, onChangeValid]);

  return (
    !isLoading && (
      <Box className={classes.root}>
        <Box className={classes.stickyContainer}>
          <Button onClick={() => handleAddInterlocutor()} variant="outlined" color="primary">
            Add person
          </Button>
        </Box>
        {localInterlocutors.length > 0 &&
          localInterlocutors.map((interlocutor, i) => (
            <PersonComponent
              key={interlocutor.id}
              person={{ ...interlocutor, name: `Person ${i + 1}` }}
              files={files}
              onDelete={handleDeleteInterlocutor}
              onChange={handleChangeInterlocutor}
            >
              <CustomMetadataComponent
                source={interlocutor}
                onChange={handleChangeInterlocutor}
                customGroupName={metadata.CUSTOM_GROUPS.PERSON.name}
              />
              <InterlocutorComponent
                classes={classes}
                interlocutor={interlocutor}
                files={files}
                usedFiles={usedFiles}
                onChange={handleChangeInterlocutor}
                onChangeFiles={handleChangeFiles}
                onDeleteUsedFiles={handleDeleteUsedFiles}
              />
            </PersonComponent>
          ))}
      </Box>
    )
  );
};

export default withStyles(styles)(AddInterlocutors);
