import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function PositionSelect({ classes, position, positions, onChange }) {
  return (
    <FormControl className={classes.positionSelect}>
      <InputLabel>Position</InputLabel>
      <Select
        variant="standard"
        value={position}
        onChange={({ target: { value } }) => onChange(value)}
      >
        {positions.map((pos) => (
          <MenuItem key={pos} value={pos}>
            {pos}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default PositionSelect;
