import React from 'react';
import { useParams } from 'react-router-dom';
import { values as _values, keyBy as _keyBy, merge as _merge, isEqual } from 'lodash';

import { withStyles, Box, LinearProgress, Button, Divider } from '@material-ui/core';
import { useFindPackagesWithExtracts } from '../../hooks/search';
import useSearchQuery from './useSearchQuery';
import PackageList from './components/PackageList';
import PackageSearch from './components/PackageSearch';
import ExportedPlaylists from './components/ExportedPlaylists';
import PackageTable from './components/PackageTable';
import PlaylistDrawer from '../../components/Playlists/PlaylistDrawer';
import FilterCard from './components/FilterCard';
import FilterSelect from './components/FilterSelect';
import useFilterFields from '../../hooks/useFilterFields';
import FilterDrawer from './components/FilterDrawer';
import Technical from './components/Technical';

const queryFiltersFromSelected = (filters) =>
  filters.map(({ field, useFacet }) => ({ field, useFacet }));

const useSelectedFilters = (filters = [], onChangeFilters) => {
  const [selectedFilters, setSelectedFilters] = React.useState([]);

  const handleChange = (newSelectedFilters) => {
    setSelectedFilters(newSelectedFilters);

    const newFilters = queryFiltersFromSelected(newSelectedFilters);

    const exitingFiltersWithValues = filters.filter(
      (f) => newFilters.some(({ field }) => f.field === field) && (f.value || f.range),
    );
    onChangeFilters(
      _values(_merge(_keyBy(newFilters, 'field'), _keyBy(exitingFiltersWithValues, 'field'))),
    );
  };
  return [selectedFilters, handleChange];
};

const BATCH_SIZE = 1;
const NUMBER = 10;

const SearchExtracts = withStyles((theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
    flexGrow: 1,
    zIndex: 1,
  },
  filter: {},
  filterContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  packageList: {
    flexBasis: '0px',
    flexGrow: 1,
  },
  searchWrapper: {
    minHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    maxHeight: `calc(100vh - 70px - ${theme.spacing(4)}px)`,
    marginRight: `-${theme.spacing(1)}px`,
    overflowY: 'auto',
  },
  buttonContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignSelf: 'flex-end',
  },
}))(({ classes }) => {
  const { filterFields, isLoading: isLoadingFilterFields } = useFilterFields();
  const {
    query,
    iteration,
    shouldStop,
    setText,
    setLabel,
    setTechnical,
    setSort,
    setFilter,
    setFacet,
    clearFilters,
    setIteration,
    setShouldStop,
  } = useSearchQuery({
    suggestion: {
      maximumSuggestions: 3,
      accuracy: 0.7,
    },
    filter: filterFields.map(({ field, useFacet }) => ({ field, useFacet })),
    facet: filterFields,
    label: {
      operation: 'AND',
      labels: [],
    },
  });

  const [localTechnical, setLocalTechnical] = React.useState([]);
  const [localFilter, setLocalFilter] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = useSelectedFilters(query.filter, setLocalFilter);
  const {
    data: { hits, maxPossibleHits, amountChecked, facet, suggestion, packages = [] } = {},
    isFetchingPackagesAndExtracts,
    isFetchingPackages,
    isFetchingExtracts,
    isError,
    loadMore,
    hasMoreToFetch,
  } = useFindPackagesWithExtracts(query, {
    batchSize: BATCH_SIZE,
    number: NUMBER,
    iteration,
    shouldStop,
  });

  const applyDisabled = React.useMemo(() => {
    const activeQuery = query.filter.filter((f) => f.field !== 'packageStatus');
    const currentlySelected = queryFiltersFromSelected(selectedFilters);
    return (
      isEqual(localTechnical, query.technical || []) &&
      (isEqual(localFilter, activeQuery) || isEqual(localFilter, currentlySelected))
    );
  }, [localTechnical, localFilter, query, selectedFilters]);

  React.useEffect(() => {
    if (!isLoadingFilterFields) {
      setFacet(filterFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFilterFields]);

  const onApply = () => {
    setFilter(localFilter);
    setTechnical(localTechnical);
  };

  const onClear = () => {
    const resetFilters = queryFiltersFromSelected(selectedFilters);
    clearFilters();
    setLocalFilter(resetFilters);
    setFilter(resetFilters);
    setLocalTechnical([]);
    setTechnical([]);
  };

  const handleLoadMore = () => {
    if (shouldStop) setShouldStop(false);
    else if (isError) loadMore();
    else setIteration(Math.floor(packages.length / NUMBER) + 1);
  };
  const handleCancel = () => {
    setShouldStop(true);
  };

  return (
    <div className={classes.root}>
      <FilterDrawer>
        {isLoadingFilterFields ? (
          <LinearProgress variant="query" />
        ) : (
          <div className={classes.filterContainer}>
            <FilterSelect filterFields={filterFields} onChange={setSelectedFilters} />
            <FilterCard
              className={classes.filter}
              FilterProps={{
                filters: selectedFilters,
                facets: facet,
                values: localFilter,
                onChange: setLocalFilter,
              }}
            />
          </div>
        )}

        <Divider />

        <Technical onSubmit={setLocalTechnical} localTechnical={localTechnical} />
        <div className={classes.buttonContainer}>
          <Button onClick={onClear}>CLEAR</Button>
          <Button onClick={onApply} disabled={applyDisabled} color="primary">
            APPLY
          </Button>
        </div>
      </FilterDrawer>
      <Box display="flex" flexDirection="column" className={classes.searchWrapper}>
        <PackageSearch
          searchText={query?.text}
          label={query.label}
          hits={hits}
          maxPossibleHits={maxPossibleHits}
          amountChecked={amountChecked}
          amountFetched={packages.length}
          suggestion={suggestion}
          enableLoadMore={
            hasMoreToFetch &&
            !(isFetchingPackagesAndExtracts || isFetchingPackages || isFetchingExtracts)
          }
          canCancel={isFetchingPackagesAndExtracts && !shouldStop}
          onLoadMore={handleLoadMore}
          onCancel={handleCancel}
          onChangeSort={setSort}
          setText={setText}
          setLabel={setLabel}
          isFetchingPackages={isFetchingPackagesAndExtracts || isFetchingPackages}
          isError={isError}
        />
        <PackageList
          className={classes.packageList}
          isFetchingExtracts={isFetchingExtracts}
          packages={packages}
        />
      </Box>
    </div>
  );
});

export default function Search() {
  const { page: searchPage } = useParams();
  return (
    <Box display="grid" gridAutoFlow="column" gridTemplateColumns="1fr auto">
      {searchPage === 'extracts' && <SearchExtracts />}
      {searchPage === 'exported-playlists' && <ExportedPlaylists />}
      {searchPage === 'packages' && <PackageTable />}
      <PlaylistDrawer />
    </Box>
  );
}
