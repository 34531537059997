import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { ListSubheader } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    width: '100%',
    '& *': {
      color: theme.palette.primary.main,
    },
    // paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiMenu-paper': {
      maxHeight: 'unset',
    },
  },
  groupHeader: {
    backgroundColor: theme.palette.common.white,
  },
  menuItem: {
    padding: '0 16px',
    '& .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {},
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const GroupedMultiselectField = ({
  className,
  classes,
  name,
  label,
  groupedOptions,
  value,
  onChange,
}) => {
  return (
    <FormControl className={clsx(className, classes.root)}>
      <InputLabel id="grouped-multiselect-field-label">{label}</InputLabel>
      <Select
        labelId="grouped-multiselect-field-label"
        id="grouped-multiselect-field"
        name={name}
        multiple
        value={value}
        onChange={(event) => {
          onChange({ value: (event?.target?.value || []).filter((v) => v !== undefined), event });
        }}
        input={<Input />}
        renderValue={(selected) => (
          <span>{selected.length > 0 ? `${selected.length} selected` : null}</span>
        )}
        MenuProps={MenuProps}
      >
        {groupedOptions.map(({ name: groupName, label: groupLabel, options }) => [
          <ListSubheader className={classes.groupHeader} key={groupName}>
            {groupLabel}
          </ListSubheader>,
          (() =>
            options.map(({ name: nameValue, label: valueLabel }) => (
              <MenuItem dense className={classes.menuItem} key={nameValue} value={nameValue}>
                <Checkbox size="small" checked={value.indexOf(nameValue) > -1} />
                <ListItemText primary={valueLabel} />
              </MenuItem>
            )))(),
        ])}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(GroupedMultiselectField);
