import { IconButton } from '@material-ui/core';
import React from 'react';

const withDisableOnClick = (ButtonComponent) =>
  React.forwardRef(({ disabled: outerDisabled, duration = 1000, onClick, ...props }, ref) => {
    const [disabled, setDisabled] = React.useState(false);
    const timeOut = React.useRef();
    const handleOnClick = (e) => {
      setDisabled(true);
      timeOut.current = setTimeout(() => setDisabled(false), duration);
      return onClick(e);
    };
    React.useEffect(() => {
      return () => {
        if (timeOut.current) {
          clearTimeout(timeOut.current);
        }
      };
    }, []);

    return (
      <ButtonComponent
        ref={ref}
        disabled={outerDisabled || disabled}
        onClick={handleOnClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  });

const DisabledOnClickIconButton = withDisableOnClick(IconButton);

export default DisabledOnClickIconButton;
