import React from 'react';
import { withStyles, alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  MoreHoriz as MenuIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  groupBy as _groupBy,
  values as _values,
  sortBy as _sortBy,
  some as _some,
  intersectionWith,
  differenceWith,
  unionWith,
  isEqual,
} from 'lodash';
import ExtractExportPropertiesDialog from './ExtractExportProperties';
import { useSnackbar } from '../../contexts/SnackbarContext';

import { useRemovePlaylistExtracts } from '../../hooks/playlist';
import ExtractDialog from './ExtractDialog';
import useConfirmationDialog from '../../contexts/ConfirmationDialogContext';
import { formatExtracts } from '../../utils/utils';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1.5),
  },
  listItem: {
    '& .listItemMenu': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      '& .listItemMenu': {
        visibility: 'visible',
      },
    },
  },
  listItemOpenMenu: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.15)} !important`,
  },
  playlistListItem: {
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
      },
      backgroundColor: 'transparent',
      '& .MuiListItemText-root': {
        '& span': {
          fontWeight: 600,
        },
      },
    },
  },
  extractListGroup: {
    paddingBottom: theme.spacing(1),
  },
  invalidGroup: {
    '& .MuiTypography-body2': {
      color: theme.palette.text.disabled,
    },
    '&.notFound': {
      '& .groupHeader': {
        color: theme.palette.error.main,
        '& .MuiSvgIcon-root': {
          color: theme.palette.error.light,
        },
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: theme.palette.primary.light,
      },
    },
    '&.noAccess': {
      '& .groupHeader': {
        color: theme.palette.warning.main,
        '& .MuiSvgIcon-root': {
          color: theme.palette.warning.light,
        },
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: theme.palette.primary.light,
      },
    },
  },
  extractListItem: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiTypography-body2': {
      fontSize: '0.75rem',
    },
  },
  extractGroupHeader: {
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    '& .groupHeader': {
      display: 'inline-flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  extractSkeleton: {
    marginLeft: theme.spacing(4),
  },
  commentText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
  },
});
const toggleExtracts = (prev, extracts, checked) =>
  (checked ? differenceWith : unionWith)(prev, extracts, isEqual);

const getHeaderTooltip = ({ missing, unauthorized }) => {
  if (missing) return 'Package not found';
  if (unauthorized) return 'No access to package';
  return '';
};

const ExtractGroupCheckbox = ({ extracts, selectedExtracts, setSelectedExtracts }) => {
  const checkedExtracts = intersectionWith(extracts, selectedExtracts, isEqual);
  const checked = checkedExtracts.length === extracts.length;
  const indeterminate = !checked && checkedExtracts.length > 0;
  return (
    <Checkbox
      checked={checked}
      color="primary"
      indeterminate={indeterminate}
      onChange={() => setSelectedExtracts((prev) => toggleExtracts(prev, extracts, checked))}
      onClick={(e) => e.stopPropagation()}
      size="small"
    />
  );
};

const ExtractCheckbox = ({ extract, selectedExtracts, setSelectedExtracts }) => {
  const checked = _some(selectedExtracts, extract);
  return (
    <Checkbox
      checked={checked}
      color="primary"
      onChange={() => setSelectedExtracts((prev) => toggleExtracts(prev, [extract], checked))}
      onClick={(e) => e.stopPropagation()}
      size="small"
    />
  );
};

const PlaylistExtractList = ({ classes, playlist, selectedExtracts, setSelectedExtracts }) => {
  const extractGroups = React.useMemo(() => {
    return _sortBy(
      _values(_groupBy(playlist.extracts, 'packageId')).map((extractGroup) => {
        const [{ name, packageId: id }] = extractGroup;
        return {
          name,
          id,
          extracts: _sortBy(formatExtracts(extractGroup), (e) => e.smpteIn),
          missing: playlist.missingPackages.includes(id),
          unauthorized: playlist.unauthorizedPackages.includes(id),
        };
      }),
      (group) => group.name.toLowerCase(),
    );
  }, [playlist]);

  const { extracts: allExtracts = [] } = playlist;
  const allExtractsFormatted = formatExtracts(allExtracts);

  const { mutateAsync: removePlaylistExtracts } = useRemovePlaylistExtracts();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuItem, setMenuItem] = React.useState(null);
  const [exportPropertiesExtract, setExportPropertiesExtract] = React.useState(null);
  const [editExtract, setEditExtract] = React.useState(null);

  const { showAlert } = useSnackbar();
  const { confirm: confirmDelete } = useConfirmationDialog({ throwOnReject: false });

  const closeMenu = () => {
    setMenuAnchor(null);
    setMenuItem(null);
  };

  const handleMenuClick = (event, id) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setMenuItem(id);
  };
  const handleCloseMenu = (event) => {
    event.stopPropagation();
    closeMenu();
  };
  const handleClickEditExtract = (extract) => {
    setEditExtract(extract);
    closeMenu();
  };
  const handleDeleteExtract = async (extract) => {
    if (
      await confirmDelete({
        titleText: 'Confirm',
        rejectText: 'Cancel',
        confirmText: 'Delete extract',
        fullWidth: true,
        children: (
          <DialogContent>
            <DialogContentText>Permanently delete extract?</DialogContentText>
          </DialogContent>
        ),
      })
    ) {
      await removePlaylistExtracts({
        playlistId: playlist.id,
        extracts: [extract],
      })
        .then(() => {
          closeMenu();
          showAlert({ severity: 'success', message: 'Succesfully deleted extract' });
        })
        .catch(() =>
          showAlert({
            severity: 'error',
            message: 'Could not delete extract',
          }),
        );
    }
  };

  return (
    <>
      {exportPropertiesExtract && (
        <ExtractExportPropertiesDialog
          extract={exportPropertiesExtract}
          playlist={playlist}
          onClose={() => setExportPropertiesExtract(null)}
          onSuccess={() => {
            showAlert({ severity: 'success', message: 'Saved Export Properties' });
            setExportPropertiesExtract(null);
          }}
          onError={() => {
            showAlert({ severity: 'error', message: 'Failed to save export properties' });
            setExportPropertiesExtract(null);
          }}
        />
      )}
      {editExtract && (
        <ExtractDialog
          open={!!editExtract}
          playlistId={playlist.id}
          extract={editExtract}
          onClose={() => setEditExtract(null)}
          onSuccess={() => {
            showAlert({ severity: 'success', message: 'Extract updated' });
            setEditExtract(null);
          }}
          onError={() => {
            showAlert({ severity: 'error', message: 'Failed update extract' });
          }}
        />
      )}
      {allExtracts.length > 0 ? (
        <div>
          <Box px={2} display="flex" alignItems="center">
            <ExtractGroupCheckbox
              extracts={allExtractsFormatted}
              selectedExtracts={selectedExtracts}
              setSelectedExtracts={setSelectedExtracts}
            />
            <Typography variant="subtitle2">Extracts</Typography>
          </Box>
          {extractGroups.length > 0 &&
            extractGroups.map((extractGroup) => (
              <List
                className={clsx(classes.extractListGroup, {
                  [classes.invalidGroup]: extractGroup.missing || extractGroup.unauthorized,
                  notFound: extractGroup.missing,
                  noAccess: extractGroup.unauthorized,
                })}
                key={extractGroup.id}
                component="div"
                disablePadding
                subheader={
                  <ListSubheader component="div" className={classes.extractGroupHeader}>
                    <ExtractGroupCheckbox
                      extracts={extractGroup.extracts}
                      selectedExtracts={selectedExtracts}
                      setSelectedExtracts={setSelectedExtracts}
                    />
                    <Tooltip title={getHeaderTooltip(extractGroup)}>
                      <Typography color="primary" variant="overline" className="groupHeader">
                        {extractGroup.missing && <ErrorIcon />}
                        {extractGroup.unauthorized && <WarningIcon />}
                        {extractGroup.name}
                      </Typography>
                    </Tooltip>
                  </ListSubheader>
                }
              >
                {extractGroup.extracts.map((extract) => (
                  <ListItem
                    key={extract.uuid}
                    dense
                    className={clsx(classes.extractListItem, classes.listItem, {
                      [classes.listItemOpenMenu]: menuItem === extract.uuid,
                    })}
                  >
                    <ExtractCheckbox
                      extract={extract}
                      selectedExtracts={selectedExtracts}
                      setSelectedExtracts={setSelectedExtracts}
                    />
                    <ListItemText
                      primary={extract.smpteTimecode}
                      secondary={
                        <Tooltip title={extract.comment ? extract.comment : ''}>
                          <span>{extract.comment ? extract.comment : undefined}</span>
                        </Tooltip>
                      }
                      secondaryTypographyProps={{
                        classes: { root: classes.commentText },
                      }}
                    />
                    {extract.useExtractSpecificChannels && (
                      <Tooltip title="Custom export properties set">
                        <SettingsIcon fontSize="small" />
                      </Tooltip>
                    )}
                    <IconButton
                      className="listItemMenu"
                      size="small"
                      onClick={(event) => handleMenuClick(event, extract.uuid)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor) && menuItem === extract.uuid}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem
                        disabled={extractGroup.missing || extractGroup.unauthorized}
                        onClick={() => {
                          handleClickEditExtract(extract);
                          closeMenu();
                        }}
                      >
                        <Typography variant="body2">Edit</Typography>
                      </MenuItem>
                      <MenuItem
                        disabled={extractGroup.missing || extractGroup.unauthorized}
                        onClick={() => {
                          setExportPropertiesExtract(extract);
                          closeMenu();
                        }}
                      >
                        <Typography variant="body2">Export properties</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleDeleteExtract(extract)}>
                        <Typography variant="body2">Remove from playlist</Typography>
                      </MenuItem>
                    </Menu>
                  </ListItem>
                ))}
              </List>
            ))}
        </div>
      ) : (
        <Box p={3}>
          <Typography variant="overline" color="textSecondary">
            No extracts added
          </Typography>
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(PlaylistExtractList);
