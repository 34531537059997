/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@material-ui/core/Box';
import {
  Button,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { SONOVA_TECHNICAL_LABEL_TRACK_PREFIX } from 'mediadb-lib/src/label/consts';
import SectionHeader from '../../../components/SectionHeader';

import {
  useTechnicalLabelTracks,
  useAddTechnicalLabelTrack,
  useUpdateTechnicalLabelTrack,
} from '../../../hooks/label';
import TechnicalLabelTrackRemoval from './TechnicalLabelTrackRemoval';

const TECHNICAL_LABEL_TRACK_GROUP = {
  name: '',
  label: '',
  isActive: true,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  fieldNamePrefix: {
    margin: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function TechnicalLabelTrackEditor({
  classes,
  selectedTrackName,
  addText,
  onAlert = () => null,
  onCancel = () => null,
}) {
  const { data: technicalLabelTracks = [], isFetching } = useTechnicalLabelTracks();

  const technicalLabelTrack = React.useMemo(() => {
    return technicalLabelTracks.length > 0
      ? technicalLabelTracks.find((track) => track.name === selectedTrackName)
      : undefined;
  }, [technicalLabelTracks, selectedTrackName]);

  const isEditing = technicalLabelTrack !== undefined;

  const {
    mutateAsync: addTechnicalLabelTrack,
    isLoading: isLoadingAdd,
  } = useAddTechnicalLabelTrack();
  const {
    mutateAsync: updateTechnicalLabelTrack,
    isLoading: isLoadingUpdate,
  } = useUpdateTechnicalLabelTrack();

  const { control, reset, handleSubmit, errors, formState } = useForm({
    defaultValues: TECHNICAL_LABEL_TRACK_GROUP,
    mode: 'all',
  });

  const existingTechnicalLabelTrackNames = technicalLabelTracks.map((field) => field.name);

  const handleAddTechnicalLabelTrack = (newTechnicalLabelTrack) => {
    return addTechnicalLabelTrack(newTechnicalLabelTrack)
      .then(() => {
        onAlert({ severity: 'success', message: 'Technical label track added' });
        reset(TECHNICAL_LABEL_TRACK_GROUP);
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Technical label track could not be added' });
      });
  };

  const handleUpdateTechnicalLabelTrack = (editedTechnicalLabelTrack) => {
    return updateTechnicalLabelTrack({ technicalLabelTrack, editedTechnicalLabelTrack })
      .then(() => {
        onAlert({ severity: 'success', message: 'Technical label track updated' });
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Technical label track could not be updated' });
      });
  };

  const submit = (submittedTrack) => {
    const namePrefixedTrack = {
      ...submittedTrack,
      name: SONOVA_TECHNICAL_LABEL_TRACK_PREFIX + submittedTrack.name,
    };
    return isEditing
      ? handleUpdateTechnicalLabelTrack(namePrefixedTrack)
      : handleAddTechnicalLabelTrack(namePrefixedTrack);
  };

  React.useEffect(() => {
    reset(
      technicalLabelTrack
        ? {
            name: technicalLabelTrack.name.replace(SONOVA_TECHNICAL_LABEL_TRACK_PREFIX, ''),
            label: technicalLabelTrack.label,
            isActive: technicalLabelTrack.isActive,
          }
        : TECHNICAL_LABEL_TRACK_GROUP,
    );
  }, [reset, technicalLabelTrack]);

  return (
    <>
      <div className={classes.header}>
        <SectionHeader
          Icon={isEditing ? EditIcon : AddIcon}
          title={isEditing ? 'Edit Technical Label Track' : 'New Technical Label Track'}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <TechnicalLabelTrackRemoval selectedTrackName={selectedTrackName} />
      </div>
      <form onSubmit={handleSubmit(submit)} className={classes.root}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={
            !isEditing && {
              required: 'Required',
              validate: {
                alreadyUsed: (v = '') => {
                  const errorMessage = 'Track Field Name is already used';
                  return existingTechnicalLabelTrackNames.includes(
                    SONOVA_TECHNICAL_LABEL_TRACK_PREFIX + v.trim(),
                  )
                    ? errorMessage
                    : undefined;
                },
              },
            }
          }
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              disabled={isEditing}
              label="Track Field Name"
              name="name"
              InputProps={{
                startAdornment: SONOVA_TECHNICAL_LABEL_TRACK_PREFIX && (
                  <InputAdornment className={classes.fieldNamePrefix} position="start">
                    {SONOVA_TECHNICAL_LABEL_TRACK_PREFIX}
                  </InputAdornment>
                ),
              }}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="label"
          control={control}
          defaultValue=""
          rules={{ required: 'Required' }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Track Label"
              error={!!errors.label}
              helperText={errors.label && errors.label.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          {isEditing && <Button onClick={onCancel}>cancel</Button>}
          <Button
            disableElevation
            disabled={
              !formState.isValid ||
              isEmpty(formState.dirtyFields) ||
              isLoadingAdd ||
              isLoadingUpdate ||
              isFetching
            }
            variant="contained"
            color="primary"
            type="submit"
          >
            {isEditing ? 'Save' : 'Add Technical Label Track'}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default withStyles(styles)(TechnicalLabelTrackEditor);
