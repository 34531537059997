import { TextField, withStyles } from '@material-ui/core';
import React from 'react';

const styles = () => ({});

function NumberField({ onChange, error: errorProp, ...props }) {
  const [error, setError] = React.useState(null);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <TextField
      onChange={(e) => onChange(e.target.value, e)}
      onKeyPress={(e) => {
        if (!/[0-9.-]/.test(e.key)) {
          e.preventDefault();
          if (e.target.value === '') {
            setError('Please enter a number');
          }
        } else {
          setError(null);
        }
      }}
      error={errorProp || !!error}
      helperText={error}
      type="text"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default withStyles(styles)(NumberField);
