import Cookies from 'universal-cookie';
import { AV_URL, AV_TOKEN_COOKIE_NAME } from '../consts/app';

const getDeviceItemsIdsFromPackage = ({ interlocutors = [] } = {}) => {
  return interlocutors.flatMap((i) => i.devices.map(({ id }) => id));
};

// AV reads authentication token either from query parameter or from a cookie
// If AV_TOKEN_COOKIE_NAME is "NO_COOKIE" then use query parameter, else set the cookie.
// Note: we use a different cookie name because AV wants the cookie to not be url encoded
//       and our VIDISPINE-TOKEN cookie is url encoded
export default function launchAV(pkg) {
  const { labelItemId } = pkg;
  const itemIds = getDeviceItemsIdsFromPackage(pkg);
  const cookies = new Cookies();
  const token = cookies.get('VIDISPINE-TOKEN');
  let uri = `${AV_URL}?mainAssetId=${labelItemId}&assetId=${[labelItemId, ...itemIds].join(',')}`;
  if (AV_TOKEN_COOKIE_NAME !== 'NO_COOKIE') {
    // We need to set it manually as libraries such as universal-cookie
    // automatically encodes it
    document.cookie = `${AV_TOKEN_COOKIE_NAME}=${token};path=/`;
  } else {
    uri += `&u=${encodeURIComponent(token)}`;
  }
  window.open(uri);
}
