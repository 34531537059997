import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Typography,
  withStyles,
  DialogContent,
  DialogContentText,
  Tab,
  Tabs,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { pkg, auditlog, metadata, device, permissionGeq } from 'mediadb-lib';

import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { pickBy, sortBy } from 'lodash';

import {
  Assignment as MetadataIcon,
  History as HistoryIcon,
  // Share as ShareIcon,
  Hearing as DeviceIcon,
  Person as UserIcon,
  Today as CreatedIcon,
  LocationCity as LocationIcon,
  Timer as DurationIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
  Equalizer as EqualizerIcon,
  Edit as EditIcon,
  PermMedia as PackageGroupIcon,
  Label as LabelIcon,
  PeopleAlt as ChangeOwnerIcon,
} from '@material-ui/icons';

import { MovieEdit, PlaylistEdit, Download, CalendarEdit, FileDownload } from 'mdi-material-ui';
import { MimeTypeIcon } from '@vidispine/vdt-materialui';

import useConfirmationDialog from '../../contexts/ConfirmationDialogContext';
import {
  downloadCollectionAsZip,
  downloadUrl,
  downloadPackageInformation,
} from '../../utils/utils';

import PackageTree from './components/PackageTree';
import PackageFileList from './components/PackageFileList';
import StatusChip from './components/StatusChip';
import SectionHeader from '../../components/SectionHeader';
import Player from '../../components/Player';
import MetadataDialog from '../../dialogs/MetadataDialog';
import ChangePackageGroupDialog from '../../dialogs/ChangePackageGroupDialog';
import MetadataChangeLogDialog from '../../dialogs/MetadataChangeLogDialog';
import MetadataList from '../../components/MetadataList';
import MetadataGrid from '../../components/MetadataGrid';

import {
  SONOVA_PACKAGE_NAME,
  SONOVA_PACKAGE_DESCRIPTION,
  SONOVA_PACKAGE_LOCATION,
} from '../../consts/metadata';

import launchAV from '../../utils/launchAV';

import { useSnackbar } from '../../contexts/SnackbarContext';

import ChangeStatusButton from './components/ChangeStatusButton';
import useDeviceProxy from '../../hooks/useDeviceProxy';
import { useUpdateDeviceTemplate } from '../../hooks/device';
import DisabledOnClickIconButton from '../../components/DisabledOnClickIconButton';
import { useCustomMetadataGroup } from '../../hooks/metadata';
import MetadataFieldView from '../../components/MetadataFieldView';
import {
  usePkgFiles,
  usePkgMetadata,
  useUpdatePkgStatus,
  usePkgUserAccess,
  usePackageTechnicalLabelTracks,
} from '../../hooks/pkg';
import { AuthContext } from '../../vdt/AuthProvider';
import PackageAccess from './components/PackageAccess';
import PackageTechnicalView from './components/PackageTechnicalView';
import { useTechnicalLabelTracks } from '../../hooks/label';
import PageError from '../../components/PageError';
import ChangeOwnerDialog from '../../dialogs/ChangeOwnerDialog';

const { SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES } = device;
const { MEDIADB_EVENT } = auditlog;
const { PACKAGE_STATUSES } = pkg;

const styles = (theme) => ({
  root: {
    '& .packageHeader': {
      paddingTop: '0',
      marginBottom: theme.spacing(3),
    },
  },
  metadataTabsRoot: {},
  metadataTab: { minWidth: 'unset' },
  metadataTabsFlexContainer: {
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  packageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '& .packageHeader__title-status-container': {
      display: 'flex',
      alignItems: 'center',
      '& .packageHeader__title': {
        flexBasis: '16rem',
        flexGrow: 1,
        flexShrink: 0,
        '& > *': {
          marginRight: theme.spacing(1),
        },
      },
      '& .packageHeader__status': {
        marginLeft: theme.spacing(2),
        flexShrink: 0,
        '& > *': {
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '16rem minmax(400px, 1fr) 550px',
    gridGap: theme.spacing(2),
  },
  paper: {
    minHeight: `calc(100vh - 70px - 72px - ${theme.spacing(8)}px)`,
    maxHeight: `calc(100vh - 70px - 72px - ${theme.spacing(8)}px)`,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  tabPaper: {
    padding: 0,
  },
  tabContent: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    overflow: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
    '& .MuiTypography-root': {
      marginTop: theme.spacing(2),
    },
  },
});
const getStatusClass = (status) => {
  switch (status) {
    case PACKAGE_STATUSES.IN_LABELING:
      return 'progress1';
    case PACKAGE_STATUSES.LABELED:
      return 'progress2';
    case PACKAGE_STATUSES.APPROVED:
      return 'success';
    case PACKAGE_STATUSES.CANCELLED:
    case PACKAGE_STATUSES.REJECTED:
    case PACKAGE_STATUSES.ERROR:
      return 'error';
    default:
      return '';
  }
};

const FilePreview = ({ file }) =>
  file ? (
    <>
      <SectionHeader
        Icon={({ className }) => <MimeTypeIcon className={className} mimeType={file.mimeType} />}
        title={file.filename}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      <Player source={{ src: file.url, type: file.mimeType }} />
    </>
  ) : null;

const DevicePreview = ({
  device: previewDevice,
  packageId,
  userPermission,
  onSuccess,
  onError,
}) => {
  const { data: customDeviceDisplayFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.DEVICE.name,
    {
      select: (fields) => fields.filter(({ isActiveInBrowse }) => isActiveInBrowse),
    },
  );
  const { data: deviceProxy } = useDeviceProxy(previewDevice);
  const { customMetadata: customMetadataGroup = {} } = previewDevice || {};
  const { uuid: metadataGroupUuid, ...customMetadata } = customMetadataGroup;

  return previewDevice ? (
    <>
      <SectionHeader
        Icon={DeviceIcon}
        title={`${previewDevice.deviceTemplate.name} ${
          previewDevice.position ? `- ${previewDevice.position}` : ''
        }`}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      {deviceProxy && <Player source={{ src: deviceProxy.uri, type: deviceProxy.mimeType }} />}
      <MetadataGrid
        metadataFields={customDeviceDisplayFields}
        metadata={customMetadata}
        metadataGroupUuid={metadataGroupUuid}
        packageId={packageId}
        editable={permissionGeq(userPermission, 'WRITE')}
        onSuccess={onSuccess}
        onError={onError}
      />
    </>
  ) : null;
};

const PersonPreview = ({
  person: previewPerson,
  packageId,
  userPermission,
  onSuccess,
  onError,
}) => {
  const { data: customPersonDisplayFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.PERSON.name,
    {
      select: (fields) => fields.filter(({ isActiveInBrowse }) => isActiveInBrowse),
    },
  );
  const { customMetadata: customMetadataGroup = {} } = previewPerson || {};
  const { uuid: metadataGroupUuid, ...customMetadata } = customMetadataGroup;

  return previewPerson ? (
    <>
      <SectionHeader
        Icon={UserIcon}
        title={`${previewPerson.name}`}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      <MetadataGrid
        metadataFields={customPersonDisplayFields}
        metadata={customMetadata}
        metadataGroupUuid={metadataGroupUuid}
        packageId={packageId}
        editable={permissionGeq(userPermission, 'WRITE')}
        onSuccess={onSuccess}
        onError={onError}
      />
    </>
  ) : null;
};

const SensorPreview = ({ sensor, packageId, userPermission, onSuccess, onError }) => {
  const { data: customSensorDisplayFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.SENSOR.name,
    {
      select: (fields) => fields.filter(({ isActiveInBrowse }) => isActiveInBrowse),
    },
  );
  const { customMetadata: customMetadataGroup = {} } = sensor || {};
  const { uuid: metadataGroupUuid, ...customMetadata } = customMetadataGroup;

  return sensor ? (
    <>
      <SectionHeader
        Icon={DeviceIcon}
        title={`${sensor.sensorTemplate.name} (${sensor.sensorTemplate.type}) ${
          sensor.position ? `- ${sensor.position}` : ''
        }`}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      <MetadataGrid
        metadataFields={customSensorDisplayFields}
        metadata={customMetadata}
        metadataGroupUuid={metadataGroupUuid}
        packageId={packageId}
        editable={permissionGeq(userPermission, 'WRITE')}
        onSuccess={onSuccess}
        onError={onError}
      />
    </>
  ) : null;
};

const TrackPreview = ({ track: previewTrack }) => {
  return previewTrack ? (
    <>
      <SectionHeader
        Icon={LabelIcon}
        title={`${previewTrack.label}`}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
    </>
  ) : null;
};

function PackageContent({
  classes,
  packageId,
  packageFiles,
  packageMetadata,
  isFetchingPackageMetadata,
  userPermission,
  technicalLabelTracks,
  packageTechnicalTracks,
  isAdmin,
}) {
  const { state: { prevPath } = {}, hash } = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [previewState, setPreviewState] = React.useState({
    selectedPerson: undefined,
    selectedDevice: undefined,
    selectedSensor: undefined,
    selectedFile: undefined,
    selectedTrack: undefined,
    currentPreview: undefined,
  });

  const {
    selectedDevice,
    selectedSensor,
    selectedFile,
    selectedPerson,
    selectedTrack,
    currentPreview,
  } = previewState;
  const [metadataDialogOpen, setMetadataDialogOpen] = React.useState(false);
  const [packageGroupDialogOpen, setPackageGroupDialogOpen] = React.useState(false);
  const [changeLogDialogOpen, setChangeLogDialogOpen] = React.useState(false);
  const [changeOwnerDialogOpen, setChangeOwnerDialogOpen] = React.useState(false);
  const { showAlert } = useSnackbar();

  const selectedMetadataTab = (hash || '#devices').slice(1);

  const { confirm } = useConfirmationDialog();

  const { mutateAsync: updatePackageStatus } = useUpdatePkgStatus();

  const history = useHistory();
  const { userName: currentUser } = React.useContext(AuthContext);

  const onEditMetadataSuccess = () => {
    showAlert({ severity: 'success', message: 'Metadata updated' });
    setMetadataDialogOpen(false);
  };

  const onEditMetadataError = () =>
    showAlert({ severity: 'error', message: 'Could not update metadata' });

  const onChangePackageGroupSuccess = () => {
    showAlert({ severity: 'success', message: 'Package group changed' });
    setPackageGroupDialogOpen(false);
  };

  const onChangePackageGroupError = () =>
    showAlert({ severity: 'error', message: 'Could not change package group' });

  const onChangeOwnerSuccess = () => {
    setChangeOwnerDialogOpen(false);
    showAlert({ severity: 'success', message: 'Package owner changed' });
  };

  const onChangeOwnerError = () =>
    showAlert({ severity: 'error', message: 'Could not change owner' });

  const downloadPackageFile = (file) => {
    if (file) {
      auditlog.addMediaDBLogEntry(
        MEDIADB_EVENT.DOWNLOAD('packageFile', { packageId, file: file.filename }),
      );
      downloadUrl(file.url, file.filename);
    } else {
      showAlert({ severity: 'error', message: 'Could not download package file' });
    }
  };

  const {
    labelItemId,
    owner,
    lastModified,
    lastModifiedBy,
    created,
    interlocutors,
    labeler,
    packageGroupName,
    name: packageName,
    description: packageDescription,
    location: packageLocation,
    duration: packageDuration,
    audioSamplingRate: packageAudioSamplingRate,
    status: packageStatus,
    preview: packagePreview,
    customMetadata: packageCustomMetadata,
    created: packageCreated,
  } = packageMetadata;

  const setSelectedFileByName = React.useCallback(
    (filename) => {
      const file = (packageFiles || []).find((f) => f.filename === filename);
      setPreviewState((prev) => ({
        ...prev,
        selectedFile: file,
        currentPreview: 'file',
      }));
    },
    [packageFiles],
  );

  // Set initial preview
  React.useEffect(() => {
    const allDevices = (interlocutors || []).flatMap((i) =>
      i.devices.map((dev) => ({ ...dev, personUuid: i.uuid })),
    );
    const previewDevice = allDevices.find((d) => d.id === packagePreview);
    const initialSelectedDevice = previewDevice || allDevices[0];

    switch (previewState.currentPreview) {
      case 'person': {
        const { uuid } = previewState.selectedPerson;
        const person = interlocutors.find((int) => int.uuid === uuid);
        setPreviewState((prev) => ({
          ...prev,
          selectedPerson: person,
        }));
        break;
      }
      case 'device': {
        const { uuid, personUuid } = previewState.selectedDevice;
        const person = interlocutors.find((int) => int.uuid === personUuid);
        const dev = { ...person.devices.find((d) => d.uuid === uuid), personUuid };
        setPreviewState((prev) => ({
          ...prev,
          selectedDevice: dev,
        }));
        break;
      }
      case 'sensor': {
        const { uuid, deviceUuid, personUuid } = previewState.selectedSensor;
        const person = interlocutors.find((int) => int.uuid === personUuid);
        const dev = person.devices.find((d) => d.uuid === deviceUuid);
        const sensor = {
          ...dev.sensors.find((s) => s.uuid === uuid),
          deviceUuid,
          personUuid,
        };
        setPreviewState((prev) => ({
          ...prev,
          selectedSensor: sensor,
        }));
        break;
      }
      default:
        if (initialSelectedDevice) {
          setPreviewState((prev) => ({
            ...prev,
            selectedDevice: initialSelectedDevice,
            currentPreview: 'device',
          }));
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagePreview, interlocutors]);
  const {
    data: customPackageFields = [],
    isFetching: isFetchingCustomPackageFields,
  } = useCustomMetadataGroup(metadata.CUSTOM_GROUPS.PACKAGE.name, {
    select: (fields) => fields.filter(({ isActiveInBrowse }) => isActiveInBrowse),
  });

  const packageAgeDays = (new Date() - new Date(packageCreated)) / (1000 * 60 * 60 * 24);
  const isProcessingAndNotOld =
    packageStatus === PACKAGE_STATUSES.PROCESSING && packageAgeDays < 1.0;

  const defaultFields = [
    {
      name: 'Description',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.String }}
          value={packageDescription}
        />
      ),
      icon: <DescriptionIcon />,
    },
    {
      name: 'Owner',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.String }}
          value={owner}
        />
      ),
      icon: <UserIcon />,
    },
    {
      name: 'Created',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.Date }}
          value={created}
        />
      ),
      icon: <CreatedIcon />,
    },
    {
      name: 'Modified by',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.String }}
          value={lastModifiedBy}
        />
      ),
      icon: <EditIcon />,
    },
    {
      name: 'Modified',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.Date }}
          value={lastModified}
        />
      ),
      icon: <CalendarEdit />,
    },
    {
      name: 'Location',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.String }}
          value={packageLocation}
        />
      ),
      icon: <LocationIcon />,
    },
    {
      name: 'Duration',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.Time }}
          value={packageDuration}
          format="seconds"
        />
      ),
      icon: <DurationIcon />,
    },
    {
      name: 'Audio Sampling Rate',
      value: (
        <MetadataFieldView
          field={{ mediaDBType: metadata.MEDIADB_FIELD_TYPES.String }}
          value={packageAudioSamplingRate ? `${packageAudioSamplingRate} Hz` : undefined}
        />
      ),
      icon: <EqualizerIcon />,
    },
  ];

  const customDisplayFields = sortBy(
    (customPackageFields || []).map((f) => ({
      name: f.fieldName,
      label: f.label,
      value: <MetadataFieldView field={f} value={packageCustomMetadata[f.fieldName]} />,
    })),
    'label',
  );

  const displayFields = defaultFields.concat(customDisplayFields);

  const deviceTemplatesPendingApproval = (interlocutors || [])
    .flatMap((i) => i.devices)
    .map((d) => d.deviceTemplate)
    .filter(
      (deviceTemplate) =>
        deviceTemplate.approvalStatus === SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.PENDING,
    );

  const { mutateAsync: updateDeviceTemplate } = useUpdateDeviceTemplate();

  const handleChangeStatus = (action) => {
    confirm({
      titleText: action.label,
      rejectText: 'Cancel',
      confirmText: 'Update Status',
      fullWidth: true,
      children: (
        <DialogContent>
          <DialogContentText>
            This action will change the status of <b>{packageName}</b> to <b>{action.newStatus}</b>
          </DialogContentText>
        </DialogContent>
      ),
    })
      .then(() => {
        updatePackageStatus({ packageId, status: action.newStatus, user: currentUser })
          .then(() => {
            const newDeviceTemplateApprovalStatus = {
              'Reject content': SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.REJECTED,
              'Approve content': SONOVA_DEVICE_TEMPLATE_APPROVAL_STATUSES.APPROVED,
            }[action.label];
            return (
              newDeviceTemplateApprovalStatus &&
              Promise.all(
                deviceTemplatesPendingApproval.map(({ uuid }) =>
                  updateDeviceTemplate({ uuid, approvalStatus: newDeviceTemplateApprovalStatus }),
                ),
              )
            );
          })
          .then(() => {
            showAlert({ severity: 'success', message: 'Package status updated' });
          })
          .catch(() => {
            showAlert({ severity: 'error', message: 'Could not update package status' });
          });
      })
      .catch(() => {});
  };

  const handleDeletePackage = () => {
    confirm({
      titleText: `Delete package`,
      rejectText: 'Cancel',
      confirmText: 'Delete',
      fullWidth: true,
      children: (
        <DialogContent>
          <DialogContentText>
            This action will permanently delete package: <b>{packageName}</b> and cannot be undone
          </DialogContentText>
        </DialogContent>
      ),
    })
      .then(() => {
        setIsLoading(true);
        pkg
          .deletePackage(packageId)
          .then(() => {
            history.push(prevPath || '/');
          })
          .then(() =>
            showAlert({ severity: 'success', message: `Succesfully deleted ${packageName}` }),
          )
          .catch((error) => {
            showAlert({ severity: 'error', message: error?.message || 'Error deleting package' });
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {});
  };

  const handleClickAction = (action) => {
    switch (action) {
      case 'editMetadata': {
        setMetadataDialogOpen(true);
        break;
      }
      case 'changePackageGroup': {
        setPackageGroupDialogOpen(true);
        break;
      }
      case 'changeLog': {
        setChangeLogDialogOpen(true);
        break;
      }
      case 'launchAV': {
        launchAV(packageMetadata);
        break;
      }

      case 'download': {
        auditlog.addMediaDBLogEntry(MEDIADB_EVENT.DOWNLOAD('package', { packageId }));
        downloadCollectionAsZip(packageId, `${packageName}.zip`);
        break;
      }
      case 'downloadJSON': {
        downloadPackageInformation(packageId, `${packageName}.json`);
        break;
      }
      case 'delete': {
        handleDeletePackage();
        break;
      }
      case 'changeOwner': {
        setChangeOwnerDialogOpen(true);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(action);
      }
    }
  };

  const editMetadata = {
    [SONOVA_PACKAGE_NAME]: packageName,
    [SONOVA_PACKAGE_LOCATION]: packageLocation,
    [SONOVA_PACKAGE_DESCRIPTION]: packageDescription,
    customMetadata: pickBy(packageCustomMetadata, (_, key) =>
      customPackageFields.some(({ fieldName }) => key === fieldName),
    ),
  };

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <div className={classes.packageHeader}>
        <div className="packageHeader__title-status-container">
          <div className="packageHeader__title">
            <Typography variant="h3">{packageName}</Typography>
            <Typography variant="body1" color="textSecondary">
              {packageGroupName}
            </Typography>
          </div>
          <div className="packageHeader__status">
            <StatusChip status={packageStatus} statusClass={getStatusClass(packageStatus)} />
            {permissionGeq(userPermission, 'WRITE') && (
              <ChangeStatusButton
                status={packageStatus}
                currentUser={currentUser}
                packageOwner={owner}
                packageLabeler={labeler}
                onChangeStatus={handleChangeStatus}
              />
            )}
          </div>
        </div>
        <div>
          <Tooltip title="Metadata change log">
            <IconButton onClick={() => handleClickAction('changeLog')}>
              <HistoryIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Package Information">
            <DisabledOnClickIconButton
              duration={2500}
              onClick={() => handleClickAction('downloadJSON')}
            >
              <FileDownload />
            </DisabledOnClickIconButton>
          </Tooltip>
          <Tooltip title="Download Package Files">
            <DisabledOnClickIconButton
              duration={2500}
              onClick={() => handleClickAction('download')}
            >
              <Download />
            </DisabledOnClickIconButton>
          </Tooltip>
          {permissionGeq(userPermission, 'WRITE') && (
            <>
              <Tooltip title="Edit Package Metadata">
                <span>
                  <IconButton
                    disabled={isFetchingPackageMetadata || isFetchingCustomPackageFields}
                    onClick={() => handleClickAction('editMetadata')}
                  >
                    <PlaylistEdit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Change Package Group">
                <span>
                  <IconButton onClick={() => handleClickAction('changePackageGroup')}>
                    <PackageGroupIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
          {isAdmin && (
            <Tooltip title="Change Owner">
              <span>
                <IconButton onClick={() => handleClickAction('changeOwner')}>
                  <ChangeOwnerIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {permissionGeq(userPermission, 'ALL') && (
            <Tooltip
              title={
                isProcessingAndNotOld
                  ? 'Packages with status "Processing" cannot be deleted until 24 hours after they were created'
                  : 'Delete Package'
              }
            >
              <span>
                <IconButton
                  disabled={isProcessingAndNotOld}
                  onClick={() => handleClickAction('delete')}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Launch in Accurate Player">
            <IconButton onClick={() => handleClickAction('launchAV')}>
              <MovieEdit />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Container className={classes.content} maxWidth={false} disableGutters>
        <Paper className={classes.paper} variant="outlined">
          <SectionHeader
            Icon={MetadataIcon}
            title="Metadata"
            titleTypographyProps={{
              variant: 'h4',
            }}
          />
          <MetadataList fields={displayFields} />
        </Paper>
        <Paper className={classes.paper} variant="outlined">
          {
            {
              file: <FilePreview file={selectedFile} />,
              device: (
                <DevicePreview
                  device={selectedDevice}
                  packageId={packageId}
                  userPermission={userPermission}
                  onSuccess={onEditMetadataSuccess}
                  onError={onEditMetadataError}
                />
              ),
              sensor: (
                <SensorPreview
                  sensor={selectedSensor}
                  packageId={packageId}
                  userPermission={userPermission}
                  onSuccess={onEditMetadataSuccess}
                  onError={onEditMetadataError}
                />
              ),
              person: (
                <PersonPreview
                  person={selectedPerson}
                  packageId={packageId}
                  userPermission={userPermission}
                  onSuccess={onEditMetadataSuccess}
                  onError={onEditMetadataError}
                />
              ),
              track: <TrackPreview track={selectedTrack} />,
            }[currentPreview]
          }
        </Paper>
        <Paper className={clsx(classes.paper, classes.tabPaper)} variant="outlined">
          <Tabs
            classes={{
              flexContainer: classes.metadataTabsFlexContainer,
              root: classes.metadataTabsRoot,
            }}
            value={selectedMetadataTab}
            onChange={(_, value) => history.push(`#${value}`)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              className={clsx(classes.metadataTab)}
              key="Device Tree"
              label="Devices"
              value="devices"
            />
            <Tab
              className={clsx(classes.metadataTab)}
              key="Technical Label Tracks"
              label="Technical"
              value="tech"
            />
            <Tab className={clsx(classes.metadataTab)} key="Files" label="Files" value="files" />
            <Tab className={clsx(classes.metadataTab)} key="Access" label="Access" value="access" />
          </Tabs>
          <div className={classes.tabContent}>
            {
              {
                devices: (
                  <PackageTree
                    interlocutors={interlocutors}
                    onChannelClick={(channel) => {
                      setSelectedFileByName(channel.filename);
                    }}
                    onDeviceClick={(d, { uuid: personUuid }) =>
                      setPreviewState((prev) => ({
                        ...prev,
                        selectedDevice: { ...d, personUuid },
                        currentPreview: 'device',
                      }))
                    }
                    onSensorClick={(sensor, { uuid: deviceUuid }, { uuid: personUuid }) => {
                      setPreviewState((prev) => ({
                        ...prev,
                        selectedSensor: { ...sensor, deviceUuid, personUuid },
                        currentPreview: 'sensor',
                      }));
                    }}
                    onPersonClick={(interlocutor) =>
                      setPreviewState((prev) => ({
                        ...prev,
                        selectedPerson: interlocutor,
                        currentPreview: 'person',
                      }))
                    }
                  />
                ),
                tech: (
                  <PackageTechnicalView
                    packageId={packageId}
                    userPermission={userPermission}
                    onClickTrack={(track) => {
                      setPreviewState((prev) => ({
                        ...prev,
                        selectedTrack: track,
                        currentPreview: 'track',
                      }));
                    }}
                    selectedTrack={selectedTrack}
                    technicalLabelTracks={technicalLabelTracks}
                    packageTechnicalTracks={packageTechnicalTracks}
                    interlocutors={interlocutors}
                  />
                ),
                files: (
                  <PackageFileList
                    onClick={(file) => {
                      setPreviewState((prev) => ({
                        ...prev,
                        selectedFile: file,
                        currentPreview: 'file',
                      }));
                    }}
                    onDownloadFile={downloadPackageFile}
                    selectedFile={selectedFile}
                    files={packageFiles}
                  />
                ),
                access: <PackageAccess packageId={packageId} userPermission={userPermission} />,
              }[selectedMetadataTab]
            }
          </div>
        </Paper>
      </Container>
      <MetadataDialog
        open={metadataDialogOpen}
        onClose={() => setMetadataDialogOpen(false)}
        onSuccess={onEditMetadataSuccess}
        onError={onEditMetadataError}
        packageId={packageId}
        defaultValues={editMetadata}
      />
      <ChangePackageGroupDialog
        open={packageGroupDialogOpen}
        onClose={() => setPackageGroupDialogOpen(false)}
        onSuccess={onChangePackageGroupSuccess}
        onError={onChangePackageGroupError}
        packageMetadata={packageMetadata}
      />
      <MetadataChangeLogDialog
        packageId={packageId}
        labelItemId={labelItemId}
        open={changeLogDialogOpen}
        onClose={() => setChangeLogDialogOpen(false)}
      />
      {changeOwnerDialogOpen && (
        <ChangeOwnerDialog
          open={changeOwnerDialogOpen}
          onClose={() => setChangeOwnerDialogOpen(false)}
          onSuccess={onChangeOwnerSuccess}
          onError={onChangeOwnerError}
          packageMetadata={packageMetadata}
        />
      )}
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
        <Typography>Deleting Package...</Typography>
      </Backdrop>
    </Container>
  );
}
const Package = ({ classes }) => {
  const { collectionId: packageId } = useParams();
  const {
    data: packageMetadata,
    status: metadataStatus,
    isFetching: isFetchingMetadata,
    error,
  } = usePkgMetadata({
    packageId,
  });

  const isNotAuthorized = error?.response?.status === 403;
  const { userName: currentUser, isAdmin } = React.useContext(AuthContext);

  const { data: packageFiles, status: filesStatus } = usePkgFiles({ packageId });
  const { data: userPermission } = usePkgUserAccess({ packageId, username: currentUser });

  const { data: technicalLabelTracks = [] } = useTechnicalLabelTracks();
  const { data: packageTechnicalInfos = [] } = usePackageTechnicalLabelTracks({ packageId });

  const packageTechnicalTracks = packageTechnicalInfos.map((trackInfo) => {
    return {
      ...technicalLabelTracks.find((track) => track.name === trackInfo.name),
      ...trackInfo,
    };
  });

  if (isNotAuthorized)
    return <PageError title="Not Authorized" subtitle="You're not authorized to view this page." />;

  const notFound = error?.response?.status === 404 || error?.status === 404;

  if (notFound)
    return (
      <PageError
        title="Package not found"
        subtitle="Looks like you're trying to reach a package that does not exist"
      />
    );

  if (metadataStatus !== 'success' || filesStatus !== 'success') return null;

  return (
    <PackageContent
      userPermission={userPermission}
      classes={classes}
      packageId={packageId}
      packageMetadata={packageMetadata}
      isFetchingPackageMetadata={isFetchingMetadata}
      packageFiles={packageFiles}
      technicalLabelTracks={technicalLabelTracks}
      packageTechnicalTracks={packageTechnicalTracks}
      isAdmin={isAdmin}
    />
  );
};

export default withStyles(styles)(Package);
