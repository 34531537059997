import React from 'react';

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import SectionHeader from '../SectionHeader';
import { useUpdateExtractBulk } from '../../hooks/playlist';

const styles = () => ({});

function ExtractTcDialog({
  onClose,
  onSuccess = () => {},
  onError = () => {},
  playlistId,
  extracts,
  open,
}) {
  const initialState = {
    comment: '',
  };
  const { control, handleSubmit } = useForm({
    defaultValues: initialState,
    mode: 'all',
  });
  const { mutateAsync: updateExtractBulk } = useUpdateExtractBulk();
  const submit = ({ comment }) => {
    const updatedExtracts = extracts.map(({ uuid }) => {
      return {
        uuid,
        comment,
      };
    });
    updateExtractBulk({
      playlistId,
      extracts: updatedExtracts,
    })
      .then(onSuccess)
      .catch(onError);
  };
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <SectionHeader
          title="Edit extracts"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Box mb={4} display="grid" gridAutoRows="min-content" gridGap={24} alignItems="start">
            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <TextField
                  multiline
                  label="Comment"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default withStyles(styles)(ExtractTcDialog);
