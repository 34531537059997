import { DateTime, Duration } from 'luxon';
import Cookies from 'universal-cookie';
import { formatTimeCodeText, formatSeconds } from '@vidispine/vdt-js';
import { pkg } from 'mediadb-lib';
import { ZIP_URL, FILE_DOWNLOAD_FROM_URL, FILE_DOWNLOAD_TO_URL } from '../consts/app';

export function updateWhere(arr, cond, value) {
  return arr.map((v, i) => (cond(v, i) ? value : v));
}

export function getDateString(value) {
  const formattedDate = DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
  return formattedDate;
}

export function getDateTimeString(value) {
  const formattedDate = DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
  return formattedDate;
}

export function sortArrayByProperty(array, property, { isTimecode = false } = {}) {
  if (array.length) {
    return array.sort((a, b) => {
      const aHasPropertyVal = a[property];
      const bHasPropertyVal = b[property];
      if (aHasPropertyVal && bHasPropertyVal) {
        const aValue = isTimecode
          ? String(formatTimeCodeText(a[property]).toSeconds())
          : a[property];
        const bValue = isTimecode
          ? String(formatTimeCodeText(b[property]).toSeconds())
          : b[property];
        return aValue.localeCompare(bValue);
      }
      // eslint-disable-next-line no-nested-ternary
      return aHasPropertyVal ? -1 : bHasPropertyVal ? 1 : 0;
    });
  }
  return array;
}
export function getFieldValue(arr, fieldName) {
  const currentField = arr.field.find((f) => f.name === fieldName);
  return currentField ? currentField.value[0].value : '';
}

export function getFieldValueFromGroup(arr, groupName, fieldName) {
  const currentGroup = arr.group.find((g) => g.name === groupName);
  return currentGroup ? getFieldValue(currentGroup, fieldName) : '';
}

export function downloadUrl(url, name) {
  const a = document.createElement('a');
  a.href = url;
  if (FILE_DOWNLOAD_FROM_URL && FILE_DOWNLOAD_TO_URL) {
    a.href = a.href.replace(FILE_DOWNLOAD_FROM_URL, FILE_DOWNLOAD_TO_URL);
  }
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadPackageInformation(packageId, filename) {
  Promise.all([pkg.getPackageMetadata(packageId), pkg.getPackageLabels({ id: packageId })]).then(
    ([metadata, labels]) => {
      const a = document.createElement('a');
      a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
        JSON.stringify({ ...metadata, labels }),
      )}`;
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  );
}

export function downloadPlaylistInfo(playlist, filename) {
  const { extracts = [], ...rest } = playlist;
  // omit interlocutors the same way it's done in the api
  const formattedPlaylist = {
    ...rest,
    extracts: extracts.map(
      ({ interlocutors, technicalLabelTracks, ...extractsRest }) => extractsRest,
    ),
  };

  const a = document.createElement('a');
  a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
    JSON.stringify({ playlist: formattedPlaylist }),
  )}`;
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadCollectionAsZip(collectionId, zipFilename) {
  pkg.getPackageFiles(collectionId).then((files) => {
    // Setup the input data
    // We base64 encode everything to avoid WAF blocking the request for various reasons,
    // like sending JSON in form-data, strings that contains possible SQL injection sequences, etc.
    const cookies = new Cookies();
    const data = {
      token: `token ${cookies.get('VIDISPINE-TOKEN')}`,
      files,
      zip_filename: zipFilename,
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString('base64');

    // We need to make a POST request _and_ make it so the browser initiates a download
    // xhr / fetch doesn't initiate a browser download, so this seems like the only way
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = ZIP_URL;

    const field = document.createElement('input');
    field.type = 'hidden';
    field.name = 'data';
    field.value = base64Data;
    form.appendChild(field);

    document.body.appendChild(form);
    form.submit();
  });
}

export function inferFileMediaType(file) {
  const mediaType = file.type && file.type.split('/')[0];
  return ['video', 'audio'].includes(mediaType) ? mediaType : 'binary';
}

export function calcNewTc(initial, paddingSec) {
  const paddingTc = formatSeconds(paddingSec, { denominator: 1, numerator: 1 });
  const updatedTimeCode = formatTimeCodeText(initial).add(paddingTc);
  return updatedTimeCode.toText();
}

export function formatExtracts(extracts) {
  return extracts.map((extract) => {
    const smpteIn = formatTimeCodeText(extract.in).toSmpte();
    const smpteOut = formatTimeCodeText(extract.out).toSmpte();
    return {
      smpteIn,
      smpteOut,
      smpteTimecode: `${smpteIn} - ${smpteOut}`,
      ...extract,
    };
  });
}

export const validateExtractTc = async (tc, ext) => {
  const { packageId } = ext;
  const { duration } = await pkg.getPackageMetadata(packageId);
  const tcSeconds = formatTimeCodeText(tc).toSeconds();
  if (tcSeconds < 0 || tcSeconds > Number(duration)) {
    return Promise.reject(new Error('Timecodes must be within package duration'));
  }
  return Promise.resolve();
};

export const validateInOutPosition = (tcIn, tcOut) => {
  const inSeconds = formatTimeCodeText(tcIn).toSeconds();
  const outSeconds = formatTimeCodeText(tcOut).toSeconds();
  if (inSeconds >= outSeconds)
    return Promise.reject(new Error('"Start" timecode must be before "End" timecode'));
  return Promise.resolve();
};

export const hhmmss = (seconds) => Duration.fromObject({ seconds }).toFormat('hh:mm:ss');

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
