import logoWhite from '../assets/Sonova_Logo_White.svg';
import logoBlue from '../assets/Sonova_Logo.svg';

export const APP_LOGO_WHITE_SRC = logoWhite;
export const APP_LOGO_BLUE_SRC = logoBlue;
export const LOGIN_EXPIRES_SECONDS = 1209600;

export const APP_TITLE = process.env.REACT_APP_TITLE;
export const THEME = process.env.REACT_APP_THEME;

// Injectable
export const VIDISPINE_URL = window.VIDISPINE_URL
  ? window.VIDISPINE_URL
  : process.env.REACT_APP_VIDISPINE_URL;
export const AV_URL = window.AV_URL ? window.AV_URL : process.env.REACT_APP_AV_URL;
export const AV_TOKEN_COOKIE_NAME = window.AV_TOKEN_COOKIE_NAME
  ? window.AV_TOKEN_COOKIE_NAME
  : process.env.REACT_APP_AV_TOKEN_COOKIE_NAME;
export const ZIP_URL = window.ZIP_URL ? window.ZIP_URL : process.env.REACT_APP_ZIP_URL;
export const FILE_DOWNLOAD_FROM_URL = window.FILE_DOWNLOAD_FROM_URL
  ? window.FILE_DOWNLOAD_FROM_URL
  : process.env.REACT_APP_FILE_DOWNLOAD_FROM_URL;
export const FILE_DOWNLOAD_TO_URL = window.FILE_DOWNLOAD_TO_URL
  ? window.FILE_DOWNLOAD_TO_URL
  : process.env.REACT_APP_FILE_DOWNLOAD_TO_URL;

export const ADMIN_GROUPS = ['_special_all', 'sonova_admin'];

document.title = process.env.REACT_APP_DOCUMENT_TITLE;
