import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveIcon from '@material-ui/icons/Remove';
import { Badge, Box, Button, TextField, Tooltip, withStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import MicIcon from '@material-ui/icons/Mic';
import VideoIcon from '@material-ui/icons/Videocam';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';

const NewPosition = ({ onSubmit, disabled }) => {
  const [position, setPosition] = React.useState('');
  const handleSubmit = (e) => {
    onSubmit(position);
    setPosition('');
    e.preventDefault();
  };
  return (
    <MenuItem disableRipple onClick={(e) => e.stopPropagation()}>
      <form>
        <TextField
          onKeyDown={(e) => e.stopPropagation()}
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment style={{ marginTop: '0px' }} variant="filled">
                <IconButton
                  type="submit"
                  size="small"
                  disabled={disabled(position)}
                  onClick={handleSubmit}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </MenuItem>
  );
};

const ChannelIcon = ({ mediaType }) => {
  return (
    {
      video: <VideoIcon />,
      audio: <MicIcon />,
      videoAndAudio: <MusicVideoIcon />,
    }[mediaType] || null
  );
};

const NewDeviceChannel = withStyles((theme) => ({
  grouped: {
    border: 'none',
    borderRadius: '50% !important',
  },
  iconWrapper: {
    height: '24px',
    width: '24px',
  },
  textField: {
    marginLeft: theme.spacing(1),
  },
  helperBadge: {
    transform: 'scale(0.7) translate(50%, -50%)',
  },
}))(({ onSubmit, disabled, classes }) => {
  const [channel, setChannel] = React.useState('');
  const [mediaType, setMediaType] = React.useState('audio');
  const newChannel = {
    name: channel,
    mediaType,
  };
  const handleSubmit = (e) => {
    onSubmit(newChannel);
    setChannel('');
    e.preventDefault();
  };
  return (
    <MenuItem disableRipple onClick={(e) => e.stopPropagation()}>
      <form>
        <ToggleButtonGroup
          exclusive
          value={mediaType}
          size="small"
          onChange={(_, val) => setMediaType(val)}
          classes={{ grouped: classes.grouped }}
        >
          <ToggleButton className={classes.buttons} value="video">
            <Tooltip title="Video">
              <span className={classes.iconWrapper}>
                <ChannelIcon mediaType="video" />
              </span>
            </Tooltip>
          </ToggleButton>
          <ToggleButton className={classes.buttons} value="audio">
            <Tooltip title="Audio">
              <span className={classes.iconWrapper}>
                <ChannelIcon mediaType="audio" />
              </span>
            </Tooltip>
          </ToggleButton>
          <ToggleButton className={classes.buttons} value="videoAndAudio">
            <Tooltip title="Video and audio">
              <span className={classes.iconWrapper}>
                <Badge
                  badgeContent={
                    <Tooltip
                      title="Devices containing this channel type cannot have more than one channel"
                      placement="top"
                      arrow
                    >
                      <span>?</span>
                    </Tooltip>
                  }
                  classes={{ badge: classes.helperBadge }}
                  color="primary"
                >
                  <ChannelIcon mediaType="videoAndAudio" />
                </Badge>
              </span>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <TextField
          className={classes.textField}
          onKeyDown={(e) => e.stopPropagation()}
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment style={{ marginTop: '0px' }} variant="filled">
                <IconButton
                  type="submit"
                  size="small"
                  onClick={handleSubmit}
                  color="primary"
                  disabled={!channel?.trim()?.length || disabled(newChannel)}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </MenuItem>
  );
});

const NewSensorChannel = ({ onSubmit, disabled }) => {
  const [channel, setChannel] = React.useState('');
  const handleSubmit = (e) => {
    onSubmit(channel);
    setChannel('');
    e.preventDefault();
  };
  return (
    <MenuItem disableRipple onClick={(e) => e.stopPropagation()}>
      <form>
        <TextField
          onKeyDown={(e) => e.stopPropagation()}
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment style={{ marginTop: '0px' }} variant="filled">
                <IconButton
                  type="submit"
                  size="small"
                  disabled={!channel?.trim()?.length || disabled(channel)}
                  onClick={handleSubmit}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </MenuItem>
  );
};

const RemovableOption = ({ onRemove, children }) => {
  const [showRemoveButton, setShowRemoveButton] = React.useState(false);

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove()
      .then(() => setShowRemoveButton(false))
      .catch(() => {});
  };
  return (
    <Box width={1} display="flex" justifyContent="space-between" alignItems="center">
      {children}
      {showRemoveButton ? (
        <Button size="small" onClick={handleRemove}>
          <Typography color="error">Remove</Typography>
        </Button>
      ) : (
        <IconButton
          size="small"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            setShowRemoveButton(true);
          }}
        >
          <RemoveIcon />
        </IconButton>
      )}
    </Box>
  );
};

export { NewPosition, NewDeviceChannel, NewSensorChannel, RemovableOption, ChannelIcon };
