import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import SourcesIcon from '@material-ui/icons/Equalizer';
import MicIcon from '@material-ui/icons/Mic';
import VideoIcon from '@material-ui/icons/Videocam';
import { withStyles, emphasize } from '@material-ui/core';
import { metadata } from 'mediadb-lib';
import DeviceComponent from './DeviceComponent';
import { updateWhere } from '../../../utils/utils';
import DeviceTemplateSelect from './DeviceTemplateSelect';
import { hasInvalidDevices } from '../uploadValidation';
import useCreateDevice from '../../../hooks/useCreateDevice';
import useCleanupEffect from '../../../hooks/useCleanupEffect';
import { useCustomMetadataGroup } from '../../../hooks/metadata';

const styles = (theme) => ({
  additionalSourcesContainer: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.05),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: '126px',
    borderRadius: '2px',
  },
  titleHeading: {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'min-content auto',
    gridGap: theme.spacing(1),
  },
});

const AddAdditionalSources = ({
  classes,
  uploadProps,
  onChange,
  onChangeUsedFiles,
  onChangeValid,
  additionalSources,
  usedFiles,
}) => {
  const { files } = uploadProps;
  const packageFiles = files.filter(({ metadata: fileMetadata }) => !fileMetadata.generic);
  const [localAdditionalSources, setLocalAdditionalSources] = React.useState(additionalSources);
  const { createDevice, canCreateDevice } = useCreateDevice();

  useCleanupEffect(() => {
    onChange(localAdditionalSources);
  }, [localAdditionalSources, onChange]);

  const handleDeleteUsedFiles = (deletedFiles) => {
    const newUsedFiles = [...usedFiles];
    deletedFiles.forEach((file) => {
      const indexToRemove = newUsedFiles.indexOf(file);
      newUsedFiles.splice(indexToRemove, 1);
    });
    onChangeUsedFiles(newUsedFiles);
  };

  const handleChangeFiles = (newChannel) => {
    const { value, prevValue } = newChannel;
    const newUsedFiles = [...usedFiles];
    const indexToRemove = usedFiles.indexOf(prevValue);
    if (indexToRemove !== -1) {
      newUsedFiles.splice(indexToRemove, 1);
    }
    newUsedFiles.push(value);
    onChangeUsedFiles(newUsedFiles);
  };

  const handleAddDevice = (deviceTemplate) => {
    const newDevice = createDevice(deviceTemplate);
    setLocalAdditionalSources((existing) => [newDevice, ...existing]);
  };
  const handleDeleteDevice = (device) => {
    const filenames = [...device.channels, ...device.sensors.flatMap((s) => s.channels)]
      .map((channel) => channel.value)
      .filter((filename) => filename !== undefined);
    if (filenames.length) handleDeleteUsedFiles(filenames);
    const newAdditionalSources = localAdditionalSources.filter((d) => d.id !== device.id);
    setLocalAdditionalSources(newAdditionalSources);
  };

  const handleChangeDevice = (newDevice) => {
    setLocalAdditionalSources(
      updateWhere(localAdditionalSources, (d) => d.id === newDevice.id, newDevice),
    );
  };

  const { data: customDeviceFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.DEVICE.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );

  const { data: customSensorFields = [] } = useCustomMetadataGroup(
    metadata.CUSTOM_GROUPS.SENSOR.name,
    {
      select: (fields) => fields.filter(({ isActiveInUpload }) => isActiveInUpload),
    },
  );

  const isValid = !hasInvalidDevices(
    localAdditionalSources,
    customDeviceFields,
    customSensorFields,
  );

  React.useEffect(() => {
    onChangeValid(isValid);
  }, [isValid, onChangeValid]);

  return (
    <Box className={classes.additionalSourcesContainer}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.titleHeading}>
          <Icon>
            <SourcesIcon />
          </Icon>
          <Typography variant="subtitle2">Additional Sources</Typography>
        </Box>
      </Box>

      <DeviceTemplateSelect
        onSubmit={handleAddDevice}
        category="source"
        disabled={!canCreateDevice}
      />
      {localAdditionalSources.length > 0 &&
        localAdditionalSources.map((device) => (
          <DeviceComponent
            device={device}
            key={device.id}
            files={packageFiles}
            usedFiles={usedFiles}
            deviceIcon={device.hasVideo ? <VideoIcon /> : <MicIcon />}
            onDelete={() => handleDeleteDevice(device)}
            onChange={handleChangeDevice}
            onChangeFiles={handleChangeFiles}
          />
        ))}
    </Box>
  );
};

export default withStyles(styles)(AddAdditionalSources);
