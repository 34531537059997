/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@material-ui/core/Box';
import { Button, FormControlLabel, Switch, TextField, withStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import SectionHeader from '../../../components/SectionHeader';

import {
  useLabelTrackGroups,
  useAddLabelTrackGroup,
  useUpdateLabelTrackGroup,
} from '../../../hooks/label';

const LABEL_TRACK_GROUP_TEMPLATE = {
  uuid: '',
  name: '',
  isActive: true,
  order: '',
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

function LabelTrackGroupEditor({
  classes,
  labelTrackGroupId,
  addText,
  onAlert = () => null,
  onCancel = () => null,
}) {
  const { data: labelTrackGroups = [], isFetching } = useLabelTrackGroups();
  const isEditing = !!labelTrackGroupId;
  const labelTrackGroup = React.useMemo(() => {
    return labelTrackGroups.length > 0
      ? labelTrackGroups.find((group) => group.uuid === labelTrackGroupId)
      : undefined;
  }, [labelTrackGroups, labelTrackGroupId]);

  const { mutateAsync: addLabelTrackGroup, isLoading: isLoadingAdd } = useAddLabelTrackGroup();
  const {
    mutateAsync: updateLabelTrackGroup,
    isLoading: isLoadingUpdate,
  } = useUpdateLabelTrackGroup();

  const { control, reset, handleSubmit, errors, formState } = useForm({
    defaultValues: LABEL_TRACK_GROUP_TEMPLATE,
    mode: 'all',
  });

  const existingLabelTrackGroupNames = labelTrackGroups.map((group) => group.name);

  const handleAddLabelTrackGroup = (newLabelTrackGroup) => {
    return addLabelTrackGroup(newLabelTrackGroup)
      .then(() => {
        onAlert({ severity: 'success', message: 'Label track group added' });
        reset(LABEL_TRACK_GROUP_TEMPLATE);
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Label track group could not be added' });
      });
  };
  const handleUpdateLabelTrackGroup = (editedLabelTrackGroup) => {
    return updateLabelTrackGroup(editedLabelTrackGroup)
      .then(() => {
        onAlert({ severity: 'success', message: 'Label track group updated' });
      })
      .catch(() => {
        onAlert({ severity: 'error', message: 'Label track group could not be updated' });
      });
  };

  const submit = (args) => {
    return isEditing ? handleUpdateLabelTrackGroup(args) : handleAddLabelTrackGroup(args);
  };

  React.useEffect(() => {
    reset(labelTrackGroup || LABEL_TRACK_GROUP_TEMPLATE);
  }, [reset, labelTrackGroup]);

  return (
    <>
      <SectionHeader
        Icon={isEditing ? EditIcon : AddIcon}
        title={isEditing ? 'Edit Label Track Group' : 'New Label Track Group'}
        titleTypographyProps={{
          variant: 'h4',
        }}
      />
      <form onSubmit={handleSubmit(submit)} className={classes.root}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: 'Required',
            validate: {
              alreadyUsed: (v = '') => {
                const errorMessage = 'Name is already used';
                if (isEditing) {
                  if (
                    v.trim() !== labelTrackGroup.name &&
                    existingLabelTrackGroupNames.includes(v.trim())
                  ) {
                    return errorMessage;
                  }
                } else {
                  return existingLabelTrackGroupNames.includes(v.trim()) ? errorMessage : undefined;
                }
                return undefined;
              },
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Name"
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
        <Controller
          name="order"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <input type="hidden" onChange={onChange} value={value} />
          )}
        />
        <Controller
          name="uuid"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <input type="hidden" onChange={onChange} value={value} />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          {isEditing && <Button onClick={onCancel}>cancel</Button>}
          <Button
            disableElevation
            disabled={
              !formState.isValid ||
              isEmpty(formState.dirtyFields) ||
              isLoadingAdd ||
              isLoadingUpdate ||
              isFetching
            }
            variant="contained"
            color="primary"
            type="submit"
          >
            {isEditing ? 'Save' : 'Add Label Track Group'}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default withStyles(styles)(LabelTrackGroupEditor);
