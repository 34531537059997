import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import DataTableWithDrop from '../../../components/DataTableWithDrop';
import TableRowWithDrag from '../../../components/TableRowWithDrag';
import { useLabelTrackGroups, useUpdateLabelTracksOrder } from '../../../hooks/label';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& th': {
      position: 'sticky',
      top: '64px',
      backgroundColor: theme.palette.background.paper,
    },
  },
  select: {
    paddingBottom: theme.spacing(2),
    position: 'sticky',
    top: '0',
    backgroundColor: theme.palette.background.paper,
  },
});

const TABLE_COLUMNS = [
  {
    name: 'Label track',
    key: 'name',
  },
  {
    name: 'Required',
    key: 'isRequired',
  },
  {
    name: 'Active',
    key: 'isActive',
  },
];

const getDraggableRowProps = (row, index) => {
  return {
    dragInfo: {
      entity: row,
      entityType: 'labelTrack',
      entityId: row.uuid,
      order: row.order,
    },
    id: row.uuid,
    index,
    wrapDragInDiv: false,
    acceptedEntityTypes: ['labelTrack'],
  };
};

const LabelTrackTable = ({
  classes,
  selected,
  selectedLabelTrackGroupIndex,
  onClickRow,
  onLabelTrackGroupChange,
  onAlert,
}) => {
  const { data: labelTrackGroups = [] } = useLabelTrackGroups();
  const [localLabelTracks, setLocalLabelTracks] = React.useState([]);
  const selectedIndex = localLabelTracks.findIndex((labelTrack) => labelTrack.uuid === selected);
  const [disableSort, setDisableSort] = React.useState(false);

  const { mutateAsync: updateLabelTracksOrder } = useUpdateLabelTracksOrder();

  React.useEffect(() => {
    if (labelTrackGroups.length > 0) {
      setLocalLabelTracks(labelTrackGroups[selectedLabelTrackGroupIndex].tracks);
    }
  }, [labelTrackGroups, selectedLabelTrackGroupIndex]);

  const resetLocalLabelTracks = () => {
    setLocalLabelTracks(labelTrackGroups[selectedLabelTrackGroupIndex].tracks);
  };

  const onDrop = () => {
    if (disableSort) return;
    const labelTracksToUpdate = [];
    localLabelTracks.forEach(({ uuid }, index) => {
      const originalLabelTrack = labelTrackGroups[selectedLabelTrackGroupIndex].tracks.find(
        (track) => track.uuid === uuid,
      );
      if (originalLabelTrack.order !== index) {
        labelTracksToUpdate.push({ uuid, order: index });
      }
    });
    if (labelTracksToUpdate.length > 0) {
      setDisableSort(true);
      updateLabelTracksOrder({
        labelTracks: labelTracksToUpdate,
        labelTrackGroupUuid: labelTrackGroups[selectedLabelTrackGroupIndex].uuid,
      })
        .catch(() => {
          onAlert({ severity: 'error', message: 'Failed to update order' });
          resetLocalLabelTracks();
        })
        .finally(() => {
          setDisableSort(false);
        });
    }
  };

  const onSort = ({ dragId, hoverId, originalDragIndex }) => {
    if (dragId === undefined || (hoverId === undefined && originalDragIndex === undefined)) return;
    // Reset order on cancel dnd
    if (originalDragIndex !== undefined) {
      resetLocalLabelTracks();
      return;
    }
    setLocalLabelTracks((state) => {
      const newState = [...state];
      const dragIndex = localLabelTracks.findIndex((label) => label.uuid === dragId);
      const hoverIndex = localLabelTracks.findIndex((label) => label.uuid === hoverId);
      const newDragIndex = hoverIndex > -1 ? hoverIndex : originalDragIndex;
      [newState[newDragIndex], newState[dragIndex]] = [newState[dragIndex], newState[newDragIndex]];
      return newState;
    });
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.select}>
        <InputLabel>Label track group</InputLabel>
        <Select
          variant="standard"
          value={selectedLabelTrackGroupIndex}
          onChange={onLabelTrackGroupChange}
        >
          {labelTrackGroups.map(({ name }, index) => (
            <MenuItem key={name} value={index}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DataTableWithDrop
        draggable
        getDraggableRowProps={getDraggableRowProps}
        onDrop={onDrop}
        columns={TABLE_COLUMNS}
        rows={localLabelTracks}
        onClickRow={onClickRow}
        selectedRowIndices={[selectedIndex]}
        RowComponent={TableRowWithDrag}
        RowProps={{
          onSort: disableSort ? undefined : onSort,
        }}
        dropInfo={{ entityType: 'labelTrack' }}
        acceptedEntityTypes={['labelTrack']}
      />
    </div>
  );
};

export default withStyles(styles)(LabelTrackTable);
