export const ACCURATE_VIDEO_GROUPS = {
  TRACK: 'AvTrack',
  MARKER: 'AvMarker',
};

export const ACCURATE_VIDEO_TRACK_FIELDS = {
  NAME: 'av_track_name',
  TYPE: 'av_track_type',
  TYPES: 'av_track_types',
  COLOUR: 'av_track_colour',
  REQUIRED: 'av_track_required',
  PARENT_ID: 'av_parent_track_id',
  ORDER: 'av_track_order',
  DELETED: 'av_track_deleted',
  INACTIVE_TYPES: 'av_track_inactive_types',
};

export const ACCURATE_VIDEO_TRACK_GROUP_FIELDS = {
  NAME: 'av_track_name',
  TYPE: 'av_track_type',
  ORDER: 'av_track_order',
  DELETED: 'av_track_deleted',
};

export const INTERLOCUTOR_TRACK_GROUP_TYPE = 'interlocutor';

export const SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT =
  'sonova_labelTrackGroupTemplatesDocument';
export const SONOVA_LABEL_TRACK_GROUPS = 'sonova_labelTrackGroups';
export const SONOVA_LABEL_TRACK_GROUP = 'sonova_labelTrackGroup';
export const SONOVA_LABEL_TRACK = 'sonova_labelTrack';

export const SONOVA_TRIGGER_UPDATE_ALL_AV_MARKER_RELATIONS =
  'sonova_trigger_update_all_av_marker_relations';

export const SONOVA_TECHNICAL_LABEL_TRACK_PREFIX = 'sonova_technicalLabelTrack_';
export const SONOVA_TECHNICAL_LABEL_TRACKS = 'sonova_technicalLabelTracks';
export const SONOVA_PACKAGE_TECHNICAL_LABEL_TRACKS = 'sonova_packageTechnicalLabelTracks';
export const SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK = 'sonova_packageTechnicalLabelTrack';
export const SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_NAME = 'sonova_packageTechnicalLabelTrackName';
export const SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_STATUS =
  'sonova_packageTechnicalLabelTrackStatus';
export const SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_ITEM_ID =
  'sonova_packageTechnicalLabelTrackItemId';
export const SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_CHANNEL_UUID =
  'sonova_packageTechnicalLabelTrackChannelUuid';

export default {
  ACCURATE_VIDEO_GROUPS,
  ACCURATE_VIDEO_TRACK_FIELDS,
  ACCURATE_VIDEO_TRACK_GROUP_FIELDS,
  INTERLOCUTOR_TRACK_GROUP_TYPE,
  SONOVA_LABEL_TRACK_GROUP_TEMPLATES_DOCUMENT,
  SONOVA_LABEL_TRACK_GROUPS,
  SONOVA_LABEL_TRACK_GROUP,
  SONOVA_LABEL_TRACK,
};
