/* eslint react/prop-types: "off" */
/* eslint import/no-extraneous-dependencies: "off" */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { LoginForm } from '@vidispine/vdt-materialui';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import AppTitle from '../../components/AppTitle';

const styles = (theme) => ({
  root: {
    '& form': {
      margin: 'auto',
      '& .MuiButton-root:hover': {
        backgroundColor: 'unset',
      },
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
  gridContainer: {
    height: '100%',
    '& .errorContainer': {
      paddingTop: theme.spacing(1),
      flexBasis: '56px',
    },
    '& .MuiGrid-item': {
      maxWidth: '480px',
      width: '100%',
    },
  },
  appTitle: {
    color: '#f09',
    maxWidth: 'fit-content',
  },
});

const Login = ({ classes, onLogin, userName, serverUrl, error }) => (
  <Box className={classes.root} p={2} height="100vh" width="100vw">
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={classes.gridContainer}
    >
      <Grid item>
        <AppTitle className={classes.appTitle} logoColor="blue" />
        <LoginForm
          className={classes.loginForm}
          onSubmit={onLogin}
          FormProps={{
            initialValues: { userName, serverUrl },
          }}
          UrlFieldComponent={null}
          RememberMeFieldComponent={null}
        />
      </Grid>
      <Grid item className="errorContainer">
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
    </Grid>
  </Box>
);

export default withStyles(styles)(Login);
