import { useQuery, useMutation, useQueryClient } from 'react-query';
import { playlist } from 'mediadb-lib';

export function useSearchPlaylists() {
  return useQuery(['searchPlaylists'], () => playlist.searchPlaylists());
}

export function usePlaylist(playlistId) {
  return useQuery(['getPlaylist', playlistId], () => playlist.getPlaylist(playlistId), {
    enabled: !!playlistId,
  });
}

export function useDeletePlaylist() {
  const queryClient = useQueryClient();
  return useMutation(playlist.deletePlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries('searchPlaylists');
    },
  });
}
export function useTogglePublicPlaylist() {
  const queryClient = useQueryClient();
  return useMutation(playlist.togglePublicPlaylist, {
    // optimistic updates because the search index is slow to update
    onMutate: async ({ playlistId, publish }) => {
      await queryClient.cancelQueries(['getPlaylist', playlistId]);
      await queryClient.cancelQueries(['searchPlaylists']);
      const prevGetData = queryClient.getQueryData(['getPlaylist', playlistId]);
      const prevSearchData = queryClient.getQueryData(['searchPlaylists']);
      queryClient.setQueryData(['getPlaylist', playlistId], (prev) => ({
        ...prev,
        isPublic: publish,
      }));
      queryClient.setQueryData(['searchPlaylists'], (prevPlaylists) =>
        prevPlaylists.map((prev) =>
          playlistId === prev.id
            ? {
                ...prev,
                isPublic: publish,
              }
            : prev,
        ),
      );
      return { prevGetData, prevSearchData };
    },
    onError: (err, { playlistId }, context) => {
      queryClient.setQueryData(['getPlaylist', playlistId], context.prevGetData);
      queryClient.setQueryData(['searchPlaylists'], context.prevSearchData);
    },
  });
}

export function useDeleteExportedPlaylist() {
  const queryClient = useQueryClient();
  return useMutation(playlist.deleteExportedPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries('searchExportedPlaylists');
    },
  });
}

export function useCreatePlaylist() {
  const queryClient = useQueryClient();
  return useMutation(playlist.createPlaylist, {
    onSuccess: ({ data: newPlaylist }) => {
      // update playlist search query data directly instead of refetching since the collection indexing takes some time.
      const currentPlaylists = queryClient.getQueryData('searchPlaylists') || [];
      const newPlaylists = [...currentPlaylists, newPlaylist];
      queryClient.setQueryData('searchPlaylists', newPlaylists);
    },
  });
}

export function useAddPlaylistExtracts() {
  const queryClient = useQueryClient();
  return useMutation(playlist.addPlaylistExtracts, {
    onSuccess: (_, { playlistId }) => {
      queryClient.invalidateQueries(['getPlaylist', playlistId]);
    },
  });
}

export function useRemovePlaylistExtracts() {
  const queryClient = useQueryClient();
  return useMutation(playlist.removePlaylistExtracts, {
    onSuccess: (_, { playlistId }) => {
      queryClient.invalidateQueries(['getPlaylist', playlistId]);
    },
  });
}

export function useUpdatePlaylistProperties() {
  const queryClient = useQueryClient();
  return useMutation(playlist.updatePlaylistProperties, {
    onSuccess: (_, { playlistId }) => {
      queryClient.invalidateQueries(['getPlaylist', playlistId]);
      queryClient.invalidateQueries('searchPlaylists');
    },
  });
}

export function useUpdateExtractProperties() {
  const queryClient = useQueryClient();
  return useMutation(playlist.updateExtractProperties, {
    onSuccess: (_, { playlistId }) => {
      queryClient.invalidateQueries(['getPlaylist', playlistId]);
    },
  });
}

export function useUpdateExtractBulk() {
  const queryClient = useQueryClient();
  return useMutation(playlist.updateExtractBulk, {
    onSuccess: (_, { playlistId }) => {
      queryClient.invalidateQueries(['getPlaylist', playlistId]);
    },
  });
}

export function useSearchExportedPlaylists(args, opts) {
  return useQuery(
    ['searchExportedPlaylists', args],
    () => playlist.searchExportedPlaylists(args),
    opts,
  );
}

export function useExportPlaylist() {
  const queryClient = useQueryClient();
  return useMutation(playlist.exportPlaylist, {
    onSuccess: () => {
      // collection doesn't appear in search if we invalidate immediately, so put an artificial delay
      setTimeout(() => queryClient.invalidateQueries('searchExportedPlaylists'), 1000);
    },
  });
}
