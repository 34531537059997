/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  CheckboxGroupField as VdtCheckboxGroupField,
  SwitchField as VdtSwitchField,
  TagField as VdtTagField,
  SliderField as VdtSliderField,
  SelectField as VdtSelectField,
  TextField as VdtTextField,
} from '@vidispine/vdt-materialui';
import { get as _get } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField as MuiTextField,
  withStyles,
} from '@material-ui/core';
import { parseISO } from 'date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import TimeField from '../../../components/metadatafields/Time';

function optionsFromFacet(facet, { includeCounts = false } = {}) {
  return (_get(facet, 'count') || [])
    .filter(({ value: facetCount }) => facetCount > 0)
    .map(({ fieldValue: label, value: facetCount }) => ({
      value: label,
      label: facetCount !== undefined && includeCounts ? `${label} (${facetCount})` : label,
    }));
}

function SwitchField({ value, onChange = () => null, ...props }) {
  return (
    <VdtSwitchField
      input={{ value, onChange: (e, v) => onChange(v), name: 'SwitchField' }}
      SwitchProps={{ color: 'primary' }}
      FormControlLabelProps={{ labelPlacement: 'end', style: { marginTop: '18px' } }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

function CheckboxGroupField({ value = [], onChange = () => null, ...props }) {
  return (
    <VdtCheckboxGroupField
      input={{ value, onChange, name: 'CheckboxGroupField' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

const useStyles = makeStyles({
  iconWithAdornment: {
    '& .MuiSelect-icon': {
      position: 'relative',
      marginLeft: '-22px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset',
    },
  },
});

const SelectField = ({ value, showClear = false, onChange = () => null, ...props }) => {
  const internalClasses = useStyles();
  return (
    <VdtSelectField
      input={{ value, onChange: (e) => onChange(e.target.value) }}
      className={showClear && value && internalClasses.iconWithAdornment}
      endAdornment={
        showClear &&
        value && (
          <InputAdornment style={{ marginLeft: 'unset' }} position="end">
            <IconButton onClick={() => onChange('')} style={{ fontSize: '1.25em', padding: '4px' }}>
              <ClearIcon style={{ fontSize: '1.25em' }} />
            </IconButton>
          </InputAdornment>
        )
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const TextField = ({ value, onChange = () => null, ...props }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <VdtTextField input={{ value, onChange: (e) => onChange(e.target.value) }} {...props} />;
};

function TagField({
  value = [],
  onChange = () => null,
  multiple = false,
  freeSolo = false,
  options,
  facet,
  ...props
}) {
  return (
    <VdtTagField
      options={facet ? optionsFromFacet(facet) : options}
      input={{ value, onChange }}
      AutocompleteProps={{
        multiple,
        freeSolo,
        renderOption: ({ labelWithFacetCount, label }) => labelWithFacetCount || label,
      }}
      size="small"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

function RangeSliderField({
  value: _value = [null, null],
  onChange: _onChange = () => null,
  // treats min and max as "infinity"
  infSteps = false,
  infStepShowValue = false,
  ...props
}) {
  // track changes locally until committed
  const [localState, setLocalState] = React.useState({
    committed: true,
    value: undefined,
  });
  const {
    valueLabelFormat,
    marks: hardcodedOptions,
    min: hardcodedMin,
    max: hardcodedMax,
    facet,
    ...restOfProps
  } = props;

  const facetValues = facet?.count?.map((f) => Number(f.fieldValue)) || [];
  const facetMin = facetValues.length ? Math.min(...facetValues) : undefined;
  const facetMax = facetValues.length ? Math.max(...facetValues) : undefined;

  const min = hardcodedMin !== undefined ? hardcodedMin : facetMin || NaN;
  const max = hardcodedMax !== undefined ? hardcodedMax : facetMax || NaN;

  const infStepsProps = (() =>
    infSteps
      ? {
          options: (() => {
            const newOptions = hardcodedOptions?.slice() || [];
            newOptions.splice(0, 0, { value: min, label: 'Min' });
            if (min !== max) {
              newOptions.splice(newOptions.length > 0 ? newOptions.length : 1, 0, {
                value: max,
                label: 'Max',
              });
            }
            return newOptions;
          })(),
          valueLabelFormat: (val) => {
            if (Number.isNaN(val)) return '#';
            if (val === min) return infStepShowValue ? min : 'Min';
            if (val === max) return infStepShowValue ? max : 'Max';
            return valueLabelFormat ? valueLabelFormat(val) : val.toString();
          },
        }
      : {
          options: (() => {
            if (hardcodedOptions) return hardcodedOptions;
            if (Number.isNaN(min) || Number.isNaN(max)) return [{ value: NaN, label: '#' }];
            return [
              { value: min, label: min },
              { value: max, label: max },
            ];
          })(),
        })();

  const value = _value &&
    !(Number.isNaN(min) || Number.isNaN(max)) && [
      _value[0] > min ? _value[0] || min : min,
      _value[1] < max ? _value[1] || max : max,
    ];

  const onChange = (val) => {
    const transformedVal = infSteps ? val.map((v) => (v === min || v === max ? null : v)) : val;
    _onChange(transformedVal);
  };

  return (
    <VdtSliderField
      isRange
      disabled={!value}
      valueLabelDisplay="auto"
      onChangeCommitted={(e, val) => {
        onChange(val);
        setLocalState({ value: val, committed: true });
      }}
      input={{
        value: localState.committed ? value : localState.value,
        onChange: (val) => setLocalState({ value: val }),
      }}
      FormControlProps={{
        style: { marginTop: '40px' },
      }}
      valueLabelFormat={valueLabelFormat}
      {...(hardcodedOptions ? { options: hardcodedOptions } : { step: 1, showMarks: false })}
      {...restOfProps}
      {...infStepsProps}
    />
  );
}

const DateRangeField = withStyles({
  root: {
    paddingTop: '16px',
    '& .react-datepicker-wrapper': {
      width: '100%',
      '& .react-datepicker__input-container': {
        width: '100%',
        '& .react-datepicker__close-icon': {},
      },
    },
  },
  input: {
    width: '100%',
  },
})(({ classes, label, onChange = () => null, value = [null, null] }) => {
  const [startDate, endDate] = value.map((v) => v && parseISO(v));
  return (
    <div className={classes.root}>
      <DatePicker
        selectsRange
        className={classes.input}
        startDate={startDate}
        endDate={endDate}
        onChange={(val) => onChange(val.map((v) => v && v.toISOString()))}
        customInput={<MuiTextField label={label} />}
        isClearable
      />
    </div>
  );
});

const TimeRangeField = withStyles({
  root: { paddingTop: '16px' },
  input: {
    width: '48%',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    marginBottom: 4,
  },
})(({ classes, label, onChange = () => null, value = [null, null] }) => {
  const [startTime, endTime] = value.map((v) => v && parseISO(v));
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div className={classes.inputContainer}>
        <div className={classes.input}>
          <TimeField
            label="From"
            value={startTime}
            onChange={(v) => {
              onChange([v && v.toISOString(), value[1]]);
            }}
          />
        </div>
        <div className={classes.input}>
          <TimeField
            label="To"
            value={endTime}
            onChange={(v) => {
              onChange([value[0], v && v.toISOString()]);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export {
  CheckboxGroupField,
  DateRangeField,
  TimeRangeField,
  TagField,
  RangeSliderField,
  SelectField,
  TextField,
  SwitchField,
};
