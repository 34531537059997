import { Chip, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import { isArray as _isArray } from 'lodash';

const styles = () => ({});

function MultipleStringField({ onChange = () => null, onBlur, value, ...props }) {
  if (!_isArray(value)) {
    // eslint-disable-next-line no-console
    console.error(`MultipleStringField: 'value' needs to be an array`);
  }

  const [error, setError] = React.useState(null);

  const inputRef = React.useRef();
  const handleChange = (newValue, e) => {
    onChange(newValue, e);
  };

  const isInputEmpty = () => inputRef.current.value === '';

  const handleAddValue = (event) => {
    if (isInputEmpty()) {
      setError('Value is empty');
      return;
    }
    if (value.includes(inputRef.current.value)) {
      setError('Value already exists');
      return;
    }
    setError(null);
    const newValue = [...value, inputRef.current.value];
    handleChange(newValue, event);
    inputRef.current.value = '';
  };

  return (
    <TextField
      inputRef={inputRef}
      error={!!error}
      helperText={error}
      onBlur={(e) => {
        if (!isInputEmpty()) {
          handleAddValue(e);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          event.preventDefault();
          handleAddValue(event);
        } else if (event.key === 'Backspace') {
          if (isInputEmpty()) {
            event.stopPropagation();
            event.preventDefault();
            const newValue = value.slice(0, value.length - 1);
            handleChange(newValue, event);
          }
        }
      }}
      InputProps={{
        startAdornment:
          value.length > 0
            ? value.map((v) => (
                <Chip
                  style={{ marginRight: '4px', marginBottom: '4px' }}
                  size="small"
                  key={v}
                  tabIndex={-1}
                  label={v}
                  onDelete={(e) => {
                    const newValue = value.filter((other) => v !== other);
                    handleChange(newValue, e);
                  }}
                />
              ))
            : undefined,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

function StringField({ multiple, onChange, ...props }) {
  return multiple ? (
    <MultipleStringField
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : (
    <TextField
      onChange={(e) => onChange(e.target.value, e)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default withStyles(styles)(StringField);
