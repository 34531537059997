/* eslint-disable react/jsx-props-no-spreading */
import { withStyles } from '@material-ui/core';
import React from 'react';
import { metadata } from 'mediadb-lib';
import StringField from './metadatafields/String';
import DateField from './metadatafields/Date';
import TimeField from './metadatafields/Time';
import EnumField from './metadatafields/Enum';
import NumberField from './metadatafields/Number';

const styles = () => ({});

const TYPES = metadata.MEDIADB_FIELD_TYPES;

function getDefaultValue({ mediaDBType, isMultiValue }) {
  switch (mediaDBType) {
    case TYPES.String:
    case TYPES.Number:
    case TYPES.Enum:
      return isMultiValue ? [] : '';
    default:
      return null;
  }
}

function MetadataFieldEdit({ field, value, onChange = () => null, ...props }) {
  const { isMultiValue = false, enumValues = [], label, required, mediaDBType } = field;
  const sharedProps = {
    value: value || getDefaultValue(field),
    required,
    label,
    onChange,
  };
  return {
    [TYPES.String]: <StringField multiple={isMultiValue} {...sharedProps} {...props} />,
    [TYPES.Enum]: (
      <EnumField {...sharedProps} multiple={isMultiValue} options={enumValues} {...props} />
    ),
    [TYPES.Date]: <DateField {...sharedProps} {...props} />,
    [TYPES.Time]: <TimeField {...sharedProps} {...props} />,
    [TYPES.Number]: <NumberField {...sharedProps} {...props} />,
  }[mediaDBType];
}

export default withStyles(styles)(MetadataFieldEdit);
