import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { MimeTypeIcon } from '@vidispine/vdt-materialui';
import { sortBy as _sortBy } from 'lodash';
import { Download } from 'mdi-material-ui';

const styles = (theme) => ({
  listItemContainer: {},
  listItemText: {
    overflow: 'hidden',
    marginRight: '24px',
  },
  fileIcon: {
    minWidth: theme.spacing(4),
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
      width: '0.7em',
      height: '0.7em',
    },
  },
});

function PackageFileList({ classes, files, onDownloadFile, onClick = () => null, selectedFile }) {
  return (
    <List>
      {files &&
        _sortBy(files, 'filename').map((file) => (
          <ListItem
            classes={{ container: classes.listItemContainer }}
            key={file.filename}
            selected={selectedFile && selectedFile.filename === file.filename}
            button
            disableRipple
            onClick={() => onClick(file)}
          >
            <ListItemIcon classes={{ root: classes.fileIcon }}>
              <MimeTypeIcon mimeType={file.mimeType} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primaryTypographyProps={{ variant: 'body2' }}
            >
              {file.filename}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton size="small" onClick={() => onDownloadFile(file)}>
                <Download />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}

export default withStyles(styles)(PackageFileList);
