/* eslint-disable no-use-before-define */
import { formatTimeCodeText } from '@vidispine/vdt-js';
import { job } from '@vidispine/vdt-api';
import {
  uniqBy as _uniqBy,
  isArray as _isArray,
  get as _get,
  mapValues as _mapValues,
  intersection,
} from 'lodash';

export const hasDuplicates = (arr, f) => _uniqBy(arr, f).length !== arr.length;

export const is404 = (error) => _get(error, 'response.status') === 404;

export const arrayToObject = (arr, keyProp, valueProp) =>
  (arr || []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr[keyProp]]: curr[valueProp],
    }),
    {},
  );

const nullAsEmptyString = (value) => (value === null ? '' : value);

export const metadataField = (name, value, opts = {}) => {
  const { mode } = opts;
  return {
    name,
    ...(mode && { mode }),
    value: _isArray(value)
      ? value.map((val) => ({ value: nullAsEmptyString(val) }))
      : [{ value: nullAsEmptyString(value) }],
  };
};

export const metadataFields = (nameToValueMap, opts) =>
  Object.entries(nameToValueMap).map(([name, value]) => metadataField(name, value, opts));

export const createMetadataDocument = ({ start = '-INF', end = '+INF', ...props }) => ({
  timespan: [
    {
      start,
      end,
      ...props,
    },
  ],
});

export const createTimespan = ({ start = '-INF', end = '+INF', ...props }) => ({
  start,
  end,
  ...props,
});

export const isValidTimeCode = (timeCodeText) => {
  try {
    return !!timeCodeText && !!formatTimeCodeText(timeCodeText);
  } catch (e) {
    return false;
  }
};

export const cartesian = (...arrays) =>
  arrays.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())));

export const parseGroupFields = (group) =>
  arrayToObject(
    _get(group, 'field', []).map(({ name, value, data }) => {
      const isMultiValue = bool(
        getValueFromData({ field: { data }, key: 'isMultiValue', defaultValue: false }),
      );
      return {
        name,
        value: isMultiValue
          ? value.map(({ value: innerValue } = {}) => innerValue)
          : _get(value, '0.value'),
      };
    }),
    'name',
    'value',
  );

function parseGroupMembers(properties, group) {
  return _mapValues(properties, (memberDef) => {
    return parseTimespanByDef(memberDef, group);
  });
}

function parseGroup(groupDef, group) {
  const { uuid, user, reference, properties, parseProperties } = groupDef;
  return {
    ...(uuid && { [uuid]: _get(group, 'uuid') }),
    ...(user && { [user]: _get(group, 'user') }),
    ...(reference && { [reference]: _get(group, 'referenced.uuid') }),
    ...(parseProperties && parseProperties(group)),
    ...parseGroupMembers(properties, group),
  };
}

function parseValue(fieldDef, value) {
  const { type = (val) => val, uuid } = fieldDef;
  const castValue = (_value) => type(_get(_value, 'value'));
  return uuid ? { [uuid]: _get(value, 'uuid'), value: castValue(value) } : castValue(value);
}

function parseValues(fieldDef, field) {
  const { list: isList } = fieldDef;
  return isList
    ? _get(field, 'value', []).map((value) => parseValue(fieldDef, value))
    : parseValue(fieldDef, _get(field, 'value.0'));
}

function parseField(fieldDef, fieldList) {
  const { field: fieldName, fieldUuid } = fieldDef;
  const matchingField = (fieldList || []).find((field) => field.name === fieldName);
  return fieldUuid
    ? { [fieldUuid]: _get(matchingField, 'uuid'), value: parseValues(fieldDef, matchingField) }
    : parseValues(fieldDef, matchingField);
}

function parseNestedGroups(groupDef, groupList) {
  const { group: nestedGroups } = groupDef;
  if (nestedGroups.length === 1) {
    return parseGroups(
      {
        ...groupDef,
        group: nestedGroups[0],
      },
      groupList,
    );
  }
  const newGroupList = _get(
    (groupList || []).find((g) => g.name === nestedGroups[0]),
    'group',
    [],
  );
  const newGroupDef = {
    ...groupDef,
    group: nestedGroups.slice(1),
  };
  return parseNestedGroups(newGroupDef, newGroupList);
}

function parseGroups(groupDef, groupList) {
  const { group: groupName, list: isList } = groupDef;
  if (_isArray(groupName)) {
    return parseNestedGroups(groupDef, groupList);
  }
  const validGroupList = groupList || [];
  return isList
    ? validGroupList
        .filter((group) => group.name === groupName)
        .map((group) => parseGroup(groupDef, group))
    : parseGroup(
        groupDef,
        validGroupList.find((group) => group.name === groupName),
      );
}

export function parseTimespanByDef(metadataDef, metadata) {
  if (!metadata) return undefined;

  if (metadataDef.group) {
    return parseGroups(metadataDef, metadata.group);
  }
  if (metadataDef.field) {
    return parseField(metadataDef, metadata.field);
  }
  return parseGroupMembers(metadataDef, metadata);
}

export function bool(value) {
  return value === true || value === 'true';
}

export function boolOpposite(value) {
  return !bool(value);
}

export function int(value) {
  return parseInt(value, 10);
}

export function Json(value) {
  return value && JSON.parse(value);
}

export const shortenUuid = (uuid) => (uuid || '').substr(0, 8);

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export const getValueFromData = ({ field = {}, key, defaultValue = undefined }) => {
  if (!key) return undefined;
  const { data = [] } = field;
  const { value = defaultValue } = data.find((d) => d.key === key) || {};
  return value;
};

export const objToArray = (obj, { key = 'key', value = 'value' } = {}) =>
  Object.entries(obj).map(([k, v]) => ({ [key]: k, [value]: v }));

// A date is valid if it's either a non-invalid Date, or a valid ISO string
export const isInvalidDate = (date) => {
  if (date instanceof Date) {
    return Number.isNaN(date.getTime());
  }
  const asDate = new Date(date);
  return Number.isNaN(asDate.getTime()) || asDate.toISOString() !== date;
};

export const isSubsetOf = (sub, sup) => {
  return intersection(sup, sub).length === sub.length;
};

export const sleep = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

export const pollJob = async (
  { jobId, pollInterval = 1000, firstPollDelay = 500, pollLimit = 5, onPoll = () => null },
  headers,
) => {
  const ERROR_STATES = ['FAILED_TOTAL', 'ABORTED'];
  const SUCCESSFUL_STATES = ['FINISHED', 'FINISHED_WARNING'];

  let pollCount = 0;
  await sleep(firstPollDelay);
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (pollCount >= pollLimit) {
      throw Error('Job timed out');
    }
    const {
      data: { data = [], status },
      // eslint-disable-next-line no-await-in-loop
    } = await job.getJob({
      jobId,
      queryParams: { metadata: true, field: 'returnValue,errorMessage' },
      headers,
    });
    onPoll({ data, status });
    pollCount += 1;
    if (SUCCESSFUL_STATES.includes(status)) {
      const ret = data.find(({ key }) => key === 'returnValue')?.value;
      return ret && JSON.parse(ret);
    }
    if (ERROR_STATES.includes(status)) {
      const errorMessage = data.find(({ key }) => key === 'errorMessage')?.value;
      throw Error(errorMessage || `Job failed with status ${status}`);
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(pollInterval);
  }
};

export const promisifyJob = ({
  jobType,
  priority = 'HIGHEST',
  pollInterval = 1000,
  firstPollDelay = 500,
  pollLimit = 5,
  defaultInput = {},
}) => {
  return async (input = {}, headers) => {
    const {
      data: { jobId },
    } = await job.createJob({
      simpleMetadataDocument: {},
      queryParams: {
        type: jobType,
        jobmetadata: [
          { key: 'customJobInput', value: JSON.stringify({ ...defaultInput, ...input }) },
        ],
        priority,
      },
      headers,
    });

    return pollJob({ jobId, firstPollDelay, pollLimit, pollInterval }, headers);
  };
};

export function permissionGeq(greater, than) {
  const PERMISSIONS = {
    NONE: 0,
    READ: 1,
    WRITE: 2,
    ALL: 3,
    OWNER: 4,
  };
  return PERMISSIONS[greater] >= PERMISSIONS[than];
}
