import { omit as _omit } from 'lodash';
import React from 'react';

const CHANGE_PAGE = 'CHANGE_PAGE';
const CHANGE_ROWS = 'CHANGE_ROWS';
const CHANGE_SORT = 'CHANGE_SORT';
const CHANGE_TEXT = 'CHANGE_TEXT';
const CHANGE_FILTER = 'CHANGE_FILTER';
const CHANGE_LABEL = 'CHANGE_LABEL';
const CLEAR_FILTERS = 'CLEAR_FILTERS';

const ACTION_TYPE = {
  CHANGE_PAGE,
  CHANGE_ROWS,
  CHANGE_SORT,
  CHANGE_TEXT,
  CHANGE_FILTER,
  CHANGE_LABEL,
  CLEAR_FILTERS,
};

const getDefaultInitialState = ({ label, sort, filter = [], facet = [], suggestion } = {}) => {
  const rowsPerPage = 10;
  const page = 0;
  return {
    first: page * rowsPerPage + 1,
    number: rowsPerPage,
    query: {
      label,
      sort,
      filter,
      suggestion,
      facet,
    },
    rowsPerPage,
    page,
  };
};

const queryReducer = (state, action) => {
  const resetPagination = {
    page: 0,
    first: 1,
  };
  switch (action.type) {
    case ACTION_TYPE.CHANGE_LABEL: {
      return {
        ...state,
        query: {
          ...state.query,
          label: action.label,
        },
      };
    }
    case ACTION_TYPE.CHANGE_SEARCHFILTER: {
      return {
        ...state,
        query: {
          ...state.query,
          filter: action.filter,
        },
        ...resetPagination,
      };
    }
    case ACTION_TYPE.CHANGE_PAGE: {
      return {
        ...state,
        page: action.page,
        first: action.page * state.rowsPerPage + 1,
      };
    }
    case ACTION_TYPE.CHANGE_ROWS: {
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0,
        first: 1,
        number: action.rowsPerPage,
      };
    }
    case ACTION_TYPE.CHANGE_SORT: {
      return {
        ...state,
        query: {
          ...state.query,
          sort: action.field
            ? [
                {
                  field: action.field,
                  order: action.order || 'descending',
                },
              ]
            : [],
        },
        ...resetPagination,
      };
    }
    case ACTION_TYPE.CHANGE_TEXT: {
      return {
        ...state,
        searchText: action.value,
        query: {
          ...state.query,
          text: action.value ? action.value : undefined,
        },
        ...resetPagination,
      };
    }
    case ACTION_TYPE.CHANGE_FILTER: {
      return {
        ...state,
        query: {
          ...state.query,
          filter: action.filter,
        },
        ...resetPagination,
      };
    }
    case ACTION_TYPE.CLEAR_FILTERS: {
      return {
        ...state,
        query: {
          ...state.query,
          filter: state.query.filter.map((f) => _omit(f, ['value', 'range'])),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default function useQuery(initialState) {
  const [state, dispatch] = React.useReducer(queryReducer, getDefaultInitialState(initialState));

  const onChangeRowsPerPage = ({ target: { value: newRows } } = {}) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_ROWS,
      rowsPerPage: newRows,
    });

  const onChangePage = ({ page: newPage }) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_PAGE,
      page: newPage,
    });

  const onChangeSort = (event, field, order) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_SORT,
      field,
      order,
    });

  const setText = React.useCallback(
    (value) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_TEXT,
        value,
      }),
    [],
  );

  const setLabel = React.useCallback(
    (label) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_LABEL,
        label,
      }),
    [],
  );

  const setFilter = React.useCallback(
    (filter) =>
      dispatch({
        type: ACTION_TYPE.CHANGE_SEARCHFILTER,
        filter,
      }),
    [],
  );

  const clearFilters = React.useCallback(() => dispatch({ type: ACTION_TYPE.CLEAR_FILTERS }), []);

  return {
    state,
    onChangeRowsPerPage,
    onChangePage,
    onChangeSort,
    setLabel,
    setText,
    setFilter,
    clearFilters,
  };
}
