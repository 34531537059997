import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // Might want later
    // top: '0',
    // position: 'sticky',
    // backgroundColor: theme.palette.background.paper,
    // zIndex: 10,
  },
  title: {
    display: 'inherit',
    alignItems: 'inherit',
    height: '48px',
  },
  subtitle: {
    marginLeft: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

const SectionHeader = ({
  classes,
  customClass,
  Icon,
  title,
  subtitle,
  Actions,
  actionProps,
  titleTypographyProps,
  subtitleTypographyProps,
}) => (
  <Box className={clsx(classes.root, customClass)}>
    <div className={classes.title}>
      {Icon && <Icon className={classes.icon} />}
      <Typography
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...titleTypographyProps}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          className={classes.subtitle}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...subtitleTypographyProps}
        >
          {subtitle}
        </Typography>
      )}
    </div>
    {Actions && (
      <Actions
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...actionProps}
      />
    )}
  </Box>
);

export default withStyles(styles)(SectionHeader);
