import React from 'react';
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { PlaylistEdit } from 'mdi-material-ui';
import SectionHeader from '../components/SectionHeader';
import PackageGroupSelect from '../components/PackageGroupSelect';
import { useChangePkgPackageGroup } from '../hooks/pkg';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

function ChangePackageGroupDialog({
  classes,
  open,
  onClose,
  packageMetadata,
  onSuccess = () => null,
  onError = () => null,
}) {
  const previousPackageGroupId = React.useMemo(() => {
    return packageMetadata.packageGroupId;
  }, [packageMetadata]);
  const initialPackageGroup = React.useMemo(() => {
    const {
      packageGroupId: id,
      packageGroupName: name,
      packageGroupDescription: description,
      packageGroupIsActive: isActive,
    } = packageMetadata;
    return id && name ? { id, name, description, isActive } : '';
  }, [packageMetadata]);
  const { mutateAsync: changePackageGroup, isLoading } = useChangePkgPackageGroup();
  const [packageGroup, setPackageGroup] = React.useState(initialPackageGroup);
  const handleChangePackageGroup = () => {
    const { packageGroupId: removeFromPackageGroupId, packageId } = packageMetadata;
    const { id: addToPackageGroupId } = packageGroup;
    changePackageGroup({ packageId, removeFromPackageGroupId, addToPackageGroupId })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch(() => {
        onError();
      });
  };
  return (
    <Dialog className={classes.root} maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <SectionHeader
          Icon={PlaylistEdit}
          title="Change Package Group"
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <PackageGroupSelect
          value={packageGroup}
          onChange={(value) => {
            setPackageGroup(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleChangePackageGroup}
          disabled={isLoading || previousPackageGroupId === packageGroup.id}
          variant="contained"
          disableElevation
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ChangePackageGroupDialog);
