import React from 'react';
import clsx from 'clsx';
import { withStyles, emphasize } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: 'inherit',
    borderColor: 'transparent',
    borderWidth: 0.7,
    borderStyle: 'solid',
    transition: 'border-color .2s ease-out',
    '&:hover': {
      '& .MuiIconButton-root': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardHeader-root': {
      backgroundColor:
        theme.palette.type === 'dark' ? emphasize(theme.palette.background.paper, 0.1) : undefined,
      color: theme.palette.type === 'dark' ? theme.palette.common.white : undefined,
    },
  },
  checked: {
    borderColor: theme.palette.secondary.main,
  },
});

function MediaCardFullWidthCard({
  className,
  classes,
  checked,
  handleHover,
  children,
  innerProps,
  ...CardProps
}) {
  return (
    <Card
      className={clsx(
        classes.root,
        {
          [classes.checked]: checked,
        },
        'VdtMediaCardFullWidthCard-root',
        className,
      )}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      raised={false}
      elevation={2}
      square
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...CardProps}
    >
      {children}
    </Card>
  );
}

export default withStyles(styles, { name: 'VdtMediaCardFullWidthCard' })(MediaCardFullWidthCard);
