import React from 'react';
import { ButtonGroup, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { device } from 'mediadb-lib';
import { AuthContext } from '../../../vdt/AuthProvider';
import { useApproveDeviceType, useRemoveDeviceType } from '../../../hooks/device';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import useConfirmationDialog from '../../../contexts/ConfirmationDialogContext';

const { SONOVA_DEVICE_TYPE_APPROVAL_STATUSES } = device;

const styles = (theme) => ({
  root: {},
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
});
const DeviceTypeApproval = ({ classes, deviceType = {} }) => {
  const { showAlert } = useSnackbar();
  const { userName } = React.useContext(AuthContext);
  const { mutateAsync: approveDeviceType } = useApproveDeviceType();
  const { mutate: removeDeviceType } = useRemoveDeviceType();
  const { approvalStatus, createdBy } = deviceType;

  const isAnotherUser = userName !== createdBy;

  const { confirm: confirmDelete } = useConfirmationDialog({
    throwOnReject: false,
  });

  const handleRemoveClick = async () => {
    if (
      await confirmDelete({
        children: (
          <DialogContent>
            <DialogContentText>Permanently delete selected device type?</DialogContentText>
          </DialogContent>
        ),
      })
    ) {
      try {
        await removeDeviceType(deviceType);
        showAlert({ severity: 'success', message: 'Succesfully deleted device type' });
      } catch (error) {
        showAlert({ severity: 'error', message: 'Failed to delete device type' });
      }
    }
  };

  return (
    <>
      {createdBy && approvalStatus !== SONOVA_DEVICE_TYPE_APPROVAL_STATUSES.APPROVED && (
        <ButtonGroup variant="text" className={classes.root}>
          {isAnotherUser && (
            <Button color="primary" onClick={() => approveDeviceType(deviceType)}>
              Approve
            </Button>
          )}
          <Button className={classes.deleteButton} onClick={handleRemoveClick}>
            Remove
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default withStyles(styles)(DeviceTypeApproval);
