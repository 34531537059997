import React from 'react';
import { Box, FormControlLabel, TextField, Button, Switch, withStyles } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { get as _get } from 'lodash';
import ChipList from '../../../components/ChipList';
import {
  useAddDeviceType,
  useDeviceTypeOptions,
  useAddPositionOption,
  useRemovePositionOption,
  useRemoveChannelOption,
  useAddChannelOption,
  useUpdateDeviceType,
  useDeviceTypes,
} from '../../../hooks/device';
import { useSensorTemplates } from '../../../hooks/sensor';
import SectionHeader from '../../../components/SectionHeader';
import DeviceTypeApproval from './DeviceTypeApproval';
import { AuthContext } from '../../../vdt/AuthProvider';

import { NewPosition, NewDeviceChannel, RemovableOption, ChannelIcon } from './EditorComponents';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

const renderChannel = ({ mediaType, name }) => {
  return (
    <Box display="flex" alignContent="center">
      <ChannelIcon mediaType={mediaType} />
      <Box ml={1}>{name}</Box>
    </Box>
  );
};

const DEFAULT_FORM_VALUES = { name: '', channels: [], positions: [], sensorTemplates: [] };
const DEFAULT_SELECTED_VALUES = { channels: '', position: '', sensorTemplate: '' };

function DeviceTypesEditor({ classes, category, deviceTypeId, onSuccess, onError, onCancel }) {
  const [selectedValues, setSelectedValues] = React.useState(DEFAULT_SELECTED_VALUES);

  const { data: deviceTypes = [] } = useDeviceTypes();
  const deviceType = deviceTypes.find((dt) => dt.uuid === deviceTypeId);
  const isEditing = !!deviceType;

  const { userName } = React.useContext(AuthContext);

  const formDeviceType = React.useMemo(
    () =>
      deviceType && {
        name: deviceType.name,
        channels: deviceType.channels,
        positions: _get(deviceType, 'positions', []).map((p) => ({ value: p })),
        isActive: deviceType.isActive,
        sensorTemplates: deviceType.sensorTemplates,
      },
    [deviceType],
  );

  const { data: { channelOptions = [], positionOptions = [] } = {} } = useDeviceTypeOptions();
  const { data: sensorTemplates = [] } = useSensorTemplates();
  const categorySensorTemplates = sensorTemplates.filter(
    (st) => st.category === category && st.isActive,
  );

  const {
    mutateAsync: updateDeviceType,
    isLoading: isLoadingUpdateDeviceType,
  } = useUpdateDeviceType();
  const { mutateAsync: addDeviceType, isLoading: isLoadingAddDeviceType } = useAddDeviceType();
  const { mutateAsync: addPositionOption } = useAddPositionOption();
  const { mutateAsync: removePositionOption } = useRemovePositionOption();
  const { mutateAsync: addChannelOption } = useAddChannelOption();
  const { mutateAsync: removeChannelOption } = useRemoveChannelOption();

  const { control, reset, handleSubmit, errors, formState, watch } = useForm({
    defaultValues: formDeviceType,
    mode: 'all',
  });

  const channels = watch('channels');
  const channelsContainsVideoWithAudio = !!channels?.some((ch) => ch.mediaType === 'videoAndAudio');

  const submit = (submittedFormDeviceType) => {
    const validDeviceType = {
      ...submittedFormDeviceType,
      positions: submittedFormDeviceType.positions.map(({ value }) => value),
    };
    const submitPromise = isEditing
      ? updateDeviceType({ ...validDeviceType, uuid: deviceType.uuid }).then(() => {
          reset(submittedFormDeviceType);
          setSelectedValues(DEFAULT_SELECTED_VALUES);
        })
      : addDeviceType({
          category,
          createdBy: userName,
          ...validDeviceType,
        }).then(() => {
          reset(DEFAULT_FORM_VALUES);
          setSelectedValues(DEFAULT_SELECTED_VALUES);
        });
    submitPromise.then(onSuccess).catch(onError);
  };

  React.useEffect(() => {
    reset(formDeviceType || DEFAULT_FORM_VALUES);
    setSelectedValues(DEFAULT_SELECTED_VALUES);
  }, [reset, formDeviceType]);

  return (
    <>
      <div className={classes.header}>
        <SectionHeader
          Icon={isEditing ? EditIcon : AddIcon}
          title={isEditing ? 'Edit device type' : 'New device type'}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <DeviceTypeApproval deviceType={deviceType} />
      </div>
      <form onSubmit={handleSubmit(submit)} className={classes.form}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: 'Required',
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              required
              label="Device type name"
              error={!!errors.name}
              helperText={errors && errors.name && errors.name.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          name="channels"
          control={control}
          defaultValue={[]}
          render={({ onChange, value }) => (
            <ChipList
              options={channelOptions}
              onChange={onChange}
              values={value}
              getOptionValue={(v) => v.name}
              getOptionLabel={(v) => v.name}
              getOptionSelected={(o1, o2) => o1.name === o2.name}
              getChipIcon={(v) => <ChannelIcon mediaType={v.mediaType} />}
              ChipProps={{ disabled: isEditing }}
              SelectButtonGroupProps={{
                value: selectedValues.channel,
                onChange: (v) => setSelectedValues((prev) => ({ ...prev, channel: v })),
                selectProps: { disabled: isEditing || channelsContainsVideoWithAudio },
                buttonProps: {
                  disabled: isEditing || channelsContainsVideoWithAudio,
                  variant: 'outlined',
                  color: 'primary',
                },
                isDisabled: (o) =>
                  !!(
                    channels?.find((ch) => ch.name === o.name) ||
                    (channels?.length > 0 && o.mediaType === 'videoAndAudio')
                  ),
                placeholder: 'Channels',
                renderSelectedOption: renderChannel,
                renderOption: (o) => (
                  <RemovableOption onRemove={() => removeChannelOption(o.uuid)}>
                    {renderChannel(o)}
                  </RemovableOption>
                ),
                children: (
                  <NewDeviceChannel
                    disabled={(newCh) =>
                      (newCh.name && newCh.name.length === 0) ||
                      channelOptions.find((ch) => ch.name === newCh.name) !== undefined
                    }
                    onSubmit={(ch) => addChannelOption(ch)}
                  />
                ),
              }}
            />
          )}
        />
        <Controller
          name="sensorTemplates"
          control={control}
          defaultValue={[]}
          render={({ onChange, value }) => (
            <ChipList
              options={categorySensorTemplates}
              onChange={onChange}
              values={value}
              getOptionValue={(o) => o.name}
              getOptionLabel={(o) => o.name}
              getOptionSelected={(v, o) => v.name === o.name}
              ChipProps={{ disabled: isEditing }}
              SelectButtonGroupProps={{
                value: selectedValues.sensorTemplate,
                onChange: (v) => setSelectedValues((prev) => ({ ...prev, sensorTemplate: v })),
                selectProps: { disabled: isEditing },
                buttonProps: { disabled: isEditing, variant: 'outlined', color: 'primary' },
                placeholder: 'Sensors',
                renderSelectedOption: (o) => o.name,
              }}
            />
          )}
        />
        <Controller
          name="positions"
          control={control}
          defaultValue={[]}
          render={({ onChange, value }) => (
            <ChipList
              options={positionOptions}
              onChange={onChange}
              values={value}
              getOptionValue={(o) => o.value}
              getOptionLabel={(o) => o.value}
              getOptionSelected={(v, o) => v.value === o.value}
              SelectButtonGroupProps={{
                value: selectedValues.position,
                onChange: (v) => setSelectedValues((prev) => ({ ...prev, position: v })),
                placeholder: 'Positions',
                renderSelectedOption: (o) => o.value,
                renderOption: ({ value: position, uuid }) => (
                  <RemovableOption onRemove={() => removePositionOption(uuid)}>
                    {position}
                  </RemovableOption>
                ),
                children: (
                  <NewPosition
                    disabled={(newPos) =>
                      newPos.length === 0 ||
                      positionOptions.find((p) => p.value === newPos) !== undefined
                    }
                    onSubmit={(position) => addPositionOption(position)}
                  />
                ),
              }}
            />
          )}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue
          render={({ onChange, value }) => (
            <FormControlLabel
              control={<Switch color="primary" checked={value} onChange={(_, v) => onChange(v)} />}
              label="Active"
            />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          {isEditing && <Button onClick={onCancel}>cancel</Button>}
          <Button
            disableElevation
            disabled={
              !formState.isValid ||
              !formState.isDirty ||
              isLoadingUpdateDeviceType ||
              isLoadingAddDeviceType
            }
            variant="contained"
            color="primary"
            type="submit"
          >
            {isEditing ? 'Save' : 'Add device type'}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default withStyles(styles)(DeviceTypesEditor);
