/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { utils as api, user as UserApi, noauth as NoAuthApi } from '@vidispine/vdt-api';
import { useApi, VidispineApi } from '@vidispine/vdt-react';

import { useCookies } from 'react-cookie';
import { useHistory, Redirect, Route, useLocation } from 'react-router-dom';
import { parseUser } from '../utils/user';

const VIDISPINE_TOKEN = 'VIDISPINE-TOKEN';
const VIDISPINE_USERNAME = 'VIDISPINE-USERNAME';
const VIDISPINE_SERVER_URL = 'VIDISPINE-SERVER-URL';
const VIDISPINE_REMEMBER_ME = 'VIDISPINE-REMEMBER-ME';

export const AuthContext = React.createContext({
  serverUrl: null,
  token: null,
  user: null,
  userName: null,
  onLogin: () => null,
  onLogout: () => null,
});

const handleGetToken = async ({ userName, password, serverUrl }) => {
  if (!NoAuthApi.isOnline()) {
    throw Error('Server is offline');
  }
  const queryParams = { seconds: 2592000, autoRefresh: 'true' };
  const headers = { password, username: userName };
  if (serverUrl) {
    api.defaultClient.defaults.baseURL = serverUrl;
  }
  try {
    const { data: token } = await UserApi.getToken({ userName, queryParams, headers });
    return token;
  } catch (error) {
    // TODO: Check if invalid CORS also
    throw Error('Incorrect username or password');
  }
};

export const AuthProvider = ({
  cookieOptions = {
    maxAge: 604800,
    path: '/',
  },
  adminGroups = ['__special_all'],
  LoginComponent,
  LoginProps = {},
  onGetToken = handleGetToken,
  onSuccess,
  onError,
  children,
}) => {
  const { data: rawUser, request: getUser } = useApi(UserApi.getUser);

  const history = useHistory();

  const location = useLocation();
  const [redirectRoute, setRedirectRoute] = useState(null);

  React.useEffect(() => {
    // only set once so it will not revert to /login
    if (!redirectRoute && location.pathname !== '/') setRedirectRoute(location.pathname);
  }, [redirectRoute, location.pathname]);

  const [cookies, setCookie, removeCookie] = useCookies([
    VIDISPINE_TOKEN,
    VIDISPINE_USERNAME,
    VIDISPINE_SERVER_URL,
    VIDISPINE_REMEMBER_ME,
  ]);
  const {
    [VIDISPINE_TOKEN]: token,
    [VIDISPINE_USERNAME]: userName,
    [VIDISPINE_SERVER_URL]: serverUrl,
  } = cookies;
  const rememberMe = cookies[VIDISPINE_REMEMBER_ME] === 'true';
  const onLogin = (values) => {
    let submissionError;
    try {
      onGetToken(values)
        .then((newToken) => {
          const {
            userName: newUserName,
            serverUrl: newServerUrl,
            rememberMe: newRememberMe,
          } = values;
          if (newUserName) setCookie(VIDISPINE_USERNAME, newUserName, cookieOptions);
          if (newServerUrl) setCookie(VIDISPINE_SERVER_URL, newServerUrl, cookieOptions);
          if (newRememberMe) setCookie(VIDISPINE_REMEMBER_ME, newRememberMe, cookieOptions);
          if (newToken) setCookie(VIDISPINE_TOKEN, newToken, cookieOptions);
          if (onSuccess) onSuccess(values);
        })
        .catch((error) => {
          submissionError = error;
          if (onError) return onError(submissionError);
          throw submissionError;
        });
    } catch (error) {
      submissionError = error;
      if (onError) return onError(submissionError);
    }
    return submissionError;
  };
  const onLogout = () => {
    removeCookie(VIDISPINE_TOKEN, cookieOptions);
    if (!rememberMe) {
      removeCookie(VIDISPINE_USERNAME, cookieOptions);
      removeCookie(VIDISPINE_SERVER_URL, cookieOptions);
      removeCookie(VIDISPINE_REMEMBER_ME, cookieOptions);
    }
    setRedirectRoute(null);
    history.push('/login');
  };

  React.useEffect(() => {
    const interceptor = api.defaultClient.interceptors.response.use(
      (response) => response,
      (error) => {
        // https://github.com/axios/axios/issues/838
        // vidispine doesn't seem to set the right CORS headers when auth fails,
        // so response === undefined is interpreted as an invalid token
        if (error.response === undefined) {
          removeCookie(VIDISPINE_TOKEN, cookieOptions);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      api.defaultClient.interceptors.response.eject(interceptor);
    };
  }, [cookieOptions, removeCookie]);

  React.useEffect(() => {
    if (token) {
      getUser({ userName });
    }
  }, [userName, getUser, token]);

  const user = React.useMemo(() => rawUser && parseUser(rawUser), [rawUser]);

  const isAdmin = React.useMemo(() => {
    const { groupList: { group: groups = [] } = {} } = user || {};
    return groups.some(({ groupName }) => adminGroups.includes(groupName));
  }, [adminGroups, user]);

  return (
    <>
      {token ? (
        <>
          {redirectRoute && <Redirect exact from="*" push to={redirectRoute} />}
          <VidispineApi token={token} username={userName} serverUrl={serverUrl}>
            <AuthContext.Provider
              value={{
                serverUrl,
                token,
                user,
                userName,
                isAdmin,
                onLogin,
                onLogout,
              }}
            >
              {children}
            </AuthContext.Provider>
          </VidispineApi>
        </>
      ) : (
        <>
          <Redirect exact from="*" push to="/login" />
          <Route path="/login">
            <LoginComponent
              onLogin={onLogin}
              userName={userName}
              serverUrl={serverUrl}
              rememberMe={rememberMe}
              {...LoginProps}
            />
          </Route>
        </>
      )}
    </>
  );
};
