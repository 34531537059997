import React from 'react';

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { playlist as playlistLib } from 'mediadb-lib';
import { PlaylistContext } from '../../contexts/PlaylistContext';

import SectionHeader from '../SectionHeader';
import { useUpdatePlaylistProperties, useCreatePlaylist } from '../../hooks/playlist';

const styles = () => ({});

function PlaylistDialog({
  onClose,
  onSuccess = () => {},
  onError = () => {},
  playlist,
  open,
  isEditing,
}) {
  const { setSelectedPlaylist } = React.useContext(PlaylistContext);
  const { control, handleSubmit, errors, formState, setError } = useForm({
    defaultValues: isEditing ? playlist : [],
    mode: 'all',
  });
  const { mutateAsync: updatePlaylistProperties } = useUpdatePlaylistProperties();
  const { mutateAsync: createPlaylist } = useCreatePlaylist();

  const submit = async ({ name, comment }) => {
    try {
      if (
        await playlistLib.checkPlaylistNameInUse({
          playlistName: name,
          playlistId: isEditing ? playlist?.id : undefined,
        })
      ) {
        setError('name', { type: 'manual', message: 'Name already in use' });
        return;
      }
      await (isEditing
        ? updatePlaylistProperties({
            playlistId: playlist.id,
            playlistName: formState.dirtyFields.name ? name : undefined,
            playlistComment: formState.dirtyFields.comment ? comment : undefined,
          })
        : createPlaylist({
            playlistName: name,
            playlistComment: formState.dirtyFields.comment ? comment : undefined,
          }).then(({ data }) => {
            setSelectedPlaylist(data);
          }));
      onSuccess();
    } catch (e) {
      onError(e);
    }
  };
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <SectionHeader
          title={isEditing ? `Edit ${playlist.name}` : 'New Playlist'}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Box
            display="grid"
            gridAutoRows="min-content"
            gridGap={24}
            alignItems="start"
            minHeight={180}
          >
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: 'Required',
              }}
              render={({ onChange, onBlur, value }) => (
                <TextField
                  required
                  label="Name"
                  error={!!errors.name}
                  helperText={errors && errors.name && errors.name.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <TextField label="Comment" onChange={onChange} onBlur={onBlur} value={value} />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary" disabled={!formState.isValid || !formState.isDirty}>
            {isEditing ? 'Save' : 'Create playlist'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default withStyles(styles)(PlaylistDialog);
