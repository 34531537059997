import React from 'react';

import { withStyles, Checkbox, FormControlLabel, Typography, Switch } from '@material-ui/core';
import { intersectionWith, differenceWith, unionWith, isEqual } from 'lodash';

import InterlocutorTree from './InterlocutorTree';

const styles = () => ({
  treeView: {
    '& .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-root:hover > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'unset',
    },
  },
});

const toggleChannels = (prev, channels, checked) =>
  (checked ? differenceWith : unionWith)(prev, channels, isEqual);

export function InterlocutorCheckbox({
  interlocutor,
  channels,
  setChannels,
  disabled = false,
  getChannelKey,
}) {
  const { name, devices } = interlocutor;
  const childChannels = devices.flatMap((d) => d.channels);
  const childChannelKeys = childChannels.map(getChannelKey);
  const checkedChannels = intersectionWith(childChannelKeys, channels, isEqual);
  const checked = childChannelKeys.length === checkedChannels.length;
  const indeterminate = !checked && checkedChannels.length > 0;
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
          color="primary"
          onChange={() => setChannels((prev) => toggleChannels(prev, childChannelKeys, checked))}
          onClick={(e) => e.stopPropagation()}
          name={name}
        />
      }
      label={<Typography variant="subtitle2">{name}</Typography>}
    />
  );
}

export function DeviceCheckbox({
  device,
  channels,
  setChannels,
  showDevicePosition,
  disabled = false,
  getChannelKey,
}) {
  const {
    position,
    deviceTemplate: {
      name,
      deviceType: { name: typeName },
    },
    channels: deviceChannels = [],
    sensors,
  } = device;
  const childChannels = [
    ...deviceChannels,
    ...sensors.flatMap(({ channels: sensorChannels = [] }) => sensorChannels),
  ];
  const childChannelKeys = childChannels.map(getChannelKey);
  const checkedChannels = intersectionWith(childChannelKeys, channels, isEqual);
  const checked = childChannelKeys.length === checkedChannels.length;
  const indeterminate = !checked && checkedChannels.length > 0;
  const displayName = `${name}${
    showDevicePosition && position ? ` - ${position} ` : ' '
  }(${typeName})`;
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
          color="primary"
          onChange={() => setChannels((prev) => toggleChannels(prev, childChannelKeys, checked))}
          onClick={(e) => e.stopPropagation()}
          name={displayName}
        />
      }
      label={<Typography variant="subtitle2">{displayName}</Typography>}
    />
  );
}

export function ChannelCheckbox({
  channel,
  channels,
  setChannels,
  disabled = false,
  getChannelKey,
}) {
  const { name } = channel;
  const channelArr = [getChannelKey(channel)];
  const checked = intersectionWith(channelArr, channels, isEqual).length === 1;
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          disabled={disabled}
          checked={checked}
          color="primary"
          onChange={() => setChannels((prev) => toggleChannels(prev, channelArr, checked))}
          name={name}
        />
      }
      label={<Typography variant="body2">{name}</Typography>}
    />
  );
}

export function SensorCheckbox({ sensor, channels, setChannels, disabled = false, getChannelKey }) {
  const {
    sensorTemplate: { name, type },
    channels: childChannels,
  } = sensor;
  const childChannelKeys = childChannels.map(getChannelKey);
  const checkedChannels = intersectionWith(childChannelKeys, channels, isEqual);
  const checked = childChannelKeys.length === checkedChannels.length;
  const indeterminate = !checked && checkedChannels.length > 0;
  const displayName = `${name} (${type})`;
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
          color="primary"
          onChange={() => setChannels((prev) => toggleChannels(prev, childChannelKeys, checked))}
          onClick={(e) => e.stopPropagation()}
          name={displayName}
        />
      }
      label={<Typography variant="subtitle2">{displayName}</Typography>}
    />
  );
}

export const getTechnicalTracksWithLabel = (technicalTrackInfos, technicalLabelTracks) => {
  return technicalTrackInfos.map((trackInfo) => {
    return {
      ...technicalLabelTracks.find((track) => track.name === trackInfo.name),
      ...trackInfo,
    };
  });
};

function ExtractChannels({ onChange = () => {}, disabled, extract }) {
  const [channels, setChannels] = React.useState(extract.exportChannels);
  const [exportTechnicalLabels, setExportTechnicalLabels] = React.useState(
    extract.exportTechnicalLabels,
  );
  React.useEffect(() => {
    onChange(channels, exportTechnicalLabels);
  }, [channels, exportTechnicalLabels, onChange]);

  const interlocutors = React.useMemo(() => {
    return extract.interlocutors.filter((int) => int.devices.length > 0);
  }, [extract]);

  return (
    <>
      <InterlocutorTree
        interlocutors={interlocutors}
        InterlocutorComponent={InterlocutorCheckbox}
        DeviceComponent={DeviceCheckbox}
        ChannelComponent={ChannelCheckbox}
        SensorComponent={SensorCheckbox}
        channels={channels}
        setChannels={setChannels}
        disabled={disabled}
        getChannelKey={(ch) => ch.uuid}
        showDevicePosition
      />
      <FormControlLabel
        control={
          <Switch
            checked={exportTechnicalLabels}
            color="primary"
            onChange={() => setExportTechnicalLabels((prev) => !prev)}
          />
        }
        label="Export technical labels"
        disabled={disabled}
      />
    </>
  );
}

export default withStyles(styles)(ExtractChannels);
