import React from 'react';

export const PlaylistContext = React.createContext({
  selectedPlaylist: undefined,
  setSelectedPlaylist: () => null,
});

export const PlaylistContextProvider = ({ children }) => {
  const [selectedPlaylist, setSelectedPlaylist] = React.useState(null);
  return (
    <PlaylistContext.Provider
      value={{
        selectedPlaylist,
        setSelectedPlaylist,
      }}
    >
      {children}
    </PlaylistContext.Provider>
  );
};
