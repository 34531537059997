import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { auditlog } from 'mediadb-lib';
import { downloadCollectionAsZip, downloadPackageInformation } from '../../../utils/utils';
import useResizeObserver from '../../../hooks/useResizeObserver';
import launchAV from '../../../utils/launchAV';

import PreviewDialog from '../../../dialogs/PreviewDialog';
import PackageGrid from './PackageGrid';

const { MEDIADB_EVENT } = auditlog;

const styles = () => ({
  root: {},
});

const PackageList = ({ classes, className, isFetchingExtracts, packages }) => {
  const [previewDialogOpen, setPreviewDialogOpen] = React.useState(false);
  const [previewPkg, setPreviewPkg] = React.useState();
  const [ref, { contentRect: { width } = {} }] = useResizeObserver();
  const history = useHistory();
  const { pathname } = useLocation();

  const handlePreviewClose = () => {
    setPreviewDialogOpen(false);
    setPreviewPkg();
  };

  const openPreview = (pkg) => {
    setPreviewDialogOpen(true);
    setPreviewPkg(pkg);
  };

  const handleActionClick = ({ action, package: pkg }) => {
    const { packageId, name } = pkg;
    switch (action) {
      case 'open': {
        history.push({
          pathname: `/package/${packageId}/`,
          state: { prevPath: pathname },
        });
        break;
      }
      case 'openTab': {
        window.open(`/package/${packageId}/`, '_blank');
        break;
      }
      case 'downloadJSON': {
        downloadPackageInformation(packageId, `${name}.json`);
        break;
      }
      case 'download': {
        auditlog.addMediaDBLogEntry(MEDIADB_EVENT.DOWNLOAD('package', { packageId }));
        downloadCollectionAsZip(packageId, `${name}.zip`);
        break;
      }
      case 'preview': {
        openPreview(pkg);
        break;
      }
      case 'launchAV': {
        launchAV(pkg);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(action);
      }
    }
  };

  return (
    <div ref={ref} className={clsx(classes.root, className)}>
      <PackageGrid
        packages={packages}
        PackageCardProps={{
          isFetchingExtracts,
          onActionClick: handleActionClick,
          hideThumbnail: width < 680,
        }}
        GridContainerProps={{
          spacing: 4,
        }}
        GridItemProps={{
          xs: 12,
        }}
      />
      <PreviewDialog
        open={previewDialogOpen}
        onClose={handlePreviewClose}
        pkg={previewPkg}
        onActionClick={handleActionClick}
      />
    </div>
  );
};

export default withStyles(styles)(PackageList);
