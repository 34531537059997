import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InterlocutorIcon from '@material-ui/icons/RecordVoiceOver';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles, emphasize } from '@material-ui/core';

const styles = (theme) => ({
  personContainer: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.05),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '2px',
  },
  titleHeading: {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'min-content auto',
    gridGap: theme.spacing(1),
  },
  formSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

function PersonComponent({ classes, person, onDelete, children }) {
  const { name } = person;

  const rootRef = React.useRef();

  // Focus person on mount
  React.useEffect(() => {
    rootRef.current.scrollIntoView(false);
  }, []);

  return (
    <Box ref={rootRef} className={classes.personContainer}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.titleHeading}>
          <Icon>
            <InterlocutorIcon />
          </Icon>
          <Typography variant="subtitle2">{name}</Typography>
        </Box>
        <IconButton onClick={() => onDelete(person)}>
          <DeleteIcon />
        </IconButton>
      </Box>
      {children}
    </Box>
  );
}

export default withStyles(styles)(PersonComponent);
