import React from 'react';
import {
  Button,
  IconButton,
  Tooltip,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';

import LaunchIcon from '@material-ui/icons/Launch';
import { VideoLibrary as PackageIcon } from '@material-ui/icons';
import SectionHeader from '../components/SectionHeader';
import Player from '../components/Player';
import useDeviceProxy from '../hooks/useDeviceProxy';

const styles = () => ({
  root: {
    '& .MuiDialogTitle-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiDialogContent-root': {
      minHeight: '50vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

function PreviewDialog({ classes, pkg, open, onClose, onActionClick }) {
  const hasPreviewDevice = !!(pkg && pkg.preview);
  const { name: packageName, preview } = pkg || {};
  const { data: deviceProxy } = useDeviceProxy({ id: preview });
  return (
    <Dialog className={classes.root} maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <SectionHeader
          Icon={PackageIcon}
          title={packageName}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
        <Tooltip title="Open package">
          <IconButton onClick={() => onActionClick({ action: 'open', package: pkg })}>
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        {hasPreviewDevice ? (
          deviceProxy && <Player source={{ src: deviceProxy.uri, type: deviceProxy.mimeType }} />
        ) : (
          <Typography>No preview device found</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(PreviewDialog);
