import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import {
  useTechnicalLabelTracks,
  useRemoveTechnicalLabelTrack,
  useIsTechnicalLabelTrackUsedbyPackage,
} from '../../../hooks/label';
import { useSnackbar } from '../../../contexts/SnackbarContext';

const styles = (theme) => ({
  root: {},
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
});

const TechnicalLabelTrackRemoval = ({ classes, selectedTrackName }) => {
  const { showAlert } = useSnackbar();
  const { mutateAsync: removeTechnicalLabelTrack } = useRemoveTechnicalLabelTrack();
  const { data: technicalLabelTracks = [] } = useTechnicalLabelTracks();
  const technicalLabelTrack = React.useMemo(
    () => technicalLabelTracks.find((track) => track.name === selectedTrackName),
    [technicalLabelTracks, selectedTrackName],
  );
  const { data: isUsedByPackage, status } = useIsTechnicalLabelTrackUsedbyPackage({
    technicalLabelTrack,
  });

  const handleRemoveClick = async () => {
    try {
      await removeTechnicalLabelTrack(technicalLabelTrack);
    } catch (error) {
      showAlert({ severity: 'error', message: error.message });
    }
  };
  return (
    <>
      {status === 'success' && (
        <Tooltip
          title={isUsedByPackage ? 'Cannot delete technical label track used by packages' : ''}
        >
          <span>
            <Button
              className={classes.deleteButton}
              disabled={isUsedByPackage}
              onClick={handleRemoveClick}
            >
              Remove
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default withStyles(styles)(TechnicalLabelTrackRemoval);
