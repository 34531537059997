export default class MediaDBError extends Error {
  constructor(message, opts) {
    const { status, error } = opts || {};
    const extendedMessage = `${message}${
      error instanceof MediaDBError ? `: ${error.message}` : ''
    }`;
    super(extendedMessage);
    this.status = status;
    this.sourceError = error;
    this.name = 'MediaDBError';
  }
}
