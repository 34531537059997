import React from 'react';

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { intersectionBy as _intersectionBy, unionBy as _unionBy } from 'lodash';
import SectionHeader from '../SectionHeader';
import { ChannelCheckbox, DeviceCheckbox, SensorCheckbox } from './ExtractChannels';
import InterlocutorTree from './InterlocutorTree';
import { usePlaylist, useUpdatePlaylistProperties } from '../../hooks/playlist';

const styles = () => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      minHeight: 'calc(100% - 64px)',
    },
  },
});

function getDevices(playlist, common) {
  const { extracts } = playlist;
  const devices = extracts.map((e) => e.interlocutors.flatMap((i) => i.devices));
  return common
    ? _intersectionBy(...devices, 'deviceTemplate.name')
    : _unionBy(...devices, 'deviceTemplate.name');
}

function getUuidToChannelMap(devices) {
  const channels = devices.flatMap((d) =>
    d.channels.map((ch) => ({ ...ch, deviceName: d.deviceTemplate.name })),
  );
  const tmp = channels.reduce(
    (acc, ch) => ({
      ...acc,
      [ch.uuid]: {
        channelName: ch.name,
        deviceName: ch.deviceName,
      },
    }),
    {},
  );
  const sensorChannels = devices.flatMap((d) =>
    d.sensors.flatMap((s) =>
      s.channels.map((ch) => ({ ...ch, sensorName: s.sensorTemplate.name })),
    ),
  );
  return sensorChannels.reduce(
    (acc, ch) => ({
      ...acc,
      [ch.uuid]: {
        channelName: ch.name,
        sensorName: ch.sensorName,
      },
    }),
    tmp,
  );
}

function PlaylistChannels({ onChange = () => {}, playlist }) {
  const [channels, setChannels] = React.useState(playlist.exportChannels);
  const [showCommon, setShowCommon] = React.useState(playlist.commonChannels);
  const [exportTechnicalLabels, setExportTechnicalLabels] = React.useState(
    playlist.exportTechnicalLabels,
  );
  React.useEffect(() => {
    onChange(channels, showCommon, exportTechnicalLabels);
  }, [channels, showCommon, exportTechnicalLabels, onChange]);

  const devices = React.useMemo(() => getDevices(playlist, showCommon), [playlist, showCommon]);
  const uuidToChannel = React.useMemo(() => getUuidToChannelMap(devices), [devices]);
  const toggleCommonChannels = () => {
    setChannels([]);
    setShowCommon(!showCommon);
  };
  return (
    <>
      <FormControl>
        <FormControlLabel
          value
          control={<Switch checked={showCommon} onChange={toggleCommonChannels} color="primary" />}
          label="Only show channels in common"
        />
      </FormControl>
      {devices.length > 0 ? (
        <DialogContentText>Select channels to export for this playlist</DialogContentText>
      ) : (
        <DialogContentText>
          {showCommon
            ? 'The extracts in this playlist have no channels in common'
            : 'The extracts in this playlist have no channels to export'}
        </DialogContentText>
      )}
      <InterlocutorTree
        devices={devices}
        DeviceComponent={DeviceCheckbox}
        ChannelComponent={ChannelCheckbox}
        SensorComponent={SensorCheckbox}
        channels={channels}
        setChannels={setChannels}
        getChannelKey={(ch) => uuidToChannel[ch.uuid]}
      />
      <FormControlLabel
        control={
          <Switch
            checked={exportTechnicalLabels}
            color="primary"
            onChange={() => setExportTechnicalLabels((prev) => !prev)}
          />
        }
        label="Export technical labels"
      />
    </>
  );
}

function PlaylistExportProperties({
  classes,
  onClose,
  onSuccess = () => {},
  onError = () => {},
  selectedPlaylist,
}) {
  const { data: playlist } = usePlaylist(selectedPlaylist.id);
  const [channels, setChannels] = React.useState([]);
  const [exportTechnicalLabels, setExportTechnicalLabels] = React.useState();
  const [commonChannels, setCommonChannels] = React.useState();
  const { mutateAsync: updatePlaylistProperties } = useUpdatePlaylistProperties();
  const handleSave = () => {
    updatePlaylistProperties({
      playlistId: selectedPlaylist.id,
      channels,
      commonChannels,
      exportTechnicalLabels,
    })
      .then(onSuccess)
      .catch(onError);
  };
  const handleChange = (c, common, playlistExportTechnicalLabels) => {
    setChannels(c);
    setCommonChannels(common);
    setExportTechnicalLabels(playlistExportTechnicalLabels);
  };
  return (
    <Dialog open={!!playlist} maxWidth="sm" fullWidth className={classes.root}>
      <DialogTitle>
        <SectionHeader
          title={selectedPlaylist.name}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>
        {playlist && <PlaylistChannels playlist={playlist} onChange={handleChange} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(PlaylistExportProperties);
