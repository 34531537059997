import { metadata } from 'mediadb-lib';
import React from 'react';
import useStateWithBrowserStorage from '../../../hooks/useStateWithBrowserStorage';

import GroupedMultiselectField from './GroupedMultiselectField';

const FilterSelect = ({ filterFields, onChange }) => {
  const [selectedFilterNames, setSelectedFilterNames] = useStateWithBrowserStorage([], {
    key: 'SEARCH_FILTER_SELECTIONS',
  });

  const handleChange = ({ value: newSelectedFilterNames }) => {
    setSelectedFilterNames(newSelectedFilterNames);
  };

  React.useEffect(() => {
    const selectedFilterFields = filterFields?.filter(({ field }) =>
      selectedFilterNames.includes(field),
    );
    onChange(selectedFilterFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterNames]);

  const filterFieldOptions = React.useMemo(() => {
    const packageFields = filterFields?.filter(({ field }) =>
      field.startsWith(metadata.CUSTOM_GROUPS.PACKAGE.prefix),
    );
    const personFields = filterFields?.filter(({ field }) =>
      field.startsWith(metadata.CUSTOM_GROUPS.PERSON.prefix),
    );
    const deviceFields = filterFields?.filter(({ field }) =>
      field.startsWith(metadata.CUSTOM_GROUPS.DEVICE.prefix),
    );
    const sensorFields = filterFields?.filter(({ field }) =>
      field.startsWith(metadata.CUSTOM_GROUPS.SENSOR.prefix),
    );
    const builtInFields = filterFields?.filter(
      ({ field }) =>
        !Object.values(metadata.CUSTOM_GROUPS).some(({ prefix }) => field.startsWith(prefix)),
    );

    const mapFieldsToOptions = (fields) =>
      fields.map(({ field, label } = {}) => ({
        name: field,
        label,
      }));

    return [
      {
        name: 'general',
        label: 'General',
        options: mapFieldsToOptions(builtInFields),
      },
      {
        name: 'package',
        label: 'Package',
        options: mapFieldsToOptions(packageFields),
      },
      {
        name: 'person',
        label: 'Person',
        options: mapFieldsToOptions(personFields),
      },
      {
        name: 'device',
        label: 'Device',
        options: mapFieldsToOptions(deviceFields),
      },
      {
        name: 'sensor',
        label: 'Sensor',
        options: mapFieldsToOptions(sensorFields),
      },
    ];
  }, [filterFields]);

  return (
    <GroupedMultiselectField
      label="Filters"
      value={selectedFilterNames}
      onChange={handleChange}
      groupedOptions={filterFieldOptions}
    />
  );
};

export default FilterSelect;
