import React from 'react';
import { VideoPlayer } from '@vidispine/vdt-videojs-react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';

const styles = (theme) => ({
  player: {
    '& .data-vjs-player': {
      maxWidth: '100%',
    },
    '& .vjs-volume-tooltip': {
      display: 'none',
    },
    '& .vjs-control-bar': {
      background: theme.palette.background.paper,
      color: 'black',
    },
    '& .vjs-volume-level': {
      backgroundColor: 'black',
    },
    '& .vjs-progress-holder': {
      margin: '0px !important',
    },
    '& .vjs-big-play-button': {
      color: 'black',
    },
  },
  audioPlayer: {
    '& .data-vjs-player': {
      width: '100%',
    },
    '& .video-js .vjs-fullscreen-control': {
      display: 'none',
    },
    '& .video-js .vjs-fullscreen-control, .vjs-tech, .vjs-big-play-button': {
      display: 'none',
    },
    '& .video-js': {
      marginBottom: '0px !important',
    },
  },
});

const ALLOWED_MIME_TYPES = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/x-icon',
  'image/png',
  'image/svg+xml',
  'audio/mp4',
  'audio/mpeg',
  'audio/x-aac',
  'audio/aac',
  'audio/x-wav',
  'audio/wav',
  'audio/accp',
  'audio/ogg',
  'audio/webm',
  'audio/x-flac',
  'video/mp4',
];

const PlayerError = ({ msg }) => (
  <Box
    display="flex"
    style={{
      marginTop: '15px',
      marginBottom: '56px',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box mr={1} display="flex" alignItems="center">
      <ErrorIcon color="secondary" />
    </Box>
    <Typography style={{ maxWidth: '80%' }} variant="body1">
      {msg}
    </Typography>
  </Box>
);

const ImageViewer = ({ src }) => {
  return <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={src} alt="" />;
};

const isAudio = (mimetype) => mimetype && mimetype.startsWith('audio');

const isImage = (mimetype) => mimetype && mimetype.startsWith('image');

function Player({ classes, source }) {
  const [error, setError] = React.useState();

  React.useEffect(() => {
    setError();
  }, [source.src]);

  return source && ALLOWED_MIME_TYPES.includes(source.type) && !error ? (
    <Box
      display="flex"
      style={{
        marginTop: isAudio(source.type) ? '15px' : '',
        marginBottom: isAudio(source.type) ? '80px' : '',
      }}
      className={clsx(classes.player, {
        [classes.audioPlayer]: isAudio(source.type),
      })}
    >
      {isImage(source.type) ? (
        <ImageViewer src={source.src} />
      ) : (
        <VideoPlayer
          setPlayer={(plr) => plr.videojs.on('error', () => setError(plr.videojs.error()))}
          controls
          sources={[source]}
          controlsBelowPlayer
        />
      )}
    </Box>
  ) : (
    <PlayerError msg={error ? error.message : 'Media format not supported'} />
  );
}

export default withStyles(styles)(Player);
