import React from 'react';
import PackageIcon from '@material-ui/icons/VideoLibrary';
import Avatar from '@material-ui/core/Avatar';

import { item as ItemApi } from '@vidispine/vdt-api';
import { formatTimeCodeText } from '@vidispine/vdt-js';
import { orderBy as _orderBy } from 'lodash';
import MediaCardFullWidth from '../../../vdt/MediaCardFullWidth/MediaCardFullWidth';

import PackageCardContent from './PackageCardContent';
import PackageCardExpandedContent from './PackageCardExpandedContent';
import PackageCardActions from './PackageCardActions';
import PackageCardHeader from './PackageCardHeader';

import { VIDISPINE_URL } from '../../../consts/app';

const CollectionAvatar = ({ image: images, onActionClick, package: pkg }) => {
  const img = images && images[0];
  return (
    <Avatar
      onClick={() =>
        onActionClick({
          action: 'preview',
          package: pkg,
        })
      }
      src={img && `${VIDISPINE_URL.replace(/\/$/, '')}${img}`}
      variant="square"
      style={{ width: '180px', height: '100%', cursor: 'pointer' }}
    >
      <PackageIcon />
    </Avatar>
  );
};
const getItem = (itemId) =>
  ItemApi.getItem({
    itemId,
    queryParams: {
      content: ['thumbnail'],
      methodType: 'AUTO',
      'noauth-url': true,
    },
  });
export default function PackageCard({
  package: pkg,
  isFetchingExtracts,
  onActionClick,
  hideThumbnail,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [thumbnailItem, setThumbnailItem] = React.useState({});
  const {
    owner,
    created,
    lastModified,
    duration: packageDuration,
    status: packageStatus,
    name: packageName,
    thumbnails,
    representativeItem,
  } = pkg;
  React.useEffect(() => {
    if (representativeItem) {
      getItem(representativeItem).then(({ data: itemType }) => {
        setThumbnailItem(itemType);
      });
    }
  }, [representativeItem]);
  const displayMetadata = [
    { name: 'Owner', value: owner, type: 'string' },
    { name: 'Status', value: packageStatus, type: 'string' },
    { name: 'Created', value: created, type: 'date' },
    { name: 'Modified', value: lastModified, type: 'date' },
    { name: 'Duration', value: packageDuration, type: 'duration' },
  ];

  const [sortState, setSortState] = React.useState({ column: 'timespan', ascending: true });
  const { extracts = [] } = pkg;

  const extendedExtracts = extracts.map((extract) => {
    return {
      startSeconds: formatTimeCodeText(extract.start).toSeconds(),
      endSeconds: formatTimeCodeText(extract.end).toSeconds(),
      ...extract,
    };
  });

  const sortedExtracts = React.useMemo(() => {
    const fieldNameArray = { duration: 'duration', timespan: 'startSeconds', coverage: 'coverage' };
    return _orderBy(
      extendedExtracts,
      fieldNameArray[sortState.column],
      sortState.ascending ? 'asc' : 'desc',
    );
  }, [sortState, extendedExtracts]);

  return (
    <MediaCardFullWidth
      package={pkg}
      onExpand={() => setExpanded((isExpanded) => !isExpanded)}
      image={thumbnails && thumbnails.split(', ')}
      ThumbnailComponent={hideThumbnail ? null : CollectionAvatar}
      ThumbnailProps={{
        onActionClick,
        package: pkg,
      }}
      ContentComponent={PackageCardContent}
      ContentProps={{
        metadata: displayMetadata,
        extracts: sortedExtracts,
        isFetchingExtracts,
        pkg,
      }}
      HeaderComponent={PackageCardHeader}
      HeaderProps={{
        title: packageName,
      }}
      ActionsComponent={PackageCardActions}
      ActionsProps={{
        onActionClick,
      }}
      expanded={expanded}
      ExpandedContentComponent={!isFetchingExtracts && PackageCardExpandedContent}
      ExpandedContentProps={{
        thumbnailItem,
        extracts: sortedExtracts,
        pkg,
      }}
      onSortClick={(columnName) => {
        setSortState((currentSortState) => ({
          column: columnName,
          ascending: currentSortState.column === columnName ? !currentSortState.ascending : true,
        }));
      }}
      sortState={sortState}
    />
  );
}
