import React from 'react';

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import SectionHeader from '../SectionHeader';
import ExtractChannels from './ExtractChannels';
import { useUpdateExtractProperties } from '../../hooks/playlist';

const styles = () => ({});

function ExtractExportProperties({
  onClose,
  onSuccess = () => {},
  onError = () => {},
  playlist,
  extract,
}) {
  const [channels, setChannels] = React.useState([]);
  const [useExtractSpecificChannels, setUseExtractSpecificChannels] = React.useState(
    extract.useExtractSpecificChannels,
  );
  const [exportTechnicalLabels, setExportTechnicalLabels] = React.useState(
    extract.exportTechnicalLabels,
  );
  const { mutateAsync: updateExtractProperties } = useUpdateExtractProperties();
  const handleSave = () => {
    updateExtractProperties({
      playlistId: playlist.id,
      extract,
      useExtractSpecificChannels,
      channels,
      exportTechnicalLabels,
    })
      .then(onSuccess)
      .catch(onError);
  };
  return (
    <Dialog open={!!extract} maxWidth="sm" fullWidth>
      <DialogTitle>
        <SectionHeader
          title={extract.name}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={useExtractSpecificChannels}
              color="primary"
              onChange={() => setUseExtractSpecificChannels((prev) => !prev)}
            />
          }
          label="Use extract-specific channels"
        />
        {extract && (
          <ExtractChannels
            disabled={!useExtractSpecificChannels}
            extract={extract}
            onChange={(extractChannels, extractExportTechnicalLabels) => {
              setChannels(extractChannels);
              setExportTechnicalLabels(extractExportTechnicalLabels);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ExtractExportProperties);
