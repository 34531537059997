import React from 'react';
import IconButton from '@material-ui/core/IconButton';
// import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import LaunchIcon from '@material-ui/icons/Launch';
import TabIcon from '@material-ui/icons/Tab';
import DownloadIcon from '@material-ui/icons/GetApp';
import { FileDownload, MovieEdit } from 'mdi-material-ui';
// import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import DisabledOnClickIconButton from '../../../components/DisabledOnClickIconButton';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
});

function PackageCardActions({ classes, onActionClick = () => null, innerProps }) {
  const { package: pkg } = innerProps;
  return (
    <div className={classes.root}>
      {/* <IconButton
        onClick={() => onActionClick({ action: 'share', id })}
      >
        <ShareIcon />
      </IconButton> */}
      <Tooltip title="Download Package Information">
        <DisabledOnClickIconButton
          duration={2500}
          onClick={() => onActionClick({ action: 'downloadJSON', package: pkg })}
        >
          <FileDownload />
        </DisabledOnClickIconButton>
      </Tooltip>
      <Tooltip title="Download Package Files">
        <DisabledOnClickIconButton
          duration={2500}
          onClick={() => onActionClick({ action: 'download', package: pkg })}
        >
          <DownloadIcon />
        </DisabledOnClickIconButton>
      </Tooltip>

      <Tooltip title="Open Package">
        <IconButton onClick={() => onActionClick({ action: 'open', package: pkg })}>
          <LaunchIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Open Package in new tab">
        <IconButton onClick={() => onActionClick({ action: 'openTab', package: pkg })}>
          <TabIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Launch in Accurate Player">
        <IconButton onClick={() => onActionClick({ action: 'launchAV', package: pkg })}>
          <MovieEdit />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Add extracts to selected playlist">
        <IconButton
          onClick={() => onActionClick({ action: 'addToPlaylist', id })}
        >
          <AddIcon />
        </IconButton>
      </Tooltip> */}
    </div>
  );
}

export default withStyles(styles)(PackageCardActions);
