import React from 'react';

export default function useCleanupEffect(f, deps = []) {
  const componentWillUnmount = React.useRef(false);

  React.useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  React.useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        f();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [f, ...deps]);
}
