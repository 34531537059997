import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core';
import '@vidispine/vdt-materialui/dist/index.css';
import CssBaseline from '@material-ui/core/CssBaseline';

import { BrowserRouter as Router } from 'react-router-dom';
import theme from './themes/theme';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

let { PUBLIC_URL: basename = '/' } = process.env;
if (basename.startsWith('http')) {
  const publicUrl = new URL(basename);
  basename = publicUrl.pathname;
}

if (basename === '') {
  basename = '/';
}

function Index() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <ErrorBoundary>
          <Router basename={basename}>
            <App basename={basename} />
          </Router>
        </ErrorBoundary>
      </CssBaseline>
    </MuiThemeProvider>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));
